import {FC, MouseEvent, useCallback, useState} from 'react';
import {
  SaveButton,
  useNotify,
  useRedirect,
  useTranslate,
  useUpdate,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import Confirm from './Confirm';

const SaveWithCommentButton: FC = ({
  resource,
  goBack = false,
  record,
  basePath,
  ...props
}: any) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const history = useHistory();
  // get values from the form
  const formState = useFormState();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [update, {loading}] = useUpdate(
    resource,
    record.id,
    formState.values,
    record,
  );
  const handleDialogClick = () => {
    setOpen(true);
  };

  const handleDialogClose = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(false);
    event.stopPropagation();
  };

  const handleSave = useCallback(
    async (_event, comment) => {
      // call dataProvider.create() manually
      // setOpen(true);
      await update(
        {
          payload: {data: {...formState.values, _edit: comment}},
        },
        {
          onSuccess: ({data: newRecord}) => {
            notify('ra.notification.updated', 'info', {
              smart_count: 1,
            });
            if (goBack) {
              history.goBack();
            } else {
              redirectTo('show', basePath, newRecord.id, newRecord);
            }
          },
          onFailure: error => {
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            );
            setOpen(false);
          },
        },
      );
    },
    [formState, history, goBack, basePath, setOpen, notify, redirectTo, update],
  );

  return (
    <>
      <SaveButton {...props} onSave={handleDialogClick} />
      <Confirm
        isOpen={open}
        loading={loading}
        title={translate('pos.title.please_confirm')}
        content={translate('pos.desc.explain_action')}
        onConfirm={handleSave}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default SaveWithCommentButton;
