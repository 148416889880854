import {InputAdornment, makeStyles, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  InputProps,
  useDataProvider,
  useListContext,
  useTranslate,
} from 'ra-core';
import {FC, useEffect, useState} from 'react';

export interface CrossReferenceSearchProps extends InputProps {
  label?: string | undefined;
  alwaysOn?: boolean | undefined;
  target: string;
}

const useStyle = makeStyles(theme => ({
  search: {
    marginBottom: theme.spacing(1),
  },
}));

export const CrossReferenceSearch: FC<CrossReferenceSearchProps> = (
  props: CrossReferenceSearchProps,
) => {
  const {reference, source, label, target} = props;
  const classes = useStyle();
  const translate = useTranslate();
  const {filterValues, displayedFilters, setFilters} = useListContext();
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const dataProvider = useDataProvider();
  useEffect(() => {
    if (search && search.length) {
      console.log('updating filters');
      dataProvider
        .getList(reference, {
          filter: {
            [source]: search,
          },
          pagination: {
            page: 1,
            perPage: 5000,
          },
          sort: {
            field: 'username',
            order: 'ASC',
          },
        })
        .then(values => {
          const f = Object.assign({}, filterValues);
          if (values.data.length > 0) {
            f[target] = {inq: values.data.map(e => e.id)};
          } else {
            f[target] = '<none>';
          }
          setFilters(f, displayedFilters);
        })
        .catch(err => {
          console.error(err);
          const f = Object.assign({}, filterValues);
          delete f[target];
          setFilters(f, displayedFilters);
        });
    } else {
      const f = Object.assign({}, filterValues);
      if (f[target]) {
        delete f[target];
        setFilters(f, displayedFilters);
      }
    }
  }, [search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, setSearch]);

  return (
    <div>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.search}
        label={translate(label ?? source)}
        size="small"
        onChange={e => setSearchTerm(e.target.value)}
        variant="outlined"
      />
    </div>
  );
};
