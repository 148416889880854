import {fetchUtils} from 'react-admin';
import storage from './storage';

const fetch = (url: string, options: fetchUtils.Options) => {
  options.user = {
    authenticated: true,
    token: storage.load('lbtoken').id,
  };
  return fetchUtils.fetchJson(url, options);
};

export default fetch;
