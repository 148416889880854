import {Group as UserIcon} from '@material-ui/icons';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

const content = {
  icon: UserIcon,
  list: UserList,
  edit: UserEdit,
  show: UserShow,
  create: UserCreate,
};

export default content;
