import {push} from 'connected-react-router';
import {FC} from 'react';
import {EditButton, ResourceComponentProps, TextField} from 'react-admin';
import {connect} from 'react-redux';
import {Datagrid, List} from '../components';

const CertificateList: FC<{push: typeof push} & ResourceComponentProps> = ({
  ...props
}) => (
  <>
    <List {...props} perPage={25} sort={{field: 'name', order: 'ASC'}}>
      <Datagrid>
        <TextField source="name" label="Name" />
        <TextField source="md5" label="md5" />
        <TextField source="comment" label="comment" />
        <EditButton />
      </Datagrid>
    </List>
  </>
);

export default connect(undefined, {push})(CertificateList);
