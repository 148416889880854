import {
  ContentOptimization,
  ContentQuality,
  OutputFileFormat,
} from '@idviu/backbone-api-client';
import {required} from 'ra-core';
import {EditProps} from 'ra-ui-materialui';
import {FC} from 'react';
import {
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from '../components';
import {DrmInput} from './AddOutputFileButton';

export const OutputFileEdit: FC<EditProps> = props => {
  return (
    <Edit {...props}>
      <SimpleForm canDelete={true}>
        <TextInput source="url" validate={required()} />
        <NumberInput source="width" />
        <NumberInput source="height" />
        <SelectInput
          fullWidth={false}
          source="format"
          choices={Object.keys(OutputFileFormat).map(e => ({id: e, name: e}))}
        />
        <SelectInput
          fullWidth={false}
          source="optimization"
          choices={Object.keys(ContentOptimization).map(e => ({
            id: e,
            name: e,
          }))}
        />
        <SelectInput
          fullWidth={false}
          source="quality"
          choices={Object.keys(ContentQuality).map(e => ({id: e, name: e}))}
        />

        <DrmInput />
      </SimpleForm>
    </Edit>
  );
};
