import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useTranslate} from 'ra-core';
import {useState} from 'react';
import {Field, withTypes} from 'react-final-form';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));

const renderInput = ({
  meta: {touched, error} = {touched: false, error: undefined},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  username?: string;
  password?: string;
}

const {Form} = withTypes<FormValues>();

const LoginForm = (props: any) => {
  const {onSubmit, handleForgotPassword} = props;
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles(props);

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                name="username"
                // @ts-ignore
                component={renderInput}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                name="password"
                // @ts-ignore
                component={renderInput}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <CardActions className={classes.actions}>
            <Button
              style={{fontSize: '0.7em'}}
              size="small"
              variant="outlined"
              disabled={loading}
              fullWidth
              onClick={handleForgotPassword}
            >
              {translate('pos.button.forgot_password')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};
export default LoginForm;
