import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useTranslate} from 'ra-core';
import React, {useEffect, useState} from 'react';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {storage} from '../dataProvider';

const useStyle = makeStyles({
  main: {
    flex: '1',
  },
  root: {
    width: '100%',
    maxWidth: 360,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
});

const RoyaltyReport = props => {
  const classes = useStyle();
  const account = props.account;
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const options = {year: 'numeric', month: 'long'};

  const fetchStats = React.useCallback(() => {
    if (!account) return;
    setLoading(true);
    const entries = [];
    var now = new Date();

    for (let i = 0; i < 7; i++) {
      var date = new Date(now);
      date.setUTCDate(1);
      date.setUTCMonth(date.getUTCMonth() - i);
      date.setUTCHours(0);
      date.setUTCMinutes(0);
      date.setUTCSeconds(0);
      date.setUTCMilliseconds(0);
      entries.push({
        date: Math.floor(date.getTime() / 1000),
      });
    }
    const requests = [];
    entries.forEach(function (entry) {
      const where = encodeURIComponent(
        JSON.stringify({accountId: account.id, slot: entry.date}),
      );
      const request = new Request(
        `${BACKBONE_REST_API_V1_URL}/certAccesses/count?where=${where}`,
        {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            authorization: storage.load('lbtoken').id,
          }),
        },
      );
      requests.push(
        fetch(request).then(async response => {
          return {
            date: entry.date,
            data: await response.json(),
          };
        }),
      );
    });
    Promise.all(requests)
      .then(results => {
        setStats(results);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [account]);

  const id = account ? account.id : null;
  useEffect(() => {
    setStats(null);
    fetchStats();
  }, [id, fetchStats]);

  //const month = today.toLocaleDateString('en-EN', options);

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate('pos.dashboard.royalty_report')}
        </Typography>
        {stats != null && !loading ? (
          <List className={classes.root}>
            {stats.map(entry => {
              return (
                <ListItem key={entry.date} role={undefined} dense>
                  <ListItemText
                    primary={`${new Date(entry.date * 1000).toLocaleDateString(
                      'en-EN',
                      options,
                    )}: ${entry.data.count}`}
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <CircularProgress />
        )}
      </Card>
    </div>
  );
};

export default RoyaltyReport;
