import {BooleanInputProps} from 'ra-ui-materialui/src/input/BooleanInput';
import {FC} from 'react';
import {BooleanInput as RaBooleanInput} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const BooleanInput: FC<BooleanInputProps> = props => (
  <RaBooleanInput {...props} />
);

BooleanInput.defaultProps = {
  ...RaBooleanInput.defaultProps,
  ...COMMON_INPUT_PROPS,
};
