import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {
  BooleanField,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import AddUserRoleButton from './AddUserRoleButton';
import DeleteRoleButton from './DeleteRoleButton';
import {UserTitle} from './UserTitle';

const UserShow: FC = props => {
  const tr = useTranslate();
  return (
    <Show {...props} title={<UserTitle />}>
      <TabbedShowLayout>
        <Tab label="infos">
          <TextField source="id" />
          <TextField source="firstName" />
          <TextField source="lastName" />
          <TextField source="email" />
          <BooleanField source="emailVerified" />
          <TextField source="username" />
          <ReferenceField
            source="realmId"
            reference={ENTITY_COLLECTIONS.realm}
            emptyText={tr('resources.users.globalRealm')}
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="singleSession" />
          <BooleanField source="useMfa" />
        </Tab>
        <Tab label="Roles" path={ENTITY_COLLECTIONS.role}>
          <ReferenceManyField
            addLabel={false}
            reference={ENTITY_COLLECTIONS.roleMapping}
            target="principalId"
            filter={{principalType: EntityType.user}}
          >
            <Datagrid>
              <ReferenceField
                label="role"
                source="roleId"
                reference={ENTITY_COLLECTIONS.role}
              >
                <TextField source="name" />
              </ReferenceField>
              <DeleteRoleButton />
            </Datagrid>
          </ReferenceManyField>
          <AddUserRoleButton />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
