import {FC} from 'react';
import {
  AutocompleteArrayInput as RaAutocompleteArrayInput,
  AutocompleteArrayInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const AutocompleteArrayInput: FC<AutocompleteArrayInputProps> =
  props => <RaAutocompleteArrayInput {...props} />;

AutocompleteArrayInput.defaultProps = COMMON_INPUT_PROPS;
