import {ContentState} from '@idviu/backbone-api-client';
import {EditProps} from 'ra-ui-materialui';
import {FC} from 'react';
import {SelectInput, SimpleForm, TextInput} from '../components';
import Edit from '../components/Edit';

export const ContentEdit: FC<EditProps> = props => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput source="title" required />
        <TextInput source="externalId" />
        <SelectInput
          source="state"
          choices={Object.keys(ContentState).map(e => ({id: e, name: e}))}
        />
      </SimpleForm>
    </Edit>
  );
};
