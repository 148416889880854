import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {usePermissions} from 'react-admin';
import {HelpCard, PleaseSelectAccount, useCurrentAccount} from '../components';
import PieStats from '../components/PieStats';
import UniqueCountStats from '../components/UniqueCountStats';

const styles = {
  flex: {display: 'flex'},
  flexColumn: {display: 'flex', flexDirection: 'column'},
  leftCol: {flex: 1, marginRight: '1em'},
  rightCol: {flex: 1, marginLeft: '1em'},
  singleCol: {marginLeft: '1em', marginTop: '2em', marginBottom: '2em'},
};

const useStyle = makeStyles({
  root: {
    flexGrow: 1,
    padding: '1em',
  },
  stats: {
    width: '100%',
    marginTop: '2em',
  },
  statsCol: {
    width: '40%',
    marginTop: '2em',
  },
});

const Dashboard = () => {
  const {loaded, permissions} = usePermissions();
  const classes = useStyle();
  const account = useCurrentAccount();

  return !loaded ? (
    <div style={styles.flexColumn}>Loading...</div>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HelpCard
            reference="dashboard_welcome"
            text="pos.dashboard.welcome"
            parse={true}
          />
        </Grid>
        {account ? (
          <>
            {
              <Grid item xs={12}>
                <UniqueCountStats filter={{account: account.name}} />
              </Grid>
            }
            {loaded && permissions[0].canAccessAccountStats(account) && (
              <>
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os"
                    filter={{account: account.name}}
                    max={5}
                    title="pos.dashboard.stats_os"
                  />
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="sdk_version"
                    filter={{account: account.name}}
                    max={10}
                    title="pos.dashboard.stats_sdk_version"
                  />
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os_version"
                    filter={{device_os: 'Android', account: account.name}}
                    title="pos.dashboard.stats_android"
                  />
                </Grid>
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os_version"
                    filter={{device_os: 'iOS', account: account.name}}
                    title="pos.dashboard.stats_ios"
                  />
                </Grid>
              </>
            )}
          </>
        ) : (
          <PleaseSelectAccount />
        )}
      </Grid>
    </div>
  );
};

export default Dashboard;
