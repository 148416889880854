import {CalendarToday, Email, Security, Settings} from '@material-ui/icons';
import {FC} from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  Record as RaRecord,
  TabbedForm,
  TextField,
  TitleProps,
  useTranslate,
} from 'react-admin';
import {NumberInput, TextInput} from '../components';
import {EmailConfigInput} from './EmailConfigInput';
import {EmailTemplateInput} from './EmailTemplateInput';
import {PasswordHashInput} from './PasswordHashInput';
import {PrincipalField} from './PrincipalField';
import {WsmpConfigInput} from './WsmpConfigInput';

function transformRecord({
  useCustomEmail: _useCustomEmail,
  ...values
}: RaRecord): RaRecord {
  return values;
}

const RealmTitle: FC<TitleProps> = props => {
  const {record} = props;
  const t = useTranslate();
  return <span>{`${t('resources.realms.desc.title')} "${record?.name}"`}</span>;
};

export const RealmEdit: FC = props => (
  <Edit {...props} transform={transformRecord} title={<RealmTitle />}>
    <TabbedForm>
      <FormTab label="resources.realms.tabs.general" icon={<Settings />}>
        <TextField source="id" />
        <TextInput source="name" variant="outlined" />
        <PrincipalField />
      </FormTab>
      <FormTab label="resources.realms.tabs.security" icon={<Security />}>
        <PasswordHashInput />
        <NumberInput source="security.passwordLength" defaultValue={12} />
        <BooleanInput
          source="security.passwordWithNumbers"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithSymbols"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithUppercase"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithLowercase"
          defaultValue={true}
        />
        <TextInput
          source="security.validateEmailUrl"
          fullWidth
          variant="outlined"
        />
        <TextInput
          source="security.resetPasswordUrl"
          fullWidth
          variant="outlined"
        />
        <BooleanInput
          source="security.singleSession"
          fullWidth
          variant="outlined"
        />
        <BooleanInput source="security.useMfa" fullWidth />
      </FormTab>
      <FormTab label="resources.realms.tabs.email" icon={<Email />}>
        <EmailConfigInput />
        <EmailTemplateInput source="emailTemplate" />
      </FormTab>
      <FormTab label="resources.realms.tabs.wsmp" icon={<CalendarToday />}>
        <WsmpConfigInput />
      </FormTab>
    </TabbedForm>
  </Edit>
);
