import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import IconSave from '@material-ui/icons/Save';
import {useDataProvider, useRedirect} from 'ra-core';
import {FC} from 'react';
import {
  BooleanInput,
  Button,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  showNotification,
  TextInput,
  Toolbar,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {SimpleForm} from '../components';
import AddNewAIDButton from './AddNewAIDButton';

interface IntegerBooleanInputProps {
  label: string;
  source: string;
  defaultValue?: unknown;
}

const IntegerBooleanInput: FC<IntegerBooleanInputProps> = props => {
  return (
    <SelectInput
      defaultValue="0"
      choices={[
        {id: '0', name: 'False'},
        {id: '1', name: 'True'},
      ]}
      {...props}
    />
  );
};

interface ValidateKeyErrors {
  expiration?: string[];
  key_APPLICATION_PACKAGE?: string[];
  aidId?: string[];
}

function validateKey(values: any): ValidateKeyErrors {
  return {
    expiration:
      !values.expiration && !values.production
        ? ['You must set an expiration date for non production licenses']
        : undefined,
    key_APPLICATION_PACKAGE:
      !values.key_APPLICATION_PACKAGE && values.production
        ? ['You must provide a package name']
        : undefined,
    aidId:
      !values.aidId && values.type === 'legacy'
        ? ['You must select an AID for legacy keys']
        : undefined,
  };
}

function downloadData(data: Iterable<number>, filename: string) {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([new Uint8Array(data)], {
    type: 'application/octet-stream',
  });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}`);
  fakeLink.click();
}

interface SaveWithDownloadButtonProps {
  label?: string;
  submitOnEnter?: boolean;
}

const SaveWithDownloadButton: FC<SaveWithDownloadButtonProps> = ({
  label,
  submitOnEnter,
}) => {
  const formState = useFormState();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const redirect = useRedirect();

  const handleClick = async () => {
    const values = formState.values;
    await dataProvider
      .create(ENTITY_COLLECTIONS.applicationKey, {data: values})
      .then(response => {
        if (response.data && response.data.data && response.data.data.data) {
          downloadData(response.data.data.data, 'application.key');
          redirect(
            `/${ENTITY_COLLECTIONS.account}/${values.accountId}/show/keys`,
          );
        } else {
          dispatch(
            showNotification('Could not retrieve application.key', 'warning'),
          );
        }
      })
      .catch(() => {
        dispatch(
          showNotification(
            'Error: could not create application.key',
            'warning',
          ),
        );
      });
  };

  return (
    <Button
      onClick={handleClick}
      label={label ?? 'pos.button.create'}
      variant="contained"
      submitOnEnter={submitOnEnter}
    >
      <IconSave />
    </Button>
  );
};

interface KeyCreateToolbarProps {
  validate?: never;
}

const KeyCreateToolbar: FC<KeyCreateToolbarProps> = ({validate, ...props}) => {
  return (
    <Toolbar {...props}>
      <SaveWithDownloadButton label="pos.button.create" submitOnEnter={true} />
    </Toolbar>
  );
};

const aidRenderer = (choice: {
  serviceId: string;
  comment: string;
  AID: string;
}) => `${choice.serviceId}, ${choice.comment}, ${choice.AID}`;

function redirect(_base: never, _id: never, data: {accountId: string}) {
  if (data.accountId) {
    return `/${ENTITY_COLLECTIONS.account}/${data.accountId}/show/keys`;
  } else {
    return 'list';
  }
}

const ApplicationKeyCreate: FC = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm
          validate={validateKey}
          toolbar={<KeyCreateToolbar />}
          redirect={redirect}
        >
          <ReferenceInput
            reference={ENTITY_COLLECTIONS.account}
            source="accountId"
            label="Account"
            perPage={1000}
            sort={{field: 'name', order: 'ASC'}}
            link="show"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectInput
            source="type"
            choices={[
              {id: 'hss5', name: 'HSS5'},
              {id: 'legacy', name: 'Legacy'},
            ]}
            defaultValue="legacy"
            disabled
          />
          <TextInput label="Package name" source="key_APPLICATION_PACKAGE" />
          <BooleanInput
            label="Production (unlimited usage)"
            source="production"
          />
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData &&
              !formData.production && (
                <DateInput
                  label="Expiration date"
                  source="expiration"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData}) =>
              formData &&
              formData.type === 'legacy' &&
              formData.accountId && (
                <>
                  <ReferenceInput
                    source="aidId"
                    reference={ENTITY_COLLECTIONS.aid}
                    variant="outlined"
                    filter={{accountId: formData.accountId}}
                  >
                    <SelectInput optionText={aidRenderer} />
                  </ReferenceInput>
                  <AddNewAIDButton record={formData} {...props} />
                </>
              )
            }
          </FormDataConsumer>
          <IntegerBooleanInput
            label="Root allowed"
            source="key_HSS_ROOT_ALLOWED"
          />
          <IntegerBooleanInput
            label="TV output allowed"
            source="key_HSS_TV_ALLOWED"
          />
          <IntegerBooleanInput
            label="Airplay allowed"
            source="key_HSS_AIRPLAY_ALLOWED"
          />
          <IntegerBooleanInput
            label="Android TV Box allowed"
            source="key_HSS_ANDROID_TV_BOX_ALLOWED"
          />
          <IntegerBooleanInput
            label="Android TV allowed"
            source="key_HSS_ANDROID_TV_ALLOWED"
          />
          <IntegerBooleanInput
            label="Emulator allowed"
            source="key_HSS_EMULATOR_ALLOWED"
          />
          <IntegerBooleanInput
            defaultValue="1"
            label="HD allowed with software DRM"
            source="key_HSS_HD_SW_DRM_ALLOWED"
          />
          <IntegerBooleanInput
            defaultValue="1"
            label="HD settings overridable by application"
            source="key_HSS_HD_ROOT_OVERRIDABLE"
          />
          <NumberInput
            defaultValue="589824"
            label="Pixel limit for HD content"
            source="key_HSS_HD_ROOT_MAX_PIXELS"
          />
          <NumberInput
            defaultValue="2500000"
            label="Bitrate limit for HD content"
            source="key_HSS_HD_ROOT_MAX_BITRATE"
          />
          <NumberInput
            defaultValue="0"
            label="Fingeprint frequency (seconds)"
            source="key_HSS_FINGERPRINT_FREQUENCY"
          />
          <IntegerBooleanInput
            defaultValue="0"
            label="Fingerprint mandatory"
            source="key_HSS_FINGERPRINT_MANDATORY"
          />
          <NumberInput
            defaultValue="2"
            label="Fingeprint tolerance"
            source="key_HSS_FINGERPRINT_TOLERANCE"
          />
          <NumberInput
            defaultValue="0"
            label="Stats level"
            source="key_HSS_STATS_LEVEL"
          />
          <IntegerBooleanInput
            defaultValue="0"
            label="Send stats after first DRM playback"
            source="key_HSS_STATS_AFTER_DRM"
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default ApplicationKeyCreate;
