import React from 'react';
import {Datagrid} from '../components';
import {TextField, EditButton} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

import rowStyle from './rowStyle';
import {usePermissions} from 'ra-core';

const listStyles = {
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const ListDesktop = ({classes, ...props}) => {
  const {loaded, permissions} = usePermissions();
  return (
    <Datagrid
      rowStyle={rowStyle}
      classes={{headerRow: classes.headerRow}}
      {...props}
    >
      <TextField source="name" />
      <TextField source="id" />
      {loaded && permissions[0].isAdmin() && <EditButton />}
    </Datagrid>
  );
};

export default withStyles(listStyles)(ListDesktop);
