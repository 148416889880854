import {usePermissions} from 'ra-core';
import {FC} from 'react';
import {Responsive} from 'react-admin';
import {List, TextInput} from '../components';
import UserListDesktop from './UserListDesktop';
import UserListMobile from './UserListMobile';

const UserFilters = [
  <TextInput
    variant="outlined"
    key="search"
    source="q$email$username$lastName$firstName$id"
    label="pos.search"
    alwaysOn
  />,
];

const UserList: FC = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <List
      {...props}
      perPage={25}
      filters={UserFilters}
      canCreate={loaded && permissions[0].isAdmin()}
      canDelete={loaded && permissions[0].isSuperAdmin()}
      sort={{field: 'email', order: 'ASC'}}
    >
      <Responsive xsmall={<UserListMobile />} medium={<UserListDesktop />} />
    </List>
  );
};

export default UserList;
