import {
  EntityType,
  ENTITY_COLLECTIONS,
  IMetadata,
} from '@idviu/backbone-api-client';
import {
  Button,
  Chip,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {FC, useCallback, useState} from 'react';
import {
  ChipField,
  FieldProps,
  InputPropTypes,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowActionsProps,
  showNotification,
  ShowProps,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useDataProvider,
  useGetOne,
  useMutation,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RoleMapping, TeamMember, User} from '../backbone-records';
import {useHasRealm} from '../resource-hooks';
import AddRoleButton from './AddRoleButton';
import {RealmWarning} from './RealmWarning';

interface DeletableChipFieldProps extends FieldProps {
  mapping?: RoleMapping;
  redirectTo?: string;
  disabled?: boolean;
}

const useStyle = makeStyles(theme => ({
  roles: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}));

const DeletableChipField: FC<DeletableChipFieldProps> = ({
  mapping,
  record = {},
  redirectTo,
  source = 'name',
  disabled,
}) => {
  const translate = useTranslate();
  const value = get(record, source);
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const classes = useStyle();
  const handleDelete = React.useCallback(async () => {
    if (disabled || !mapping || !mapping.id) return;
    await dataProvider
      .delete(ENTITY_COLLECTIONS.roleMapping, {
        id: mapping.id,
        previousData: mapping,
      })
      .then(() => {
        if (redirectTo) redirect(redirectTo);
      })
      .catch(() => {
        dispatch(showNotification('Error: could not remove role', 'warning'));
      });
  }, [dataProvider, mapping, redirectTo, redirect, disabled, dispatch]);
  return (
    <Chip
      className={classes.chip}
      label={translate(value, {_: value})}
      onDelete={disabled ? undefined : handleDelete}
      disabled={disabled}
    />
  );
};

DeletableChipField.propTypes = {
  ...InputPropTypes,
  ...ChipField.propTypes,
  addLabel: PropTypes.bool,
  label: PropTypes.string,
};

DeletableChipField.defaultProps = {
  addLabel: true,
};

interface RoleChipFieldProps extends FieldProps<RoleMapping> {
  redirectTo?: string;
  disabled?: boolean;
  deletable?: boolean;
}

const RoleChipField: FC<RoleChipFieldProps> = ({
  deletable,
  record,
  source,
  className,
  redirectTo,
  ...props
}) => {
  return (
    <ReferenceField
      record={record}
      source="roleId"
      reference={ENTITY_COLLECTIONS.role}
      link={false}
      {...props}
    >
      <DeletableChipField
        disabled={deletable === false}
        mapping={record}
        redirectTo={redirectTo}
        className={className}
      />
    </ReferenceField>
  );
};

interface RolesFieldProps {
  location: ShowProps['location'];
}
const RolesField: FC<RolesFieldProps & FieldProps<TeamMember>> = ({
  location,
  record: teamMember,
  ...props
}) => {
  const classes = useStyle();
  const hasRealm = useHasRealm(teamMember?.userId);
  return (
    <ReferenceManyField
      {...props}
      reference={ENTITY_COLLECTIONS.roleMapping}
      target="principalId"
      filter={{principalType: EntityType.teamMember}}
    >
      <SingleFieldList className={classes.roles}>
        <RoleChipField
          deletable={!hasRealm}
          redirectTo={(location ?? '') as string}
        />
      </SingleFieldList>
    </ReferenceManyField>
  );
};

RolesField.defaultProps = {
  addLabel: true,
  label: 'resources.users.fields.roles',
};

const useServiceStyle = makeStyles(theme => ({
  main: {
    marginTop: theme.spacing(1),
  },
}));

const MaybeEditService: FC<FieldProps<User>> = ({record}) => {
  const [value, setValue] = useState<string | undefined>(
    (record?.metadata as IMetadata)?.wsmpService as string,
  );
  const {data: realm} = useGetOne(
    ENTITY_COLLECTIONS.realm,
    record?.realmId ?? '',
    {
      enabled: !!record?.realmId,
    },
  );
  const canEdit = !!realm?.wsmp?.allowedServices;
  const classes = useServiceStyle();
  const [setService] = useMutation();
  const handleChange = useCallback(
    e => {
      setValue(e.target.value);
      const o = setService({
        type: 'update',
        resource: ENTITY_COLLECTIONS.user,
        payload: {
          id: record?.id ?? '',
          data: {
            ...record,
            metadata: {
              ...record?.metadata,
              wsmpService: e.target.value,
            },
          },
        },
      });
      if (o) {
        // eslint-disable-next-line no-void
        void o;
      }
    },
    [setValue, record, setService],
  );

  const t = useTranslate();
  return canEdit ? (
    <div className={classes.main}>
      <Typography>{t('resources.teamMembers.fields.service')} </Typography>
      <Select
        id="service-select"
        value={value}
        variant="outlined"
        label="Service"
        onChange={handleChange}
      >
        {realm?.wsmp?.allowedServices?.map((e: string) => (
          <MenuItem value={e} key={e}>
            {e}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : (
    <span> N/A </span>
  );
};

MaybeEditService.defaultProps = {
  addLabel: true,
  label: 'resources.teamMembers.fields.service',
};

const RealmField: FC<FieldProps<TeamMember>> = props => {
  const teamMember = useRecordContext<TeamMember>(props);
  const hasRealm = useHasRealm(teamMember?.userId);

  const tr = useTranslate();
  return (
    <>
      <ReferenceField
        {...props}
        source="userId"
        reference={ENTITY_COLLECTIONS.user}
        link={false}
      >
        <ReferenceField
          source="realmId"
          reference={ENTITY_COLLECTIONS.realm}
          emptyText={tr('resources.users.globalRealm')}
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        {...props}
        source="userId"
        reference={ENTITY_COLLECTIONS.user}
        link={false}
      >
        <MaybeEditService />
      </ReferenceField>
      {hasRealm && <RealmWarning containerStyle={{marginTop: '8px'}} />}
    </>
  );
};

RealmField.defaultProps = {
  addLabel: true,
  label: 'resources.users.fields.realm',
};

const ShowActions: FC<ShowActionsProps> = () => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        {translate('pos.button.back')}
      </Button>
    </TopToolbar>
  );
};

const TeamMembersShow: FC<ShowProps> = props => (
  <Show {...props} actions={<ShowActions />}>
    <SimpleShowLayout>
      <ReferenceField
        label="resources.users.fields.id"
        source="userId"
        reference="users"
        link={false}
      >
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField
        label="resources.users.fields.email"
        source="userId"
        reference={ENTITY_COLLECTIONS.user}
        link={false}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        label="resources.users.fields.username"
        source="userId"
        reference={ENTITY_COLLECTIONS.user}
        link={false}
      >
        <TextField source="username" />
      </ReferenceField>
      <RolesField location={props.location} />
      <RealmField />
      <AddRoleButton />
    </SimpleShowLayout>
  </Show>
);

export default TeamMembersShow;
