import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {useTranslate} from 'react-admin';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const PleaseSelectAccount = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {translate('pos.title.oops')}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {translate('pos.desc.select_account')}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PleaseSelectAccount;
