import PanoramaOutlined from '@material-ui/icons/PanoramaOutlined';
import {ResourceComponents} from '../components';
import {ContentCreate} from './ContentCreate';
import {ContentEdit} from './ContentEdit';
import {ContentList} from './ContentList';
import {ContentShow} from './ContentShow';

const components: ResourceComponents = {
  create: ContentCreate,
  edit: ContentEdit,
  icon: PanoramaOutlined,
  list: ContentList,
  show: ContentShow,
};
export default components;
