import {usePermissions} from 'ra-core';
import React from 'react';
import {AutocompleteInput, ReferenceInput, TextInput} from 'react-admin';
import {Edit, SimpleForm} from '../components';

const AIDEdit = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <Edit {...props} title="Modify AID">
        <SimpleForm canDelete={loaded && permissions[0].isSuperAdmin()}>
          <TextInput source="AID" label="AID" />
          <TextInput source="KEY" label="KEY" />
          <TextInput source="IV" label="IV" />
          <TextInput source="SIG" label="SIG" />
          <TextInput source="serviceId" label="Service ID" />
          <TextInput source="comment" label="Comments" />
          <ReferenceInput
            label="Account"
            source="accountId"
            reference="accounts"
            filterToQuery={searchText => ({qname: searchText})}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default AIDEdit;
