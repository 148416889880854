import {ContentState} from '@idviu/backbone-api-client';
import {CreateProps} from 'ra-ui-materialui';
import {FC} from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  useCurrentAccount,
} from '../components';

export const ContentCreate: FC<CreateProps> = props => {
  const account = useCurrentAccount();

  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput
          source="accountId"
          required
          disabled
          defaultValue={account?.id}
        />
        <TextInput source="title" required />
        <TextInput source="externalId" />
        <SelectInput
          source="state"
          choices={Object.keys(ContentState).map(e => ({id: e, name: e}))}
        />
      </SimpleForm>
    </Create>
  );
};
