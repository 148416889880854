import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import authProviderInternal from './authProvider';
import provider from './dataProvider';
export * from './authProvider';
export {default as storage} from './storage';
export {authProvider};

const dataProvider = provider(BACKBONE_REST_API_V1_URL);
const authProvider = authProviderInternal;

export default dataProvider;
