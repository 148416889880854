import {Theme} from '@material-ui/core';
import {Styles} from '@material-ui/styles';

export const USER_LIST_STYLES: Styles<Theme, {}, 'headerRow' | 'comment'> = {
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
