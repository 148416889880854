import {FC} from 'react';
import {NumberInput as RaNumberInput, NumberInputProps} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const NumberInput: FC<NumberInputProps> = props => (
  <RaNumberInput {...props} />
);

NumberInput.defaultProps = {
  ...RaNumberInput.defaultProps,
  variant: 'outlined',
  ...COMMON_INPUT_PROPS,
};
