import {FC} from 'react';
import {
  AutocompleteInput as RaAutocompleteInput,
  AutocompleteInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const AutocompleteInput: FC<AutocompleteInputProps> = props => (
  <RaAutocompleteInput {...props} />
);

AutocompleteInput.defaultProps = {...COMMON_INPUT_PROPS, variant: 'outlined'};
