import {usePermissions} from 'ra-core';
import AccountListAdmin from './AccountListAdmin';

const AccountList = props => {
  const {loaded, permissions} = usePermissions();
  //if (!permissions) return null;
  /*if (permissions.hasMainRole())*/
  return (
    <AccountListAdmin loaded={loaded} permissions={permissions} {...props} />
  );
  //eturn  <AccountListUser loaded permissions {...props} />
};

export default AccountList;
