import {FC} from 'react';
import {
  BooleanField,
  Datagrid,
  ResourceComponentProps,
  ShowButton,
  TextField,
} from 'react-admin';

const ServerListDesktop: FC<{classes?: never} & ResourceComponentProps> = ({
  classes,
  ...props
}) => (
  <Datagrid {...props}>
    <TextField source="name" />
    <TextField source="type" />
    <TextField source="cluster" />
    <TextField source="url" />
    <BooleanField source="default" />
    <ShowButton />
  </Datagrid>
);

export default ServerListDesktop;
