import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {push} from 'connected-react-router';
import {Component, FC} from 'react';
import {
  DateField,
  FunctionField,
  Record,
  ReferenceField,
  ResourceComponentPropsWithId,
  ShowController,
  ShowView,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {connect} from 'react-redux';

const ApplicationKeyTitle: FC<{record: Record}> = ({record}) => (
  <span>{record ? `"${record.name}"` : ''} details</span>
);

const AllValues: FC<{record: Record}> = ({record}) => (
  <>
    {Object.keys(record.keys).map(key => (
      <div key={key}>
        <strong>{key}</strong>: {record.keys[key]}
      </div>
    ))}
  </>
);

class ApplicationKeyShow extends Component<
  ResourceComponentPropsWithId & {push: typeof push}
> {
  render() {
    const props = this.props;
    return (
      <>
        <ShowController {...props}>
          {controllerProps => {
            if (controllerProps.record) {
              return (
                <ShowView
                  {...props}
                  {...controllerProps}
                  title={
                    <ApplicationKeyTitle record={controllerProps.record} />
                  }
                >
                  <TabbedShowLayout>
                    <Tab label="Main">
                      <TextField source="id" />
                      <DateField source="createdOn" showTime />
                      <ReferenceField
                        source="userId"
                        label="By"
                        reference={ENTITY_COLLECTIONS.user}
                        link="show"
                      >
                        <TextField source="email" />
                      </ReferenceField>
                      <DateField
                        label="Expiration"
                        source="expiration"
                        showTime
                      />
                      <TextField label="Type" source="type" />
                      <FunctionField
                        label="Package name"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="APPLICATION_PACKAGE"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS URL"
                        render={(record?: Record) => (
                          <TextField record={record?.keys} source="HSS_URL" />
                        )}
                      />
                    </Tab>
                    <Tab label="Security Settings">
                      <FunctionField
                        label="HSS_ROOT_ALLOWED"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_ROOT_ALLOWED"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS_TV_ALLOWED"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_TV_ALLOWED"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS_AIRPLAY_ALLOWED"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_AIRPLAY_ALLOWED"
                          />
                        )}
                      />
                    </Tab>
                    <Tab label="HD Settings">
                      <FunctionField
                        label="HSS_HD_SW_DRM_ALLOWED"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_HD_SW_DRM_ALLOWED"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS_HD_ROOT_OVERRIDABLE"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_HD_ROOT_OVERRIDABLE"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS_HD_ROOT_MAX_PIXELS"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_HD_ROOT_MAX_PIXELS"
                          />
                        )}
                      />
                      <FunctionField
                        label="HSS_HD_ROOT_MAX_BITRATE"
                        render={(record?: Record) => (
                          <TextField
                            record={record?.keys}
                            source="HSS_HD_ROOT_MAX_BITRATE"
                          />
                        )}
                      />
                    </Tab>
                    <Tab label="All values">
                      <div>
                        <br />
                      </div>
                      <AllValues record={controllerProps.record} />
                    </Tab>
                  </TabbedShowLayout>
                </ShowView>
              );
            } else {
              return <div />;
            }
          }}
        </ShowController>
      </>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(ApplicationKeyShow);
