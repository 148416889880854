import AIDIcon from '@material-ui/icons/EnhancedEncryption';
import AIDCreate from './AIDCreate';
import AIDEdit from './AIDEdit';
import AIDList from './AIDList';

const content = {
  icon: AIDIcon,
  list: AIDList,
  create: AIDCreate,
  edit: AIDEdit,
};

export default content;
