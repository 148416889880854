import {FC} from 'react';
import {DateField as RaDateField, DateFieldProps} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const DateField: FC<DateFieldProps> = props => (
  <RaDateField {...props} />
);

DateField.defaultProps = {
  ...RaDateField.defaultProps,
  ...COMMON_INPUT_PROPS,
};
