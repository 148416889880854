import {ContentState} from '@idviu/backbone-api-client';
import {Button} from '@material-ui/core';
import {Record, useTranslate} from 'ra-core';
import {FC} from 'react';
import {Link} from 'react-router-dom';

export interface PlayContentButtonProps {
  record?: Record;
}

export const PlayContentButton: FC<PlayContentButtonProps> = props => {
  const {record} = props;
  const translate = useTranslate();

  return (
    <Button
      disabled={record?.state !== ContentState.ready}
      component={Link}
      to={{
        pathname: `/play`,
        search: `?cid=${record?.id}`,
        state: {
          cid: record?.id,
        },
      }}
      variant="contained"
      color="primary"
      size="small"
      id={`play_${record?.id}`}
    >
      {translate('pos.button.play')}
    </Button>
  );
};
