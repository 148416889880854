import {FC} from 'react';
import {
  Edit,
  NumberInput,
  ResourceComponentPropsWithId,
  SimpleForm,
} from 'react-admin';
import {connect} from 'react-redux';

const CertificateEdit: FC<ResourceComponentPropsWithId> = props => (
  <>
    <Edit {...props} title="Modify Certificate Access">
      <SimpleForm>
        <NumberInput source="slot" label="Slot" />
        <NumberInput source="access" label="First Access" />
        <NumberInput source="lastAccess" label="Last Access" />
      </SimpleForm>
    </Edit>
  </>
);

export default connect(undefined, {})(CertificateEdit);
