import {isNil} from '@idviu/ts-helpers';
import {Box, Tab, Tabs} from '@material-ui/core';
import Editor, {EditorProps} from '@monaco-editor/react';
import monaco from 'monaco-editor';
import {CSSProperties, FC, useCallback, useState} from 'react';
import ReactMarkdown from 'react-markdown';

const TabPanel: FC<{value: number; index: number}> = props => {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number): {} {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MD_DOCUMENTATION = `# Email template editor documentation

## Introduction

This editor relies on a combination of Nunjucks templates of MJML responsive documents.

As such, you can build email templates using the MJML syntax (derived from HTML) and reference variables by using nunjucks instructions.

## Example

The following example will display content with size 14, a black color and an Helvetica-type font:

\`\`\`mjml
<mjml>
  <mj-body>
    <mj-section>
      <mj-column>
        <mj-text font-size="14px" color="#000" font-family="helvetica">
          {{ message }}
        </mj-text>
      </mj-column>
    </mj-section>
  </mj-body>
</mjml>
\`\`\`

## Further reading

* [MJML Documentation](https://documentation.mjml.io/#getting-started)
* [Nunjucks Documentation](https://mozilla.github.io/nunjucks/templating.html)
`;

const EDITOR_THEME: EditorProps['theme'] = 'vs-dark';

/**
 * MJML Editor labels
 * @public
 */
export type MjmlEditorLabelsKeys =
  | 'codeTitle'
  | 'previewTitle'
  | 'htmlTitle'
  | 'docTitle'
  | 'refreshingPreview'
  | 'noHtml'
  | 'documentation';

/** MJML Editor Label definition */
export type MjmlEditorLabels = Record<MjmlEditorLabelsKeys, string>;

export const DEFAULT_MJML_EDITOR_LABELS: MjmlEditorLabels = {
  codeTitle: 'Code',
  previewTitle: 'Preview',
  htmlTitle: 'HTML',
  docTitle: 'Documentation',
  refreshingPreview: 'Refreshing Preview',
  noHtml: 'No HTML to display',
  documentation: MD_DOCUMENTATION,
};

const PREVIEW_STYLE: CSSProperties = {backgroundColor: '#fff'};

/**
 * MJML Editor properties
 * @public
 */
export interface MjmlEditorProps {
  /** Current MJML code */
  value?: string;
  /** Event fired when the MJML value has changed */
  onChange?: (value?: string) => void;
  /** HTML preview */
  html?: string;
  /** Event fired when the HTML preview should be updated */
  onRefresh?: () => void;
  /** True if the HTML preview is currently refreshing, false otherwise */
  refreshing?: boolean;
  /** Optional replacement labels */
  labels?: MjmlEditorLabels;
  /** Fixed editor height */
  editorHeight?: string;
}

type CodeProps = Pick<MjmlEditorProps, 'value' | 'onChange'> &
  Required<Pick<MjmlEditorProps, 'editorHeight'>>;

const COMMON_EDITOR_OPTIONS: monaco.editor.IEditorOptions &
  monaco.editor.IGlobalEditorOptions = {
  codeLens: false,
  insertSpaces: true,
  minimap: {enabled: false},
  renderWhitespace: 'all',
  renderIndentGuides: true,
  renderFinalNewline: true,
  tabSize: 4,
};

function initializeCodeEditor(
  editor: monaco.editor.IStandaloneCodeEditor,
): void {
  editor.updateOptions(COMMON_EDITOR_OPTIONS);
}

function initializeHtmlEditor(
  editor: monaco.editor.IStandaloneCodeEditor,
): void {
  editor.updateOptions({
    ...COMMON_EDITOR_OPTIONS,
    readOnly: true,
    domReadOnly: true,
  });
}

const Code: FC<CodeProps> = ({editorHeight, value, onChange}) => (
  <Editor
    height={editorHeight}
    theme={EDITOR_THEME}
    language="xml"
    value={value}
    onChange={onChange}
    onMount={initializeCodeEditor}
  />
);

type HtmlProps = Pick<MjmlEditorProps, 'html'> &
  Required<Pick<MjmlEditorProps, 'editorHeight'>>;

const Html: FC<HtmlProps> = ({html, editorHeight}) => (
  <Editor
    height={editorHeight}
    theme={EDITOR_THEME}
    language="html"
    value={html}
    onMount={initializeHtmlEditor}
  />
);

type PreviewProps = Pick<MjmlEditorProps, 'html' | 'refreshing'> &
  Required<Pick<MjmlEditorProps, 'labels' | 'editorHeight'>>;

const Preview: FC<PreviewProps> = ({
  html,
  refreshing,
  labels,
  editorHeight,
}) => (
  <div style={{height: editorHeight}}>
    {refreshing ? (
      labels.refreshingPreview
    ) : isNil(html) ? (
      labels.noHtml
    ) : (
      <div dangerouslySetInnerHTML={{__html: html}} style={PREVIEW_STYLE} />
    )}
  </div>
);

type DocumentationProps = Required<
  Pick<MjmlEditorProps, 'editorHeight' | 'labels'>
>;

const Documentation: FC<DocumentationProps> = ({editorHeight, labels}) => (
  <div style={{height: editorHeight}}>
    <ReactMarkdown>{labels.documentation}</ReactMarkdown>
  </div>
);

/**
 * MJML Editor
 * @public
 */
export const MjmlEditor: FC<MjmlEditorProps> = ({
  value,
  onChange,
  html,
  onRefresh,
  refreshing,
  labels = DEFAULT_MJML_EDITOR_LABELS,
  editorHeight = '50vh',
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = useCallback(
    (_event, index) => {
      if ((index === 1 || index === 2) && tabIndex !== 1 && tabIndex !== 2) {
        onRefresh?.();
      }
      setTabIndex(index);
    },
    [onRefresh, tabIndex, setTabIndex],
  );
  return (
    <Box style={{width: '100%'}}>
      <Box style={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label={labels.codeTitle} {...a11yProps(0)} />
          <Tab label={labels.previewTitle} {...a11yProps(1)} />
          <Tab label={labels.htmlTitle} {...a11yProps(2)} />
          <Tab label={labels.docTitle} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Code value={value} onChange={onChange} editorHeight={editorHeight} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Preview
          html={html}
          refreshing={refreshing}
          labels={labels}
          editorHeight={editorHeight}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Html html={html} editorHeight={editorHeight} />
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <Documentation editorHeight={editorHeight} labels={labels} />
      </TabPanel>
    </Box>
  );
};
