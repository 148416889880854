import {Route} from 'react-router-dom';
import ApplicationKeyAnalysis from './applicationKeys/ApplicationKeyAnalysis';
import Configuration from './configuration/Configuration';
import {PlayerTest} from './player';
import StatsPage from './stats/StatsPage';

const routes = [
  <Route
    key={'/configuration'}
    exact
    path="/configuration"
    render={() => <Configuration />}
  />,
  <Route
    key={'/keyDump'}
    exact
    path="/keyDump"
    render={() => <ApplicationKeyAnalysis />}
  />,
  <Route key={'/stats'} exact path="/stats" render={() => <StatsPage />} />,
  <Route key={'/play'} exact path="/play" render={() => <PlayerTest />} />,
];

export default routes;
