import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {AuthProvider, Identifier} from 'ra-core';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {Account, Company} from '../types';
import storage from './storage';

class Permission {
  roles: any;
  teams: any;
  token: string;
  priviledgedTeams: any[];
  priviledgedCompanies: any[];
  priviledgedAccounts: any[];
  userId: string;

  constructor(token: any) {
    this.userId = token.userId;
    this.token = token.id;
    this.roles = token.roles || [];
    this.teams = token.teams || [];
    this.priviledgedTeams = [];
    this.priviledgedCompanies = [];
    this.priviledgedAccounts = [];
    this.teams.forEach((team: any) => {
      if (team.roles && team.roles.length > 0) {
        this.priviledgedTeams.push(team);
        if (team.principalType === EntityType.account)
          this.priviledgedAccounts.push(team);
        else if (team.principalType === EntityType.company)
          this.priviledgedCompanies.push(team);
      }
    });
  }

  /**
   * Check if the user has access to more than one company.
   * This is the case if it has a main role, or if it has roles for more than one company
   */
  hasMultipleCompanies() {
    return this.roles.length || this.priviledgedCompanies.length > 1;
  }

  /**
   * Check if the user has access to exactly one company.
   */
  hasOneCompany() {
    return this.priviledgedCompanies.length === 1;
  }

  /**
   * Get the authentication token
   */
  getToken() {
    return this.token;
  }

  /**
   * Check if the user has access to exactly one accpi,t.
   */
  hasOneAccount() {
    return this.priviledgedAccounts.length === 1;
  }

  /**
   * Check if the user has access to more than one account.
   * This is the case if it has a main role, or if it has roles for more than one account, or
   * if it has a role for at least one company
   */
  hasMultipleAccounts() {
    return (
      this.roles.length > 0 ||
      this.priviledgedCompanies.length > 0 ||
      this.priviledgedAccounts.length > 1
    );
  }

  /**
   * For users with role for only one company, return this company ID
   */
  getCompanyId() {
    if (this.priviledgedCompanies.length > 0) {
      return this.priviledgedCompanies[0].principalId;
    }
  }

  /**
   * For users with role for only one account, return this account ID
   */
  getAccountId() {
    if (this.priviledgedAccounts.length > 0) {
      return this.priviledgedAccounts[0].principalId;
    }
    return null;
  }

  /**
   * Check if we are a global super admin
   */
  isSuperAdmin() {
    return this.roles.indexOf('superAdmin') !== -1;
  }

  /**
   * Check if we are a global admin (or super admin as it superseeds admins)
   */
  isAdmin() {
    return this.isSuperAdmin() || this.roles.indexOf('admin') !== -1;
  }

  /**
   * Check if we have a main role (not company/account related)
   */
  hasMainRole() {
    return this.roles.length > 0;
  }

  /**
   * Check if we can access edit company settings (or,most likely, settings for the company's accounts)
   * @param company - the company object or the company ID
   */
  canModifyCompanySettings(company: Company | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!company) return false;
    let companyId: Identifier | null = null;
    if (typeof company === 'object') {
      companyId = company.id;
    } else {
      companyId = company;
    }
    if (companyId) {
      let isAdmin = false;
      this.priviledgedCompanies.forEach(
        (a: {principalId: null; roles: string | string[]}) => {
          if (
            a.principalId === companyId &&
            (a.roles.includes('admin') || a.roles.includes('operator'))
          ) {
            isAdmin = true;
          }
        },
      );
      return isAdmin;
    }
    return false;
  }

  /**
   * Can we access the company stats
   * @param company - The company to check for stats access
   */
  canAccessCompanyStats(company: Company | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!company) return false;
    let companyId: Identifier | null = null;
    if (typeof company === 'object') {
      companyId = company.id;
    } else {
      companyId = company;
    }
    if (companyId) {
      let isAdmin = false;
      this.priviledgedCompanies.forEach(
        (a: {principalId: null; roles: string | string[]}) => {
          if (
            a.principalId === companyId &&
            (a.roles.includes('admin') ||
              a.roles.includes('operator') ||
              a.roles.includes('viewer'))
          ) {
            isAdmin = true;
          }
        },
      );
      return isAdmin;
    }
    return false;
  }

  /**
   * Can we see (but maybe not modify) the company settings
   * @param company - The company to check for settings visibility
   */
  canAccessCompanySettings(company: Company | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!company) return false;
    let companyId: Identifier | null = null;
    if (typeof company === 'object') {
      companyId = company.id;
    } else {
      companyId = company;
    }
    if (companyId) {
      let isAdmin = false;
      this.priviledgedCompanies.forEach(
        (a: {principalId: null; roles: string | string[]}) => {
          if (
            a.principalId === companyId &&
            (a.roles.includes('admin') ||
              a.roles.includes('operator') ||
              a.roles.includes('viewer') ||
              a.roles.includes('developer'))
          ) {
            isAdmin = true;
          }
        },
      );
      return isAdmin;
    }
    return false;
  }

  /**
   * Can we access the keydump page
   *
   */
  canAccessKeyDump() {
    if (this.isAdmin()) return true;
    let isAdmin = false;
    this.priviledgedCompanies.forEach(
      (a: {principalId: null; roles: string | string[]}) => {
        if (a.roles.includes('admin') || a.roles.includes('operator')) {
          isAdmin = true;
        }
      },
    );
    return isAdmin;
  }

  /**
   * Can we see (but maybe not modify) the company users
   * @param company - The company to check for visibility
   */
  canAccessCompanyUsers(company: Company | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!company) return false;
    let companyId: Identifier | null = null;
    if (typeof company === 'object') {
      companyId = company.id;
    } else {
      companyId = company;
    }
    if (companyId) {
      let isAdmin = false;
      this.priviledgedCompanies.forEach(
        (a: {principalId: null; roles: string | string[]}) => {
          if (a.principalId === companyId && a.roles.includes('admin')) {
            isAdmin = true;
          }
        },
      );
      return isAdmin;
    }
    return false;
  }

  /**
   * Can we modify the company users
   * @param company - The company to check for permissions
   */
  canModifyCompanyUsers(company: Company | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!company) return false;
    let companyId: Identifier | null = null;
    if (typeof company === 'object') {
      companyId = company.id;
    } else {
      companyId = company;
    }
    if (companyId) {
      let isAdmin = false;
      this.priviledgedCompanies.forEach(
        (a: {principalId: null; roles: string | string[]}) => {
          if (a.principalId === companyId && a.roles.includes('admin')) {
            isAdmin = true;
          }
        },
      );
      return isAdmin;
    }
    return false;
  }

  /**
   * Check if we can access the stats for the given account or account identifier
   * @param account - The account to check access
   */
  canAccessAccountSettings(account: Account | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!account) return false;
    if (
      typeof account === 'object' &&
      account.companyId &&
      this.canAccessCompanySettings(account.companyId)
    ) {
      return true;
    }
    const accountId = typeof account === 'object' ? account.id : account;
    return (
      accountId &&
      this.priviledgedAccounts.some(
        (account: any) =>
          account.principalId === accountId &&
          (account.roles.includes('admin') ||
            account.roles.includes('viewer') ||
            account.roles.includes('operator') ||
            account.roles.includes('developer')),
      )
    );
  }

  /**
   * Check if we can access the stats for the given account or account identifier
   * @param account - The account to check access
   */
  canAccessAccountStats(account: Account | Identifier | null) {
    if (this.isAdmin()) return true;
    if (!account) return false;
    if (
      typeof account === 'object' &&
      account.companyId &&
      this.canAccessCompanyStats(account.companyId)
    ) {
      return true;
    }
    const accountId = typeof account === 'object' ? account.id : account;
    return (
      accountId &&
      this.priviledgedAccounts.some(
        (account: any) =>
          account.principalId === accountId &&
          (account.roles.includes('admin') ||
            account.roles.includes('viewer') ||
            account.roles.includes('operator')),
      )
    );
  }

  /**
   * Check if we can access the list of users who have access to the given account
   * @param account - the account object or ID
   */
  canAccessAccountUsers(account: any) {
    if (this.isAdmin()) return true;
    if (!account) return false;
    if (
      typeof account === 'object' &&
      account.companyId &&
      this.canAccessCompanyUsers(account.companyId)
    ) {
      return true;
    }
    let accountId: Identifier | null = null;
    if (typeof account === 'object') {
      accountId = account.id;
    } else {
      accountId = account;
    }
    if (accountId) {
      let isAdmin = false;
      this.priviledgedAccounts.forEach((a: any) => {
        if (a.principalId === accountId && a.roles.includes('admin')) {
          isAdmin = true;
        }
      });
      return isAdmin;
    }
    return false;
  }

  /**
   * Check if we can modify the list of users who have access to the given account
   * @param account - the account object or ID
   */
  canModifyAccountUsers(account: any) {
    if (this.isAdmin()) return true;
    if (!account) return false;
    if (
      typeof account === 'object' &&
      account.companyId &&
      this.canModifyCompanyUsers(account.companyId)
    ) {
      return true;
    }
    let accountId: Identifier | null = null;
    if (typeof account === 'object') {
      accountId = account.id;
    } else {
      accountId = account;
    }
    if (accountId) {
      let isAdmin = false;
      this.priviledgedAccounts.forEach((a: any) => {
        if (a.principalId === accountId && a.roles.includes('admin')) {
          isAdmin = true;
        }
      });
      return isAdmin;
    }
    return false;
  }

  /**
   * Check if we can edit settings for an account
   * @param account - the account object or the account ID
   */
  canModifyAccountSettings(account: any) {
    if (this.isAdmin()) return true;
    if (!account) return false;
    if (
      typeof account === 'object' &&
      account.companyId &&
      this.canModifyCompanySettings(account.companyId)
    ) {
      return true;
    }
    let accountId: Identifier | null = null;
    if (typeof account === 'object') {
      accountId = account.id;
    } else {
      accountId = account;
    }
    if (accountId) {
      let isAdmin = false;
      this.priviledgedAccounts.forEach((a: any) => {
        if (
          a.principalId === accountId &&
          (a.roles.includes('admin') || a.roles.includes('operator'))
        ) {
          isAdmin = true;
        }
      });
      return isAdmin;
    }
    return false;
  }

  hasCompanyAccess() {
    return (
      this.priviledgedCompanies.length > 0 ||
      this.isPriviledgedUserForRoles(this.roles)
    );
  }

  hasCompanyAccessAsAdmin() {
    let result = false;
    this.priviledgedCompanies.forEach(c => {
      if (c.roles.indexOf('admin') !== -1) {
        result = true;
      }
    });
    if (this.roles.indexOf('superAdmin') !== -1) result = true;
    return result;
  }

  hasAccountAccess() {
    return this.priviledgedAccounts.length > 0 || this.hasCompanyAccess();
  }

  isPriviledgedRole(name: string) {
    return name === 'superAdmin' || name === 'admin' || name === 'reader';
  }

  isPriviledgedUserForRoles(roles: string[]) {
    let result = false;
    roles.forEach((role: string) => {
      if (this.isPriviledgedRole(role)) result = true;
    });
    return result;
  }
}

let lastAuthError: any = null;
const authProvier: AuthProvider = {
  login: (params: any) => {
    lastAuthError = null;
    const request = new Request(
      `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/login`,
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({'Content-Type': 'application/json'}),
      },
    );
    return fetch(request)
      .then(response => {
        return response.json();
      })
      .then(({ttl, ...data}) => {
        if (data.error) {
          lastAuthError = data.error;
          throw new Error(data.error.code);
        } else {
          storage.save('lbtoken', data, ttl);
        }
      });
  },
  logout: () => {
    storage.remove('lbtoken');
    return Promise.resolve();
  },
  checkError: ({status}) => {
    if (status === 401) {
      storage.remove('lbtoken');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: params => {
    const token = storage.load('lbtoken');
    if (token && token.id) {
      return Promise.resolve();
    } else {
      storage.remove('lbtoken');
      return Promise.reject({redirectTo: params.noAccessPage || '/login'});
    }
  },
  getPermissions: () => {
    const token = storage.load('lbtoken');
    return Promise.resolve([new Permission(token)]);
  },
  clearLastError: () => {
    lastAuthError = null;
    return Promise.resolve();
  },
  getLastError: () => {
    return Promise.resolve(lastAuthError);
  },
};

export default authProvier;
