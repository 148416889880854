import {PRODUCTS} from '@idviu/backbone-api-client';
import React from 'react';
import {Create, FormTab, ReferenceInput} from 'react-admin';
import 'url-search-params-polyfill';
import {v4 as uuid} from 'uuid';
import {SelectInput, TabbedForm, TextInput} from '../components';

const redirect = (base, id, data) => {
  if (data.accountId) {
    return '/accounts/' + data.accountId + '/show/productAccess';
  } else {
    return 'edit';
  }
};

const ProductAccessCreate = props => {
  return (
    <>
      <Create {...props}>
        <TabbedForm redirect={redirect}>
          <FormTab label="summary">
            <ReferenceInput source="companyId" reference="companies">
              <SelectInput optionText="name" disabled />
            </ReferenceInput>
            <ReferenceInput source="accountId" reference="accounts">
              <SelectInput optionText="name" disabled />
            </ReferenceInput>

            <SelectInput
              label="Product"
              source="product"
              choices={Object.values(PRODUCTS).map(t => ({id: t, name: t}))}
            ></SelectInput>
            <TextInput source="apiKey" defaultValue={uuid()} />
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};

export default ProductAccessCreate;
