import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/styles';
import {Identifier, useDataProvider} from 'ra-core';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  fetchEnd,
  fetchStart,
  SaveButton,
  showNotification,
  TextInput,
} from 'react-admin';
import {useField, useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import SimpleForm from './SimpleForm';

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    float: 'right',
    marginBottom: '2em',
  },
});

interface SaveButtonProps {
  onComplete(id: Identifier): void;
}

const MySaveButton: FC<SaveButtonProps> = ({onComplete}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const values = formState.values;

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    dataProvider
      .create('credentials', {data: values})
      .then(({data}) => {
        // Update the main react-admin form (in this case, the comments creation form)
        onComplete(data.id);
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [formState.valid, formState.values, dataProvider, dispatch, onComplete]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="pos.button.create"
    />
  );
};

interface FormActionsProps {
  basePath?: unknown;
  setShowDialog?: unknown;
  handleCloseClick: (event: any) => void;
}

const FormActions: FC<FormActionsProps> = ({
  basePath,
  setShowDialog,
  handleCloseClick,
  ...props
}) => (
  <DialogActions {...props}>
    <MySaveButton onComplete={handleCloseClick} />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

const AddNewCredentialsButton = () => {
  const classes = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const formState = useFormState();
  const credId = useField('credentialId');
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = (event: any) => {
    setShowDialog(false);
    credId.input.onChange(event);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label="ra.action.add"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add new credentials"
      >
        <DialogTitle>Add new credentials</DialogTitle>
        <DialogContent>
          <SimpleForm
            resource={ENTITY_COLLECTIONS.credential}
            // We want no toolbar at all as we have our modal actions
            toolbar={undefined}
          >
            <TextInput
              source="principalId"
              label="Owner"
              defaultValue={formState.values && formState.values.principalId}
              disabled
            />
            <TextInput
              source="principalType"
              label="Owner type"
              defaultValue={formState.values && formState.values.principalType}
              disabled
            />
            <TextInput source="alias" label="Alias" />
            <TextInput source="login" label="Login" />
            <TextInput source="password" label="Password" variant="outlined" />
            <FormActions
              setShowDialog={setShowDialog}
              handleCloseClick={handleCloseClick}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewCredentialsButton;
