import {PasswordHashAlgorithm} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {SelectInput, SelectInputProps} from 'react-admin';
import {PASSWORD_HASH_ALGORITHM_CHOICES} from './common';

export type PasswordHashInputProps = Omit<
  SelectInputProps,
  'choices' | 'defaultValue'
>;

export const PasswordHashInput: FC<PasswordHashInputProps> = props => (
  <SelectInput
    {...props}
    choices={PASSWORD_HASH_ALGORITHM_CHOICES}
    variant="outlined"
    defaultValue={PasswordHashAlgorithm.bcrypt}
  />
);

PasswordHashInput.defaultProps = {
  source: 'security.passwordHash',
  required: true,
  fullWidth: true,
};
