import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {ButtonProps, makeStyles} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  ListContextProvider,
  ResourceComponentPropsWithId,
  useListController,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'ra-core';
import {FC} from 'react';
import {
  Button,
  DeleteButton,
  ListProps,
  Pagination,
  ReferenceField,
  RichTextField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {Datagrid, DateField, useCurrentAccount} from '../components';
import {InviteLinkButton} from './InviteLinkButton';

const useStyle = makeStyles(theme => ({
  button: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },
}));

const AddParticipantButton: FC<ButtonProps> = () => {
  const classes = useStyle();
  const record = useRecordContext();
  return (
    <Button
      label="pos.button.add_participant"
      variant="contained"
      className={classes.button}
      component={Link}
      to={{
        pathname: '/virtualEventAccesses/create',
        state: {
          record: {virtualEventId: record.id},
        },
        search: `?source=${JSON.stringify({
          virtualEventId: record.id,
        })}`,
      }}
    >
      <AddIcon />
    </Button>
  );
};

const ParticipantsList: FC = (props: any) => {
  const {record} = props;
  const filter = {
    virtualEventId: record?.id,
  };
  const theprops: ListProps = {
    ...props,
    basePath: '/virtualEventAccesses',
    resource: ENTITY_COLLECTIONS.virtualEventAccess,
    filter: {...filter},
  };
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  return (
    <>
      {loaded && permissions[0].canModifyAccountSettings(account) && (
        <>
          <AddParticipantButton />
          <InviteLinkButton record={record} />
        </>
      )}
      {
        <ListContextProvider value={useListController(theprops)}>
          <Datagrid basePath={theprops.basePath} resource={theprops.resource}>
            <ReferenceField
              label="resources.users.fields.email"
              source="userId"
              reference={ENTITY_COLLECTIONS.user}
              link={false}
            >
              <TextField source="email" />
            </ReferenceField>
            <ReferenceField
              label="resources.users.fields.username"
              source="userId"
              reference={ENTITY_COLLECTIONS.user}
              link={false}
            >
              <TextField source="username" />
            </ReferenceField>
            <ReferenceField
              label="resources.users.fields.firstName"
              source="userId"
              reference={ENTITY_COLLECTIONS.user}
              link={false}
            >
              <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField
              label="resources.users.fields.lastName"
              source="userId"
              reference={ENTITY_COLLECTIONS.user}
              link={false}
            >
              <TextField source="lastName" />
            </ReferenceField>
            <ReferenceField
              label="resources.virtualEvents.fields.role"
              source="roleId"
              reference={ENTITY_COLLECTIONS.role}
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            {loaded && permissions[0].canModifyAccountSettings(account) && (
              <DeleteButton undoable={false} redirect={false} />
            )}
          </Datagrid>

          <Pagination />
        </ListContextProvider>
      }
    </>
  );
};

const VirtualEventTitle = ({record}: any) => {
  const t = useTranslate();
  return (
    <span>
      {t('resources.virtualEvents.desc.title')}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

export const VirtualEventShow: FC<ResourceComponentPropsWithId> = props => (
  <Show {...props} title={<VirtualEventTitle />}>
    <TabbedShowLayout>
      <Tab label="resources.virtualEvents.tabs.settings">
        <TextField source="name" />
        <RichTextField source="description" />
        <TextField source="image" />
        <TextField source="logo" />
        <DateField source="start" showTime />
        <DateField source="end" showTime />
      </Tab>
      <Tab
        label="resources.virtualEvents.tabs.participants"
        path="participants"
      >
        <ParticipantsList />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
