import {RealmPrincipalType} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {RadioButtonGroupInput} from 'react-admin';

const PRINCIPAL_TYPES = Object.keys(RealmPrincipalType).map(id => ({
  id,
  name: `pos.principal.${id}`,
}));

export const PrincipalTypeInput: FC = props => (
  <RadioButtonGroupInput
    {...props}
    source="principalType"
    choices={PRINCIPAL_TYPES}
    defaultValue={RealmPrincipalType.account}
    required
  />
);

PrincipalTypeInput.defaultProps = {
  fullWidth: true,
};
