import {
  EntityType,
  ENTITY_COLLECTIONS,
  ProgrammingLanguage,
  PROGRAMMING_LANGUAGES_EXTENSIONS,
} from '@idviu/backbone-core';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import IconReceipt from '@material-ui/icons/Receipt';
import {ListBase, useListContext, usePermissions, useTranslate} from 'ra-core';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  fetchEnd,
  fetchStart,
  FieldProps,
  Filter,
  ListProps,
  sanitizeListRestProps,
  SaveButton,
  SelectInput,
  showNotification,
  SimpleForm,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {HelpCard} from '../components';
import storage from '../dataProvider/storage';

const ConstantFilter = (props: any) => {
  const {...otherProps} = props;
  const {loaded, permissions} = usePermissions();

  const choices =
    loaded && permissions[0].isAdmin()
      ? [
          {id: 'hss_setting', name: 'resources.constants.type.hss_setting'},
          {
            id: 'player_setting',
            name: 'resources.constants.type.player_setting',
          },
          {
            id: 'checkin_setting',
            name: 'resources.constants.type.checkin_setting',
          },
          {id: 'idviu_event', name: 'resources.constants.type.idviu_event'},
          {
            id: 'legacy_player_error',
            name: 'resources.constants.type.legacy_player_error',
          },
          {
            id: 'legacy_player_info',
            name: 'resources.constants.type.legacy_player_info',
          },
          {
            id: 'legacy_download_error',
            name: 'resources.constants.type.legacy_download_error',
          },
        ]
      : [
          {id: 'idviu_event', name: 'resources.constants.type.idviu_event'},
          {
            id: 'legacy_player_error',
            name: 'resources.constants.type.legacy_player_error',
          },
          {
            id: 'legacy_player_info',
            name: 'resources.constants.type.legacy_player_info',
          },
          {
            id: 'legacy_download_error',
            name: 'resources.constants.type.legacy_download_error',
          },
        ];
  return (
    <Filter {...otherProps} variant="outlined">
      <TextInput
        label="pos.search"
        source="q$name$value$description$extra"
        alwaysOn={true}
      />
      <SelectInput
        source="type"
        choices={choices}
        allowEmpty={false}
        alwaysOn
      />
    </Filter>
  );
};

const DetailsButton: FC<FieldProps> = ({record = {}}) => {
  return (
    <Button
      variant="contained"
      component={Link}
      to={{
        pathname: '/constants/' + record.id + '/show',
      }}
      label="pos.button.details"
    />
  );
};

const downloadData = (data: any, filename: string) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([new Uint8Array(data)], {
    type: 'application/octet-stream',
  });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}`);
  fakeLink.click();
};

const DownloadButton = ({close}: {close: () => void}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const {filterValues} = useListContext();
  const handleDownloadClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }
    const params = formState.values;
    const extension =
      PROGRAMMING_LANGUAGES_EXTENSIONS[
        params.language as ProgrammingLanguage
      ] ?? '';
    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    const url = new URL(`${BACKBONE_REST_API_V1_URL}/constants/download`);
    Object.keys(params).forEach(key => {
      url.searchParams.append(key, params[key]);
    });
    url.searchParams.append(
      'filter',
      JSON.stringify({where: {type: filterValues.type}}),
    );

    fetch(url.toString(), {
      headers: {authorization: storage.load('lbtoken').id},
    })
      .then(async response => {
        if (response.status >= 400) {
          throw JSON.parse(await response.text());
        }
        return response.arrayBuffer();
      })
      .then(data =>
        downloadData(
          data,
          (params.className || EntityType.constant) + extension,
        ),
      )
      .catch(err => {
        dispatch(
          showNotification(
            'Could not download file: ' +
              (err.error && err.error.message
                ? err.error.message
                : JSON.stringify(err)),
          ),
        );
      })
      .finally(() => {
        dispatch(fetchEnd());
        close();
      });
  }, [dispatch, formState.valid, formState.values, close, filterValues.type]);
  return (
    <SaveButton
      handleSubmitWithRedirect={handleDownloadClick}
      label="pos.button.download"
    />
  );
};

const ConstantDownloadActions = ({close, ...props}: {close: () => void}) => (
  <DialogActions {...props}>
    <DownloadButton close={close} />
    <Button label="ra.action.cancel" onClick={() => close()}>
      <IconCancel />
    </Button>
  </DialogActions>
);

const ExportCodeButton = () => {
  const [isOpen, setOpen] = useState(false);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const translate = useTranslate();
  return (
    <>
      <Dialog open={isOpen} onClose={close}>
        <DialogTitle>
          {translate('resources.constants.download_code')}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {translate('resources.constants.desc.download_code')}
          </Typography>
          <SimpleForm
            resources={ENTITY_COLLECTIONS.constant}
            // We want no toolbar at all as we have our modal actions
            toolbar={<div />}
          >
            <SelectInput
              variant="outlined"
              source="type"
              choices={[
                {
                  id: 'idviu_event',
                  name: 'resources.constants.type.idviu_event',
                },
              ]}
              defaultValue={'idviu_event'}
              style={{display: 'none'}}
              disabled
            />
            <SelectInput
              label="resources.constants.fields.language"
              variant="outlined"
              source="language"
              choices={[
                {id: ProgrammingLanguage.java, name: 'Java'},
                {id: ProgrammingLanguage.c, name: 'C Header'},
                {id: ProgrammingLanguage.cs, name: 'C#'},
              ]}
              validate={(value: any) =>
                value ? undefined : 'You must select a language'
              }
            />
            <TextInput
              label="resources.constants.fields.className"
              variant="outlined"
              source="className"
            />
            <ConstantDownloadActions close={close} />
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        label={translate('resources.constants.download_code')}
      >
        <IconReceipt />
      </Button>
    </>
  );
};

const useActionStyle = makeStyles(() => ({
  help: {
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    justifySelf: 'flex-end',
  },
}));

const ConstantActions = (props: any) => {
  const {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    className,
    exporter,
    filters,
    permanentFilter,
    maxResults,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...rest
  } = props;
  const {basePath, filterValues} = useListContext();
  const {loaded, permissions} = usePermissions();
  const classes = useActionStyle();

  return (
    <TopToolbar {...sanitizeListRestProps(rest)} className={classes.toolbar}>
      {loaded && permissions[0].isAdmin() && (
        <CreateButton basePath={basePath} />
      )}
      {filterValues && filterValues.type === 'idviu_event' && (
        <ExportCodeButton />
      )}
    </TopToolbar>
  );
};

ConstantActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const FlexibleDatagrid: FC = () => {
  const {filterValues} = useListContext();
  const {loaded, permissions} = usePermissions();
  const needsExtra =
    filterValues &&
    filterValues.type &&
    filterValues.type.startsWith('legacy_');

  return (
    <Datagrid>
      <TextField source="name" />
      <TextField source="value" />
      {needsExtra && <TextField source="extra" />}
      <TextField source="description" />
      <DetailsButton />
      {loaded && permissions[0].isAdmin() && <EditButton />}
      {loaded && permissions[0].isAdmin() && (
        <DeleteButton undoable={false} redirect={false} />
      )}
    </Datagrid>
  );
};

const ConstantList: FC<ListProps> = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <ListBase
        {...props}
        perPage={25}
        filterDefaultValues={{type: 'idviu_event'}}
        actions={<ConstantActions />}
        canDelete={loaded && permissions[0].isAdmin()}
        canCreate={loaded && permissions[0].isAdmin()}
        sort={{field: 'name', order: 'ASC'}}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ConstantFilter />
          <ConstantActions />
        </div>
        <HelpCard
          style={{marginBottom: '1em'}}
          text="resources.constants.help"
          reference="constants"
        />
        <Card>
          <FlexibleDatagrid />
        </Card>
      </ListBase>
    </>
  );
};

export default ConstantList;
