import {stringify} from 'query-string';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import fetchJson from './fetch';

const dataProvider = (apiUrl: string, httpClient = fetchJson) => {
  /**
   * @param type - One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param resource - Name of the resource to fetch, e.g. 'posts'
   * @param params - The data request params, depending on the type
   * @returns The HTTP request parameters
   */
  function convertDataRequestToHTTP(
    type: string,
    resource: string,
    params: any,
  ): {
    url: string;
    options: {method: 'PATCH' | 'POST' | 'DELETE'; body: string};
  } {
    let url = '';
    const options: any = {};
    //resource = remapResource(type, resource, params, options);
    switch (type) {
      case GET_LIST: {
        const {page, perPage} = params.pagination ?? {page: 1, perPage: 100};
        const {field, order} = params.sort ?? {field: 'id', order: 'ASC'};
        let include = params.include;
        const fields = params.fields;
        const query: Record<string, any> = {};
        const multiFields: any[] = [];
        if (params.filter && params.filter.include) {
          include = params.filter.include;
          delete params.filter.include;
        }
        if (params.filter && params.filter.qname) {
          params.filter.name = {like: params.filter.qname, options: 'i'};
          delete params.filter.qname;
        }
        if (params.filter && params.filter.qserviceId) {
          params.filter.serviceId = {
            like: params.filter.qserviceId,
            options: 'i',
          };
          delete params.filter.qserviceId;
        }
        if (params.filter) {
          Object.keys(params.filter).forEach(e => {
            if (e.startsWith('q$')) {
              const keys = e.split('$');
              const or = [];
              for (let i = 1; i < keys.length; i++) {
                const cond: Record<string, any> = {};
                cond[keys[i]] = {like: params.filter[e], options: 'i'};
                or.push(cond);
              }
              multiFields.push({or: or});
              delete params.filter[e];
            }
          });
        }
        if (multiFields.length > 0) {
          query.where = {and: [{...params.filter}]};
          multiFields.forEach(e => {
            query.where.and.push(e);
          });
        } else {
          query.where = {...params.filter};
        }
        if (field) query.order = [field + ' ' + order];
        if (perPage > 0) {
          query.limit = perPage;
          if (page >= 0) query.offset = (page - 1) * perPage;
        }
        if (include) query.include = include;
        if (fields) query.fields = fields;
        url = `${apiUrl}/${resource}?${stringify({
          filter: JSON.stringify(query),
        })}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        const listId = params.ids.map((id: string) => {
          return {id};
        });
        const query = {
          where: {or: listId},
        };
        url = `${apiUrl}/${resource}?${stringify({
          filter: JSON.stringify(query),
        })}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const include = params.include;
        const fields = params.fields;
        const query: any = {};
        query.where = {...params.filter};
        if (params.target) query.where[params.target] = params.id;
        if (field) query.order = [field + ' ' + order];
        if (perPage > 0) {
          query.limit = perPage;
          if (page >= 0) query.skip = (page - 1) * perPage;
        }
        if (include) query.include = include;
        if (fields) query.fields = fields;
        url = `${apiUrl}/${resource}?${stringify({
          filter: JSON.stringify(query),
        })}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        if (!options.method) options.method = 'PATCH';
        options.body = JSON.stringify(params.data);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        if (!options.method) options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        if (!options.method) options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return {url, options};
  }

  /**
   * @param response - HTTP response from fetch()
   * @param type - One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param resource - Name of the resource to fetch, e.g. 'posts'
   * @param params - The data request params, depending on the type
   * @returns Data response
   */
  const convertHTTPResponse = (
    response: {headers: any; json: any},
    type: string,
    params: {id?: string; data: unknown},
  ) => {
    const {headers, json} = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
          );
        }
        return {
          data: json,
          total: parseInt(headers.get('content-range').split('/').pop(), 10),
        };
      case CREATE:
        return {data: {...json}};
      case UPDATE:
      case DELETE:
        const existing = (params.data as object) ?? {};
        return {data: {...existing, ...json, id: params.id}};
      default:
        return {data: json};
    }
  };

  /**
   * @param type - Request type, e.g GET_LIST
   * @param resource - Resource name, e.g. "posts"
   * @param payload - Request parameters. Depends on the request type
   * @returns the Promise for a data response
   */
  return (
    type: string,
    resource: string,
    params: {ids?: string[]; id?: string; data: unknown},
  ) => {
    if (type === UPDATE_MANY || type === DELETE_MANY) {
      if (!params.ids) {
        const msg = `ids param is required when type is UPDATE_MANY or DELETE_MANY`;
        return Promise.reject(new Error(msg));
      }
    }
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      //resource = remapResource(type, resource, params, options);
      return Promise.all<any>(
        params.ids!.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          }),
        ),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      //resource = remapResource(type, resource, params, options);
      return Promise.all(
        params.ids!.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then(responses => ({
        data: responses.map(response => response.json),
      }));
    }
    try {
      const {url, options} = convertDataRequestToHTTP(type, resource, params);
      return httpClient(url, options).then(response =>
        convertHTTPResponse(response, type, params),
      );
    } catch (err) {
      return Promise.reject('Exception thrown in dataProvider:' + err);
    }
  };
};

export default dataProvider;
