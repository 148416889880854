import React from 'react';
import {Create, TextInput} from 'react-admin';
import {SimpleForm} from '../components';

const CompanyCreate = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm redirect="list">
          <TextInput source="name" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default CompanyCreate;
