import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {stringify} from 'query-string';
import React, {Component} from 'react';
import {CardActions, List, Responsive} from 'react-admin';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import 'url-search-params-polyfill';
import {changeCompany} from '../actions/actions';
import ServerAccessListDesktop from './ServerAccessListDesktop';
import ServerAccessListMobile from './ServerAccessListMobile';

const CustomTitle = ({server}) => {
  return <span>{server && server.name ? server.name : ''} accesses</span>;
};

const CustomActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  location,
  onUnselectItems,
  resource,
  router,
  selectedIds,
  showFilter,
  total,
  translate,
}) => (
  <CardActions>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/serverAccesses/create', // mktodo: this is deprecated, right? remove if yes
        search:
          filterValues && filterValues.companyId
            ? stringify({companyId: filterValues.companyId})
            : '',
      }}
    >
      <AddIcon />
      {translate('pos.button.add')}
    </Button>
  </CardActions>
);

class TheList extends Component {
  render() {
    var props = Object.assign({}, this.props);
    if (!props.company) {
      props.history.push('/companies');
    }
    delete props.changeCompany;

    return props.company ? (
      <>
        <List
          {...props}
          title={<CustomTitle {...props} />}
          actions={<CustomActions {...props} />}
          perPage={25}
          filter={
            props.company.companyId
              ? {companyId: props.company.companyId}
              : null
          }
          sort={{field: 'name', order: 'ASC'}}
        >
          <Responsive
            xsmall={<ServerAccessListMobile />}
            medium={<ServerAccessListDesktop />}
          />
        </List>
      </>
    ) : null;
  }
}

const mapStateToProps = state => ({company: state.company});

export default connect(mapStateToProps, {changeCompany})(TheList);
