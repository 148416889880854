import {Button} from '@material-ui/core';
import {FC} from 'react';
import {
  Edit,
  EditProps,
  TopToolbar,
  useEditController,
  useTranslate,
} from 'react-admin';
import {useHistory} from 'react-router-dom';
import CatalogSetup from './CatalogSetup';

const EditActions: FC = () => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        {translate('pos.button.cancel')}
      </Button>
    </TopToolbar>
  );
};

const CatalogTitle: FC<EditProps> = props => {
  const {record} = useEditController(props);
  const translate = useTranslate();
  if (record) {
    return (
      <div>
        {translate('resources.catalogs.name', {smart_count: 1})} {record.name}
      </div>
    );
  } else {
    return <div>{translate('resources.catalogs.name', {smart_count: 1})}</div>;
  }
};

const CatalogEdit: FC<EditProps> = props => {
  return (
    <>
      <Edit
        title={<CatalogTitle {...props} />}
        {...props}
        actions={<EditActions />}
      >
        <CatalogSetup />
      </Edit>
    </>
  );
};

export default CatalogEdit;
