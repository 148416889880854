import CameraswitchIcon from '@material-ui/icons/VideocamRounded';
import VirtualEventCreate from './VirtualEventCreate';
import VirtualEventEdit from './VirtualEventEdit';
import VirtualEventList from './VirtualEventList';
import {VirtualEventShow} from './VirtualEventShow';

const content = {
  list: VirtualEventList,
  icon: CameraswitchIcon,
  create: VirtualEventCreate,
  edit: VirtualEventEdit,
  show: VirtualEventShow,
};

export default content;
