import {
  ENTITY_COLLECTIONS,
  VirtualEventAccess,
} from '@idviu/backbone-api-client';
import {CreateProps, ReferenceInput, SimpleForm} from 'ra-ui-materialui';
import {FC} from 'react';
import {required} from 'react-admin';
import {User} from '../backbone-records';
import {
  AutocompleteInput,
  Create,
  PleaseSelectAccount,
  SelectInput,
  useCurrentAccount,
} from '../components';

const VirtualEventAccessCreate: FC<CreateProps> = props => {
  const account = useCurrentAccount();
  const redirect = (_basePath: string, _id: string, data: VirtualEventAccess) =>
    `/virtualEvents/${data.virtualEventId}/show/participants`;

  return account ? (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          label="resources.virtualEventAccesses.fields.virtualEvent"
          source="virtualEventId"
          reference={ENTITY_COLLECTIONS.virtualEvent}
          validate={required()}
        >
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(text: string) => ({
            q$email$firstName$lastName: text,
            realmId: account?.realmId,
          })}
          label="resources.virtualEventAccesses.fields.participant"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          validate={required()}
        >
          <AutocompleteInput
            translateChoice={false}
            optionText={(record: User | undefined) => {
              if (record?.firstName && record?.lastName) {
                return `${record?.firstName} ${record?.lastName} (${record?.email})`;
              } else {
                return `${record?.username} (${record?.email})`;
              }
            }}
            matchSuggestion={(
              filterValue: string,
              choice: User | undefined,
            ) => {
              return (
                choice?.firstName?.match(filterValue) ||
                choice?.lastName?.match(filterValue) ||
                choice?.email?.match(filterValue)
              );
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="resources.virtualEventAccesses.fields.role"
          source="roleId"
          filter={{name: {inq: ['admin', 'viewer']}}}
          reference={ENTITY_COLLECTIONS.role}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  ) : (
    <PleaseSelectAccount />
  );
};

export default VirtualEventAccessCreate;
