import {Button} from '@material-ui/core';
import React from 'react';
import {Create, CreateProps, TopToolbar, useTranslate} from 'react-admin';
import {useHistory} from 'react-router-dom';
import CatalogSetup from './CatalogSetup';

const CreateActions = () => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        {translate('pos.button.cancel')}
      </Button>
    </TopToolbar>
  );
};

const CatalogCreate: React.FC<CreateProps> = props => {
  return (
    <Create {...props} actions={<CreateActions />}>
      <CatalogSetup />
    </Create>
  );
};

export default CatalogCreate;
