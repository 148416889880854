import {
  AudioCodecType,
  DrmSystemId,
  IAudioTrack,
  ISubtitleTrack,
  IVideoTrack,
  MediaTrackType,
  SubtitleCodecType,
  VideoCodecType,
} from '@idviu/backbone-api-client';
import {notNil} from '@idviu/ts-helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {Code, Settings} from '@material-ui/icons';
import {get} from 'lodash';
import {FC} from 'react';
import {
  DateField,
  FieldProps,
  FunctionField,
  NumberField,
  Record as RaRecord,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  Translate,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import {EnumField, MetadataField, useCurrentAccount} from '../components';
import {OutputFile} from '../types';

type MediaTrack = IAudioTrack | IVideoTrack | ISubtitleTrack;

const TracksTableHeader: FC = () => {
  const tr = useTranslate();
  return (
    <TableHead>
      <TableRow>
        <TableCell>{tr('resources.outputFiles.tracks.fields.type')}</TableCell>
        <TableCell>{tr('resources.outputFiles.tracks.fields.codec')}</TableCell>
        <TableCell>{tr('resources.outputFiles.tracks.fields.name')}</TableCell>
        <TableCell>{tr('resources.outputFiles.tracks.fields.width')}</TableCell>
        <TableCell>
          {tr('resources.outputFiles.tracks.fields.height')}
        </TableCell>
        <TableCell>
          {tr('resources.outputFiles.tracks.fields.profile')}
        </TableCell>
        <TableCell>
          {tr('resources.outputFiles.tracks.fields.framerate')}
        </TableCell>
        <TableCell>
          {tr('resources.outputFiles.tracks.fields.channels')}
        </TableCell>
        <TableCell>
          {tr('resources.outputFiles.tracks.fields.sampleRate')}
        </TableCell>

        <TableCell>
          {tr('resources.outputFiles.tracks.fields.language')}
        </TableCell>
        <TableCell>{tr('resources.outputFiles.tracks.fields.role')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const TypeCell: FC<{type: MediaTrackType}> = ({type}) => {
  const tr = useTranslate();
  return <TableCell>{tr(`resources.mediaTrackTypes.${type}`)}</TableCell>;
};

const CodecCell: FC<{
  type: MediaTrackType;
  codec?: AudioCodecType | VideoCodecType | SubtitleCodecType;
}> = ({type, codec}) => {
  const tr = useTranslate();
  return <TableCell>{tr(`resources.${type}Codecs.${codec}`)}</TableCell>;
};

const RoleCell: FC<{track: MediaTrack}> = ({track}) => {
  const tr = useTranslate();
  return (
    <TableCell>
      {track.role ? tr(`resources.mediaTrackRoles.${track.role}`) : ''}
    </TableCell>
  );
};

const TracksTableBody: FC<{tracks: MediaTrack[]}> = ({tracks}) => (
  <TableBody>
    {tracks.map((track, index) => (
      <TableRow key={index}>
        <TypeCell type={track.type} />
        <CodecCell type={track.type} codec={track.codec} />
        <TableCell>{track.metadata?.name}</TableCell>
        <TableCell>{track.width}</TableCell>
        <TableCell>{track.height}</TableCell>
        <TableCell>{track.profile}</TableCell>
        <TableCell>{track.framerate}</TableCell>
        <TableCell>{track.channels}</TableCell>
        <TableCell>{track.sampleRate}</TableCell>
        <TableCell>{track.language}</TableCell>

        <RoleCell track={track} />
      </TableRow>
    ))}
  </TableBody>
);

export const TracksField: FC<FieldProps> = props => {
  const {source} = props;
  notNil(source);
  const record = useRecordContext(props);
  const tracks: MediaTrack[] = get(record, source, []);
  return (
    <Table>
      <TracksTableHeader />
      <TracksTableBody tracks={tracks} />
    </Table>
  );
};

TracksField.defaultProps = {
  addLabel: true,
  label: 'resources.outputFiles.fields.tracks',
};

export function getSystemIdName(id: DrmSystemId): string {
  const index = Object.values(DrmSystemId).indexOf(id);
  if (index === -1) return '';
  return Object.keys(DrmSystemId)[index];
}

export function getActiveDrm(
  file: OutputFile | undefined,
  tr: Translate,
): string {
  if (!file) return '';
  const drm: Array<string> = [];
  if (file.drm?.systemIds) {
    drm.push(...(file.drm?.systemIds.map(s => getSystemIdName(s)) ?? []));
  }
  file.tracks?.forEach(t => {
    if (t.drm && t.drm.systemIds) {
      drm.push(...(t.drm.systemIds.map(s => getSystemIdName(s)) ?? []));
    }
  });

  return drm.map(name => tr(`resources.drm.${name}`)).join(', ');
}

export function getBitrates(bitrates: number[] = []): string {
  return bitrates.join(', ');
}

export const OutputFileShow: FC<ShowProps> = props => {
  const tr = useTranslate();
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();

  return (
    <Show
      {...props}
      hasEdit={loaded && permissions[0].canModifyAccountSettings(account)}
    >
      <TabbedShowLayout>
        <Tab label="resources.contents.tabs.general" icon={<Settings />}>
          <TextField source="id" />
          <EnumField source="format" labelPath="resources.outputFileFormats" />
          <NumberField source="width" />
          <NumberField source="height" />
          <FunctionField
            label="resources.outputFiles.fields.bitrates"
            render={(record?: RaRecord) => getBitrates(record?.bitrates)}
          />
          <FunctionField
            label="resources.outputFiles.fields.drm"
            render={(record?: OutputFile) => getActiveDrm(record, tr)}
          />
          <TracksField source="tracks" />
          <TextField source="url" />
          <DateField source="created" showTime={true} />
          <DateField source="modified" showTime={true} />
        </Tab>
        <Tab label="pos.fields.metadata" icon={<Code />}>
          <MetadataField source="metadata" addLabel={false} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
