import {makeStyles} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import MuiToolbar, {
  ToolbarProps as MuiToolbarProps,
} from '@material-ui/core/Toolbar';
import withWidth from '@material-ui/core/withWidth';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {MutationMode, Record, RedirectionSideEffect} from 'ra-core';
import * as React from 'react';
import {
  Children,
  FC,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import {ClassesOverride, DeleteButton, SaveButton} from 'react-admin';
import {FormRenderProps} from 'react-final-form';

const useStyles = makeStyles(
  theme => ({
    toolbar: {
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '16px',
      width: '100%',
      boxSizing: 'border-box',
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    spacer: {
      [theme.breakpoints.down('xs')]: {
        height: '5em',
      },
    },
  }),
  {name: 'RaToolbar'},
);

const valueOrDefault = (value: any, defaultValue: any) =>
  typeof value === 'undefined' ? defaultValue : value;

export interface CustomToolbarProps extends ToolbarProps {
  canDelete?: boolean;
}

/**
 * The Toolbar displayed at the bottom of forms.
 *
 */
const Toolbar: FC<CustomToolbarProps> = props => {
  const {
    alwaysEnableSaveButton,
    basePath,
    children,
    className,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    canDelete,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    mutationMode,
    validating,
    width,
    ...rest
  } = props;
  const classes = useStyles(props);

  // Use form pristine and validating to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(
    alwaysEnableSaveButton,
    !pristine && !validating,
  );

  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          {
            [classes.mobileToolbar]: width === 'xs',
            [classes.desktopToolbar]: width !== 'xs',
          },
          className,
        )}
        role="toolbar"
        {...rest}
      >
        {Children.count(children) === 0 ? (
          <div className={classes.defaultToolbar}>
            <SaveButton
              handleSubmitWithRedirect={
                handleSubmitWithRedirect || handleSubmit
              }
              disabled={disabled}
              invalid={invalid}
              redirect={redirect}
              saving={saving || validating}
              submitOnEnter={submitOnEnter}
            />
            {record &&
              typeof record.id !== 'undefined' &&
              (canDelete ?? true) && (
                <DeleteButton
                  basePath={basePath}
                  record={record}
                  resource={resource}
                  undoable={undoable}
                  mutationMode={mutationMode}
                />
              )}
          </div>
        ) : (
          children &&
          isValidElement(children) &&
          Children.map(children, (button: ReactElement) =>
            button && isValidElement<any>(button)
              ? React.cloneElement(button, {
                  basePath: valueOrDefault(button.props.basePath, basePath),
                  handleSubmit: valueOrDefault(
                    button.props.handleSubmit,
                    handleSubmit,
                  ),
                  handleSubmitWithRedirect: valueOrDefault(
                    button.props.handleSubmitWithRedirect,
                    handleSubmitWithRedirect,
                  ),
                  onSave: button.props.onSave,
                  invalid,
                  pristine,
                  record: valueOrDefault(button.props.record, record),
                  resource: valueOrDefault(button.props.resource, resource),
                  saving,
                  submitOnEnter: valueOrDefault(
                    button.props.submitOnEnter,
                    submitOnEnter,
                  ),
                  undoable: valueOrDefault(button.props.undoable, undoable),
                })
              : null,
          )
        )}
      </MuiToolbar>
      <div className={classes.spacer} />
    </Fragment>
  );
};

export interface ToolbarProps<RecordType extends Record = Record>
  extends Omit<MuiToolbarProps, 'classes'> {
  children?: ReactNode;
  alwaysEnableSaveButton?: boolean;
  className?: string;
  classes?: ClassesOverride<typeof useStyles>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  handleSubmit?: FormRenderProps['handleSubmit'];
  invalid?: boolean;
  mutationMode?: MutationMode;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  redirect?: RedirectionSideEffect;
  basePath?: string;
  record?: RecordType;
  resource?: string;
  /** @deprecated use mutationMode: undoable instead */
  undoable?: boolean;
  validating?: boolean;
  width?: Breakpoint;
}

Toolbar.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validating: PropTypes.bool,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

Toolbar.defaultProps = {
  submitOnEnter: true,
};

export default withWidth({initialWidth: 'xs'})(Toolbar);
