import ProductIcon from '@material-ui/icons/Extension';
import ProductCreate from './ProductCreate';
import ProductEdit from './ProductEdit';
import ProductList from './ProductList';

const content = {
  icon: ProductIcon,
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
};

export default content;
