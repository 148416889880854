import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {notNil} from '@idviu/ts-helpers';
import {FC} from 'react';
import {InputProps, ReferenceInput, required} from 'react-admin';
import {SelectInput, useCurrentAccount} from '../components';

export interface RealmInputProps {
  autoDisable?: boolean;
}

export const RealmInput: FC<InputProps & RealmInputProps> = ({
  source,
  autoDisable,
}) => {
  const account = useCurrentAccount();
  notNil(account);
  const filter = {
    or: [
      {principalType: EntityType.account, principalId: account.id},
      {principalType: EntityType.company, principalId: account.companyId},
    ],
  };
  return (
    <ReferenceInput
      source={source}
      reference={ENTITY_COLLECTIONS.realm}
      translateChoices={false}
      perPage={-1}
      filter={filter}
      defaultValue={account.realmId}
      sort={{field: 'name', order: 'ASC'}}
      validate={required()}
    >
      <SelectInput
        source="id"
        variant="outlined"
        optionValue="id"
        optionText="name"
        allowEmpty={false}
        disabled={autoDisable && !!account.realmId}
      />
    </ReferenceInput>
  );
};

RealmInput.defaultProps = {
  label: 'resources.users.fields.realm',
  autoDisable: false,
};
