import {ResourceComponents} from '../components';
import {VariantCreate} from './VariantCreate';
import {VariantEdit} from './VariantEdit';
import {VariantShow} from './VariantShow';

const components: ResourceComponents = {
  create: VariantCreate,
  edit: VariantEdit,
  show: VariantShow,
};
export default components;
