import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import {Cancel, Https} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles} from '@material-ui/styles';
import {
  fetchEnd,
  fetchStart,
  Record,
  ResourceComponentPropsWithId,
  usePermissions,
  useTranslate,
} from 'ra-core';
import {FC, useState} from 'react';
import {
  Button,
  Datagrid,
  DeleteButton,
  ReferenceField,
  ReferenceManyField,
  ShowController,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {ShowView} from '../components';

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    color: 'white',
    float: 'right',
  },
  actionButton: {
    marginRight: '1em',
  },
});

const ServerTitle: FC<{record?: Record}> = ({record}) => {
  return <span>{record ? `"${record.name}"` : ''} details</span>;
};

const AddNewAccessButton: FC<{record?: Record}> = ({record}) => {
  const classes = useStyle();
  return (
    <>
      {record && (
        <Button
          className={classes.button}
          variant="contained"
          component={Link}
          to={{
            pathname: `/${ENTITY_COLLECTIONS.serverAccess}/create`,
            search: `?source=${JSON.stringify({serverId: record.id})}`,
            state: {record: {serverId: record.id}},
          }}
          label="pos.button.add_server_access"
        >
          <AddIcon />
        </Button>
      )}
    </>
  );
};

interface GetAccessTokenButtonProps {
  record?: any;
  basePath?: string;
}

const GetAccessTokenButton: FC<GetAccessTokenButtonProps> = ({record}) => {
  const classes = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const {loaded, permissions} = usePermissions();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const handleClick = async () => {
    if (accessToken) {
      setShowDialog(true);
    } else {
      dispatch(fetchStart());
      await fetch(
        `${BACKBONE_REST_API_V1_URL}/servers/${record?.id}/accessToken`,
        {
          method: 'GET',
          headers: {
            authorization: (loaded && permissions[0].getToken()) || '',
          },
        },
      )
        .then(res => res.json())
        .then(json => {
          setAccessToken(json.id);
          setShowDialog(true);
        })
        .finally(() => {
          dispatch(fetchEnd());
        });
    }
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        className={classes.actionButton}
        onClick={handleClick}
        label="pos.button.access_token"
        variant="contained"
      >
        <Https />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <DialogContent>
          <Typography>{translate('pos.desc.access_token')}</Typography>
          <Typography>{accessToken}</Typography>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <Cancel />
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

const ServerShow: FC<ResourceComponentPropsWithId> = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <>
      <ShowController {...props}>
        {controllerProps => (
          <ShowView
            {...props}
            {...controllerProps}
            title={<ServerTitle />}
            canEdit={loaded && permissions[0].isAdmin()}
            /* eslint-disable react/jsx-key */
            otherActions={[<GetAccessTokenButton />]}
          >
            <TabbedShowLayout>
              <Tab label="Server details">
                <TextField source="name" />
                <TextField source="id" />
                <TextField source="type" />
                <TextField source="cluster" />
                <TextField source="url" />
                <TextField source="internalUrl" />
              </Tab>
              <Tab label="Accesses" path="accesses">
                <ReferenceManyField
                  addLabel={false}
                  reference={ENTITY_COLLECTIONS.serverAccess}
                  target="serverId"
                  {...{title: ' - accesses'}}
                >
                  <Datagrid>
                    <ReferenceField
                      label="Role"
                      source="roleId"
                      reference={ENTITY_COLLECTIONS.role}
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField source="login" />
                    <TextField source="password" />
                    {loaded && permissions[0].isSuperAdmin() && (
                      <DeleteButton undoable={false} />
                    )}
                  </Datagrid>
                </ReferenceManyField>
                <AddNewAccessButton />
              </Tab>
            </TabbedShowLayout>
          </ShowView>
        )}
      </ShowController>
    </>
  );
};

export default ServerShow;
