import ConstantCreate from './ConstantCreate';
import ConstantList from './ConstantList';
import ConstantEdit from './ConstantEdit';
import ConstantIcon from '@material-ui/icons/FormatListNumbered';
import ConstantShow from './ConstantShow';
const constants = {
  icon: ConstantIcon,
  list: ConstantList,
  edit: ConstantEdit,
  create: ConstantCreate,
  show: ConstantShow,
};

export default constants;
