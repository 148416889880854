import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {isNilOrEmpty, notNil} from '@idviu/ts-helpers';
import {Identifier, useGetOne} from 'react-admin';
import {BackboneRecord, TeamMember, User} from './backbone-records';

export function useResource<
  TResource extends BackboneRecord<TBackboneObj>,
  TBackboneObj extends object = {},
>(resourceName: string, id: Identifier | undefined): TResource | undefined {
  notNil(id);
  const {data, error} = useGetOne<TResource>(resourceName, id);
  if (error) throw new Error(error);
  return data;
}

export function useUser(id: string | undefined): User | undefined {
  return useResource<User>(ENTITY_COLLECTIONS.user, id);
}

export function useTeamMember(id: string | undefined): TeamMember | undefined {
  return useResource<TeamMember>(ENTITY_COLLECTIONS.teamMember, id);
}

export function useHasRealm(userId: string | undefined): boolean {
  const user = useUser(userId);
  return !isNilOrEmpty(user?.realmId);
}
