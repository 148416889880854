import {push} from 'connected-react-router';
import React, {Component} from 'react';
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';

class AIDCreate extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <Create {...props} title="Create new AID">
          <SimpleForm>
            <TextInput source="AID" label="AID" />
            <TextInput source="KEY" label="KEY" />
            <TextInput source="IV" label="IV" />
            <TextInput source="SIG" label="SIG" />
            <TextInput source="serviceId" label="Service ID" />
            <TextInput source="comment" label="Comments" />
            <ReferenceInput
              label="Account"
              source="accountId"
              reference="accounts"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleForm>
        </Create>
      </>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(AIDCreate);
