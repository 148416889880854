import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {usePermissions} from 'ra-core';
import React from 'react';
import {
  Button,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  ShowController,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {ShowView} from '../components';
import BooleanField from '../components/BooleanField';
import DeleteUserButton from './DeleteUserButton';

const UserField = ({record}) => {
  return (
    <>
      <TextField record={record} source="firstName" />
      <TextField record={record} source="lastName" />
    </>
  );
};

const CompanyTitle = ({record}) => {
  return <span>{record ? `"${record.name}"` : ''} details</span>;
};

const useStyles = makeStyles({
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    color: 'white',
  },
});

const AddNewUserButton = ({record}) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: '/teamMembers/create',
        search: `?source=${JSON.stringify({
          principalId: record.id,
          principalType: 'company',
        })}`,
        state: {record: {principalId: record.id, principalType: 'company'}},
      }}
      label="pos.button.add_users"
    >
      <AddIcon />
    </Button>
  );
};

const EditRolesButton = ({classes, record}) => (
  <Button
    to={{
      pathname: '/teamMembers/' + record.id + '/show',
      state: {record: {principalId: record.id, principalType: 'company'}},
    }}
    component={Link}
    label="pos.button.edit_roles"
  >
    <EditIcon />
  </Button>
);

const CompanyShow = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <ShowController {...props}>
        {({translate, record, ...controllerProps}) => (
          <ShowView
            {...props}
            record={record}
            {...controllerProps}
            title={<CompanyTitle />}
            canEdit={
              loaded &&
              record &&
              permissions[0].canModifyCompanySettings(record)
            }
          >
            <TabbedShowLayout>
              <Tab label="company details">
                <TextField source="name" />
                <TextField source="id" />
                <BooleanField source="blocked" />
              </Tab>
              <Tab label="users" path="users">
                <ReferenceManyField
                  addLabel={false}
                  reference="teamMembers"
                  title=" - users"
                  filter={{principalType: 'company'}}
                  target="principalId"
                >
                  <Datagrid>
                    <ReferenceField
                      label="Name"
                      source="userId"
                      reference="users"
                      link={false}
                    >
                      <UserField />
                    </ReferenceField>
                    <ReferenceField
                      label="Email"
                      source="userId"
                      reference="users"
                      link={false}
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    {loaded &&
                      record &&
                      permissions[0].canModifyCompanySettings(record) && (
                        <EditRolesButton />
                      )}
                    {loaded &&
                      record &&
                      permissions[0].canModifyCompanySettings(record) && (
                        <DeleteUserButton />
                      )}
                  </Datagrid>
                </ReferenceManyField>
                {loaded &&
                  record &&
                  permissions[0].canModifyCompanySettings(record) && (
                    <AddNewUserButton />
                  )}
              </Tab>
            </TabbedShowLayout>
          </ShowView>
        )}
      </ShowController>
    </>
  );
};

export default CompanyShow;
