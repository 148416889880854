import React from 'react';
import {Create, TextInput} from 'react-admin';
import {SimpleForm} from '../components';

const CredentialCreate = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm redirect="list">
          <TextInput source="alias" />
          <TextInput source="login" />
          <TextInput source="password" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default CredentialCreate;
