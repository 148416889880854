import {FC} from 'react';
import {EditButton, ShowButton, TextField, usePermissions} from 'react-admin';
import {Datagrid, DateField, List, useCurrentAccount} from '../components';

const VirtualEventList: FC = props => {
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  return (
    <List
      {...props}
      title="pos.menu.virtualEvents"
      perPage={25}
      filter={{accountId: account?.id}}
      canCreate={loaded && permissions[0]?.canModifyAccountSettings(account)}
      canDelete={loaded && permissions[0]?.canModifyAccountSettings(account)}
      sort={{field: 'name', order: 'ASC'}}
    >
      <Datagrid {...props}>
        <TextField source="name" />
        <DateField source="start" showTime />
        <TextField source="id" />
        <ShowButton />
        {loaded && permissions[0]?.canModifyAccountSettings(account) && (
          <EditButton />
        )}
      </Datagrid>
    </List>
  );
};

export default VirtualEventList;
