import {usePermissions} from 'ra-core';
import React from 'react';
import {List} from '../components';
import CompanyListDesktop from './CompanyListDesktop';

const CompanyList = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <List
        {...props}
        perPage={25}
        canCreate={loaded && permissions[0].isAdmin()}
        canDelete={loaded && permissions[0].isSuperAdmin()}
        sort={{field: 'name', order: 'ASC'}}
      >
        <CompanyListDesktop />
      </List>
    </>
  );
};

export default CompanyList;
