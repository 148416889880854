import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import Chart from 'chart.js';
import 'chartjs-plugin-colorschemes';
//import 'chartjs-plugin-piechart-outlabels'
import {useTranslate, useVersion} from 'ra-core';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {storage} from '../dataProvider';

const useStyle = makeStyles({
  main: {
    width: '100%',
  },
  root: {
    width: '100%',
    maxWidth: 360,
  },
  canvas: {
    paddingBottom: -20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    paddingLeft: 10,
    paddingRight: 10,
    minHeight: 52,
  },
});

export interface PieStatsProps {
  max: number;
  title: string;
  field: never;
  filter: never;
}

const PieStats: FC<PieStatsProps> = props => {
  const {max, field, filter, title} = props;
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [, setChart] =
    useState<((chart?: Chart) => Chart | undefined) | undefined>(undefined);
  const classes = useStyle();
  const canvas = useRef(null);
  const translate = useTranslate();
  const version = useVersion();
  //we use a string here, because if the object changes but not its actual values, it would force an unnecessary reload
  const filterstr = filter ? JSON.stringify(filter) : null;

  const fetchData = useCallback(() => {
    const url = BACKBONE_REST_API_V1_URL;
    const headers = new Headers({
      'Content-Type': 'application/json',
      cache: 'no-cache',
      authorization: storage.load('lbtoken').id,
    });
    const options = {
      field: field,
      bucket_count: max,
      filter: filterstr ? JSON.parse(filterstr) : filterstr,
    };
    const optionsString = encodeURIComponent(JSON.stringify(options));
    const request = new Request(
      `${url}/${ENTITY_COLLECTIONS.account}/stats?type=top&options=${optionsString}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    setLoading(true);
    setError(undefined);
    setData(null);
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setLoading(false);
        if (data.buckets.length === 0) {
          setError('no available data');
        } else {
          setData(data);
        }
      })
      .catch(() => {
        setLoading(false);
        setError('could not get data');
      });
  }, [filterstr, max, field]);

  const drawData = useCallback(
    data => {
      setChart((chart?: Chart) => {
        if (!data || !Array.isArray(data)) return undefined;
        if (!chart) {
          const cfg: Chart.ChartConfiguration = {
            type: 'pie',
            data: {
              labels: data.map(e => e.key),
              datasets: [
                {
                  label: 'checkin requests',
                  data: data.map(e => e.doc_count),
                  borderWidth: 0,
                },
              ],
            },
            options: {
              layout: {
                padding: {
                  bottom: 40,
                },
              },
              title: {
                position: 'top',
                display: true,
                text: title
                  ? translate(title)
                  : 'Number of checkin requests per ' +
                    field +
                    ' in the last 7 days',
              },
              legend: {
                display: true,
                position: 'right',
                labels: {
                  boxWidth: 20,
                  padding: 5,
                },
              },
              cutoutPercentage: 0,
              rotation: 0.2 * Math.PI,
              plugins: {
                colorschemes: {
                  scheme: 'brewer.Paired12',
                  override: true,
                },
              },
            },
          };
          const currentCanvas = canvas.current;
          if (!currentCanvas) return undefined;
          return new Chart(currentCanvas, cfg);
        } else {
          chart.data.datasets = [
            {
              label: 'checkin requests',
              data: data.map(e => e.doc_count),
              borderWidth: 0,
            },
          ];
          chart.data.labels = data.map(e => e.key);
          chart.update();
          return chart;
        }
      });
    },
    [field, title, translate, setChart],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, version]);

  useEffect(() => {
    drawData(data ? data.buckets : null);
  }, [data, drawData]);

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        {!loading && !error && (
          <canvas
            className={classes.canvas}
            width="400"
            height="320"
            ref={canvas}
          />
        )}
        {loading && <CircularProgress />}
        {error && <div>{error}</div>}
      </Card>
    </div>
  );
};

export default PieStats;
