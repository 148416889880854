import {push} from 'connected-react-router';
import {Component, FC} from 'react';
import {
  BooleanInput,
  FormTab,
  Record,
  ResourceComponentPropsWithId,
  TabbedForm,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';
import {Edit} from '../components';

const ServerTitle: FC<{record?: Record}> = ({record}) => {
  return <span>Server {record ? `"${record.name}"` : ''}</span>;
};

class ServerEdit extends Component<
  ResourceComponentPropsWithId & {push: typeof push}
> {
  render() {
    const props = this.props;
    return (
      <>
        <Edit {...props} title={<ServerTitle />}>
          <TabbedForm>
            <FormTab label="Server details">
              <TextInput source="name" />
              <TextInput source="url" />
              <TextInput source="internalUrl" />
              <TextInput source="cluster" />
              <BooleanInput source="default" />
            </FormTab>
          </TabbedForm>
        </Edit>
      </>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(ServerEdit);
