import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  FormWithRedirect,
  FormWithRedirectProps,
  MutationMode,
  OnFailure,
  OnSuccess,
  Record,
  RedirectionSideEffect,
} from 'ra-core';
import {useTabbedFormViewStyles} from 'ra-ui-materialui/lib/form/TabbedFormView';
import {
  Children,
  FC,
  HtmlHTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import {ClassesOverride, FormTab as RaFormTab, FormTabProps} from 'react-admin';
import {useLocation, useRouteMatch} from 'react-router';
import {TabbedFormView} from './TabbedFormView';

/**
 * Form layout where inputs are divided by tab, one input per line.
 *
 */
export const TabbedForm = (props: TabbedFormProps) => {
  const match = useRouteMatch();
  const location = useLocation();
  const formRootPathname = match ? match.url : location.pathname;

  return (
    <FormWithRedirect
      {...props}
      formRootPathname={formRootPathname}
      render={formProps => <TabbedFormView {...formProps} />}
    />
  );
};

TabbedForm.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  // @ts-ignore
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  sanitizeEmptyValues: PropTypes.bool,
};

export interface TabbedFormProps
  extends Omit<FormWithRedirectProps, 'render'>,
    Omit<
      HtmlHTMLAttributes<HTMLFormElement>,
      'defaultValue' | 'onSubmit' | 'children'
    > {
  basePath?: string;
  children: ReactNode;
  className?: string;
  classes?: ClassesOverride<typeof useTabbedFormViewStyles>;
  initialValues?: any;
  canDelete?: boolean;
  margin?: 'none' | 'normal' | 'dense';
  mutationMode?: MutationMode;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  sanitizeEmptyValues?: boolean;
  save?: (
    data: Partial<Record>,
    redirectTo: RedirectionSideEffect,
    options?: {
      onSuccess?: OnSuccess;
      onFailure?: OnFailure;
    },
  ) => void;
  submitOnEnter?: boolean;
  syncWithLocation?: boolean;
  tabs?: ReactElement;
  toolbar?: ReactElement;
  /** @deprecated use mutationMode: undoable instead */
  undoable?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  warnWhenUnsavedChanges?: boolean;
}

export interface CustomFormTabProps extends FormTabProps {
  only?: (record: Record | undefined) => boolean;
}

export const FormTab: FC<CustomFormTabProps> = props => {
  return <RaFormTab {...props} />;
};

export const findTabsWithErrors = (children: any, errors: any) => {
  console.warn(
    'Deprecated. FormTab now wrap their content inside a FormGroupContextProvider. If you implemented custom forms with tabs, please use the FormGroupContextProvider. See https://marmelab.com/react-admin/CreateEdit.html#grouping-inputs',
  );

  return Children.toArray(children).reduce((acc: any[], child) => {
    if (!isValidElement(child)) {
      return acc;
    }

    const inputs = Children.toArray(child.props.children);

    if (
      inputs.some(
        input => isValidElement(input) && get(errors, input.props.source),
      )
    ) {
      return [...acc, child.props.label];
    }

    return acc;
  }, []);
};
