import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {usePermissions} from 'ra-core';
import {Record} from 'ra-core/src/types';
import {FC} from 'react';
import {
  ReferenceField,
  SelectField,
  ShowController,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {ShowView} from '../components';

// const useStyle = makeStyles({
//   button: {
//     marginTop: '1em',
//     marginBottom: '1em',
//     color: 'white',
//     float: 'right',
//   },
// });

interface CatalogTitle {
  record?: Record;
}

const CatalogTitle: FC<CatalogTitle> = ({record}) => {
  return <span>{record ? `"${record.name}"` : ''} details</span>;
};

// const AddNewAccessButton = ({record, ...props})=> {
//     const classes = useStyle();
//     return (<Button
//         className={classes.button}
//         variant="contained"
//         component={Link}
//         to={{
//             pathname: '/CatalogAccesses/create',
//             search: `?source=${JSON.stringify({CatalogId : record.id })}`,
//             state: { record: {CatalogId : record.id }}
//         }}
//         label="pos.button.add_Catalog_access"
//     >
//         <AddIcon />
//     </Button>
// )};

const CatalogShow: FC = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <>
      <ShowController {...props}>
        {({translate, ...controllerProps}) => (
          <ShowView
            {...props}
            {...controllerProps}
            title={<CatalogTitle />}
            canEdit={loaded && permissions[0].isAdmin()}
          >
            <TabbedShowLayout>
              <Tab label="Catalog details">
                <TextField source="name" />
                <TextField source="redemptionCode" />
                {controllerProps.record && controllerProps.record.dynamic && (
                  <TextField source="dynamic_type" />
                )}
                {controllerProps.record && controllerProps.record.dynamic && (
                  <ReferenceField
                    link={false}
                    addLabel={true}
                    label="credentials"
                    reference={ENTITY_COLLECTIONS.credential}
                    source="credentialId"
                    {...{title: ' - credentials'}}
                  >
                    <TextField source="login" />
                  </ReferenceField>
                )}
                {controllerProps.record &&
                  (controllerProps.record.dynamic_type === 'otvp_npvr_list' ||
                    controllerProps.record.dynamic_type ===
                      'otvp_channel_list') && (
                    <SelectField
                      {...controllerProps}
                      source="otvp_environment"
                      label="Environment"
                      choices={[
                        {id: 'prod', name: 'Production'},
                        {id: 'hidden_prod', name: 'Hidden production'},
                      ]}
                    />
                  )}
                {controllerProps.record &&
                  (controllerProps.record.dynamic_type === 'otvp_npvr_list' ||
                    controllerProps.record.dynamic_type ===
                      'otvp_channel_list') && (
                    <SelectField
                      {...controllerProps}
                      source="userAgent"
                      label="Device type"
                      choices={[
                        {
                          id: 'OTVP_Android_8.2.2_samsung_SM-G960F=WVL1_10',
                          name: 'Android',
                        },
                        {
                          id: 'OTVP_iOS_8.3.0_Apple_iPhone7,1=FP+WVL3_12.4',
                          name: 'iOS',
                        },
                      ]}
                    />
                  )}
              </Tab>
              <Tab label="items" path="items">
                <p>Coming soon...</p>
              </Tab>
            </TabbedShowLayout>
          </ShowView>
        )}
      </ShowController>
    </>
  );
};

export default CatalogShow;
