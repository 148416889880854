import {FC} from 'react';
import {
  CheckboxGroupInput as RaCheckboxGroupInput,
  CheckboxGroupInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const CheckboxGroupInput: FC<CheckboxGroupInputProps> = props => (
  <RaCheckboxGroupInput {...props} />
);

CheckboxGroupInput.defaultProps = {
  ...RaCheckboxGroupInput.defaultProps,
  ...COMMON_INPUT_PROPS,
};
