import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  styled,
} from '@material-ui/core';
import {Cancel, Edit, Save} from '@material-ui/icons';
import {FC, useCallback, useEffect, useState} from 'react';
import {InputProps, Labeled, usePermissions, useTranslate} from 'react-admin';
import {Field, useFormState} from 'react-final-form';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';

interface EditDialogProps {
  value: string | undefined;
  show: boolean;
  resource?: string;
  label?: string;
  source: string;
  onHide: () => void;
  onSave: (url: string | null) => void;
}

const Input = styled('input')({
  display: 'none',
});

const useDialogStyles = makeStyles(theme => ({
  content: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '600px',
    display: 'block',
  },
  button: {
    marginTop: theme.spacing(2),
    display: 'block',
  },
}));

const useInputStyle = makeStyles(theme => ({
  image: {
    maxWidth: '400px',
    maxHeight: '200px',
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const EditDialog: FC<EditDialogProps> = ({
  value,
  show,
  onHide,
  onSave,
  label,
  source,
  resource,
}) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [imgSrc, setImgSrc] = useState<string | undefined>(value);
  const [loading, setLoading] = useState(false);
  const {loaded, permissions} = usePermissions();

  const onChange = useCallback(
    e => {
      setFile(
        e.target.files && e.target.files.length ? e.target.files[0] : undefined,
      );
    },
    [setFile],
  );
  const handleSave = useCallback(async () => {
    if (imgSrc) {
      if (imgSrc.startsWith('blob:') && file) {
        //need to upload
        setLoading(true);
        const data = new FormData();
        data.append(source, file);
        await fetch(`${BACKBONE_REST_API_V1_URL}/files`, {
          method: 'POST',
          headers: {
            authorization: (loaded && permissions[0].getToken()) || '',
          },
          body: data,
        })
          .then(res => res.json())
          .then(json => {
            if (!json.files || !json.files.length) {
              throw new Error('oups');
            }
            onSave(json.files[0].path);
          })
          .catch(err => {
            console.log('oups, ', err);
            onHide();
          })
          .then(() => setLoading(false));
      } else {
        onSave(imgSrc);
      }
    } else {
      onSave(imgSrc ?? null);
    }
  }, [loaded, onHide, permissions, source, file, imgSrc, setLoading, onSave]);
  useEffect(() => {
    if (file) {
      setImgSrc(URL.createObjectURL(file));
    } else {
      setImgSrc(undefined);
    }
  }, [file]);
  const classes = useDialogStyles();
  const tr = useTranslate();
  return (
    <Dialog maxWidth="xl" open={show} onClose={onHide}>
      <DialogTitle>
        {tr(label ?? `resources.${resource}.fields.${source}`)}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {imgSrc && imgSrc.length && (
          <img src={imgSrc} className={classes.image} />
        )}
        <label htmlFor="contained-button-file">
          <Input id="contained-button-file" type="file" onChange={onChange} />
          <Button
            variant="contained"
            component="span"
            className={classes.button}
          >
            {tr('pos.button.pick_image')}
          </Button>
        </label>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          color="primary"
          onClick={onHide}
          startIcon={<Cancel />}
        >
          {tr('pos.button.cancel')}
        </Button>
        <Button
          disabled={loading}
          color="primary"
          onClick={handleSave}
          startIcon={<Save />}
        >
          {tr('pos.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ImageUploadInput: FC<InputProps<string>> = ({
  validate,
  resource,
  label,
  source,
  ...props
}) => {
  const state = useFormState();
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => setShow(true), [setShow]);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const [url, setUrl] = useState<string>(state.initialValues[source]);
  const classes = useInputStyle();
  const onSave = useCallback(
    (newUrl: string) => {
      setUrl(newUrl);
      onHide();
    },
    [setUrl, onHide],
  );
  return (
    <Field name={props.name ?? source} value={url}>
      {props => (
        <>
          <Labeled label={label ?? `resources.${resource}.fields.${source}`}>
            <div className={classes.container}>
              {props.input.value && props.input.value.length && (
                <img src={props.input.value} className={classes.image} />
              )}
              <Button onClick={onShow} startIcon={<Edit />} />
            </div>
          </Labeled>
          <EditDialog
            resource={resource}
            label={label}
            source={source}
            value={props.input.value}
            show={show}
            onHide={onHide}
            onSave={v => {
              onSave(v ?? '');
              props.input.onChange(v);
            }}
          />
        </>
      )}
    </Field>
  );
};
