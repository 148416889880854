import React from 'react';
import {TextInput} from 'react-admin';
import {Edit, SimpleForm} from '../components';

const CredentialEdit = props => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="alias" />
          <TextInput source="principalType" disabled />
          <TextInput source="principalId" disabled />
          <TextInput source="login" />
          <TextInput source="password" />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default CredentialEdit;
