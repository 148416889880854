import {FC} from 'react';
import {
  Create,
  ResourceComponentProps,
  SelectInput,
  TextInput,
} from 'react-admin';
import {SimpleForm} from '../components';

const ServerCreate: FC<ResourceComponentProps> = props => (
  <>
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="name" required />
        <SelectInput
          source="type"
          choices={[
            {id: 'api', name: 'API'},
            {id: 'edge', name: 'Edge'},
            {id: 'storage', name: 'Storage'},
            {id: 'elastic-search', name: 'Elastic Search'},
          ]}
          required
        />
        <TextInput source="url" />
        <TextInput source="internalUrl" />
        <TextInput source="cluster" />
      </SimpleForm>
    </Create>
  </>
);

export default ServerCreate;
