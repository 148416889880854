import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import {AppBar} from 'react-admin';
import ProjectPicker from '../components/ProjectPicker';
import Logo from './Logo';

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 10,
  },
  spacer: {
    flex: 1,
  },
}));

// const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItemLink
//             ref={ref}
//             to="/configuration"
//             primaryText={translate('pos.configuration')}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//             sidebarIsOpen
//         />
//     );
// });

const CustomUserMenu = ({logout}: {logout?: React.ReactElement}) => (
  <div>{logout ? React.cloneElement(logout) : <span />}</div>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <ProjectPicker />
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
