import {usePermissions} from 'ra-core';
import React from 'react';
import {
  DeleteButton,
  EditButton,
  Filter,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';
import {Datagrid, List} from '../components';

const MyReferenceField = ({record, ...props}) => {
  return (
    <MyReferenceField source="accountId" reference="accounts" {...props}>
      <TextField source="name" />
    </MyReferenceField>
  );
};

const AIDFilter = props => {
  const {...otherProps} = props;

  return (
    <Filter {...otherProps} variant="outlined">
      <TextInput label="Service ID" source="qserviceId" alwaysOn />
    </Filter>
  );
};

const AIDList = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <List
        {...props}
        perPage={25}
        filters={<AIDFilter />}
        sort={{field: 'name', order: 'ASC'}}
      >
        <Datagrid>
          <TextField source="AID" label="AID" />
          <TextField source="serviceId" label="Service" />
          <TextField source="comment" label="comment" />
          <ReferenceField source="accountId" reference="accounts">
            <TextField source="name" />
          </ReferenceField>
          {loaded && permissions[0].isAdmin() && <EditButton />}
          {loaded && permissions[0].isSuperAdmin() && (
            <DeleteButton undoable={false} redirect={false} />
          )}
        </Datagrid>
      </List>
    </>
  );
};

export default AIDList;
