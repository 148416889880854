import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/FileCopy';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import React, {Component, FC} from 'react';
import {
  CreateButton,
  Datagrid,
  DateField,
  Record,
  ReferenceField,
  ShowButton,
  TextField,
  usePermissions,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {List} from '../components';

interface ExpirationFieldProps {
  label?: string;
  record?: {keys: {LGY_HSS_EXPIRATION: any}};
  source: string;
}

const ExpirationField: FC<ExpirationFieldProps> = ({
  record = {},
  label = 'Expiration',
  source,
}) => {
  let expiration = record.keys && record.keys.LGY_HSS_EXPIRATION;
  if (expiration !== '0')
    expiration = moment(expiration * 1000).format('DD-MM-YYYY HH:mm:ss');
  else expiration = 'unlimited';
  return <span {...{label, source}}>{expiration}</span>;
};

const styles = {
  raisedButton: {
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '1em',
    display: 'inline-flex',
    color: 'white',
  },
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '1em',
    display: 'inline-flex',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const KeyAnalysisButton: FC<{className?: string}> = props => (
  <Button
    {...props}
    size="small"
    component={Link}
    variant="contained"
    color="primary"
    to={{
      pathname: '/keyDump',
    }}
  >
    <FindInPageIcon /> Key analysis
  </Button>
);

function downloadData(data: Iterable<number>, filename: string): void {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([new Uint8Array(data)], {
    type: 'application/octet-stream',
  });
  fakeLink.setAttribute('href', URL.createObjectURL(blob));
  fakeLink.setAttribute('download', `${filename}`);
  fakeLink.click();
}

interface DownloadKeyButtonProps {
  record?: Record;
  classes: {link: string};
}

class DownloadKeyButton extends Component<DownloadKeyButtonProps> {
  handleClick = () => {
    const {record} = this.props;
    if (!record?.data?.data) return;
    downloadData(record.data.data, 'application.key');
  };

  render() {
    const {classes} = this.props;

    return (
      <Button
        size="small"
        color="primary"
        onClick={this.handleClick}
        className={classes.link}
        {...{label: 'Download'}} // TODO - Verify whether this property is useful
      >
        <DownloadIcon />
      </Button>
    );
  }
}

const DownloadKey = withStyles(styles)(DownloadKeyButton);

const useStyle = makeStyles({
  button: {
    marginRight: '1em',
  },
  toolbar: {
    marginRight: '-1.5em',
  },
});

interface ActionsProps {
  basePath?: string;
  canCreate?: boolean;
  displayedFilters?: unknown;
  filters?: React.DetailedReactHTMLElement<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >;
  filterValues?: unknown;
  resource?: string;
  showFilter?: boolean;
}

const Actions: FC<ActionsProps> = ({
  basePath,
  canCreate,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
}) => {
  const classes = useStyle();
  return (
    <Toolbar className={classes.toolbar}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          variant: 'contained',
          context: 'button',
        })}
      {canCreate && (
        <CreateButton
          basePath={basePath}
          label="Add"
          variant="contained"
          className={classes.button}
        />
      )}
      <KeyAnalysisButton />
    </Toolbar>
  );
};

const Empty: FC<{basePath?: string}> = ({basePath}) => {
  const classes = useStyle();
  return (
    <Box textAlign="center" m={1}>
      <Typography variant="h4" paragraph>
        No application keys available
      </Typography>
      <Typography variant="body1">
        Create one or analyze an existing one
      </Typography>
      <CreateButton
        className={classes.button}
        basePath={basePath}
        variant="contained"
        color="primary"
      />
      <KeyAnalysisButton />
    </Box>
  );
};

const ApplicationKeyList: FC = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <>
      <List
        {...props}
        perPage={25}
        sort={{field: 'name', order: 'ASC'}}
        actions={<Actions canCreate={loaded && permissions[0].isAdmin()} />}
        empty={<Empty />}
      >
        <Datagrid>
          <TextField label="Type" source="type" />
          <DateField label="Created on" source="createdOn" showTime />
          <ReferenceField
            label="By"
            source="userId"
            reference={ENTITY_COLLECTIONS.user}
            link="show"
          >
            <TextField source="email" />
          </ReferenceField>
          <TextField label="Bundle ID" source="keys.APPLICATION_PACKAGE" />
          <ExpirationField label="Expiration" source="LGY_HSS_EXPIRATION" />
          <ShowButton />
          <DownloadKey />
        </Datagrid>
      </List>
    </>
  );
};

export default ApplicationKeyList;
