import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {push} from 'connected-react-router';
import React from 'react';
import {
  Create,
  ReferenceInput,
  required,
  ResourceComponentProps,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';

class UserCreate extends React.Component<
  ResourceComponentProps & {push: typeof push}
> {
  render() {
    const props = this.props;
    return (
      <Create {...props} title="Create new account">
        <SimpleForm>
          <TextInput source="name" label="Name" validate={required()} />
          <ReferenceInput
            label="Company"
            source="companyId"
            reference={ENTITY_COLLECTIONS.company}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(UserCreate);
