import CatalogIcon from '@material-ui/icons/ArtTrack';
import CatalogCreate from './CatalogCreate';
import CatalogEdit from './CatalogEdit';
import CatalogList from './CatalogList';
import CatalogShow from './CatalogShow';

const content = {
  icon: CatalogIcon,
  list: CatalogList,
  show: CatalogShow,
  edit: CatalogEdit,
  create: CatalogCreate,
};

export default content;
