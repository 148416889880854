import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {escapePath, Record} from 'ra-core';
import {
  ClassesOverride,
  getTabFullPath,
  Tab as RATab,
  TabbedShowLayoutTabs,
  TabProps as RATabProps,
} from 'ra-ui-materialui';
import {
  ChangeEvent,
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import {Route, useRouteMatch} from 'react-router-dom';

const sanitizeRestProps = ({
  children,
  className,
  record,
  resource,
  basePath,
  version,
  initialValues,
  staticContext,
  translate,
  tabs,
  ...rest
}: any) => rest;

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  {name: 'RaTabbedShowLayout'},
);

/**
 * Tabbed Layout for a Show view, showing fields grouped in tabs.
 *
 * Receives the current `record` from the parent `<Show>` component,
 * and passes it to its children. Children should be Tab components.
 * The component passed as `tabs` props replaces the default material-ui's <Tabs> component.
 *
 */
export const TabbedShowLayout = (props: TabbedShowLayoutProps) => {
  const {
    basePath,
    children,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    classes: classesOverride,
    className,
    record,
    resource,
    syncWithLocation = true,
    tabs,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    value,
    version,
    ...rest
  } = props;
  const match = useRouteMatch();
  const classes = useStyles(props);

  const nonNullChildren = Children.toArray(children).filter(child => {
    if (child === null) return false;
    if (isValidElement(child) && child.props.only) {
      return child.props.only(record);
    }
    return true;
  });
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_event: ChangeEvent<{}>, value: any): void => {
    if (!syncWithLocation) {
      setTabValue(value);
    }
  };

  return (
    <div className={className} key={version} {...sanitizeRestProps(rest)}>
      {tabs &&
        cloneElement(
          tabs,
          {
            syncWithLocation,
            onChange: handleTabChange,
            value: tabValue,
          },
          nonNullChildren,
        )}

      <Divider />
      <div className={classes.content}>
        {Children.map(nonNullChildren, (tab, index) =>
          tab && isValidElement(tab) ? (
            syncWithLocation ? (
              <Route
                exact
                path={escapePath(getTabFullPath(tab, index, match.url))}
                render={() =>
                  cloneElement(tab, {
                    context: 'content',
                    resource,
                    record,
                    basePath,
                  })
                }
              />
            ) : tabValue === index ? (
              cloneElement(tab, {
                context: 'content',
                resource,
                record,
                basePath,
              })
            ) : null
          ) : null,
        )}
      </div>
    </div>
  );
};

export interface TabbedShowLayoutProps {
  basePath?: string;
  className?: string;
  classes?: ClassesOverride<typeof useStyles>;
  children: ReactNode;
  record?: Record;
  resource?: string;
  syncWithLocation?: boolean;
  tabs?: ReactElement;
  value?: any;
  version?: number;
}

TabbedShowLayout.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  location: PropTypes.object,
  match: PropTypes.object,
  typeFilter: PropTypes.func,
  record: PropTypes.object,
  resource: PropTypes.string,
  syncWithLocation: PropTypes.bool,
  tabs: PropTypes.element,
  value: PropTypes.number,
  version: PropTypes.number,
};

TabbedShowLayout.defaultProps = {
  tabs: <TabbedShowLayoutTabs />,
};

export interface TabProps extends RATabProps {
  only?: (record?: Record) => boolean;
}

export const Tab: FC<TabProps> = props => <RATab {...props} />;
