import {FC} from 'react';
import {
  Datagrid,
  EditButton,
  ShowButton,
  TextField,
  usePermissions,
} from 'react-admin';
import {List} from '../components';
import {PrincipalField} from './PrincipalField';

export const RealmList: FC = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <List
      {...props}
      perPage={25}
      canCreate={loaded && permissions[0].isAdmin()}
      canDelete={loaded && permissions[0].isSuperAdmin()}
      sort={{field: 'name', order: 'ASC'}}
    >
      <Datagrid>
        <TextField source="name" />
        <PrincipalField />
        <TextField source="id" />
        <ShowButton />
        {loaded && permissions[0].isAdmin() && <EditButton />}
      </Datagrid>
    </List>
  );
};
