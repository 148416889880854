import {FC} from 'react';
import {BooleanField, useTranslate} from 'react-admin';

export const SecurityUseMfaField: FC = props => {
  const tr = useTranslate();
  return <BooleanField {...props} emptyText={tr('pos.boolean.false')} />;
};

SecurityUseMfaField.defaultProps = {
  source: 'security.useMfa',
  addLabel: true,
};
