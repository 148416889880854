import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {notNil} from '@idviu/ts-helpers';
import {FC, useCallback} from 'react';
import {
  Datagrid,
  EditProps,
  FunctionField,
  Record as RaRecord,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useTranslate,
} from 'react-admin';
import {Edit, SimpleForm} from '../components';
import AddRoleButton from './AddRoleButton';
import DeleteRoleButton from './DeleteRoleButton';

const TeamMembersEdit: FC<EditProps> = props => {
  const tr = useTranslate();
  const onRenderRole = useCallback(
    (role?: RaRecord) => {
      notNil(role);
      return tr(`resources.roles.fields.${role.name}`, {_: role.name});
    },
    [tr],
  );
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField
          label="resources.users.fields.email"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="resources.users.fields.username"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <ReferenceManyField
          label="Roles"
          reference={ENTITY_COLLECTIONS.roleMapping}
          target="principalId"
          filter={{principalType: EntityType.teamMember}}
        >
          <Datagrid>
            <ReferenceField
              source="roleId"
              reference={ENTITY_COLLECTIONS.role}
              link={false}
            >
              <FunctionField render={onRenderRole} />
            </ReferenceField>
            <DeleteRoleButton />
          </Datagrid>
        </ReferenceManyField>
        <AddRoleButton />
      </SimpleForm>
    </Edit>
  );
};

export default TeamMembersEdit;
