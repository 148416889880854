import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import {SelectInput, TextInput} from 'react-admin';
import {Edit, SimpleForm} from '../components';

const ConstantEdit = props => {
  return (
    <>
      <Edit {...props}>
        <SimpleForm redirect="list">
          <SelectInput
            source="type"
            choices={[
              {id: 'hss_setting', name: 'HSS Setting'},
              {id: 'player_setting', name: 'Player setting'},
              {id: 'checkin_setting', name: 'Checkin setting'},
              {id: 'idviu_event', name: 'IDVIU Event'},
              {id: 'legacy_player_error', name: 'Player error'},
              {id: 'legacy_player_info', name: 'Player info'},
              {id: 'legacy_download_error', name: 'Download error'},
            ]}
          />
          <TextInput source="name" />
          <TextInput source="value" />
          <SelectInput
            source="valueType"
            choices={[
              {id: null, name: 'Not applicable'},
              {id: 'string', name: 'String'},
              {id: 'number', name: 'Number'},
              {id: 'boolean', name: 'Boolean'},
            ]}
            defaultValue=""
          />
          <TextInput source="extra" />
          <TextInput source="description" />
          <ArrayInput source="products">
            <SingleValueFormIterator>
              <TextInput />
            </SingleValueFormIterator>
          </ArrayInput>
          <RichTextInput source="notes" />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ConstantEdit;
