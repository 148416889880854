import React from 'react';

const Logo = props => (
  <div
    aria-label="~IDVIU Admin Console~"
    style={{
      lineHeight: 1.25,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    fontWeight={400}
    fontSize={7.056}
    fontFamily="Permanent Marker"
    letterSpacing={0}
    wordSpacing={0}
    strokeWidth={0.265}
    fill="#fff"
  >
    IDVIU Admin Console
  </div>
);

export default Logo;
