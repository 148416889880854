import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

const ProductCreate = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm>
          <TextInput source="name" variant="outlined" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default ProductCreate;
