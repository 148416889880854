import ProductAccessIcon from '@material-ui/icons/Extension';
import ProductAccessCreate from './ProductAccessCreate';
import ProductAccessEdit from './ProductAccessEdit';
import ProductAccessList from './ProductAccessList';
import ProductAccessShow from './ProductAccessShow';

const content = {
  icon: ProductAccessIcon,
  list: ProductAccessList,
  create: ProductAccessCreate,
  edit: ProductAccessEdit,
  show: ProductAccessShow,
};

export default content;
