import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {push} from 'connected-react-router';
import {Identifier, Record} from 'ra-core/src/types';
import {Component, FC} from 'react';
import {
  EditButton,
  Filter,
  ReferenceField,
  ReferenceInput,
  ResourceComponentProps,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';
import {Datagrid, List} from '../components';

const AccountFilter: FC = props => {
  const {...otherProps} = props;

  return (
    <Filter {...otherProps}>
      <ReferenceInput
        label="Account"
        source="accountId"
        reference={ENTITY_COLLECTIONS.account}
        sort={{field: 'name', order: 'ASC'}}
        alwaysOn
      >
        <SelectInput source="Name" />
      </ReferenceInput>
      <TextInput label="A" source="qA" alwaysOn />
    </Filter>
  );
};

interface DateFieldProps {
  // FIXME - Record should not be optional but CertificateList doesn't pass it as prop to the date fields.
  record?: Record;
  source: Identifier;
  label: string;
}

const CustomDateField: FC<DateFieldProps> = props => {
  const {record} = props;
  const val = record?.[props.source] || 0;
  const date = new Date(val * 1000);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return <p>{date.toLocaleDateString('en-EN', options)}</p>;
};

const ShortDateField: FC<DateFieldProps> = props => {
  const {record} = props;
  const val = record?.[props.source] || 0;
  const date = new Date(val * 1000);
  const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long'};
  return <p>{date.toLocaleDateString('en-EN', options)}</p>;
};

class CertificateList extends Component<ResourceComponentProps> {
  render() {
    const props = this.props;
    return (
      <>
        <List
          {...props}
          perPage={25}
          filters={<AccountFilter />}
          sort={{field: 'accountId', order: 'ASC'}}
        >
          <Datagrid>
            <ReferenceField
              label="Account"
              source="accountId"
              reference={ENTITY_COLLECTIONS.account}
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ShortDateField source="slot" label="Slot" />
            <TextField source="A" label="device A" />
            <TextField source="version" label="SDK version" />
            <TextField source="IP" label="IP" />
            <TextField source="romId" label="ROM ID" />
            <TextField source="UA" label="UserAgent" />
            <CustomDateField source="access" label="First Access" />
            <CustomDateField source="lastAccess" label="Last Access" />
            <EditButton />
          </Datagrid>
        </List>
      </>
    );
  }
}

export default connect(undefined, {push})(CertificateList);
