import {FC} from 'react';
import {
  NullableBooleanInput as RaNullableBooleanInput,
  NullableBooleanInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const NullableBooleanInput: FC<NullableBooleanInputProps> = props => (
  <RaNullableBooleanInput {...props} />
);

NullableBooleanInput.defaultProps = {
  ...COMMON_INPUT_PROPS,
  nullLabel: 'pos.undefined',
};
