import {EntityType} from '@idviu/backbone-api-client';
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import * as React from 'react';
import {FC} from 'react';
import {
  Button,
  CreateButtonProps,
  ListBase,
  ListProps,
  ShowButton,
  useListContext,
  usePermissions,
  useTranslate,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {PleaseSelectAccount, useCurrentAccount} from '../components';

const useStyles = makeStyles(theme => ({
  name: {
    fontSize: theme.typography.h5.fontSize,
  },
  card: {
    width: '100%',
    margin: '0.5em',
    display: 'block',
    verticalAlign: 'top',
  },
  content: {},
  actions: {
    float: 'right',
    marginRight: 10,
  },
  description: {
    color: theme.palette.text.secondary,
  },
  create: {
    float: 'right',
  },
  raisedButton: {
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '1em',
    display: 'inline-flex',
    float: 'right',
    color: 'white',
  },
}));

const AddNewProductAccessButton: FC<CreateButtonProps> = props => {
  const {record} = props;
  const classes = useStyles();
  return (
    <Button
      className={classes.raisedButton}
      variant="contained"
      component={Link}
      to={{
        pathname: '/productAccesses/create',
        state: {record: {accountId: record?.id, companyId: record?.companyId}},
        search: `?source=${JSON.stringify({
          accountId: record?.id,
          companyId: record?.companyId,
        })}`,
      }}
      label="pos.button.add_product_access"
    >
      <AddIcon />
    </Button>
  );
};

const ProductGrid = () => {
  const {ids, data, basePath} = useListContext();
  const classes = useStyles();
  const translate = useTranslate();
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  return (
    <div style={{margin: '1em'}}>
      {loaded && permissions[0].isAdmin() && (
        <AddNewProductAccessButton record={account} />
      )}
      <div style={{margin: '1em'}}>{translate('pos.desc.product_list')}</div>
      {ids.map(id => (
        <Card key={id} className={classes.card}>
          <CardContent className={classes.content}>
            <Typography>{data[id]?.product?.toUpperCase()}</Typography>
            <Typography
              variant="subtitle1"
              className={classes.description}
              display="block"
              gutterBottom
            >
              {data[id] !== null
                ? translate('products.' + data[id].product + '.desc')
                : ''}
            </Typography>

            <div>
              <b>{translate('pos.desc.your_api_key')}</b> {data[id].apiKey}
            </div>
          </CardContent>
          <CardActions className={classes.actions}>
            {loaded && permissions[0].canAccessAccountSettings(account) && (
              <ShowButton
                resource={EntityType.productAccess}
                basePath={basePath}
                record={data[id]}
                label={translate('pos.button.settings')}
                icon={<SettingsIcon />}
              />
            )}
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

const ProductAccessList: React.FC<ListProps> = (props: any) => {
  const account = useCurrentAccount();

  return !account ? (
    <PleaseSelectAccount />
  ) : (
    <ListBase {...props} filter={{accountId: account.id}}>
      <ProductGrid />
    </ListBase>
  );
};

export default ProductAccessList;
