import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import {Datagrid, ReferenceField, TextField} from 'react-admin';
import EditServerAccessButton from './EditServerAccessButton';

const listStyles = {
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const ListDesktop = ({classes, filterValues, ...props}) => (
  <Datagrid classes={{headerRow: classes.headerRow}} {...props}>
    <ReferenceField label="Company" source="companyId" reference="companies">
      <TextField source="name" />
    </ReferenceField>
    <TextField source="product" />

    <TextField source="apiKey" />
    <TextField label="Access ID" source="id" />
    <EditServerAccessButton />
  </Datagrid>
);

export default withStyles(listStyles)(ListDesktop);
