import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/styles';
import {FC, useCallback} from 'react';
import {Button, ButtonProps, DELETE, useNotify, useRefresh} from 'react-admin';
import dataProvider from '../dataProvider';

const useStyles = makeStyles({
  button: {
    float: 'right',
  },
});

export const DeleteUserButton: FC<ButtonProps> = props => {
  const {record} = props;
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();
  const handleClick = useCallback(() => {
    if (!record?.id) return;
    dataProvider(DELETE, 'teamMembers', {
      id: record.id as string,
      data: undefined,
    })
      .then(() => refresh())
      .catch(() => notify('Error: could not remove user', 'warning'));
  }, [record]);

  return (
    <Button
      label="Delete"
      className={classes.button}
      color="secondary"
      onClick={handleClick}
    >
      <DeleteIcon />
    </Button>
  );
};

export default DeleteUserButton;
