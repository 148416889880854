import AccountIcon from '@material-ui/icons/Book';
import AccountCreate from './AccountCreate';
import AccountEdit from './AccountEdit';
import AccountList from './AccountList';
import AccountShow from './AccountShow';

const content = {
  icon: AccountIcon,
  list: AccountList,
  edit: AccountEdit,
  show: AccountShow,
  create: AccountCreate,
};

export default content;
