import frenchMessages from 'ra-language-french';
import {
  DEFAULT_MJML_EDITOR_LABELS,
  MjmlEditorLabels,
} from '../components/MjmlEditor';
import customEnglishMessages, {
  AUDIO_CODECS,
  CONTENT_STATES as EN_CONTENT_STATES,
  DRM,
  INPUT_FILE_FORMATS as EN_INPUT_FILE_FORMATS,
  MEDIA_TRACK_ROLES as EN_MEDIA_TRACK_ROLES,
  MEDIA_TRACK_TYPES as EN_MEDIA_TRACK_TYPES,
  OUTPUT_FILE_FORMAT,
  SUBTITLE_CODECS,
  VARIANT_TYPES as EN_VARIANT_TYPES,
  VIDEO_CODECS,
} from './en';

const CONTENT_STATES: typeof EN_CONTENT_STATES = {
  created: 'Créé',
  encoding: 'Encodage',
  updating: 'Mise à jour',
  ready: 'Prêt',
  error: 'Erreur',
};

const MEDIA_TRACK_TYPES: typeof EN_MEDIA_TRACK_TYPES = {
  audio: 'Audio',
  video: 'Vidéo',
  subtitle: 'Sous-titres',
};

const MEDIA_TRACK_ROLES: typeof EN_MEDIA_TRACK_ROLES = {
  forced: 'Forcé',
  main: 'Principal',
  subtitle: 'Sous-titres',
  undefined: 'Non défini',
};

const VARIANT_TYPES: typeof EN_VARIANT_TYPES = {
  otf: 'À la volée',
  vod: 'VOD',
  wmk: 'Watermark',
  live: 'Flux live',
};

const INPUT_FILE_FORMATS: typeof EN_INPUT_FILE_FORMATS = EN_INPUT_FILE_FORMATS;

const customFrenchMessages: typeof customEnglishMessages = {
  ...frenchMessages,
  '': '',
  admin: 'Administrateur',
  operator: 'Opérateur',
  developer: 'Développeur',
  viewer: 'Lecteur',
  emails: {
    addedToProjectTitle: 'Vous avez été ajouté au projet #account#',
    addedToProjectNewUser: `Bienvenue sur la console d'administration des projets IDVIU !<br/><br/>Vous avez été ajouté au projet #account#.<br/><br/>Votre mot de passe est: #password#<br/><br/>Afin de vérifier votre adresse e-mail et confirmer votre inscription, veuillez cliquer sur le lien ci-dessous:<br/><a href="#verifyLink#">#verifyLink#</a><br/><br/>A bientôt !<br/>L'équipe IDVIU.`,
    addedToProject: `Vous avez été ajouté à un nouveau projet !<br/><br/>Pour rappel, vous pouvez accéder à la console d'administration des projets IDVIU en cliquant sur le lien ci-dessous :<br/>#loginLink#<br/><br/>A bientôt !<br/>L'équipe IDVIU.`,
    addedToCompanyTitle: 'Vous avez été ajouté aux projets de #company#',
    addedToCompanyNewUser: `Bienvenue sur la console d'administration des projets IDVIU !<br/><br/>Vous avez été ajouté aux projets de la société #company#.<br/><br/>Votre mot de passe est: #password#<br/><br/>Afin de vérifier votre adresse e-mail et confirmer votre inscription, veuillez cliquer sur le lien ci-dessous:<br/><a href="#verifyLink#">#verifyLink#</a><br/><br/>A bientôt !<br/>L'équipe IDVIU.`,
    addedToCompany: `Vous avez été ajouté aux projets de #company#.

Pour rappel, vous pouvez accéder à la console d'administration des projets IDVIU en cliquant sur le lien ci-dessous :
#loginLink#

A bientôt !
L'équipe IDVIU.`,
  },
  pos: {
    undefined: 'Non défini',
    boolean: {
      true: 'Oui',
      false: 'Non',
    },
    search: 'Rechercher',
    configuration: 'Préférences',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    optimizations: {
      download: 'Téléchargement',
      streaming: 'Streaming',
    },
    dashboard: {
      companies_count: 'Companies',
      accounts_count: 'Accounts',
      royalty_report: 'MS Royalties report',
      stats_report: 'Stats',
      stats_account:
        'Number of checkin requests per account in the last 7 days',
      stats_os: 'Number of checkin requests per OS in the last 7 days',
      stats_sdk_version:
        'Number of checkin requests per SDK version in the last 7 days',
      stats_android:
        'Number of checkin requests per Android version in the last 7 days',
      stats_ios:
        'Number of checkin requests per iOS version in the last 7 days',
      welcome:
        "Bienvenue dans la console d'administration des produits IDVIU !<br/>Ici, vous aurez accès à toutes les informations utiles à l'utilisation et à la configuration de nos différents produits.<br/>Vous verrez d'ailleurs ce type de message à différents endroits de l'interface, afin de vous guider dans son utilisation.",
    },
    title: {
      oops: 'Oups',
      new_rule: 'Nouvelle règle',
      please_confirm: 'Confirmation requise',
      add_role: 'Ajouter un rôle',
    },
    menu: {
      accessTokens: "Jetons d'accès",
      accounts: 'Projets',
      admin: 'Administration',
      aids: 'AID',
      applicationKeys: 'Clés de licence',
      catalogs: 'Catalogues',
      certAccesses: 'Logs des certificats',
      certificates: 'Certificats',
      companies: 'Entreprises',
      constants: 'Constantes',
      contents: 'Contenus',
      dashboard: 'Accueil',
      encodings: 'Encodages',
      files: 'Fichiers',
      inputFiles: "Fichiers d'entrée",
      outputFiles: 'Fichiers de sortie',
      key_analysis: 'Analyse de licence',
      my_account: 'Mon compte',
      my_company: 'Mon entreprise',
      player: 'Lecteur vidéo',
      products: 'Produits',
      servers: 'Serveurs',
      settings: 'Paramètres de compte',
      realms: 'Domaines',
      stats: 'Statistiques',
      users: 'Utilisateurs',
      virtualEvents: 'Evènements virtuels',
    },
    button: {
      select_project: 'Sélectionnez un projet',
      access_token: "Générer token d'accès",
      create: 'Ajouter',
      add: 'Ajouter',
      save: 'Enregistrer',
      modify: 'Modifier',
      delete: 'Supprimer',
      add_role: 'Ajouter un rôle',
      add_output_file: 'Ajouter un fichier de sortie',
      edit: 'Modifier',
      edit_roles: 'Modifier les rôles',
      edit_filters: 'Modifier les filtres',
      add_participant: 'Add participant',
      add_rule: 'Ajouter une règle',
      add_product_access: 'Ajouter un accès produit',
      add_server_access: 'Ajouter des identifiants',
      add_users: 'Ajouter des utilisateurs',
      add_variant: 'Ajouter une variante',
      invite_link: "Obtenir un lien d'invitation",
      create_aid: 'Ajouter un AID',
      details: 'Détails',
      forgot_password: 'Mot de passe oublié',
      reset_password: 'Ré-initialiser le mot de passe',
      cancel: 'Annuler',
      send_verification_email: "Envoyer l'e-mail de vérification",
      save_password: 'Enregistrer le mot de passe',
      generate_legacy_key: 'Générer la licence',
      download: 'Télécharger',
      edit_rule: 'Modifier les rèfles',
      settings: 'Paramètres',
      download_csv: 'Télécharger le(s) CSV',
      previous: 'Etape précédente',
      next: 'Etape suivante',
      back: 'Retour',
      send: 'Envoyer',
      pick_image: 'Choisir une image',
      play: 'Lire',
    },
    fields: {
      email: 'E-mail',
      principal: 'Propriétaire',
      metadata: 'Métadonnées',
      name: 'Nom',
      value: 'Valeur',
      type: 'Type',
    },
    desc: {
      access_token:
        "Notez soigneusement votre token d'accès, il ne pourra plus être affiché ensuite et un autre token devra être généré :",
      enter_email: 'Veuillez saisir votre e-mail:',
      email_not_verified:
        "Your e-mail has not been verified yet. If you did not receive the confirmation e-mail yet, click the button below to send it again. Don't forget to check your SPAM folder as well!",
      enter_new_password: 'Choisissez votre nouveau mot de passe :',
      password_dont_match: 'Les mots de passe ne correspondent pas',
      select_account:
        "Vous devez d'abord sélectionner un projet dans le menu en haut à gauche.",
      product_list: 'Voici la liste des produits auxquels vous avez accès:',
      your_api_key: "Votre clé d'API est:",
      explain_action: 'Veuillez décrire votre action:',
    },
    rules: {
      operators: {
        eq: 'Equals',
        eqi: 'Equals Ignore Case',
        neq: 'Not Equals',
        neqi: 'Not Equals Ignore Case',
        gt: 'Greater Than',
        gte: 'Greater Than Or Equals',
        lt: 'Lesser Than',
        lte: 'Lesser Than Or Equals',
        oneof: 'One Of',
        noneof: 'Not One Of',
        oneofi: 'One Of Ignore Case',
        noneofi: 'Not One Of Ignore Case',
        regexp: 'Like (regexp)',
      },
      keys: {
        device_manufacturer: 'Device Manufacturer',
        device_model: 'Device Model',
        device_id: 'Device ID',
        hashed_device_id: 'SHA1(Device ID)',
        device_a: 'Device A',
        os_version: 'OS Version',
        os_build: 'OS Build',
        rom_id: 'ROM ID',
        app_id: 'App package name',
        app_version: 'App version',
      },
    },
    principal: {
      company: 'Entreprise',
      account: 'Projet',
    },
  },
  player: {
    title: 'Lecteur de test',
  },
  stats: {
    title: {
      account_stats: 'Statistiques',
      player_stats: 'Statistiques Player',
    },
    fields: {
      start_date: 'Date de début',
      end_date: 'Date de fin',
    },
    player: {
      csv_description:
        'Les statistiques Player sont collectées dans Elastic Search et gardées pendant 3 mois. Il vous est possible de les exporter au format CSV en sélectionnant une date de début et de fin ci-dessous :',
    },
  },
  products: {
    hss_android: {
      desc: 'Le SDK Android pour streamer et télécharger vos médias de manière sécurisée.',
    },
    hss_ios: {
      desc: 'Le SDK iOS/iPadOS pour streamer et télécharger vos médias de manière sécurisée.',
    },
    hss_tvos: {
      desc: 'Le SDK TV OS pour streamer et télécharger vos médias de manière sécurisée.',
    },
    hss_osx: {
      desc: 'Le SDK Mac OSX pour streamer et télécharger vos médias de manière sécurisée.',
    },
    hss_w10: {
      desc: 'Le SDK Windows 10 pour streamer et télécharger vos médias de manière sécurisée.',
    },
    hss_unknown: {
      desc: 'Accès aux anciens SDK HSS. Cet accès sera bientôt supprimé car non utilisé.',
    },
    immersive_tour: {
      desc: "Evènements virtuels avec de l'interactivité, des caméras 360 et de la diffusion en direct.",
    },
    provisioning: {
      desc: "La plateforme d'ingestion et transformation de medias",
    },
    cdm: {
      desc: 'La plateforme de diffusion de médias',
    },
  },
  resources: {
    accounts: {
      name: 'Compte |||| Comptes',
      project: 'Projet',
      fields: {
        realm: "Domaine d'utilisateurs",
      },
    },
    audioCodecs: AUDIO_CODECS,
    catalogs: {
      name: 'Catalogue |||| Catalogues',
      fields: {
        name: 'Nom',
        redemptionCode: "Code d'accès",
        owner_account: 'ce projet seulement',
        owner_company: 'tous les projets de la société',
        items: 'Contenus',
        item: 'Contenu',
        url: 'URL',
        ...DRM,
        playreadyUrl: 'URL licenser Playready',
        playreadyCustomdata: 'Custom data Playready',
        widevineUrl: 'URL licenser Widevine',
        widevineCustomdata: 'Custom data Widevine',
        fairplayUrl: 'URL licenser Fairplay',
        fairplayCustomdata: 'Custom data Fairplay',
        fairplayPublicKey: 'Clé publique Fairplay',
      },
      desc: {
        choose_name: 'Choisissez un nom pour votre catalogue :',
        choose_scope:
          'Choisissez si ce catalogue sera associé uniquement à ce projet (%{project}) ou à tous les projets de votre société :',
        choose_redemption_code:
          "Vous pouvez choisir un code d'accès pour votre catalogue (optionnel). Si vous le faite, votre catalogue sera accessible à l'aide de ce code depuis les applications de test IDVIU.",
      },
      required: {
        name: 'Vous devez fournir un nom au catalogue',
      },
      steps: {
        name: 'Nom',
        scope: "Droits d'accès",
        redemption_code: "Code d'accès",
        content: 'Contenu',
      },
      help: "Les catalogues vous permettent de facilement tester vos contenus dans les applications de test fournies avec les SDK IDVIU. En créant un catalogue, vous pouvez lui assigner un code d'accès, qu'il vous suffira ensuite d'utiliser dans les applications d'exemple pour accéder à vos contenus.",
    },
    changes: {
      fields: {
        on: 'Date',
        by: 'Modifié par',
        comment: 'Commentaire',
        changes: 'Modifications',
        date: 'Date',
      },
    },
    constants: {
      fields: {
        productIds: 'Produits concernés',
        products: 'Produits concernés',
        className: 'Nom de la classe / du fichier',
        language: 'Langage de programmation',
        name: 'Nom',
        value: 'Valeur',
        extra: 'Extra',
      },
      download_code: 'Télécharger le code',
      desc: {
        download_code:
          'Sélectionnez le language de programmation et le nom de la classe / du fichier:',
      },
      help: 'Vous trouverez ici la description de toutes les constantes décrivant des évènements dans les SDK IDVIU.<br/>La plupart du temps, vous rechercherez les constantes de type "Event IDVIU", ces valeurs étant uniques dans tous nos produits. Pour les intégrations plus anciennes, vous pourrez rechercher les constantes liées aux erreurs player (reçues dans la callback onError) et aux infos player (reçues dans la callback onInfo).',
      type: {
        idviu_event: 'Events IDVIU (onEvent)',
        hss_setting: 'Settings HSS',
        player_setting: 'Settings Player',
        checkin_setting: 'Settings Checkin',
        legacy_player_error: 'Erreurs Player (onError)',
        legacy_player_info: 'Infos Player (onInfo)',
        legacy_download_error: 'Erreurs de téléchargement',
      },
    },
    contents: {
      tabs: {
        general: 'Général',
        variants: 'Variantes',
      },
      fields: {
        title: 'Titre',
        details: 'Détails',
        externalId: 'Ref Id',
        state: 'Statut',
        created: 'Créé',
        modified: 'Modifié',
        variants: 'Variantes',
      },
    },
    contentStates: CONTENT_STATES,
    drm: DRM,
    dynamicSettings: {
      fields: {
        name: 'Nom',
        rule: 'Filtre(s)',
        key: 'Paramètre',
        value: 'Valeur appliquée',
        createdOn: 'Créée le',
        updatedOn: 'Mise à jour le',
        deletedOn: 'Supprimée le',
        lastModifications: 'Dernières modifications :',
      },
    },
    outputFiles: {
      fields: {
        id: 'ID',
        url: 'URL',
        quality: 'Qualité',
        optimization: 'Optimisé pour',
        format: 'Format',
        created: 'Créé',
        modified: 'Modifié',
        tracks: 'Pistes',
        bitrates: 'Débits (bit/s)',
        width: 'Largeur (px)',
        height: 'Hauteur (px)',
        drm: 'DRM',
      },
      tracks: {
        fields: {
          type: 'Type',
          codec: 'Codec',
          language: 'Langue',
          role: 'Role',
          channels: 'Canaux',
          sampleRate: 'Echantillonnage',
          profile: 'Profile',
          width: 'Largeur',
          height: 'Hauteur',
          framerate: 'Images/sec',
          name: 'Nom',
        },
      },
      dialog: {
        title: {
          add: 'Créer un fichier de sortie',
        },
      },
    },
    mediaTrackTypes: MEDIA_TRACK_TYPES,
    mediaTrackRoles: MEDIA_TRACK_ROLES,
    outputFileFormats: OUTPUT_FILE_FORMAT,
    realms: {
      name: 'Domaine |||| Domaines',
      tabs: {
        general: 'Général',
        security: 'Sécurité',
        email: 'Email',
        wsmp: 'wsmp',
      },
      fields: {
        id: 'ID',
        name: 'Nom',
        principalType: 'Type de propriétaire',
        security: {
          passwordLength: 'Longueur des mots de passe générés',
          passwordWithNumbers: 'Mots de passe générés avec des chiffres',
          passwordWithSymbols: 'Mots de passe générés avec des symboles',
          passwordWithUppercase: 'Mots de passe générés avec des majuscules',
          passwordWithLowercase: 'Mots de passe générés avec des minuscules',
          validateEmailUrl: 'URL de validation des emails',
          resetPasswordUrl: 'URL de réinitalisation de mot de passe',
          singleSession:
            'Restreindre la lecture à une seule session simultanée',
          useMfa: 'Authentification multi-facteur',
          passwordHash: 'Algorithme de chiffrement des mots de passe',
        },
        useCustomEmail: 'Utiliser une configuration personnalisée',
        noCustomEmail: 'Pas de configuration email personnalisée',
        email: {
          smtpConfiguration: 'Configuration SMTP',
          host: 'Hôte',
          port: 'Port',
          username: "Nom d'utilisateur",
          password: 'Mot de passe',
          secure: 'Connexion sécurisée',
          selfSignedCertificate: 'Certificat non-signé',
          senderName: "Nom de l'émetteur",
          senderEmail: "Adresse email de l'émetteur",
          template: 'Template email racine',
          editTemplate: 'Editer le template email',
          mjmlEditor: {
            ...DEFAULT_MJML_EDITOR_LABELS,
            previewTitle: 'Aperçu',
            refreshingPreview: 'Raffraichissement en cours...',
            noHtml: 'Pas de HTML généré',
          } as MjmlEditorLabels,
          sendTestEmail: 'Envoyer un email de test',
          testEmailRecipient: 'Adresse email du destinataire',
        },
        useWsmp: 'Utiliser un serveur WSMP',
        noWsmp: 'Pas de serveur WSMP',
        wsmp: {
          wsmpConfiguration: 'Configuration WSMP',
          serverUrl: 'URL du serveur',
          service: 'Service',
          aid: 'AID',
          sig: 'Sig',
          account: 'Compte',
        },
      },
      desc: {
        title: 'Domaine',
      },
      passwordHash: {
        bcrypt: 'bcrypt (recommandé)',
        sha1: 'SHA-1 (historique)',
      },
      errors: {
        failedToRefresh: "Impossible de raffraîchir l'aperçu du template email",
        testEmailFailed: "L'envoi de l'email de test a échoué",
      },
      info: {
        sendingTestEmail: "Envoi de l'email de test...",
        testEmailSent: 'Email de test envoyé avec succés',
      },
    },
    roles: {
      name: 'Role |||| Roles',
      fields: {
        admin: 'Administrateur',
        operator: 'Opérateur',
        developer: 'Développeur',
        viewer: 'Lecteur',
      },
    },
    rules: {
      name: 'Règle |||| Règles',
      desc: {
        choose_name: 'Veuillez donner un nom à cette règle:',
        choose_parameter:
          'Choisissez le paramètre que vous souhaitez modifier:',
      },
      fields: {
        filter_key: 'Clé du filtre',
        filter_operator: 'Opérateur',
        filter_value: 'Valeur du filtre',
        key: 'Critère',
        operator: 'Operateur',
        value: 'Valeur(s) du critère',
        name: 'Nom de la règle',
        rules:
          'Si vous souhaitez filtrer cette règle selon un ou plusieurs paramètres, ajoutez-les ci-dessous. Sans aucun filtre, la règle sera appliquée à tous les appareils:',
        description: 'Veuillez décrire le rôle de cette règle:',
      },
    },
    products: {
      name: 'Produit |||| Produits',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
    },
    productAccesses: {
      name: 'Produit ||| Produits',
      fields: {
        account: 'Projet',
        product: 'Produit',
        blocked: 'Bloqué',
        name: 'Produit',
        gdpr: 'Confidentialité GDPR appliquée',
        apiKey: "Clé d'accès à l'API",
        cdmSettings: {
          servers: 'Serveurs DRM',
        },
        drm: 'Type de DRM',
        priority: 'Priorité du serveur',
        url: 'URL du serveur',
        provider: 'Provider',
        providerKey: 'Provider KEY (hex)',
        providerIv: 'Provider IV (hex)',
      },
      desc: {
        blocked:
          "Les règles ci-dessous permettent de déterminer les appareils qui n'auront plus le droit d'utiliser le SDK:",
        blocked_deleted:
          'Ci-dessous, les règles de blocage des appareils qui ne sont plus appliquées:',
        blocked_fingerprint:
          'Si les requêtes de "fingerprint" ont été activées pour votre produit, vous pouvez ajouter ci-dessous des règles de blocage spécifiques. Le blocage par fingerprint ne bloque que la lecture des contenus DRMisés:',
        blocked_fingerprint_deleted:
          'Ci-dessous, les règles de blocage par fingerprint qui ne sont plus appliquées:',
        checkin:
          'Avec les règles ci-dessous, vous pouvez changer la fréquence des requêtes de checkin. Plus la fréquence est élevée, plus les paramètres que vous appliquez à distance seront pris en charge rapidement par les appareils:',
        checkin_deleted:
          'Ci-dessous les règles de checkin qui ont été supprimées:',
        sdk: 'Avec les règles ci-dessous, vous pouvez changer certains paramètres de HSS:',
        sdk_deleted:
          'Les règles ci-dessous ont été supprimées et ne sont plus appliquées:',
        player:
          'Vous pouvez changer les paramètres par défaut du player avec les règles ci-dessous:',
        player_deleted:
          'Les règles ci-dessous ont été supprimées et ne sont plus appliquées:',
        no_rules: 'Aucune règle à afficher',
      },
      tabs: {
        summary: 'En bref',
        blocked: 'Appareils bloqués',
        checkin_settings: 'Paramètres de checkin',
        hss_settings: 'Paramètres HSS',
        player_settings: 'Paramètres player',
        cdm_settings: 'Paramètres CDM',
      },
      title: {
        show: 'Paramètres',
      },
    },
    subtitleCodecs: SUBTITLE_CODECS,
    teamMembers: {
      fields: {
        scope: "Portée de l'accès",
        type: "Type d'utilisateur",
        principalType: "Portée de l'accès",
        service: 'Service',
      },
      scope: {
        company: 'Société',
        account: 'Projet',
      },
      type: {
        backend: 'Backend (Admin console)',
        frontend: 'Frontend',
      },
      desc: {
        enterEmail:
          "Entrez l'adresse e-mail de la personne que vous souhaitez inviter :",
        chooseRole: "Choisissez le rôle qu'aura cette personne :",
        type: 'Est-ce que cet utilisateur aura accès au backend (cette console) ou au frontend (nécessite un domaine) ?',
        scope:
          'Est-ce que cet utilisateur doit avoir accès uniquement à ce projet ou à tous ceux de la société ?',
        realm: 'Sélectionnez un domaine :',
        name: 'Entrez son prénom et son nom (facultatif) :',
        realmWarning: [
          'Après création, les utilisateurs enregistrés dans un domaine',
          "d'authentification ne pourront pas accéder au portail",
          "d'administration.",
          "En cas de besoin d'accéder à ce portail d'administration, il sera",
          'nécessaire de créer un compte non-lié à un domaine',
          "d'authentification.",
        ].join(' '),
      },
    },
    users: {
      fields: {
        email: 'E-mail',
        id: 'User Id',
        username: 'Identifiant',
        password: 'Mot de passe',
        firstName: 'Prénom',
        lastName: 'Nom',
        roles: 'Rôles',
        singleSession: 'Restreindre la lecture à une seule session simultanée',
        useMfa: 'Authentification multi-facteur',
        realm: 'Domaine',
      },
      globalRealm: 'Global',
    },
    virtualEvents: {
      tabs: {
        settings: 'Paramètres',
        participants: 'Participants',
      },
      fields: {
        role: 'Rôle',
        name: "Nom de l'évènement",
        userName: 'Nom',
        moderator: 'Modérateur',
        start: "Début de l'évènement",
        end: "Fin de l'évènement",
        description: 'Description',
        accountId: 'Projet',
        locationName: "Lieu de l'évènement",
        locationCoord: "Coordonnées GPS de l'évènement",
        website: 'Site web',
        organizer: "Nom de l'organisateur",
        image: "Image pour l'évènement",
        logo: "Logo de l'évènement",
      },
      desc: {
        invite_link: "Le lien pour rejoindre l'évènement est :",
        title: 'Evènement virtuel',
        invite_form:
          "Vous pouvez obtenir un lien pour rejoindre la conférence liée à l'évènement avec ce formulaire. L'invité ne sera pas ajouté à la liste des participants de l'évènment mais il pourra se connecter à la conférence",
      },
    },
    virtualEventAccesses: {
      fields: {
        role: 'Rôle',
        virtualEvent: 'Evènement virtuel',
        participant: 'Participant',
      },
    },
    undefined: {
      fields: {
        title: 'Title',
        url: 'URL',
        language: 'Language',
        className: 'Class Name',
        widevineUrl: 'Widevine licenser URL',
        playreadyUrl: 'Playready licenser URL',
        playreadyCustomdata: 'Playready custom data',
        fairplayUrl: 'Fairplay licenser URL',
        fairplayPublicKey: 'Fairplay licenser public key (base64 encoded)',
      },
    },
    variants: {
      fields: {
        id: 'ID',
        type: 'Type',
        created: 'Créé',
        modified: 'Modifié',
        input: 'Entrée',
        output: 'Sorties',
      },
    },
    variantTypes: VARIANT_TYPES,
    videoCodecs: VIDEO_CODECS,
    inputFileFormats: INPUT_FILE_FORMATS,
  },
};

export default customFrenchMessages;
