import {
  Button,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import parser from 'html-react-parser';
import React, {FC, useCallback, useState} from 'react';
import {useTranslate} from 'react-admin';

const useStyle = makeStyles(() => ({
  root: {
    width: '100%',
  },
  header: {
    padding: 8,
  },
  content: {
    paddingTop: 0,
    paddingBottom: 8,
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  bulb: {
    width: 32,
  },
}));

const HelpCard: FC<HelpProps> = ({text, reference, parse = true, ...props}) => {
  const translate = useTranslate();
  const classes = useStyle();
  const textval = translate(text, {
    allowMissing: true,
    onMissingKey: ({key}: {key: string}) => key,
  });
  const [dismissed, setDismissed] = useState(
    localStorage.getItem('help_' + reference),
  );
  const onDismiss = useCallback(() => {
    localStorage.setItem('help_' + reference, 'dismissed');
    setDismissed('dismissed');
  }, [reference, setDismissed]);
  return (
    <>
      {dismissed === null ? (
        <Card className={classes.root} {...props}>
          <CardHeader
            className={classes.header}
            avatar={
              <img
                alt="lightbulb"
                src={(window as any).PUBLIC_URL + '/lightbulb.svg'}
                className={classes.bulb}
              />
            }
            action={
              <Button onClick={onDismiss}>
                <CloseIcon />
              </Button>
            }
          />
          <CardContent className={classes.content}>
            <Typography>{parse ? parser(textval) : textval}</Typography>
          </CardContent>
        </Card>
      ) : (
        <span />
      )}
    </>
  );
};

export interface HelpProps {
  reference: string;
  text: string;
  parse?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
}

export default HelpCard;
