import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/core/styles';
import {Title, useLocale, useSetLocale, useTranslate} from 'react-admin';
import {useDispatch, useSelector} from 'react-redux';
import {changeTheme} from '../actions/actions';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {AppState} from '../types';

const fetch = require('node-fetch');

const useStyles = makeStyles(() => ({
  label: {
    width: '10em',
    display: 'inline-block',
  },
  button: {
    margin: '1em',
  },
  card: {
    margin: '1em',
  },
}));

let oldPassword: string | null = null;
let newPassword: string | null = null;

async function changePassword() {
  if (
    oldPassword === null ||
    newPassword === null ||
    oldPassword === '' ||
    newPassword === ''
  ) {
    alert('Invalid parameters');
    return;
  }

  try {
    const data = {
      oldPassword,
      newPassword,
    };

    const lbtoken = window.localStorage.getItem('lbtoken');
    if (lbtoken === null) return;
    const accessToken = JSON.parse(lbtoken).value.id;

    const options = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'content-type': 'application/json',
        authorization: accessToken,
      },
    };

    const url = `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/changePassword`;
    const response = await fetch(url, options);
    if (response.status >= 400) {
      const json = await response.json();
      if (json && json.error && json.error.message)
        alert('Error : ' + json.error.message);
      else alert('Error status: ' + response.status);
      return;
    } else alert('Password changed.');
  } catch (ex) {
    alert('error 565');
  }
}

const Configuration = () => {
  const translate = useTranslate();
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const theme = useSelector((state: AppState) => state.theme);
  const dispatch = useDispatch();
  return (
    <Card className={classes.card}>
      <Title title={translate('pos.configuration')} />
      <CardContent>
        <div className={classes.label}>{translate('pos.theme.name')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={() => {
            localStorage.setItem('theme', 'light');
            dispatch(changeTheme('light'));
          }}
        >
          {translate('pos.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={() => {
            localStorage.setItem('theme', 'dark');
            dispatch(changeTheme('dark'));
          }}
        >
          {translate('pos.theme.dark')}
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>{translate('pos.language')}</div>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'en' ? 'primary' : 'default'}
          onClick={async () => {
            localStorage.setItem('language', 'en');
            await setLocale('en');
          }}
        >
          en
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={locale === 'fr' ? 'primary' : 'default'}
          onClick={async () => {
            localStorage.setItem('language', 'fr');
            await setLocale('fr');
          }}
        >
          fr
        </Button>
      </CardContent>
      <CardContent>
        <div className={classes.label}>Change password</div>
        <TextField
          placeholder="Old password"
          onChange={v => {
            oldPassword = v.target.value;
          }}
          style={{marginRight: 15}}
        />
        <TextField
          placeholder="New password"
          onChange={v => {
            newPassword = v.target.value;
          }}
        />
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => changePassword()}
        >
          Apply
        </Button>
      </CardContent>
    </Card>
  );
};

export default Configuration;
