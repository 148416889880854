import {FC} from 'react';
import {
  RadioButtonGroupInput as RaRadioButtonGroupInput,
  RadioButtonGroupInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const RadioButtonGroupInput: FC<RadioButtonGroupInputProps> = props => (
  <RaRadioButtonGroupInput {...props} />
);

RadioButtonGroupInput.defaultProps = {
  ...RaRadioButtonGroupInput.defaultProps,
  ...COMMON_INPUT_PROPS,
};
