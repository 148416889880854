import {Button} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {
  FormWithRedirect,
  FormWithRedirectProps,
  Record,
  RedirectionSideEffect,
  useTranslate,
} from 'ra-core';
import * as React from 'react';
import {
  Children,
  FC,
  HtmlHTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
} from 'react';
import {ClassesOverride, SaveButton, ToolbarProps} from 'react-admin';
import {FormRenderProps} from 'react-final-form';
import StepperFormSteps from './StepperFormSteps';

/**
 * Form layout where inputs are divided in steps.
 *
 * Pass FormStep components as children.
 *
 * @example
 * ```tsx
 * import * as React from "react";
 * import {
 *     Edit,
 *     StepperForm,
 *     FormStep,
 *     Datagrid,
 *     TextField,
 *     DateField,
 *     TextInput,
 *     ReferenceManyField,
 *     NumberInput,
 *     DateInput,
 *     BooleanInput,
 *     EditButton
 * } from 'react-admin';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <StepperForm>
 *             <FormStep label="summary">
 *                 <TextInput disabled label="Id" source="id" />
 *                 <TextInput source="title" validate={required()} />
 *                 <TextInput multiline source="teaser" validate={required()} />
 *             </FormStep>
 *             <FormStep label="body">
 *                 <RichTextInput source="body" validate={required()} addLabel={false} />
 *             </FormStep>
 *             <FormStep label="Miscellaneous">
 *                 <TextInput label="Password (if protected post)" source="password" type="password" />
 *                 <DateInput label="Publication date" source="published_at" />
 *                 <NumberInput source="average_note" validate={[ number(), minValue(0) ]} />
 *                 <BooleanInput label="Allow comments?" source="commentable" defaultValue />
 *                 <TextInput disabled label="Nb views" source="views" />
 *             </FormStep>
 *             <FormStep label="comments">
 *                 <ReferenceManyField reference="comments" target="post_id" addLabel={false}>
 *                     <Datagrid>
 *                         <TextField source="body" />
 *                         <DateField source="created_at" />
 *                         <EditButton />
 *                     </Datagrid>
 *                 </ReferenceManyField>
 *             </FormStep>
 *         </StepperForm>
 *     </Edit>
 * );
 * ```
 * @param props - Component properties
 */
const StepperForm: FC<StepperFormProps> = props => (
  <FormWithRedirect
    {...props}
    render={formProps => <StepperFormView {...formProps} />}
  />
);

StepperForm.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // @ts-ignore
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  sanitizeEmptyValues: PropTypes.bool,
};

/** StepperForm props you can use (other props are injected by Create or Edit) */
export interface StepperFormProps
  extends Omit<FormWithRedirectProps, 'render'>,
    Omit<
      HtmlHTMLAttributes<HTMLFormElement>,
      'defaultValue' | 'onSubmit' | 'children'
    > {
  basePath?: string;
  // FormStep elements
  children: ReactNode;
  className?: string;
  initialValues?: any;
  /** Apply margin to all inputs. Possible values are 'none', 'normal', and 'dense' (default) */
  margin?: 'none' | 'normal' | 'dense';
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  /** Wether or not deleted record attributes should be recreated with a `null` value (default: true) */
  sanitizeEmptyValues?: boolean;
  save?: (
    data: Partial<Record>,
    redirectTo: RedirectionSideEffect,
    options?: {
      onSuccess?: (data?: any) => void;
      onFailure?: (error: any) => void;
    },
  ) => void;
  submitOnEnter?: boolean;
  steps?: ReactElement;
  /** The element displayed at the bottom of the form, containing the SaveButton */
  toolbar?: ReactElement;
  undoable?: boolean;
  /** Apply variant to all inputs. Possible values are 'standard', 'outlined', and 'filled' (default) */
  variant?: 'standard' | 'outlined' | 'filled';
  warnWhenUnsavedChanges?: boolean;
}

const useStyles = makeStyles(
  theme => ({
    errorTabButton: {color: theme.palette.error.main},
    content: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  {name: 'RaStepperForm'},
);

export interface StepperToolbarProps extends ToolbarProps {
  next?: () => void;
  previous?: () => void;
  currentStep?: number;
  maxStep?: number;
  stepsWithErrors?: Array<number>;
}

const useToolbarStyles = makeStyles(theme => ({
  defaultToolbar: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));

export const StepperToolbar: FC<StepperToolbarProps> = props => {
  const {
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    redirect,
    saving,
    submitOnEnter,
    previous,
    next,
    currentStep = 0,
    maxStep = 0,
    stepsWithErrors = [],
  } = props;

  const classes = useToolbarStyles(props);
  const translate = useTranslate();

  const saveDisabled = false;
  const lastStep = currentStep === maxStep - 1;
  const inError = stepsWithErrors.includes(currentStep);
  return (
    <div className={classes.defaultToolbar}>
      <Button
        disabled={currentStep === 0}
        variant="contained"
        color="primary"
        onClick={() => previous && previous()}
      >
        {translate('pos.button.previous')}
      </Button>
      {!lastStep && (
        <Button
          variant="contained"
          disabled={inError}
          color="primary"
          onClick={() => next && next()}
        >
          {translate('pos.button.next')}
        </Button>
      )}
      {lastStep && (
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
          disabled={saveDisabled || inError}
          invalid={invalid}
          redirect={redirect}
          saving={saving}
          submitOnEnter={submitOnEnter}
        />
      )}
    </div>
  );
};

export const StepperFormView: FC<StepperFormViewProps> = props => {
  const {
    basePath,
    children,
    className,
    form,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect: defaultRedirect,
    resource,
    saving,
    submitOnEnter,
    steps,
    toolbar,
    undoable,
    variant,
    margin,
  } = props;
  const stepsWithErrors = findStepsWithErrors(children, form.getState().errors);
  const classes = useStyles(props);
  const [currentStep, setCurrentStep] = React.useState(0);
  const maxStep = React.Children.count(children);
  const nextStep = () => {
    setCurrentStep(step => step + 1);
  };

  const previousStep = () => {
    setCurrentStep(step => step - 1);
  };
  return (
    <form className={className}>
      {steps &&
        React.cloneElement(
          steps,
          {
            currentStep,
            stepsWithErrors,
          },
          children,
        )}
      <Divider />
      <div className={classes.content}>
        {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
        {React.Children.map(children, (step: any, index) => {
          return currentStep === index && isValidElement<any>(step)
            ? React.cloneElement(step, {
                intent: 'content',
                resource,
                record,
                basePath,
                hidden: false,
                variant: step.props.variant || variant,
                margin: step.props.margin || margin,
              })
            : null;
        })}
      </div>
      <Divider />
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          className: 'toolbar',
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          previous: previousStep,
          next: nextStep,
          currentStep,
          maxStep,
          stepsWithErrors,
          redirect: defaultRedirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
    </form>
  );
};

StepperFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated

  location: PropTypes.object,
  match: PropTypes.object,
  // @ts-ignore
  pristine: PropTypes.bool,
  // @ts-ignore
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  // @ts-ignore
  handleSubmit: PropTypes.func, // passed by react-final-form
  // @ts-ignore
  invalid: PropTypes.bool,
  // @ts-ignore
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  steps: PropTypes.element.isRequired,
  stepsWithErrors: PropTypes.arrayOf(PropTypes.number),
  toolbar: PropTypes.element,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  value: PropTypes.number,
  version: PropTypes.number,
};

StepperFormView.defaultProps = {
  submitOnEnter: true,
  steps: <StepperFormSteps />,
  toolbar: <StepperToolbar />,
};

export interface StepperFormViewProps extends FormRenderProps {
  basePath?: string;
  classes?: ClassesOverride<typeof useStyles>;
  className?: string;
  margin?: 'none' | 'normal' | 'dense';
  handleSubmitWithRedirect?: (redirectTo: RedirectionSideEffect) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  save?: (
    data: Partial<Record>,
    redirectTo: RedirectionSideEffect,
    options?: {
      onSuccess?: (data?: any) => void;
      onFailure?: (error: any) => void;
    },
  ) => void;
  saving?: boolean;
  steps?: ReactElement;
  toolbar?: ReactElement;
  undoable?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  submitOnEnter?: boolean;
  __versions?: any; // react-final-form internal prop, missing in their type
}

export const findStepsWithErrors = (children: ReactNode, errors: any) => {
  return Children.toArray(children).reduce((acc: any[], child, index) => {
    if (!isValidElement(child)) {
      return acc;
    }

    const inputs = Children.toArray(child.props.children);

    if (
      inputs.some(
        input => isValidElement(input) && get(errors, input.props.source),
      )
    ) {
      return [...acc, index];
    }

    return acc;
  }, []);
};

export default StepperForm;
