import {Grid, Typography} from '@material-ui/core';
import {CSSProperties, FC} from 'react';
import {useTranslate} from 'react-admin';

const FONT_SIZE: CSSProperties['fontSize'] = '.8em';

export interface RealmWarningProps {
  containerStyle?: CSSProperties;
}

export const RealmWarning: FC<RealmWarningProps> = ({containerStyle}) => {
  const tr = useTranslate();
  return (
    <Grid style={containerStyle} container direction="row">
      <Typography style={{fontSize: FONT_SIZE}}>
        {'⚠'}&nbsp;<i>{tr('resources.teamMembers.desc.realmWarning')}</i>
      </Typography>
    </Grid>
  );
};
