import {ENTITY_COLLECTIONS, RealmPrincipalType} from '@idviu/backbone-core';
import {isNil} from '@idviu/ts-helpers';
import {Grid} from '@material-ui/core';
import {FC} from 'react';
import {ReferenceField, TextField, useRecordContext} from 'react-admin';
import {PrincipalTypeIcon} from './PrincipalTypeIcon';

export const PrincipalField: FC = props => {
  const record = useRecordContext(props);
  const principalType = record?.principalType as RealmPrincipalType | undefined;
  const reference = !isNil(principalType)
    ? ENTITY_COLLECTIONS[principalType]
    : undefined;
  return (
    <>
      {!(isNil(principalType) || isNil(reference)) && (
        <Grid container direction="row" alignItems="center">
          <PrincipalTypeIcon />
          <ReferenceField source="principalId" reference={reference}>
            <TextField source="name" />
          </ReferenceField>
        </Grid>
      )}
    </>
  );
};

PrincipalField.defaultProps = {
  addLabel: true,
  label: 'pos.fields.principal',
};
