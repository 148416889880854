import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {withStyles, WithStyles} from '@material-ui/core';
import {FC} from 'react';
import {
  Datagrid,
  ReferenceField,
  ShowButton,
  TextField,
  useTranslate,
} from 'react-admin';
import {USER_LIST_STYLES} from './listStyles';
import rowStyle from './rowStyle';

const UserListDesktop: FC<WithStyles> = ({classes, ...props}) => {
  const tr = useTranslate();
  return (
    <Datagrid
      rowStyle={rowStyle}
      classes={{headerRow: classes.headerRow}}
      {...props}
    >
      <TextField source="username" />
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <ReferenceField
        source="realmId"
        reference={ENTITY_COLLECTIONS.realm}
        emptyText={tr('resources.users.globalRealm')}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="id" />
      <ShowButton />
    </Datagrid>
  );
};
export default withStyles(USER_LIST_STYLES)(UserListDesktop);
