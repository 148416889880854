import {cloneElement, FC, ReactElement} from 'react';
import {
  EditButton,
  ShowControllerProps,
  ShowProps,
  ShowView as ReactAdminShowView,
  TopToolbar,
} from 'react-admin';

const ShowActions = ({
  canEdit,
  basePath,
  data,
  otherActions,
}: {
  canEdit: boolean;
  basePath?: string | undefined;
  data?: any;
  otherActions?: Array<ReactElement> | undefined;
}) => {
  return (
    <TopToolbar>
      {otherActions &&
        otherActions.map((e, i) =>
          cloneElement(e, {
            basePath,
            record: data,
            key: `action_${i}`,
          }),
        )}
      {canEdit && (
        <EditButton
          basePath={basePath}
          record={data}
          variant="contained"
          label="pos.button.modify"
        />
      )}
    </TopToolbar>
  );
};

export {ShowActions};

const ShowView: FC<CustomShowProps> = ({
  canEdit = false,
  actions,
  otherActions,
  ...props
}) => {
  return (
    <ReactAdminShowView
      actions={<ShowActions canEdit={canEdit} otherActions={otherActions} />}
      {...props}
    />
  );
};

export interface CustomShowProps extends ShowViewProps {
  canEdit?: boolean;
  otherActions?: Array<ReactElement> | undefined;
}

interface ShowViewProps
  extends ShowProps,
    Omit<ShowControllerProps, 'resource'> {
  children: ReactElement;
}

export default ShowView;
