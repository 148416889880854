import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {fetchEnd, fetchStart, showNotification} from 'ra-core';
import {useState} from 'react';
import {Field, withTypes} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {FileFieldInput} from '../components';
import {storage} from '../dataProvider';

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    float: 'right',
    marginBottom: '2em',
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  card: {
    minWidth: 275,
    width: '90%',
    marginBottom: '2em',
    'overflow-wrap': 'break-word',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 20,
  },
  pos: {
    marginBottom: 12,
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
});

const AllValues = (props: any) => {
  const {keys} = props;
  const classes = useStyle();
  if (!keys) return <div />;
  return (
    <div className={classes.card}>
      {Object.keys(keys).map(key => {
        return (
          <div key={key}>
            <strong>{key}</strong>: {keys[key]}
          </div>
        );
      })}
    </div>
  );
};

interface FormValues {
  key?: File;
}

const {Form} = withTypes<FormValues>();

const ApplicationKeyAnalysis = () => {
  const [keys, setKeys] = useState(undefined);
  const dispatch = useDispatch();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (values: FormValues) => {
    if (values.key === null) return;
    setLoading(true);
    dispatch(fetchStart());
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;
      if (result === null) {
        dispatch(fetchEnd());
        setLoading(false);
        return;
      }
      const fileAsBase64 = result.substr(result.indexOf(',') + 1);
      const bodyObj = {key: fileAsBase64};

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('authorization', storage.load('lbtoken').id);
      const request = new Request(
        `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.applicationKey}/dump`,
        {
          method: 'POST',
          body: JSON.stringify(bodyObj),
          headers: headers,
        },
      );
      fetch(request)
        .then(response => {
          return response.json();
        })
        .then(({...data}) => {
          if (data.error) {
            dispatch(
              showNotification(
                data.error.message || 'Could not upload or analyze key',
                'warning',
              ),
            );
          } else {
            setKeys(data);
          }
        })
        .finally(() => {
          dispatch(fetchEnd());
          setLoading(false);
        });
    };

    reader.onabort = () => fetchEnd();
    reader.onerror = () => fetchEnd();

    reader.readAsDataURL(values.key!);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                name="key"
                // @ts-ignore
                component={FileFieldInput}
                label="key"
                disabled={loading}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {'Analyze'}
            </Button>
          </CardActions>
          {keys && (
            <Card>
              <CardContent>
                <AllValues keys={keys} />
              </CardContent>
            </Card>
          )}
        </form>
      )}
    />
  );
};

export default ApplicationKeyAnalysis;
