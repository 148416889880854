import React from 'react';
import {Create, ReferenceInput, SelectInput, TextInput} from 'react-admin';
import {SimpleForm} from '../components';

const redirect = (base, id, data) => {
  return '/servers/' + data.serverId + '/show/accesses';
};

const CreateServerAccess = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm redirect={redirect}>
          <ReferenceInput source="serverId" reference="servers">
            <SelectInput source="name" disabled />
          </ReferenceInput>
          <ReferenceInput label="Role" source="roleId" reference="roles">
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="login" />
          <TextInput source="password" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default CreateServerAccess;
