import {
  postApiV1RealmsSendTestEmail,
  SendTestEmailInput,
} from '@idviu/backbone-api-client';
import {Send} from '@material-ui/icons';
import {FC, useCallback, useState} from 'react';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  useTranslate,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {getBackboneRequestOpts} from '../backbone-helpers';
import {LoadingButton} from '../components/LoadingButton';
import {TestEmailDialog} from './TestEmailDialog';

function getTestEmailBody(
  email: string,
  values: Record<string, any>,
): SendTestEmailInput {
  return {
    to: email,
    config: {
      host: values.email.host,
      port: values.email.port,
      username: values.email.username,
      password: values.email.password,
      secure: values.email.secure,
      selfSignedCertificate: values.email.selfSignedCertificate,
      senderName: values.email.senderName,
      senderEmail: values.email.senderEmail,
    },
  };
}

type SendTestEmailStatus = 'none' | 'sending' | 'ok' | 'error';

interface SendTestEmailState {
  status: SendTestEmailStatus;
  error?: string;
}

type SetSendTestEmailState = (state: SendTestEmailState) => void;

function canSendTestEmail(
  state: SendTestEmailState,
  values: Record<string, any>,
): boolean {
  return state.status !== 'sending' && !!values.email.host;
}

function useSendTestEmailStart(setState: SetSendTestEmailState): () => void {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchStart());
    setState({status: 'sending'});
  }, [setState, dispatch]);
}

function useSendTestEmailOk(setState: SetSendTestEmailState): () => void {
  const dispatch = useDispatch();
  const tr = useTranslate();
  return useCallback(() => {
    setState({status: 'ok'});
    const msg = tr('resources.realms.info.testEmailSent');
    dispatch(showNotification(msg, 'success'));
  }, [setState, dispatch, tr]);
}

function useSendTestEmailError(
  setState: SetSendTestEmailState,
): (error: unknown) => void {
  const dispatch = useDispatch();
  const tr = useTranslate();
  return useCallback(
    (error: unknown) => {
      const msg = tr('resources.realms.errors.testEmailFailed');
      console.error(msg, error);
      setState({status: 'error', error: msg});
      dispatch(showNotification(msg, 'error'));
    },
    [setState, dispatch, tr],
  );
}

function useSendTestEmailEnd(): () => void {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(fetchEnd()), [dispatch]);
}

async function sendTestEmail(
  email: string,
  values: Record<string, any>,
): Promise<void> {
  const body: SendTestEmailInput = getTestEmailBody(email, values);
  const requestOpts = getBackboneRequestOpts();
  return await postApiV1RealmsSendTestEmail(body, requestOpts);
}

function useSendTestEmailCallback(
  canSend: boolean,
  setState: SetSendTestEmailState,
  values: Record<string, any>,
): (email: string) => void {
  const onStart = useSendTestEmailStart(setState);
  const onOk = useSendTestEmailOk(setState);
  const onError = useSendTestEmailError(setState);
  const onEnd = useSendTestEmailEnd();
  return useCallback(
    (email: string) => {
      if (!canSend) return;
      onStart();
      sendTestEmail(email, values).then(onOk).catch(onError).finally(onEnd);
    },
    [canSend, values, onStart, onOk, onError, onEnd],
  );
}

function useSendTestEmail(
  state: SendTestEmailState,
  setState: (state: SendTestEmailState) => void,
): [boolean, (email: string) => void] {
  const {values} = useFormState();
  const canSend = canSendTestEmail(state, values);
  const sendTestEmail = useSendTestEmailCallback(canSend, setState, values);
  return [canSend, sendTestEmail];
}

function useSendTestEmailForm(
  onSent: () => void,
): [boolean, SendTestEmailState, (email: string) => void] {
  const [state, setState] = useState<SendTestEmailState>({status: 'none'});
  const [canTestEmail, sendTestEmail] = useSendTestEmail(state, setState);
  const onSend = useCallback(
    (email: string) => {
      sendTestEmail(email);
      onSent();
    },
    [sendTestEmail, onSent],
  );
  return [canTestEmail, state, onSend];
}

function useDialog(): [boolean, () => void, () => void] {
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => setShow(true), [setShow]);
  const onHide = useCallback(() => setShow(false), [setShow]);
  return [show, onShow, onHide];
}

export const TestEmailButton: FC = props => {
  const [showDialog, onShow, onHide] = useDialog();
  const [canTestEmail, state, onSend] = useSendTestEmailForm(onHide);
  const tr = useTranslate();
  return (
    <>
      <LoadingButton
        {...props}
        onClick={onShow}
        disabled={!canTestEmail}
        loading={state.status === 'sending'}
        loadingText={tr('resources.realms.info.sendingTestEmail')}
        startIcon={<Send />}
      >
        {tr('resources.realms.fields.email.sendTestEmail')}
      </LoadingButton>
      <TestEmailDialog show={showDialog} onCancel={onHide} onSend={onSend} />
    </>
  );
};
