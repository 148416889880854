import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/styles';
import {push} from 'connected-react-router';
import React, {FC} from 'react';
import {
  BulkDeleteButton,
  CreateButton,
  ExportButton,
  List as ReactAdminList,
  ListActionsProps,
  ListProps,
} from 'react-admin';

const BulkActionButtons = (props: any) => (
  <>
    <BulkDeleteButton undoable={false} {...props} />
  </>
);

const useStyle = makeStyles({
  button: {
    marginLeft: '1em',
  },
  toolbar: {
    marginRight: '-1.5em',
  },
});

const Actions: FC<IdviuListActionsProps> = ({
  basePath,
  currentSort,
  canCreate,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => {
  const classes = useStyle();
  return (
    <Toolbar className={classes.toolbar}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          variant: 'contained',
          context: 'button',
        })}
      {canCreate && (
        <CreateButton
          basePath={basePath}
          label="pos.button.add"
          variant="contained"
          className={classes.button}
        />
      )}
      {(typeof exporter !== 'boolean' || (exporter as boolean) === true) && (
        <ExportButton
          disabled={total === 0}
          variant="contained"
          className={classes.button}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          exporter={typeof exporter === 'boolean' ? undefined : exporter}
        />
      )}
    </Toolbar>
  );
};

Actions.defaultProps = {
  canCreate: false,
};

export interface IdviuListActionsProps extends ListActionsProps {
  canCreate?: boolean;
}

const List: FC<IDVIUListProps & {push?: typeof push}> = ({
  canDelete,
  canCreate,
  exporter,
  children,
  push,
  ...props
}) => {
  if (!children || !React.isValidElement(children)) {
    throw new Error(
      'List component is not properly configured, you must include one child',
    );
  }
  return (
    <ReactAdminList
      empty={false}
      bulkActionButtons={canDelete ? <BulkActionButtons /> : false}
      actions={<Actions canCreate={canCreate} exporter={exporter} />}
      {...props}
    >
      {children}
    </ReactAdminList>
  );
};

export interface IDVIUListProps extends ListProps {
  canCreate?: boolean;
  canDelete?: boolean;
}

List.defaultProps = {
  exporter: false,
  canCreate: false,
  canDelete: false,
};

export default List;
