import {useTranslate} from 'ra-core';
import {FC} from 'react';
import {FieldProps} from 'react-admin';

const RuleField: FC<FieldProps> = ({record}) => {
  const translate = useTranslate();
  if (!record) return <span />;
  const key = translate('pos.rules.keys.' + record.key);
  const operator = translate('pos.rules.operators.' + record.operator);
  const value = record.value;
  return <span>{`${key} ${operator} "${value}"`}</span>;
};

export default RuleField;
