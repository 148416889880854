import {usePermissions} from 'ra-core';
import React from 'react';
import {Responsive} from 'react-admin';
import {List} from '../components';
import ProductListDesktop from './ProductListDesktop';
import ProductListMobile from './ProductListMobile';

const ProductList = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <List
        {...props}
        perPage={25}
        canCreate={loaded && permissions[0].isAdmin()}
        sort={{field: 'name', order: 'ASC'}}
      >
        <Responsive
          xsmall={<ProductListMobile />}
          medium={<ProductListDesktop />}
        />
      </List>
    </>
  );
};

export default ProductList;
