import {ENTITY_COLLECTIONS, InputFileFormat} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {
  FieldProps,
  Filter,
  ListProps,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import {
  Datagrid,
  DateField,
  DefinedInput,
  EnumField,
  List,
  SelectInput,
  TextInput,
  useCurrentAccount,
} from '../components';
import {TracksField} from '../outputFiles/OutputFileShow';

const InputFilter: FC = props => (
  <Filter {...props} variant="outlined">
    <TextInput source="q$uri" alwaysOn={true} label="pos.search" />
    <SelectInput
      source="format"
      choices={Object.keys(InputFileFormat).map(type => ({
        id: type,
        name: type,
      }))}
      alwaysOn={true}
    />
    <DefinedInput source="error" label="With error" alwaysOn={true} />
  </Filter>
);

export const MaybeTracksField: FC<FieldProps> = () => {
  const record = useRecordContext();
  return record?.tracks && record?.tracks.length ? (
    <TracksField source="tracks" />
  ) : (
    <div />
  );
};

export const InputFileDetail: FC<FieldProps> = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <MaybeTracksField />
      </SimpleShowLayout>
    </Show>
  );
};

export const InputFileList: FC<ListProps> = props => {
  const account = useCurrentAccount();
  const filter = account ? {accountId: account.id} : {};
  return (
    <List
      {...props}
      filter={filter}
      filters={<InputFilter />}
      sort={{field: 'modified', order: 'DESC'}}
    >
      <Datagrid expand={<InputFileDetail />}>
        <TextField source="uri" />
        <DateField source="created" showTime />
        <DateField source="modified" showTime />
        <EnumField source="format" labelPath="resources.inputFileFormats" />
        <NumberField source="size" />
        <TextField source="error" />
        <ReferenceField
          source="serverId"
          reference={ENTITY_COLLECTIONS.server}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
