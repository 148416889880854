import {makeStyles, Typography} from '@material-ui/core';
import {FC} from 'react';
import {
  BooleanField as RABooleanField,
  FieldProps,
  useTranslate,
} from 'react-admin';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    display: 'flex',
    '& span': {
      display: 'flex',
    },
  },
  textField: {
    paddingLeft: 8,
    display: 'inline-block',
  },
}));

const BooleanField: FC<FieldProps> = ({source, record, ...props}) => {
  const translate = useTranslate();
  const classes = useStyles();
  if (source && record) {
    if (!record[source]) record[source] = false;
  }
  const val = source && record ? (record[source] as boolean) : false;
  return (
    <div className={classes.root}>
      <RABooleanField
        source={source}
        className={classes.field}
        valueLabelTrue={translate('pos.boolean.true')}
        valueLabelFalse={translate('pos.boolean.false')}
        record={record}
        {...props}
        label={props.label || source}
      />
      <Typography className={classes.textField}>
        {translate('pos.boolean.' + val)}
      </Typography>
    </div>
  );
};

BooleanField.defaultProps = {
  addLabel: true,
};

export default BooleanField;
