import RARichTextInput from 'ra-input-rich-text';
import {FC} from 'react';
import {TextInputProps} from 'react-admin';

export const RichTextInput: FC<TextInputProps> = (props: any) => {
  return <RARichTextInput {...props} />;
};

RichTextInput.defaultProps = {
  ...RARichTextInput.defaultProps,
  variant: 'outlined',
};
