import {BACKBONE_REST_URL} from './app-constants';
import storage from './dataProvider/storage';

function getBackboneAuthHeaders(): {authorization: string | undefined} {
  return {authorization: storage.load('lbtoken').id};
}

export function getBackboneRequestOpts() {
  const headers = getBackboneAuthHeaders();
  const requestOpts = {
    baseUrl: BACKBONE_REST_URL,
    headers,
  };
  return requestOpts;
}
