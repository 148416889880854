import {ENTITY_COLLECTIONS, VariantType} from '@idviu/backbone-api-client';
import {EditProps, ReferenceArrayInput, SimpleForm} from 'ra-ui-materialui';
import {FC, useState} from 'react';
import {
  AutocompleteArrayInput,
  Edit,
  SelectInput,
  TextInput,
} from '../components';
import AddOutputFileButton from '../outputFiles/AddOutputFileButton';

export const VariantEdit: FC<EditProps> = props => {
  const [version, setVersion] = useState(0);

  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="accountId" disabled />
        <TextInput source="contentId" disabled />
        <SelectInput
          source="type"
          choices={Object.keys(VariantType).map(e => ({id: e, name: e}))}
        />
        <ReferenceArrayInput
          label="Outputs"
          key={version}
          reference={ENTITY_COLLECTIONS.outputFile}
          filterToQuery={(text: string) => ({q$url: text})}
          source="output"
        >
          <AutocompleteArrayInput
            optionText="url"
            shouldRenderSuggestions={(value: string) => value.length >= 2}
          />
        </ReferenceArrayInput>
        <AddOutputFileButton onChange={() => setVersion(version + 1)} />
      </SimpleForm>
    </Edit>
  );
};
