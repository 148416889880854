import {push} from 'connected-react-router';
import {Component} from 'react';
import {
  Create,
  ResourceComponentProps,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';

class CertificateCreate extends Component<
  ResourceComponentProps & {push: typeof push}
> {
  render() {
    const props = this.props;
    return (
      <>
        <Create {...props} title="Create new Certificate">
          <SimpleForm>
            <TextInput source="name" label="Name" />
            <TextInput source="data64" label="Data64" />
            <TextInput source="md5" label="MD5" />
            <TextInput source="comment" label="Comment" />
          </SimpleForm>
        </Create>
      </>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(CertificateCreate);
