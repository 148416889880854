import {ContentState} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {
  DateField,
  Filter,
  Pagination,
  PaginationProps,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import {Datagrid, List, useCurrentAccount} from '../components';
import {PlayContentButton} from './PlayContentButton';
import {ContentStateIcon} from './StateIcon';

const ContentFilter: FC = props => (
  <Filter {...props} variant="outlined">
    <TextInput source="q$title$externalId" alwaysOn={true} label="pos.search" />
    <SelectInput
      source="state"
      choices={Object.keys(ContentState).map(type => ({
        id: type,
        name: `resources.contentStates.${type}`,
      }))}
      alwaysOn={true}
    />
  </Filter>
);

const ContentPagination: FC<PaginationProps> = props => (
  <Pagination
    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 10000, 100000]}
    {...props}
  />
);

/* const useToolbarStyle = makeStyles({
  toolbar: {
    display: 'flex',
    flexDirection: 'row'
  },
});

const ContentActions = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
  const {resource, displayedFilters, filterValues, basePath, showFilter} =
    useListContext();
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  const classes = useToolbarStyle();
  return (
    <div style={{width: '100%', marginBottom: '1em'}}>
      <TopToolbar {...sanitizeListRestProps(rest)} className={classes.toolbar}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        {loaded && permissions[0].canModifyAccountSettings(account) && <CreateButton
          variant="contained"
          basePath={basePath}
          label="pos.button.create"
        />}
      </TopToolbar>
    </div>
  );
};*/

export const ContentList: FC = props => {
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  const filter = account ? {accountId: account.id} : {};

  return (
    <List
      {...props}
      filter={filter}
      filters={<ContentFilter />}
      sort={{field: 'title', order: 'ASC'}}
      canCreate={loaded && permissions[0].canModifyAccountSettings(account)}
      canDelete={loaded && permissions[0].canModifyAccountSettings(account)}
      pagination={<ContentPagination />}
    >
      <Datagrid>
        <ContentStateIcon />
        <TextField source="title" />
        <TextField source="externalId" />
        <DateField source="availabilityStart" showTime={true} />
        <DateField source="availabilityEnd" showTime={true} />
        <PlayContentButton />
        <ShowButton
          variant="contained"
          color="primary"
          label="pos.button.details"
        />
      </Datagrid>
    </List>
  );
};
