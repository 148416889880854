import {Reducer} from 'redux';
import {CHANGE_ACCOUNT, changeAccount} from '../actions/actions';
import {Account} from '../types';

type State = Account | null;
type Action = ReturnType<typeof changeAccount>;

const accountReducer: Reducer<State, Action> = (
  previousState = null,
  action: Action,
) => {
  if (action.type === CHANGE_ACCOUNT) {
    return action.payload;
  }
  return previousState;
};

export default accountReducer;
