import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {required, usePermissions, useTranslate} from 'ra-core';
import {
  BooleanInput,
  CheckboxGroupInput,
  FormDataConsumer,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import {AddNewCredentials, useCurrentAccount} from '../components';
import FormStep from '../components/FormStep';
import StepperForm from '../components/StepperForm';
import CatalogInput from './CatalogInput';

const useStyle = makeStyles({
  flexInput: {
    marginLeft: '1em',
  },
  input: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
});

const CatalogSetup = (props: any) => {
  const translate = useTranslate();
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  const classes = useStyle();
  return (
    <StepperForm {...props}>
      <FormStep label="resources.catalogs.steps.name">
        <Typography>
          {translate('resources.catalogs.desc.choose_name')}
        </Typography>
        <TextInput
          variant="outlined"
          source="name"
          validate={required('resources.catalogs.required.name')}
        />
      </FormStep>
      <FormStep label="resources.catalogs.steps.scope">
        <Typography>
          {translate('resources.catalogs.desc.choose_scope', {
            project: account ? account.name : '',
          })}
        </Typography>
        <RadioButtonGroupInput
          label={''}
          source="principalType"
          defaultValue={EntityType.account}
          choices={[
            {
              id: EntityType.account,
              name: 'resources.catalogs.fields.owner_account',
            },
            {
              id: EntityType.company,
              name: 'resources.catalogs.fields.owner_company',
            },
          ]}
        />
        <FormDataConsumer>
          {({formData}) => {
            if (formData.principalType === EntityType.account) {
              return (
                <TextInput
                  source="principalId"
                  defaultValue={account && account.id}
                  className={classes.hidden}
                />
              );
            } else if (formData.principalType === EntityType.company) {
              return (
                <TextInput
                  source="principalId"
                  defaultValue={account && account.companyId}
                  className={classes.hidden}
                />
              );
            }
            return <></>;
          }}
        </FormDataConsumer>
      </FormStep>
      <FormStep label="resources.catalogs.steps.redemption_code">
        <Typography>
          {translate('resources.catalogs.desc.choose_redemption_code')}
        </Typography>
        <TextInput source="redemptionCode" variant="outlined" />
      </FormStep>
      <FormStep label="resources.catalogs.steps.content">
        {loaded && permissions[0].isAdmin() && (
          <BooleanInput source="dynamic" />
        )}
        <FormDataConsumer>
          {({formData, ...rest}) =>
            formData.dynamic && (
              <>
                <SelectInput
                  {...rest}
                  source="dynamic_type"
                  choices={[
                    {id: 'btv_channel_list', name: 'B.TV channel list'},
                    {id: 'btv_npvr_list', name: 'B.TV NPVR list'},
                    {id: 'bein_channel_list', name: 'beIN channel list'},
                    {id: 'otvp_npvr_list', name: 'OTVP NPVR list'},
                    {id: 'otvp_channel_list', name: 'OTVP channel list'},
                  ]}
                />
                <ReferenceInput
                  {...rest}
                  className={classes.flexInput}
                  source="credentialId"
                  reference={ENTITY_COLLECTIONS.credential}
                  label="credentials"
                  filter={{
                    ownerId: formData.principalId,
                    ownerType: formData.principalType,
                  }}
                >
                  <SelectInput optionText="login" />
                </ReferenceInput>
                <AddNewCredentials />
                {(formData.dynamic_type === 'otvp_npvr_list' ||
                  formData.dynamic_type === 'otvp_channel_list') && (
                  <SelectInput
                    {...rest}
                    className={classes.input}
                    source="otvp_environment"
                    label="Environment"
                    defaultValue={'prod'}
                    choices={[
                      {id: 'prod', name: 'Production'},
                      {id: 'hidden_prod', name: 'Hidden production'},
                    ]}
                  />
                )}
                {formData.dynamic_type === 'bein_channel_list' && (
                  <SelectInput
                    {...rest}
                    className={classes.input}
                    source="bein_environment"
                    label="Environment"
                    defaultValue={'prod'}
                    choices={[
                      {id: 'mena_uat_android', name: 'Mena UAT Android'},
                      {id: 'mena_uat_ios', name: 'Mena UAT iOS'},
                      {id: 'mena_uat_ios_vip', name: 'Mena UAT iOS VIP'},
                      {id: 'mena_prod_android', name: 'Mena Prod Android'},
                      {id: 'mena_prod_ios', name: 'Mena Prod iOS'},
                      {id: 'mena_prod_ios_vip', name: 'Mena Prod iOS VIP'},
                      {id: 'fr_uat_android', name: 'FR UAT Android'},
                      {id: 'fr_uat_ios', name: 'FR UAT iOS'},
                      {id: 'fr_prod_android', name: 'FR Prod Android'},
                      {id: 'fr_prod_ios', name: 'FR Prod iOS'},
                    ]}
                  />
                )}
                {(formData.dynamic_type === 'btv_channel_list' ||
                  formData.dynamic_type === 'btv_npvr_list') && (
                  <SelectInput
                    {...rest}
                    className={classes.input}
                    source="btv_environment"
                    label="Environment"
                    defaultValue={'btv_prod_mobile'}
                    choices={[
                      {id: 'miami_prod', name: 'Miami Prod'},
                      {id: 'btv_prod_fixe', name: 'BTV Prod Fixe'},
                      {id: 'btv_prod_mobile', name: 'BTV Prod Mobile'},
                      {id: 'btv_prod_tizen', name: 'BTV Prod Tizen'},
                      {id: 'btv_tb1_fixe', name: 'BTV TB1 Fixe'},
                      {id: 'btv_tb1_mobile', name: 'BTV TB1 Mobile'},
                      {id: 'btv_tb2_fixe', name: 'BTV TB2 Fixe'},
                      {id: 'btv_tb2_mobile', name: 'BTV TB2 Mobile'},
                      {id: 'btv_tb3_fixe', name: 'BTV TB3 Fixe'},
                      {id: 'btv_tb3_mobile', name: 'BTV TB3 Mobile'},
                    ]}
                  />
                )}
                {(formData.dynamic_type === 'btv_channel_list' ||
                  formData.dynamic_type === 'btv_npvr_list') && (
                  <NumberInput
                    {...rest}
                    className={classes.input}
                    source="btv_rateshaping"
                    label="Rateshaping"
                    defaultValue={50000}
                    step={1000}
                  />
                )}
                {(formData.dynamic_type === 'btv_channel_list' ||
                  formData.dynamic_type === 'btv_npvr_list') && (
                  <NumberInput
                    {...rest}
                    className={classes.input}
                    source="btv_start_time"
                    label="Start Time offset (seconds)"
                    defaultValue={-1}
                    step={60}
                  />
                )}
                {(formData.dynamic_type === 'btv_channel_list' ||
                  formData.dynamic_type === 'btv_npvr_list') && (
                  <NumberInput
                    {...rest}
                    className={classes.input}
                    source="btv_end_time"
                    label="End Time offset (seconds)"
                    defaultValue={-1}
                    step={60}
                  />
                )}
                {(formData.dynamic_type === 'btv_channel_list' ||
                  formData.dynamic_type === 'btv_npvr_list') && (
                  <CheckboxGroupInput
                    source="btv_filter"
                    variant="outlined"
                    choices={[
                      {id: '{DASH-WIDEVINE}', name: 'Dash Widevine'},
                      {id: '{DASH-PLAYREADY}', name: 'Dash Playready'},
                      {id: '{HLS-FAIRPLAY}', name: 'HLS Fairplay'},
                      {id: '{HLS-AES}', name: 'HLS AES'},
                    ]}
                  />
                )}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({formData}) => <>{!formData.dynamic && <CatalogInput />}</>}
        </FormDataConsumer>
      </FormStep>
    </StepperForm>
  );
};

export default CatalogSetup;
