import {OutputFile} from '@idviu/backbone-api-client';
import {HEADER_ACCOUNT, HEADER_API_KEY} from '@idviu/backbone-core';
import {IDPlayerReact} from '@idviu/player-wrapper-react';
import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import {Identifier, useGetList, useTranslate} from 'ra-core';
import {FC, useCallback, useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import {useHistory} from 'react-router';
import {TextInput, useCurrentAccount} from '../components';

const useStyle = makeStyles(theme => ({
  main: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
  },
  paper: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  player: {
    boxSizing: 'border-box',
    flex: '1 1 0%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: -16,
    padding: theme.spacing(1),
  },
  form: {
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(1),
    margin: 0,
  },
  formLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    margin: 0,
  },
  formContent: {
    //grid
    flex: '1 1 0%',
    alignItems: 'center',
    margin: 0,
    boxSizing: 'border-box',
  },
  submit: {
    flex: '0 0 auto',
    marginLeft: theme.spacing(2),
  },
  input: {
    margin: 0,
    boxSizing: 'border-box',
    height: '40px',
  },
  item: {
    margin: 0,
    boxSizing: 'border-box',
  },
  select: {
    alignSelf: 'start',
    marginLeft: theme.spacing(2),
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    minWidth: '50%',
  },
  error: {
    alignSelf: 'start',
    color: 'red',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    minWidth: '50%',
  },
}));

export interface PlayerTestProps {}

interface PlayerTestState {
  cid?: string;
  fid?: string;
}

/*interface Server {
  id: string;
  name?: string;
  type?: string;
  url?: string;
}*/

const getOutputName = (o: OutputFile): string => {
  if (!o) return 'und';
  return `${o.format} - ${o.quality} - ${o.optimization}`;
};

export const PlayerTest: FC<PlayerTestProps> = props => {
  const classes = useStyle(props);
  const translate = useTranslate();
  const history = useHistory<PlayerTestState | undefined>();
  const [src, setSrc] = useState<any | undefined>();
  const [error, setError] = useState<string | undefined>();
  const fid = history.location.state?.fid;
  const cid = history.location.state?.cid;
  const account = useCurrentAccount();

  const {
    data: edges,
    ids: edgeIds,
    loaded: edgesLoaded,
  } = useGetList(
    'servers',
    {page: 1, perPage: 10},
    {field: 'id', order: 'ASC'},
    {type: 'edge'},
  );
  const {data: accesses, ids: accessIds} = useGetList(
    'productAccesses',
    {page: 1, perPage: 10},
    {field: 'id', order: 'ASC'},
    {product: 'cdm', accountId: account?.id},
    {
      enabled: account?.id !== undefined,
    },
  );
  const [access, setAccess] = useState<any | undefined>();
  useEffect(() => {
    if (accessIds.length) {
      setAccess(accesses[accessIds[0]]);
    }
  }, [accesses, accessIds, setAccess]);
  const [edgeId, setEdgeId] = useState<string>('');
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [sources, setSources] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    url: 'https://bitmovin-a.akamaihd.net/content/MI201109210084_1/mpds/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.mpd',
  });
  useEffect(() => {
    if (edgesLoaded && !edgeId && edgeIds.length) {
      setEdgeId(edgeIds[0] as string);
    }
  }, [edgesLoaded, edgeIds, edgeId, setEdgeId]);

  const selectEdge = useCallback(
    e => {
      setEdgeId(e.target.value);
    },
    [setEdgeId],
  );

  const selectSource = useCallback(
    e => {
      setSelectedSource(e.target.value);
      console.log('new initial values', sources[parseInt(e.target.value)]);
      setInitialValues(sources[parseInt(e.target.value)]);
    },
    [sources, setSelectedSource],
  );

  useEffect(() => {
    if (!cid || !edgeId || !access) return;
    const server = edges[edgeId];
    if (!server || !server.url) return;
    setError(undefined);
    const headers = {
      'content-type': 'application/json',
      [HEADER_ACCOUNT]: account?.name ?? 'null',
      [HEADER_API_KEY]: access?.apiKey ?? 'null',
    };
    fetch(`${server.url}/cdm/play/${cid}?externalId=false`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        includeLicenseUrl: true,
      }),
    })
      .then(async res => {
        const data = await res.json();
        if (res.status >= 400) {
          //error
          setError(
            typeof data.error === 'string'
              ? data.error
              : JSON.stringify(data.error),
          );
        } else {
          setSources(
            data.outputs.map((o: any) => ({
              name: getOutputName(o),
              url: o.url,
              widevineUrl: o.licenseUrl ?? `${server.url}/cdm/license/${o.id}`,
              customLicenseHeaders: {
                [HEADER_ACCOUNT]: account?.name ?? 'null',
                [HEADER_API_KEY]: access?.apiKey ?? 'null',
              },
            })),
          );
        }
      })
      .catch(err => {
        setError(`Play request failed: ${err.message}`);
      });
  }, [cid, edges, edgeId, setError, setSources, getOutputName, access]);

  const onSubmit = useCallback(
    values => {
      setSrc(values);
    },
    [setSrc],
  );

  useEffect(() => {
    if (!fid) return;
  }, [fid]);

  useEffect(() => {
    if (!cid) return;
  }, [cid]);

  return (
    <div className={classes.main}>
      <Paper className={classes.paper}>
        <Typography className={classes.title} variant="h5" component="h3">
          {translate('player.title')}
        </Typography>
        {error && <Typography className={classes.error}>{error}</Typography>}
        {cid && (
          <TextField
            select
            size="small"
            id="edge-select"
            value={edgeId}
            label="CDM"
            onChange={selectEdge}
            variant="outlined"
            className={classes.select}
          >
            {edgeIds?.map((e: Identifier) => (
              <MenuItem key={`edge_${e}`} value={e}>
                {edges[e]?.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {cid && (
          <TextField
            select
            size="small"
            id="output-select"
            value={selectedSource}
            label="Select output"
            onChange={selectSource}
            variant="outlined"
            className={classes.select}
          >
            {sources?.map((s: any, index) => (
              <MenuItem key={`source_${index}`} value={index.toString()}>
                {s.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <Form
          onSubmit={onSubmit}
          initialValues={{...initialValues}}
          className={classes.form}
        >
          {({handleSubmit}) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.formLayout}>
                  <Grid className={classes.formContent} container spacing={2}>
                    <Grid item xs={12} className={classes.item}>
                      <TextInput
                        className={classes.input}
                        source="url"
                        label="Url"
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.item}>
                      <TextInput
                        className={classes.input}
                        source="widevineUrl"
                        label="Widevine URL"
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.item}>
                      <TextInput
                        className={classes.input}
                        source="fairplayUrl"
                        label="Fairplay URL"
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.item}>
                      <TextInput
                        className={classes.input}
                        source="playreadyUrl"
                        label="Playready URL"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    className={classes.submit}
                    color="primary"
                    type="submit"
                    variant="contained"
                  >
                    Open
                  </Button>
                </div>
              </form>
            );
          }}
        </Form>
        <IDPlayerReact className={classes.player} src={src} options={{}} />
      </Paper>
    </div>
  );
};
