import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import {ResourceComponents} from '../components';
import {OutputFileEdit} from './OutputFileEdit';
import {OutputFileList} from './OutputFileList';
import {OutputFileShow} from './OutputFileShow';

const components: ResourceComponents = {
  edit: OutputFileEdit,
  icon: InsertDriveFile,
  list: OutputFileList,
  show: OutputFileShow,
};
export default components;
