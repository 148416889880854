import ApplicationKeyIcon from '@material-ui/icons/VpnKey';
import ApplicationKeyCreate from './ApplicationKeyCreate';
import ApplicationKeyList from './ApplicationKeyList';
import ApplicationKeyShow from './ApplicationKeyShow';

const content = {
  icon: ApplicationKeyIcon,
  list: ApplicationKeyList,
  show: ApplicationKeyShow,
  create: ApplicationKeyCreate,
};

export default content;
