import * as React from 'react';
import {FC, ReactElement} from 'react';
import PropTypes from 'prop-types';
import {useTranslate, Record} from 'ra-core';
import {FormInput} from 'react-admin';
import {CSSProperties} from '@material-ui/core/styles/withStyles';
import {Step, StepLabel} from '@material-ui/core';

const hiddenStyle: CSSProperties = {display: 'none'};

const FormStep: FC<FormStepProps> = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  intent,
  label,
  margin,
  path,
  record,
  resource,
  variant,
  completed,
  ...rest
}) => {
  const translate = useTranslate();

  const renderHeader = () => (
    <Step key={label} completed={completed} {...rest}>
      <StepLabel>{translate(label, {_: label})}</StepLabel>
    </Step>
  );

  const renderContent = () => (
    <span style={hidden ? hiddenStyle : undefined} className={contentClassName}>
      {children &&
        basePath &&
        record &&
        resource &&
        React.Children.map(children, input => {
          if (!React.isValidElement(input)) return null;
          return input && input.props && input.props.source ? (
            <FormInput
              basePath={basePath}
              input={input}
              record={record}
              resource={resource}
              variant={input.props.variant || variant}
              margin={input.props.margin || margin}
            />
          ) : (
            React.cloneElement(input)
          );
        })}
    </span>
  );

  return intent === 'header' ? renderHeader() : renderContent();
};

FormStep.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  intent: PropTypes.oneOf(['header', 'content']),
  hidden: PropTypes.bool,
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  path: PropTypes.string,
  // @ts-ignore
  record: PropTypes.object,
  resource: PropTypes.string,
  index: PropTypes.number,
  completed: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};

export interface FormStepProps {
  basePath?: string;
  className?: string;
  contentClassName?: string;
  hidden?: boolean;
  icon?: ReactElement;
  intent?: 'header' | 'content';
  label: string;
  margin?: 'none' | 'normal' | 'dense';
  path?: string;
  record?: Record;
  resource?: string;
  index?: number;
  completed?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

FormStep.displayName = 'FormStep';

export default FormStep;
