import {useTranslate} from 'ra-core';

export function useEnumChoices(
  values: Record<string, string>,
  trPath: string,
): {id: string; name: string}[] {
  const translate = useTranslate();
  return Object.keys(values).map(key => ({
    id: key,
    name: translate(`${trPath}.${key}`),
  }));
}
