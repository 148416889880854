import {FC} from 'react';
import {
  DateTimeInput as RaDateTimeInput,
  DateTimeInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const DateTimeInput: FC<DateTimeInputProps> = props => (
  <RaDateTimeInput {...props} />
);

DateTimeInput.defaultProps = {
  ...RaDateTimeInput.defaultProps,
  ...COMMON_INPUT_PROPS,
  variant: 'outlined',
};
