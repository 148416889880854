import {Stepper} from '@material-ui/core';
import {TabsProps} from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import {Children, cloneElement, FC, isValidElement, ReactElement} from 'react';

const StepperFormSteps: FC<StepperFormStepsProps> = ({
  children,
  currentStep = 0,
}) => {
  return (
    <div>
      <Stepper activeStep={currentStep} alternativeLabel>
        {Children.map(children, (step, index) => {
          if (!isValidElement<any>(step)) return null;
          return cloneElement(step, {
            intent: 'header',
            index: index,
            completed: index < currentStep,
          });
        })}
      </Stepper>
    </div>
  );
};

StepperFormSteps.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  currentStep: PropTypes.number,
  /* @ts-ignore */
  stepsWithErrors: PropTypes.arrayOf(PropTypes.number),
};

export const getStepFullPath = (
  step: ReactElement,
  index: number,
  baseUrl: string = '',
): string =>
  `${baseUrl}${
    step.props.path ? `/${step.props.path}` : index > 0 ? `/${index}` : ''
  }`;

export interface StepperFormStepsProps extends Omit<TabsProps, 'value'> {
  classes?: any;
  currentStep?: number;
  stepsWithErrors?: number[];
}

export default StepperFormSteps;
