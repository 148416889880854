import {Typography} from '@material-ui/core';
import {FC} from 'react';

export interface LabelProps {
  text: string;
}

export const Label: FC<LabelProps> = ({text}) => (
  <Typography component="span" variant="body2">
    {text}
  </Typography>
);
