import {
  Constant,
  DynamicSetting,
  EntityType,
  ENTITY_COLLECTIONS,
} from '@idviu/backbone-api-client';
import {
  Record as RaRecord,
  usePermissions,
  useQuery,
  useTranslate,
} from 'ra-core';
import {FC} from 'react';
import {
  ArrayField,
  BooleanField,
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  RichTextField,
  SelectField,
  ShowController,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import {
  Changes,
  Datagrid,
  ShowView,
  useCurrentAccount,
  User,
} from '../components';

const DynamicSettingTitle: FC<{record: DynamicSetting}> = ({record}) => {
  const tr = useTranslate();
  return (
    <span>
      {tr('resources.rules.name', {smart_count: 1})}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const DeletedInfo: FC = () => (
  <>
    <DateField source="deletedOn" showTime={true} />
    <ReferenceField
      label="Deleted by"
      source="deletedBy"
      reference={ENTITY_COLLECTIONS.user}
      link={false}
    >
      <User />
    </ReferenceField>
  </>
);

const Constants: FC<{record: RaRecord}> = ({record}) => {
  const {data: constants}: {data?: Constant[]} = useQuery({
    type: 'getList',
    resource: ENTITY_COLLECTIONS.constant,
    payload: {
      pagination: {page: 1, perPage: 1000},
      sort: {id: 'name', order: 'ASC'},
    },
  });
  return (
    <>
      {record.type && !record.type.startsWith('blocked') && (
        <SelectField
          source="key"
          choices={
            constants
              ? constants.map(c => {
                  return {id: c.value, name: c.name};
                })
              : []
          }
        />
      )}
      {constants &&
        record.key &&
        constants.filter(c => c.value === record.key)[0].valueType ===
          'boolean' && <BooleanField source="value" />}
      {constants &&
        record.key &&
        constants.filter(c => c.value === record.key)[0].valueType ===
          'number' && <NumberField source="value" />}
      {constants &&
        record.key &&
        constants.filter(c => c.value === record.key)[0].valueType ===
          'string' && <TextField source="value" />}
    </>
  );
};

const TranslatedTextField: FC<{
  record?: RaRecord;
  source: keyof RaRecord;
  translationBase?: string;
}> = ({record, source, translationBase = ''}) => {
  const translate = useTranslate();
  return <span>{record && translate(translationBase + record[source])}</span>;
};

const Rules: FC<{
  record?: RaRecord;
}> = ({record}) => (
  <Labeled label="resources.dynamicSettings.fields.rules">
    <ArrayField record={record} source="rules">
      <Datagrid>
        <TranslatedTextField source="key" translationBase="pos.rules.keys." />
        <TranslatedTextField
          source="operator"
          translationBase="pos.rules.operators."
        />
        <TextField source="value" />
      </Datagrid>
    </ArrayField>
  </Labeled>
);

const DynamicSettingShow: FC = (props: any) => {
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  return (
    <>
      <ShowController {...props}>
        {({translate, record, ...controllerProps}) => (
          <ShowView
            {...props}
            record={record}
            {...controllerProps}
            title={<DynamicSettingTitle {...props} />}
            canEdit={
              loaded &&
              permissions[0].canModifyAccountSettings(account) &&
              record &&
              !record.deleted
            }
          >
            <SimpleShowLayout>
              <TextField source="name" />
              <RichTextField source="description" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              {record?.deleted && <DeletedInfo />}
              {record && <Constants record={record} />}
              <Rules record={record} />
              {record && (
                <Changes
                  principalType={EntityType.dynamicSetting}
                  principalId={record.id}
                />
              )}
            </SimpleShowLayout>
          </ShowView>
        )}
      </ShowController>
    </>
  );
};

export default DynamicSettingShow;
