import {usePermissions} from 'ra-core';
import React from 'react';
import {BooleanInput, FormTab, TextInput} from 'react-admin';
import {Edit, TabbedForm} from '../components';

const CompanyTitle = ({record}) => {
  return <span>Company {record ? `"${record.name}"` : ''}</span>;
};

const CompanyEdit = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <Edit {...props} title={<CompanyTitle />}>
        <TabbedForm canDelete={loaded && permissions[0].isSuperAdmin()}>
          <FormTab label="company details">
            <TextInput source="name" />
            <TextInput source="id" disabled />
            <BooleanInput source="blocked" />
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default CompanyEdit;
