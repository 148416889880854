import {Constant, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {RuleConditionKey, RuleConditionOperator} from '@idviu/backbone-core';
import {makeStyles} from '@material-ui/styles';
import {useQuery, useTranslate} from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import {ComponentType, FC} from 'react';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  NumberInput,
  ResourceComponentPropsWithId,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import {StaticContext} from 'react-router';
import {
  DeleteWithCommentButton,
  Edit,
  SaveWithCommentButton,
  SingleValueFormIterator,
  useEnumChoices,
} from '../components';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomToolbar: FC<
  ResourceComponentPropsWithId<{}, StaticContext, unknown>
> = props => (
  <Toolbar {...props} classes={useStyles()}>
    <SaveWithCommentButton {...props} />
    <DeleteWithCommentButton />
  </Toolbar>
);

const DynamicSettingTitle: FC<{record?: {name: string}}> = ({record}) => {
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.rules.name', {smart_count: 1})}{' '}
      {record ? `"${record.name}"` : ''}
    </span>
  );
};

const requiredSettingValue =
  (message = 'Please enter a setting value') =>
  (value?: unknown) =>
    value !== null && value !== undefined ? undefined : message;

const requiredKey =
  (message = 'Please enter a key that will be used to apply the rule') =>
  (value?: unknown) =>
    value ? undefined : message;

const requiredOperator =
  (
    message = 'Please select an operator that will be used to compare the key with the value',
  ) =>
  (value?: unknown) =>
    value ? undefined : message;

const requiredValue =
  (message = 'Please enter a value that will be used to apply the rule') =>
  (value?: unknown) =>
    value !== null && value !== undefined ? undefined : message;

const DynamicSettingEdit: ComponentType<
  ResourceComponentPropsWithId<{}, StaticContext, unknown>
> = props => {
  const {data: constants}: {data?: Constant[]} = useQuery({
    type: 'getList',
    resource: ENTITY_COLLECTIONS.constant,
    payload: {
      pagination: {page: 1, perPage: 1000},
      sort: {id: 'name', order: 'ASC'},
    },
  });
  return (
    <>
      <Edit {...props} title={<DynamicSettingTitle />}>
        <SimpleForm redirect={false} toolbar={<CustomToolbar {...props} />}>
          <TextInput source="name" variant="outlined" />
          <RichTextInput source="description" variant="outlined" />
          <FormDataConsumer>
            {({formData}) =>
              formData &&
              formData.type &&
              !formData.type.startsWith('blocked') && (
                <SelectInput
                  label="resources.dynamicSettings.fields.key"
                  source="key"
                  variant="outlined"
                  choices={
                    constants
                      ? constants.map(constant => {
                          return {id: constant.value, name: constant.name};
                        })
                      : []
                  }
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData &&
              constants &&
              formData.key &&
              constants.find(constant => constant.value === formData.key)
                ?.valueType === 'boolean' && (
                <BooleanInput
                  source="value"
                  {...rest}
                  validate={requiredSettingValue()}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData &&
              constants &&
              formData.key &&
              constants.find(constant => constant.value === formData.key)
                ?.valueType === 'number' && (
                <NumberInput
                  source="value"
                  {...rest}
                  variant="outlined"
                  validate={requiredSettingValue()}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData &&
              constants &&
              formData.key &&
              constants.find(constant => constant.value === formData.key)
                ?.valueType === 'string' && (
                <TextInput
                  source="value"
                  {...rest}
                  variant="outlined"
                  validate={requiredSettingValue()}
                />
              )
            }
          </FormDataConsumer>

          <ArrayInput
            label="resources.dynamicSettings.fields.rules"
            source="rules"
            defaultValue={[]}
            variant="outlined"
          >
            <SingleValueFormIterator>
              <SelectInput
                label="resources.rules.fields.key"
                source="key"
                choices={useEnumChoices(RuleConditionKey, 'pos.rules.keys')}
                validate={requiredKey()}
                variant="outlined"
              />
              <SelectInput
                label="resources.rules.fields.operator"
                source="operator"
                choices={useEnumChoices(
                  RuleConditionOperator,
                  'pos.rules.operators',
                )}
                validate={requiredOperator()}
                variant="outlined"
              />
              <TextInput
                label="resources.rules.fields.value"
                source="value"
                validate={requiredValue()}
              />
            </SingleValueFormIterator>
          </ArrayInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default DynamicSettingEdit;
