import {isNilOrEmpty} from '@idviu/ts-helpers';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import {Cancel, Send} from '@material-ui/icons';
import {ChangeEvent, FC, useCallback, useState} from 'react';
import {useTranslate} from 'react-admin';

export interface TestEmailDialogProps {
  show: boolean;
  onCancel: () => void;
  onSend: (email: string) => void;
}

const Title: FC = () => {
  const tr = useTranslate();
  return (
    <DialogTitle>
      {tr('resources.realms.fields.email.sendTestEmail')}
    </DialogTitle>
  );
};

const RecipientInput: FC<TextFieldProps> = props => {
  const tr = useTranslate();
  return (
    <TextField
      autoFocus
      margin="dense"
      label={tr('resources.realms.fields.email.testEmailRecipient')}
      type="email"
      fullWidth
      {...props}
    />
  );
};

const CancelButton: FC<ButtonProps> = props => {
  const tr = useTranslate();
  return (
    <Button color="primary" startIcon={<Cancel />} {...props}>
      {tr('pos.button.cancel')}
    </Button>
  );
};

const SendButton: FC<ButtonProps> = props => {
  const tr = useTranslate();
  return (
    <Button color="primary" startIcon={<Send />} {...props}>
      {tr('pos.button.send')}
    </Button>
  );
};

const Body: FC<TestEmailDialogProps> = ({onCancel, onSend}) => {
  const [email, setEmail] = useState('');
  const handleChangeEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setEmail(event.currentTarget.value),
    [setEmail],
  );
  const handleClickSend = useCallback(() => onSend(email), [email, onSend]);
  return (
    <>
      <DialogContent>
        <RecipientInput value={email} onChange={handleChangeEmail} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel} />
        <SendButton onClick={handleClickSend} disabled={isNilOrEmpty(email)} />
      </DialogActions>
    </>
  );
};

export const TestEmailDialog: FC<TestEmailDialogProps> = props => {
  return (
    <Dialog open={props.show} onClose={props.onCancel}>
      <Title />
      <Body {...props} />
    </Dialog>
  );
};
