import {
  ENTITY_COLLECTIONS,
  ProductAccessWithRelations,
} from '@idviu/backbone-api-client';
import {notNil} from '@idviu/ts-helpers';
import {Box, Theme, useMediaQuery} from '@material-ui/core';
import {Security} from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PlayerIcon from '@material-ui/icons/OndemandVideo';
import StatsIcon from '@material-ui/icons/PieChart';
import SettingsIcon from '@material-ui/icons/Settings';
import {FC, useState} from 'react';
import {
  MenuItemLink,
  MenuProps,
  usePermissions,
  useQuery,
  useTranslate,
} from 'react-admin';
import {useSelector} from 'react-redux';
import accounts from '../accounts';
import aids from '../aids';
import applicationKeys from '../applicationKeys';
import catalogs from '../catalogs';
import certAccesses from '../certAccesses';
import certificates from '../certificates';
import companies from '../companies';
import {useCurrentAccount} from '../components';
import constants from '../constants';
import contents from '../contents';
import encodings from '../encodings';
import inputFiles from '../inputFiles';
import outputFiles from '../outputFiles';
import products from '../products';
import realms from '../realms';
import servers from '../servers';
import {AppState} from '../types';
import users from '../users';
import virtualEvents from '../virtualEvents';
import SubMenu from './SubMenu';

type MenuName = 'menuAdmin';

const Menu: FC<MenuProps> = ({onMenuClick, logout, dense = false}) => {
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  const translate = useTranslate();
  const {data: productAccesses} = useQuery(
    {
      type: 'getList',
      resource: ENTITY_COLLECTIONS.productAccess,
      payload: {
        filter: {
          accountId: account?.id,
        },
      },
    },
    {enabled: !!account},
  );
  const hasContents =
    productAccesses &&
    productAccesses.filter(
      (p: ProductAccessWithRelations) => p?.product === 'provisioning',
    ).length > 0;
  const hasEvents =
    productAccesses &&
    productAccesses.filter(
      (p: ProductAccessWithRelations) => p.product === 'immersive_tour',
    ).length > 0;
  const hasHSS =
    productAccesses &&
    productAccesses.filter((p: ProductAccessWithRelations) =>
      (p.product ?? '').startsWith('hss'),
    ).length > 0;
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );
  const [state, setState] = useState({
    menuAdmin: false,
  });
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState(state => ({...state, [menu]: !state[menu]}));
  };
  notNil(outputFiles.icon);
  notNil(contents.icon);
  return (
    <Box mt={1}>
      {' '}
      <MenuItemLink
        to="/"
        sidebarIsOpen={open}
        primaryText={translate('pos.menu.dashboard')}
        leftIcon={<DashboardIcon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/${ENTITY_COLLECTIONS.productAccess}`}
        sidebarIsOpen={open}
        primaryText={translate('pos.menu.products')}
        leftIcon={<products.icon />}
        onClick={onMenuClick}
      />
      {hasContents && (
        <>
          <MenuItemLink
            to={`/${ENTITY_COLLECTIONS.content}`}
            sidebarIsOpen={open}
            primaryText={translate('pos.menu.contents')}
            leftIcon={<contents.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/${ENTITY_COLLECTIONS.inputFile}`}
            sidebarIsOpen={open}
            primaryText={translate('pos.menu.inputFiles')}
            leftIcon={<inputFiles.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/${ENTITY_COLLECTIONS.encoding}`}
            sidebarIsOpen={open}
            primaryText={translate('pos.menu.encodings')}
            leftIcon={<encodings.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/${ENTITY_COLLECTIONS.outputFile}`}
            sidebarIsOpen={open}
            primaryText={translate('pos.menu.outputFiles')}
            leftIcon={<outputFiles.icon />}
            onClick={onMenuClick}
          />
        </>
      )}
      {hasContents && (
        <MenuItemLink
          to={`/play`}
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.player')}
          leftIcon={<PlayerIcon />}
          onClick={onMenuClick}
        />
      )}
      {hasEvents && (
        <MenuItemLink
          to={`/${ENTITY_COLLECTIONS.virtualEvent}`}
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.virtualEvents')}
          leftIcon={<virtualEvents.icon />}
          onClick={onMenuClick}
        />
      )}
      <MenuItemLink
        to={`/${ENTITY_COLLECTIONS.constant}`}
        sidebarIsOpen={open}
        primaryText={translate('pos.menu.constants')}
        leftIcon={<constants.icon />}
        onClick={onMenuClick}
      />
      {hasHSS && (
        <MenuItemLink
          to={`/${ENTITY_COLLECTIONS.catalog}`}
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.catalogs')}
          leftIcon={<catalogs.icon />}
          onClick={onMenuClick}
        />
      )}
      {hasHSS && loaded && permissions[0].canAccessAccountStats(account) && (
        <MenuItemLink
          to="/stats"
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.stats')}
          leftIcon={<StatsIcon />}
          onClick={onMenuClick}
        />
      )}
      {loaded && permissions[0].canAccessAccountUsers(account) && (
        <MenuItemLink
          to={`/${ENTITY_COLLECTIONS.teamMember}`}
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.users')}
          leftIcon={<users.icon />}
          onClick={onMenuClick}
        />
      )}
      {hasHSS && loaded && permissions[0].canAccessKeyDump() && (
        <MenuItemLink
          to="/keyDump"
          sidebarIsOpen={open}
          primaryText={translate('pos.menu.key_analysis')}
          leftIcon={<applicationKeys.icon />}
          onClick={onMenuClick}
        />
      )}
      {loaded && permissions[0].isAdmin() && (
        <>
          <SubMenu
            handleToggle={() => handleToggle('menuAdmin')}
            isOpen={state.menuAdmin}
            sidebarIsOpen={open}
            name="pos.menu.admin"
            icon={<Security />}
            dense={dense}
          >
            {loaded && permissions[0].isSuperAdmin() && (
              <>
                <MenuItemLink
                  to={`/${ENTITY_COLLECTIONS.product}`}
                  sidebarIsOpen={open}
                  primaryText={translate('pos.menu.products')}
                  leftIcon={<products.icon />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/${ENTITY_COLLECTIONS.server}`}
                  sidebarIsOpen={open}
                  primaryText={translate('pos.menu.servers')}
                  leftIcon={<servers.icon />}
                  onClick={onMenuClick}
                />

                <MenuItemLink
                  to={`/${ENTITY_COLLECTIONS.certificate}`}
                  sidebarIsOpen={open}
                  primaryText={translate('pos.menu.certificates')}
                  leftIcon={<certificates.icon />}
                  onClick={onMenuClick}
                />
              </>
            )}

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.aid}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.aids')}
              leftIcon={<aids.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.company}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.companies')}
              leftIcon={<companies.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.account}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.accounts')}
              leftIcon={<accounts.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.realm}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.realms')}
              leftIcon={<realms.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.user}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.users')}
              leftIcon={<users.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.applicationKey}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.applicationKeys')}
              leftIcon={<applicationKeys.icon />}
              onClick={onMenuClick}
            />

            <MenuItemLink
              to={`/${ENTITY_COLLECTIONS.certAccess}`}
              sidebarIsOpen={open}
              primaryText={translate('pos.menu.certAccesses')}
              leftIcon={<certAccesses.icon />}
              onClick={onMenuClick}
            />
          </SubMenu>
        </>
      )}
      {
        <MenuItemLink
          to="/configuration"
          sidebarIsOpen={open}
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          dense={dense}
        />
      }
      {isXSmall && logout}
    </Box>
  );
};

export default Menu;
