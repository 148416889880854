import {CalendarToday, Email, Security, Settings} from '@material-ui/icons';
import {FC} from 'react';
import {
  Create,
  FormTab,
  NullableBooleanInput,
  Record as RaRecord,
  ResourceComponentPropsWithId,
  TabbedForm,
  TextInput,
} from 'react-admin';
import {BooleanInput, NumberInput} from '../components';
import {EmailConfigInput} from './EmailConfigInput';
import {PasswordHashInput} from './PasswordHashInput';
import {PrincipalIdInput} from './PrincipalIdInput';
import {PrincipalTypeInput} from './PrincipalTypeInput';
import {WsmpConfigInput} from './WsmpConfigInput';

function onTransform({useCustomEmail, email, ...values}: RaRecord): RaRecord {
  return useCustomEmail ? {...values, email} : values;
}

export const RealmCreate: FC<ResourceComponentPropsWithId> = props => (
  <Create {...props} transform={onTransform}>
    <TabbedForm>
      <FormTab label="resources.realms.tabs.general" icon={<Settings />}>
        <TextInput source="name" required fullWidth />
        <PrincipalTypeInput />
        <PrincipalIdInput />
      </FormTab>
      <FormTab label="resources.realms.tabs.security" icon={<Security />}>
        <PasswordHashInput fullWidth />
        <NumberInput source="security.passwordLength" defaultValue={12} />
        <BooleanInput
          source="security.passwordWithNumbers"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithSymbols"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithUppercase"
          defaultValue={true}
        />
        <BooleanInput
          source="security.passwordWithLowercase"
          defaultValue={true}
        />
        <TextInput source="security.validateEmailUrl" fullWidth />
        <TextInput source="security.resetPasswordUrl" fullWidth />
        <NullableBooleanInput source="security.singleSession" fullWidth />
        <NullableBooleanInput source="security.useMfa" fullWidth />
      </FormTab>
      <FormTab label="resources.realms.tabs.email" icon={<Email />}>
        <EmailConfigInput />
      </FormTab>
      <FormTab label="resources.realms.tabs.wsmp" icon={<CalendarToday />}>
        <WsmpConfigInput />
      </FormTab>
    </TabbedForm>
  </Create>
);
