import {push} from 'connected-react-router';
import React, {Component} from 'react';
import {SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';
import {connect} from 'react-redux';
import {Edit} from '../components';

const CustomTitle = ({record}) => {
  return <span>Product {record ? `"${record.name}"` : ''}</span>;
};

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

class TheEdit extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <Edit title={<CustomTitle />} {...props}>
          <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source="name" disabled />
            <TextInput source="id" disabled />
            <TextInput source="displayName" />
          </SimpleForm>
        </Edit>
      </>
    );
  }

  handleClose = () => {
    this.props.push('/reviews');
  };
}

export default connect(undefined, {push})(TheEdit);
