import {FormControlLabel, FormGroup, Switch} from '@material-ui/core';
import {FieldTitle, InputProps, useListContext} from 'ra-core';
import {useCallback, useEffect, useState} from 'react';
import {sanitizeInputRestProps} from 'react-admin';

export const DefinedInput = (props: InputProps) => {
  const {
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {filterValues, setFilters} = useListContext();
  const [on, setOn] = useState(false);
  console.log('filterValues:', filterValues);
  const handleChange = useCallback(
    (_, value) => {
      setOn(value);
    },
    [setOn],
  );
  useEffect(() => {
    if (on && !filterValues[source]) {
      filterValues[source] = {neq: null};
      setFilters(filterValues, filterValues);
    } else if (!on && filterValues[source]) {
      delete filterValues[source];
      setFilters(filterValues, filterValues);
    }
  }, [filterValues, on]);

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            onChange={handleChange}
            value={on}
            {...options}
            disabled={disabled}
          />
        }
        label={<FieldTitle label={label} source={source} resource={resource} />}
      />
    </FormGroup>
  );
};
