import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import {useCallback, useEffect, useState} from 'react';
import {useGetList, useTranslate} from 'react-admin';
import {useDispatch} from 'react-redux';
import {changeAccount} from '../actions/actions';
import {Account} from '../types';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root: {
    height: 30,
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    alignItems: 'center',
  },
  labelRoot: {},
  labelFocused: {
    display: 'block',
  },
}));

const ProjectPicker = () => {
  const [accountId, setAccountId] = useState(() =>
    localStorage.getItem('project'),
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const {data, ids, loaded} = useGetList<Account>(
    ENTITY_COLLECTIONS.account,
    {page: 1, perPage: 200},
    {field: 'name', order: 'ASC'},
    {},
  );

  const handleChange = useCallback(e => {
    localStorage.setItem('project', e.target.value);
    setAccountId(e.target.value);
  }, []);

  useEffect(() => {
    //we should load a list of accounts
    let account: Account | null = null;
    if (data && accountId) {
      account = data[accountId] || null;
    }
    if (!account && data && ids && ids.length > 0) {
      const id = ids[0] as string;
      setAccountId(id);
      account = data[id];
    }
    if (loaded) {
      dispatch(changeAccount(account));
    }
  }, [loaded, data, ids, setAccountId, dispatch, accountId]);

  const hasValue = !!(accountId && ids?.includes(accountId ?? '') && data);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        id="project-picker-label"
        classes={{root: classes.labelRoot, focused: classes.labelFocused}}
      >
        {translate('resources.accounts.project', {smart_count: 1})}
      </InputLabel>
      <Select
        labelId="project-picker-label"
        id="select-project"
        value={hasValue ? accountId : ''}
        classes={{root: classes.root}}
        onChange={handleChange}
        label={translate('resources.accounts.project', {smart_count: 1})}
      >
        {!hasValue && <MenuItem id="none" value="" />}
        {hasValue &&
          ids.map(id => (
            <MenuItem key={id} value={id}>
              {data[id].name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProjectPicker;
