import {Button} from '@material-ui/core';
import React from 'react';
import {
  ChipField,
  ReferenceArrayField,
  RichTextField,
  SelectField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import {useHistory} from 'react-router-dom';

const ShowActions = props => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        {translate('pos.button.back')}
      </Button>
    </TopToolbar>
  );
};

const ConstantShow = props => {
  return (
    <>
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <SelectField
            source="type"
            choices={[
              {id: 'hss_setting', name: 'HSS Setting'},
              {id: 'player_setting', name: 'Player setting'},
              {id: 'checkin_setting', name: 'Checkin setting'},
              {id: 'idviu_event', name: 'IDVIU Event'},
              {id: 'legacy_player_error', name: 'Player error'},
              {id: 'legacy_player_info', name: 'Player info'},
              {id: 'legacy_download_error', name: 'Download error'},
            ]}
          />
          <TextField source="name" />
          <TextField source="value" />
          <SelectField
            source="valueType"
            choices={[
              {id: null, name: 'Not applicable'},
              {id: 'string', name: 'String'},
              {id: 'number', name: 'Number'},
              {id: 'boolean', name: 'Boolean'},
            ]}
            defaultValue=""
          />
          <TextField source="extra" />
          <TextField source="description" />
          <ReferenceArrayField source="productIds" reference="products">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <RichTextField source="notes" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

export default ConstantShow;
