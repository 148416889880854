import {FC} from 'react';
import {SelectField} from 'react-admin';
import {PASSWORD_HASH_ALGORITHM_CHOICES} from './common';

export const SecurityPasswordHashField: FC = props => (
  <SelectField {...props} choices={PASSWORD_HASH_ALGORITHM_CHOICES} />
);

SecurityPasswordHashField.defaultProps = {
  source: 'security.passwordHash',
  addLabel: true,
};
