import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {
  CreateContextProvider,
  InputProps,
  useCreateController,
  useDataProvider,
  useTranslate,
} from 'ra-core';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  fetchEnd,
  fetchStart,
  FieldProps,
  ReferenceInput,
  refreshView,
  SaveButton,
  SelectInput,
  showNotification,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {TeamMember} from '../backbone-records';
import {SimpleForm} from '../components';
import {useHasRealm} from '../resource-hooks';

const ALLOWED_ROLES = ['admin', 'operator', 'developer', 'viewer'];

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    float: 'right',
    marginBottom: '2em',
  },
});

const MySaveButton = ({
  close,
  teamMember,
}: {
  close: () => void;
  teamMember: TeamMember | undefined;
}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const values = formState.values;

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    values.principalType = EntityType.teamMember;
    values.principalId = teamMember && teamMember.id;
    dataProvider
      .create(ENTITY_COLLECTIONS.roleMapping, {data: values})
      .then(() => {
        // Update the main react-admin form (in this case, the comments creation form)
        close();
        dispatch(refreshView());
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [
    close,
    dataProvider,
    teamMember,
    dispatch,
    formState.valid,
    formState.values,
  ]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="pos.button.add"
    />
  );
};

const FormActions: FC<AddRoleFieldProps> = ({
  teamMember,
  close,
  basePath,
  ...props
}) => (
  <DialogActions {...props}>
    <MySaveButton teamMember={teamMember} close={close} />
    <Button label="ra.action.cancel" onClick={() => close()}>
      <IconCancel />
    </Button>
  </DialogActions>
);

export interface AddRoleFieldProps extends FieldProps {
  teamMember: TeamMember | undefined;
  close: () => void;
}

export const RoleSelectInput: FC<InputProps> = ({
  translateChoices,
  choices,
  ...props
}) => {
  const translate = useTranslate();
  const roles = choices
    ? choices.map((r: any) => {
        const v = Object.assign({}, r);
        v.name = translate(v.name);
        return v;
      })
    : [];
  return <SelectInput translateChoice={false} choices={roles} {...props} />;
};

const AddRoleButton: FC<FieldProps<TeamMember>> = ({
  record: teamMember,
  ...props
}) => {
  const classes = useStyle();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const createControllerProps = useCreateController(props);
  createControllerProps.basePath = `/${ENTITY_COLLECTIONS.roleMapping}`;
  createControllerProps.resource = ENTITY_COLLECTIONS.roleMapping;
  const close = () => setShowDialog(false);
  const hasRealm = useHasRealm(teamMember?.userId);
  return hasRealm ? (
    <span />
  ) : (
    <>
      <Button
        className={classes.button}
        onClick={() => setShowDialog(true)}
        label="pos.button.add_role"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => close()}
        aria-label="Add role"
      >
        <DialogTitle>{translate('pos.title.add_role')}</DialogTitle>
        <DialogContent>
          <CreateContextProvider value={createControllerProps}>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              resource={ENTITY_COLLECTIONS.roleMapping}
              // We want no toolbar at all as we have our modal actions
              noToolbar={true}
            >
              <ReferenceInput
                label={translate('resources.roles.name', {smart_count: 1})}
                source="roleId"
                reference={ENTITY_COLLECTIONS.role}
                filter={{name: {inq: ALLOWED_ROLES}}}
              >
                <RoleSelectInput source="roleId" optionText="name" />
              </ReferenceInput>
              <FormActions teamMember={teamMember} close={close} />
            </SimpleForm>
          </CreateContextProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddRoleButton;
