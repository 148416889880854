import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {usePermissions, useRedirect} from 'ra-core';
import {FC, useCallback, useEffect, useState} from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  ReferenceField,
  Show,
  ShowButton,
  ShowProps,
  SingleFieldList,
  TextField,
  useTranslate,
} from 'react-admin';
import {useSelector} from 'react-redux';
import {
  BooleanField,
  EmptyReferenceManyField,
  RuleField,
  ShowActions,
  Tab,
  TabbedShowLayout,
} from '../components';
import DeleteWithCommentButton from '../components/DeleteWithCommentButton';
import {AppState} from '../types';
import AddNewRuleButton from './AddNewRuleButton';

const useStyle = makeStyles({
  input: {
    marginTop: '0.2em',
    minWidth: '540px',
  },

  title: {
    fontSize: 14,
    width: 'auto',
    marginTop: '2em',
    marginBottom: '0.1em',
  },

  deleteButton: {
    float: 'right',
  },

  ruleButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  addButton: {
    display: 'block',
    float: 'none',
    textAlign: 'left',
  },
  formControl: {
    width: '100%',
  },
});

const MyEditButton = (props: any) => {
  return <EditButton label="pos.button.edit" {...props} />;
};

const MyShowButton = (props: any) => {
  return <ShowButton label="pos.button.details" {...props} />;
};

const emptyRulesStyle = makeStyles(() => ({
  root: {
    textAlign: 'left',
  },
}));

const EmptyRules = () => {
  const translate = useTranslate();
  const classes = emptyRulesStyle();
  return (
    <Typography className={classes.root}>
      {translate('resources.productAccesses.desc.no_rules')}
    </Typography>
  );
};

const ProductAccessShow: FC<ShowProps> = props => {
  const classes = useStyle();
  const translate = useTranslate();
  const account = useSelector((appState: AppState) => appState.account);
  const redirect = useRedirect();
  const [currentAccount, setCurrentAccount] = useState(account);
  const {loaded, permissions} = usePermissions();
  const canEditOrDelete =
    loaded && permissions[0].canModifyAccountSettings(account);
  useEffect(() => {
    //go back to product access list if we change the current account
    if (!currentAccount) {
      setCurrentAccount(account);
    } else if (currentAccount !== account) {
      redirect(`/${ENTITY_COLLECTIONS.productAccess}`);
    }
  }, [currentAccount, setCurrentAccount, account, redirect]);
  const canShow = useCallback((record: any | undefined, match: string) => {
    if (!record) return false;
    const product = record.product;
    if (product && product.includes(match)) return true;
    return false;
  }, []);

  return (
    <Show
      {...props}
      title={translate('resources.productAccesses.title.show')}
      actions={<ShowActions canEdit={canEditOrDelete} />}
    >
      <TabbedShowLayout>
        <Tab label="resources.productAccesses.tabs.summary">
          <ReferenceField
            label={translate('resources.productAccesses.fields.account')}
            link={false}
            source="accountId"
            reference={ENTITY_COLLECTIONS.account}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="product" />
          <BooleanField source="blocked" />
          <BooleanField source="gdpr" />
          <TextField source="apiKey" />
        </Tab>
        <Tab
          label="resources.productAccesses.tabs.blocked"
          path="blocked"
          only={r => canShow(r, 'hss')}
        >
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.blocked"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            filter={{
              principalType: EntityType.productAccess,
              type: 'blocked',
              deleted: {neq: true},
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <MyShowButton />
              {canEditOrDelete && <MyEditButton />}
              {canEditOrDelete && <DeleteWithCommentButton goBack={false} />}
            </Datagrid>
          </EmptyReferenceManyField>
          {canEditOrDelete && (
            <AddNewRuleButton type="blocked" className={classes.ruleButton} />
          )}
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.blocked_deleted"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            sortBy="deletedOn"
            sortByOrder="DESC"
            filter={{
              principalType: EntityType.productAccess,
              type: 'blocked',
              deleted: true,
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <DateField source="deletedOn" showTime={true} />
              <MyShowButton />
            </Datagrid>
          </EmptyReferenceManyField>
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.blocked_fingerprint"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            filter={{
              principalType: EntityType.productAccess,
              type: 'blocked_fingerprint',
              deleted: {neq: true},
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <MyShowButton />
              {canEditOrDelete && <MyEditButton />}
              {canEditOrDelete && <DeleteWithCommentButton goBack={false} />}
            </Datagrid>
          </EmptyReferenceManyField>
          {canEditOrDelete && (
            <AddNewRuleButton
              type="blocked_fingerprint"
              className={classes.ruleButton}
            />
          )}
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.blocked_fingerprint_deleted"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            sortBy="deletedOn"
            sortByOrder="DESC"
            filter={{
              principalType: EntityType.productAccess,
              type: 'blocked_fingerprint',
              deleted: true,
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <DateField source="deletedOn" showTime={true} />
              <MyShowButton />
            </Datagrid>
          </EmptyReferenceManyField>
        </Tab>
        <Tab
          label="resources.productAccesses.tabs.checkin_settings"
          path="checkin_settings"
          only={r => canShow(r, 'hss')}
        >
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.checkin"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            filter={{
              principalType: EntityType.productAccess,
              type: 'checkin_setting',
              deleted: {neq: true},
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <MyShowButton />
              {canEditOrDelete && <MyEditButton />}
              {canEditOrDelete && <DeleteWithCommentButton goBack={false} />}
            </Datagrid>
          </EmptyReferenceManyField>
          {canEditOrDelete && (
            <AddNewRuleButton
              type="checkin_setting"
              className={classes.ruleButton}
            />
          )}
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.checkin_deleted"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            sortBy="deletedOn"
            sortByOrder="DESC"
            filter={{
              principalType: EntityType.productAccess,
              type: 'checkin_setting',
              deleted: true,
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <DateField source="deletedOn" showTime={true} />
              <MyShowButton />
            </Datagrid>
          </EmptyReferenceManyField>
        </Tab>
        <Tab
          label="resources.productAccesses.tabs.hss_settings"
          path="hss_settings"
          only={r => canShow(r, 'hss')}
        >
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.sdk"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            filter={{
              principalType: EntityType.productAccess,
              type: 'hss_setting',
              deleted: {neq: true},
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <MyShowButton />
              {canEditOrDelete && <MyEditButton />}
              {canEditOrDelete && <DeleteWithCommentButton goBack={false} />}
            </Datagrid>
          </EmptyReferenceManyField>
          {canEditOrDelete && (
            <AddNewRuleButton
              type="hss_setting"
              className={classes.ruleButton}
            />
          )}
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.sdk_deleted"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            sortBy="deletedOn"
            sortByOrder="DESC"
            filter={{
              principalType: EntityType.productAccess,
              type: 'hss_setting',
              deleted: true,
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <DateField source="deletedOn" showTime={true} />
              <MyShowButton />
            </Datagrid>
          </EmptyReferenceManyField>
        </Tab>
        <Tab
          label="resources.productAccesses.tabs.player_settings"
          path="player_settings"
          only={r => canShow(r, 'hss')}
        >
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.player"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            filter={{
              principalType: EntityType.productAccess,
              type: 'player_setting',
              deleted: {neq: true},
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <MyShowButton />
              {canEditOrDelete && <MyEditButton />}
              {canEditOrDelete && <DeleteWithCommentButton goBack={false} />}
            </Datagrid>
          </EmptyReferenceManyField>
          {canEditOrDelete && (
            <AddNewRuleButton
              type="player_setting"
              className={classes.ruleButton}
            />
          )}
          <EmptyReferenceManyField
            empty={<EmptyRules />}
            label="resources.productAccesses.desc.player_deleted"
            reference={ENTITY_COLLECTIONS.dynamicSetting}
            sortBy="deletedOn"
            sortByOrder="DESC"
            filter={{
              principalType: EntityType.productAccess,
              type: 'player_setting',
              deleted: true,
            }}
            target="principalId"
          >
            <Datagrid>
              <TextField source="name" />
              <ArrayField source="rules">
                <SingleFieldList linkType={false}>
                  <RuleField />
                </SingleFieldList>
              </ArrayField>
              <TextField source="key" />
              <TextField source="value" />
              <DateField source="createdOn" showTime={true} />
              <DateField source="updatedOn" showTime={true} />
              <DateField source="deletedOn" showTime={true} />
              <MyShowButton />
            </Datagrid>
          </EmptyReferenceManyField>
        </Tab>
        <Tab
          label="resources.productAccesses.tabs.cdm_settings"
          path="cdm_settings"
          only={r => canShow(r, 'cdm')}
        >
          <ArrayField source="cdmSettings.servers">
            <Datagrid>
              <TextField source="drm" />
              <TextField source="type" />
              <TextField source="url" />
              <NumberField source="priority" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab
          label="Immersive Settings"
          path="immersive_settings"
          only={r => canShow(r, 'immersive')}
        >
          <TextField
            label="JWT token app ID"
            source="immersiveTourSettings.jwtAppId"
          />
          <TextField
            label="JWT token secret"
            source="immersiveTourSettings.jwtSecret"
          />
          <TextField
            label="Jitsi Server"
            source="immersiveTourSettings.jitsiServer"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ProductAccessShow;
