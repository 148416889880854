import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Chip from '@material-ui/core/Chip';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/FileCopy';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import {usePermissions} from 'ra-core';
import React, {Component} from 'react';
import {
  ArrayField,
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  ShowButton,
  ShowController,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import {Link} from 'react-router-dom';
import {BooleanField, ShowView} from '../components';
import AddNewAIDButton from './AddNewAIDButton';
import DeleteUserButton from './DeleteUserButton';

const AccountTitle = ({record}) => {
  return <span>{record ? `"${record.name}"` : ''} details</span>;
};

const UserField = ({record}) => {
  return (
    <>
      <TextField record={record} source="firstName" />
      <TextField record={record} source="lastName" />
    </>
  );
};

const ServerField = ({record}) => {
  return <span>{record.name + ' - ' + record.type}</span>;
};

// const AltNameField = ({record}) => {
//   return <span>{record}</span>;
// };

const useStyles = makeStyles({
  chip: {margin: 4},
});

const StringChipField = ({record = '', ...rest}) => {
  const classes = useStyles();

  return <Chip className={classes.chip} label={record} />;
};

const ExpirationField = ({record = {}}) => {
  var expiration = record.keys && record.keys['LGY_HSS_EXPIRATION'];
  if (expiration !== '0')
    expiration = moment(expiration * 1000).format('DD-MM-YYYY HH:mm:ss');
  else expiration = 'unlimited';
  return <span>{expiration}</span>;
};
ExpirationField.defaultProps = {label: 'Expiration'};

const styles = {
  raisedButton: {
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '1em',
    display: 'inline-flex',
    color: 'white',
  },
  button: {
    marginTop: '1em',
    marginBottom: '1em',
    marginLeft: '1em',
    display: 'inline-flex',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const AddNewUserButton = withStyles(styles)(({classes, record}) => (
  <Button
    className={classes.raisedButton}
    component={Link}
    variant="contained"
    to={{
      pathname: '/teamMembers/create',
      search: `?source=${JSON.stringify({
        principalId: record.id,
        principalType: 'account',
      })}`,
      state: {record: {principalId: record.id, principalType: 'account'}},
    }}
    label="Add users"
  >
    <AddIcon />
  </Button>
));

const EditRolesButton = ({record}) => (
  <Button
    component={Link}
    to={{
      pathname: '/teamMembers/' + record.id + '/show',
      search: `?source=${JSON.stringify({
        principalId: record.id,
        principalType: 'account',
      })}`,
      state: {record: {principalId: record.id, principalType: 'account'}},
    }}
    label="pos.button.edit_roles"
  >
    <EditIcon />
  </Button>
);

const downloadData = (data, filename) => {
  const fakeLink = document.createElement('a');
  fakeLink.style.display = 'none';
  document.body.appendChild(fakeLink);
  const blob = new Blob([new Uint8Array(data)], {
    type: 'application/octet-stream',
  });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, `${filename}`);
  } else {
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', `${filename}`);
    fakeLink.click();
  }
};

class DownloadKeyButton extends Component {
  handleClick = () => {
    const {record} = this.props;
    if (!record.data || !record.data.data) return;
    downloadData(record.data.data, 'application.key');
  };

  render() {
    const {classes} = this.props;

    return (
      <Button
        size="small"
        color="primary"
        label="Download"
        onClick={this.handleClick}
        className={classes.link}
      >
        <DownloadIcon />
      </Button>
    );
  }
}

const DownloadKey = withStyles(styles)(DownloadKeyButton);

const AddNewProductAccessButton = withStyles(styles)(({classes, record}) => (
  <Button
    className={classes.raisedButton}
    variant="contained"
    component={Link}
    to={{
      pathname: '/productAccesses/create',
      state: {record: {accountId: record.id, companyId: record.companyId}},
      search: `?source=${JSON.stringify({
        accountId: record.id,
        companyId: record.companyId,
      })}`,
    }}
    label="pos.button.add_product_access"
  >
    <AddIcon />
  </Button>
));

const GenerateLegacyApplicationKeyButton = withStyles(styles)(
  ({classes, record}) => (
    <Button
      className={classes.button}
      variant="contained"
      component={Link}
      to={{
        pathname: '/applicationKeys/create',
        state: {
          record: {accountId: record.id, type: 'legacy'},
          account: record,
        },
        search: `?source=${JSON.stringify({
          accountId: record.id,
          type: 'legacy',
        })}`,
      }}
      label="pos.button.generate_legacy_key"
    >
      <BuildIcon />
    </Button>
  ),
);

// const CustomEditButton = ({basePath, ...props}) => {
//   return <EditButton {...props} />;
// };

const CustomTypography = ({basePath, ...props}) => {
  return <Typography {...props} />;
};

const useAcccountStyle = makeStyles({
  input: {
    marginTop: '0.2em',
    minWidth: '540px',
  },

  title: {
    fontSize: 14,
    width: 'auto',
    marginTop: '2em',
    marginBottom: '0.1em',
  },
});

const AccountShowAdmin = props => {
  const classes = useAcccountStyle();
  const {loaded, permissions} = usePermissions();
  return (
    <ShowController {...props}>
      {({translate, record, ...controllerProps}) => (
        <ShowView
          {...props}
          record={record}
          {...controllerProps}
          title={<AccountTitle />}
          canEdit={
            loaded && record && permissions[0].canModifyAccountSettings(record)
          }
        >
          <TabbedShowLayout>
            <Tab label="summary">
              <TextField source="name" label="Name" />
              {record && record.altNames && record.altNames.length && (
                <ArrayField source="altNames" linkType={false}>
                  <SingleFieldList>
                    <StringChipField />
                  </SingleFieldList>
                </ArrayField>
              )}
              <ReferenceField
                link="show"
                label="Company"
                source="companyId"
                reference="companies"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                link="show"
                label="resources.accounts.fields.realm"
                source="realmId"
                reference={ENTITY_COLLECTIONS.realm}
              >
                <TextField source="name" />
              </ReferenceField>
              <BooleanField source="blocked" />
              <BooleanField source="gdpr" label="enforce GDPR" />
              <RichTextField source="description" />
            </Tab>
            <Tab label="stats">
              <BooleanField
                source="statsEnabled"
                label="Enabled"
                addLabel={true}
              />
              {record && record.statsServer && (
                <ReferenceField
                  label="stats Server"
                  source="statsServer"
                  reference="servers"
                  link={false}
                >
                  <ServerField />
                </ReferenceField>
              )}
              <BooleanField
                source="distinctCheckin"
                label="Distinct checkin index"
                addLabel={true}
              />
            </Tab>
            <Tab label="Product accesses" path="productAccess">
              <ReferenceManyField
                pagination={<Pagination />}
                reference="productAccesses"
                target="accountId"
                addLabel={false}
              >
                <Datagrid>
                  <TextField source="apiKey" />
                  <TextField source="product" />
                  <ShowButton resource="productAccesses" />
                  {loaded && permissions[0].isSuperAdmin() && (
                    <DeleteButton undoable={false} redirect={false} />
                  )}
                </Datagrid>
              </ReferenceManyField>
              {loaded && permissions[0].isAdmin() && (
                <div style={{float: 'right'}}>
                  <AddNewProductAccessButton
                    record={record}
                    {...props}
                    {...controllerProps}
                  />{' '}
                </div>
              )}
            </Tab>
            <Tab label="Users" path="users">
              <ReferenceManyField
                pagination={<Pagination />}
                addLabel={false}
                reference="teamMembers"
                filter={{principalType: 'account'}}
                target="principalId"
              >
                <Datagrid>
                  <ReferenceField
                    label="Name"
                    source="userId"
                    reference="users"
                  >
                    <UserField />
                  </ReferenceField>
                  <ReferenceField
                    label="Email"
                    source="userId"
                    reference="users"
                  >
                    <TextField source="email" />
                  </ReferenceField>
                  {loaded &&
                    record &&
                    typeof record.id !== 'undefined' &&
                    permissions[0].canModifyAccountSettings(record) && (
                      <EditRolesButton
                        label="pos.button.edit_roles"
                        record={record}
                      />
                    )}
                  {loaded &&
                    record &&
                    typeof record.id !== 'undefined' &&
                    permissions[0].canModifyAccountSettings(record) && (
                      <DeleteUserButton record={record} />
                    )}
                </Datagrid>
              </ReferenceManyField>
              {loaded &&
                record &&
                permissions[0].canModifyAccountSettings(record) && (
                  <div style={{float: 'right'}}>
                    <AddNewUserButton record={record} />
                  </div>
                )}
            </Tab>
            <Tab label="AIDs" path="aids">
              <ReferenceManyField
                pagination={<Pagination />}
                addLabel={false}
                reference="aids"
                target="accountId"
              >
                <Datagrid>
                  <TextField label="AID" source="AID" />
                  <TextField label="serviceId" source="serviceId" />
                  <TextField label="comment" source="comment" />
                  {loaded &&
                    record &&
                    typeof record.id !== 'undefined' &&
                    permissions[0].canModifyAccountSettings(record) && (
                      <EditButton />
                    )}
                </Datagrid>
              </ReferenceManyField>
              {loaded &&
                record &&
                permissions[0].canModifyAccountSettings(record) && (
                  <div style={{float: 'right', marginBottom: '1em'}}>
                    <AddNewAIDButton record={record} />
                  </div>
                )}
            </Tab>
            <Tab label="Key Settings">
              <CustomTypography className={classes.title} color="textPrimary">
                Optional custom server url:
              </CustomTypography>
              <TextField
                className={classes.input}
                label="HSS_URL"
                source="legacy_hss_url"
              />
              <TextField
                className={classes.input}
                label="CHECKIN_URL"
                source="legacy_checkin_url"
              />
              <TextField
                className={classes.input}
                label="TIME_SERVER_URL"
                source="legacy_time_server_url"
              />

              <CustomTypography className={classes.title} color="textPrimary">
                {'No longer used with SDK > 4.9.20:'}
              </CustomTypography>
              <TextField
                className={classes.input}
                label="DATA_SIGNATURE"
                source="legacy_data_signature"
              />

              <CustomTypography className={classes.title} color="textPrimary">
                Only used is using Playready 1.2:
              </CustomTypography>
              <TextField
                className={classes.input}
                label="DRM_RANDOM1"
                source="legacy_drm_random1"
              />
              <TextField
                className={classes.input}
                label="DRM_RANDOM2"
                source="legacy_drm_random2"
              />
              <TextField
                className={classes.input}
                label="DRM_RANDOM3"
                source="legacy_drm_random3"
              />

              <CustomTypography className={classes.title} color="textPrimary">
                Only used for Android (Make sure they match for existing clients
                or they will loose data):
              </CustomTypography>
              <TextField
                className={classes.input}
                label="DATA_RANDOM1"
                source="legacy_data_random1"
              />
              <TextField
                className={classes.input}
                label="DATA_RANDOM2"
                source="legacy_data_random2"
              />
              <TextField
                className={classes.input}
                label="DATA_RANDOM3"
                source="legacy_data_random3"
              />
            </Tab>
            <Tab label="Keys" path="keys">
              <ReferenceManyField
                pagination={<Pagination />}
                addLabel={false}
                reference="applicationKeys"
                target="accountId"
              >
                <Datagrid>
                  <TextField label="Type" source="type" />
                  <DateField label="Created on" source="createdOn" showTime />
                  <ReferenceField
                    label="By"
                    source="userId"
                    reference="users"
                    link="show"
                  >
                    <TextField source="email" />
                  </ReferenceField>
                  <TextField
                    label="Bundle ID"
                    source="keys.APPLICATION_PACKAGE"
                  />
                  <ExpirationField
                    label="Expiration"
                    source="LGY_HSS_EXPIRATION"
                  />
                  <ShowButton />
                  <DownloadKey />
                </Datagrid>
              </ReferenceManyField>
              {loaded &&
                record &&
                record.id &&
                permissions[0].canModifyAccountSettings(record) && (
                  <div style={{float: 'right'}}>
                    <GenerateLegacyApplicationKeyButton
                      record={record}
                      {...props}
                      {...controllerProps}
                    />
                  </div>
                )}
            </Tab>
          </TabbedShowLayout>
        </ShowView>
      )}
    </ShowController>
  );
};

export default AccountShowAdmin;
