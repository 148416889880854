import {FC} from 'react';
import {ReferenceInput, required, SimpleForm} from 'react-admin';
import {
  DateTimeInput,
  Edit,
  SelectInput,
  TextInput,
  useCurrentAccount,
} from '../components';
import {ImageUploadInput} from '../components/ImageUploadInput';

const VirtualEventEdit: FC = props => {
  const account = useCurrentAccount();
  return (
    <Edit {...props} title="Edit virtual event">
      <SimpleForm initialValues={{accountId: account?.id}}>
        <ReferenceInput
          validate={required()}
          source="accountId"
          reference="accounts"
        >
          <SelectInput optionText="name" disabled />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline fullWidth />
        <DateTimeInput source="start" />
        <DateTimeInput source="end" />
        <ImageUploadInput source="image" fullWidth />
        <ImageUploadInput source="logo" fullWidth />
        <TextInput source="website" />
        <TextInput source="organizer" />
        <TextInput source="locationName" />
        <TextInput source="locationCoord" />
      </SimpleForm>
    </Edit>
  );
};

export default VirtualEventEdit;
