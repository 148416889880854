import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Cancel as IconCancel, Share} from '@material-ui/icons';
import {Record} from 'ra-core/src/types';
import {BooleanInput, SaveButton} from 'ra-ui-materialui';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  fetchEnd,
  fetchStart,
  usePermissions,
  useTranslate,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {SimpleForm, TextInput} from '../components';

const useStyle = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    float: 'right',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export interface SaveButtonFieldProps {
  handleInviteLink: (link: string | undefined) => void;
  virtualEvent?: any;
}

const MySaveButton: FC<SaveButtonFieldProps> = ({
  handleInviteLink,
  virtualEvent,
}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const {loaded, permissions} = usePermissions();

  const handleSaveClick = useCallback(async () => {
    if (!formState.valid) {
      return;
    }

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    const {name, email, avatar, moderator} = formState.values;
    const data = {
      name,
      email,
      avatar,
      moderator,
    };
    await fetch(
      `${BACKBONE_REST_API_V1_URL}/virtualEvents/${virtualEvent?.id}/inviteLink`,
      {
        method: 'POST',
        headers: {
          authorization: (loaded && permissions[0].getToken()) || '',
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    )
      .then(res => res.json())
      .then(json => {
        handleInviteLink(json.url);
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  }, [
    formState.valid,
    formState.values,
    dispatch,
    handleInviteLink,
    virtualEvent?.id,
    loaded,
    permissions,
  ]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="ra.action.save"
    />
  );
};

const FormActions = ({
  virtualEvent,
  handleInviteLink,
  handleCloseClick,
}: {
  virtualEvent?: any;
  setShowDialog: any;
  handleInviteLink: (link: string | undefined) => void;
  handleCloseClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}) => (
  <DialogActions>
    <MySaveButton
      virtualEvent={virtualEvent}
      handleInviteLink={handleInviteLink}
    />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

export interface InviteLinkButtonProps {
  record: Record;
}

export const InviteLinkButton: FC<InviteLinkButtonProps> = props => {
  const classes = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const [showInviteLink, setShowInviteLink] = useState(false);
  const [inviteLink, setInviteLink] = useState<string | undefined>(undefined);

  const translate = useTranslate();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
    setShowInviteLink(false);
  };

  const handleInviteLink = (link: string | undefined) => {
    setShowDialog(false);
    setInviteLink(link);
    if (link) {
      setShowInviteLink(true);
    }
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label="pos.button.invite_link"
        variant="contained"
      >
        <Share />
      </Button>
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick}>
        <DialogContent>
          <SimpleForm
            resource="virtualEvents" //not important, we override the send event
            canDelete={false}
            // We want no toolbar at all as we have our modal actions
            noToolbar={true}
          >
            <Typography>
              {translate('resources.virtualEvents.desc.invite_form')}
            </Typography>
            <TextInput
              source="name"
              label="resources.virtualEvents.fields.userName"
              required
            />
            <TextInput source="email" />
            <TextInput source="avatar" />
            <BooleanInput source="moderator" />

            <FormActions
              virtualEvent={props.record}
              setShowDialog={setShowDialog}
              handleCloseClick={handleCloseClick}
              handleInviteLink={handleInviteLink}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
      <Dialog fullWidth open={showInviteLink} onClose={handleCloseClick}>
        <DialogContent>
          <div>{translate('resources.virtualEvents.desc.invite_link')}</div>
          <div>
            <a
              className={classes.link}
              href={inviteLink}
              target={'_blank'}
              rel="noreferrer"
            >
              {inviteLink}
            </a>
          </div>
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
