import {makeStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  FieldProps,
  useNotify,
  useRefresh,
  useTranslate,
  useUpdate,
} from 'react-admin';
import {useHistory} from 'react-router-dom';
import Confirm from './Confirm';

const useStyles = makeStyles(
  theme => ({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  }),
  {name: 'RaDeleteWithCommentButton'},
);

const DeleteWithCommentButton: FC<DeleteWithCommentButtonProps> = ({
  resource = '',
  record = {id: ''},
  content,
  icon = defaultIcon,
  className,
  label = 'pos.button.delete',
  goBack = true,
  ...props
}) => {
  const history = useHistory();
  const refresh = useRefresh();
  const notify = useNotify();
  // get values from the form
  const [open, setOpen] = useState(false);
  const [update, {loading}] = useUpdate(resource, record.id, {}, record);
  const classes = useStyles(props);
  const translate = useTranslate();
  const handleDialogClick = () => {
    setOpen(true);
  };

  const handleDialogClose = (e: any) => {
    setOpen(false);
    e.stopPropagation();
  };

  const handleSave = useCallback(
    async (_event, comment) => {
      // call dataProvider.create() manually
      // setOpen(true);
      await update(
        {
          payload: {data: {...record, _edit: comment, deleted: true}},
        },
        {
          onSuccess: () => {
            notify('ra.notification.deleted', 'info', {
              smart_count: 1,
            });
            if (goBack) {
              history.goBack();
            } else {
              refresh();
            }
          },
          onFailure: error => {
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning',
            );
            setOpen(false);
          },
        },
      );
    },
    [setOpen, goBack, history, notify, record, refresh, update],
  );

  return (
    <>
      <Button
        onClick={handleDialogClick}
        label={label}
        className={classnames(
          'ra-delete-button',
          classes.deleteButton,
          className,
        )}
        key="button"
        {...props}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={translate('pos.title.please_confirm')}
        content={content || translate('pos.desc.explain_action')}
        onConfirm={handleSave}
        onClose={handleDialogClose}
      />
    </>
  );
};

const defaultIcon = <ActionDelete />;

export interface DeleteWithCommentButtonProps extends FieldProps {
  goBack?: boolean;
  icon?: any | undefined;
  content?: any | undefined;
  label?: string | undefined;
}

export default DeleteWithCommentButton;
