import {FC} from 'react';
import {
  Edit,
  ResourceComponentPropsWithId,
  SimpleForm,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';

const CertificateEdit: FC<ResourceComponentPropsWithId> = props => (
  <>
    <Edit {...props} title="Modify Certificate">
      <SimpleForm>
        <TextInput source="name" label="Name" />
        <TextInput source="data64" label="Data64" />
        <TextInput source="md5" label="md5" />
        <TextInput source="comment" label="comment" />
      </SimpleForm>
    </Edit>
  </>
);

export default connect(undefined, {})(CertificateEdit);
