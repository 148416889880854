import React from 'react';
import {Datagrid, TextField, EditButton} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';

const listStyles = {
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

const ListMobile = ({classes, ...props}) => (
  <Datagrid classes={{headerRow: classes.headerRow}} {...props}>
    <TextField source="name" />
    <TextField source="id" />
    <EditButton />
  </Datagrid>
);

export default withStyles(listStyles)(ListMobile);
