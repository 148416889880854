import {EntityType, ENTITY_COLLECTIONS, User} from '@idviu/backbone-api-client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {useDataProvider} from 'ra-core';
import {FC, useCallback, useState} from 'react';
import {
  Button,
  fetchEnd,
  fetchStart,
  ReferenceInput,
  refreshView,
  SaveButton,
  SelectInput,
  showNotification,
  TextInput,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {SimpleForm} from '../components';

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    float: 'right',
    marginBottom: '2em',
  },
});

const MySaveButton: FC<{onComplete: (cond: boolean) => void}> = ({
  onComplete,
}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const values = formState.values;

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    // As we want to know when the new post has been created in order to close the modal, we use the
    // dataProvider directly
    values.principalType = EntityType.user;
    dataProvider
      .create(ENTITY_COLLECTIONS.roleMapping, {data: values})
      .then(() => {
        // Update the main react-admin form (in this case, the comments creation form)
        onComplete(false);
        dispatch(refreshView());
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [formState.valid, formState.values, dataProvider, dispatch, onComplete]);

  return <SaveButton handleSubmitWithRedirect={handleSaveClick} />;
};

const FormActions: FC<{
  basePath?: string;
  setShowDialog: (show: boolean) => void;
  handleCloseClick: () => void;
}> = ({basePath, setShowDialog, handleCloseClick, ...props}) => (
  <DialogActions {...props}>
    <MySaveButton onComplete={setShowDialog} />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

const AddUserRoleButton: FC<{record?: User}> = ({record}) => {
  const classes = useStyle();

  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label="pos.button.add_role"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add role"
      >
        <DialogTitle>Add role</DialogTitle>
        <DialogContent>
          <SimpleForm
            // We override the redux-form name to avoid collision with the react-admin main form
            resource={ENTITY_COLLECTIONS.roleMapping}
            // We want no toolbar at all as we have our modal actions
            toolbar={<span />}
          >
            {record && (
              <TextInput
                label="User Id"
                source="principalId"
                defaultValue={record.id}
                disabled
              />
            )}

            <ReferenceInput
              label="Role"
              source="roleId"
              reference={ENTITY_COLLECTIONS.role}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <FormActions
              setShowDialog={setShowDialog}
              handleCloseClick={handleCloseClick}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUserRoleButton;
