import {
  AudioCodecType,
  ContentState,
  DrmType,
  InputFileFormat,
  MediaTrackRole,
  MediaTrackType,
  OutputFileFormat,
  RuleConditionKey,
  RuleConditionOperator,
  SubtitleCodecType,
  VariantType,
  VideoCodecType,
} from '@idviu/backbone-core';
import {TranslationMessages} from 'ra-core';
import englishMessages from 'ra-language-english';
import {DEFAULT_MJML_EDITOR_LABELS} from '../components/MjmlEditor';

type Messages = TranslationMessages & {
  pos: {
    rules: {
      operators: Record<RuleConditionOperator, string>;
      keys: Record<RuleConditionKey, string>;
    };
    [name: string]:
      | string
      | Record<string, string>
      | Record<string, Record<string, string>>;
  };
};

export const OUTPUT_FILE_FORMAT: Record<OutputFileFormat, string> = {
  ismv: 'ISMV',
  m3u8: 'M3U8',
  mpd: 'MPEG-Dash',
  ismc: 'ISMC',
};

export const CONTENT_STATES: Record<ContentState, string> = {
  created: 'Created',
  encoding: 'Encoding',
  updating: 'Updating',
  ready: 'Ready',
  error: 'Error',
};

export const DRM: Record<DrmType, string> = {
  fairplay: 'FairPlay',
  marlin: 'Marlin',
  playready: 'PlayReady',
  widevine: 'Widevine',
};

export const AUDIO_CODECS: Record<AudioCodecType, string> = {
  aac: 'AAC',
  mp3: 'MP3',
  ac3: 'AC-3',
  eac3: 'EAC-3',
  wav: 'WAV',
  mp2: 'MPEG2-Audio',
};

export const VIDEO_CODECS: Record<VideoCodecType, string> = {
  av1: 'AV1',
  h264: 'H.264',
  hevc: 'HEVC',
};

export const SUBTITLE_CODECS: Record<SubtitleCodecType, string> = {
  ttml: 'TTML',
  vtt: 'VTT',
  webvtt: 'WebVTT',
};

export const MEDIA_TRACK_TYPES: Record<MediaTrackType, string> = {
  audio: 'Audio',
  video: 'Video',
  subtitle: 'Subtitle',
};

export const MEDIA_TRACK_ROLES: Record<MediaTrackRole, string> & {
  [props: string]: string;
} = {
  forced: 'Forced',
  main: 'Main',
  subtitle: 'Subtitle',
  undefined: 'Not set',
};

export const VARIANT_TYPES: Record<VariantType, string> = {
  otf: 'On the fly',
  vod: 'VOD',
  wmk: 'Watermark',
  live: 'Live stream',
};

export const INPUT_FILE_FORMATS: Record<
  InputFileFormat & {[props: string]: string},
  string
> = {
  /**
   * MP4 container
   */
  mp4: 'mp4',
  /**
   * Mpeg-TS container
   */
  ts: 'ts',
  /** MKV container */
  mkv: 'mkv',
  /** AVI container */
  avi: 'avi',
  /** STL subtitle file */
  stl: 'stl',
  /** TTML subtitle file */
  ttml: 'ttml',
  /** WebVTT subtitle file */
  webvtt: 'webvtt',
  /** SRT subtitle file */
  srt: 'srt',
  /** XML metadata from Castlab */
  castlab: 'castlab',
  /** Mobtab XML metadata  */
  mobtab: 'mobtab',
  /** JSON */
  json: 'json',
  /** unknown */
  undefined: 'unknown',
};

const customEnglishMessages: Messages = {
  ...englishMessages,
  '': '',
  admin: 'Administrator',
  operator: 'Operator',
  developer: 'Developer',
  emails: {
    addedToProjectTitle: 'You have been invited to project #account#',
    addedToProjectNewUser: `Welcome to the IDVIU administration console!<br/><br/>You have been added as a member of #account#.<br/><br/>Your password is: #password#<br/>To verify your e-mail address and confirm your access, please click on the link below:<br/><a href="#verifyLink#">#verifyLink#</a><br/><br/>Best regards,<br/>the IDVIU team.`,
    addedToProject: `You have been invited to project #account#!<br/><br/>As a reminder, you can access the IDVIU administration console at this address:<br/>#loginLink#<br/><br/>Best regards,<br/>the IDVIU team.`,
    addedToCompanyTitle: "You have been invited to #company#;'s projects",
    addedToCompanyNewUser: `Welcome to the IDVIU administration console!<br/><br/>You have been invited to #company#'s projects.<br/><br/>Your password is: #password#<br/><br/>To verify your e-mail address and confirm your access, please click on the link below:<br/><a href="#verifyLink#">#verifyLink#</a><br/><br/>Best regards,<br/>the IDVIU team.`,
    addedToCompany: `You have been invited to join #company#'s projects.<br/><br/>As a reminder, you can access the IDVIU administration console at this address:<br/>#loginLink#<br/><br/>Best regards,<br/>the IDVIU team.`,
  },
  viewer: 'Viewer',
  pos: {
    undefined: 'Undefined',
    boolean: {
      true: 'Yes',
      false: 'No',
    },
    search: 'Search',
    configuration: 'Preferences',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    optimizations: {
      download: 'Download',
      streaming: 'Streaming',
    },
    dashboard: {
      companies_count: 'Companies',
      accounts_count: 'Accounts',
      royalty_report: 'MS Royalties report',
      stats_report: 'Stats',
      stats_account:
        'Number of checkin requests per account in the last 7 days',
      stats_os: 'Number of checkin requests per OS in the last 7 days',
      stats_sdk_version:
        'Number of checkin requests per SDK version in the last 7 days',
      stats_android:
        'Number of checkin requests per Android version in the last 7 days',
      stats_ios:
        'Number of checkin requests per iOS version in the last 7 days',
      welcome:
        'Welcome to the IDVIU administration console!<br/>Here, you can find useful information about our products and customize their settings.<br/>You will see these messages (with the lightbulb) in different places to help you navigate and use the console. Enjoy!',
    },
    title: {
      oops: 'Oops',
      new_rule: 'New rule',
      please_confirm: 'Please confirm',
      add_role: 'Add role',
    },
    menu: {
      accessTokens: 'Access Tokens',
      accounts: 'Accounts',
      admin: 'Admin',
      aids: 'AID',
      applicationKeys: 'Application keys',
      catalogs: 'Catalogs',
      certAccesses: 'Cert logs',
      certificates: 'Certificates',
      companies: 'Companies',
      constants: 'Constants',
      contents: 'Contents',
      dashboard: 'Dashboard',
      encodings: 'Encodings',
      files: 'Files',
      inputFiles: 'Input files',
      outputFiles: 'Output files',
      key_analysis: 'Key analysis',
      my_account: 'My account',
      my_company: 'My company',
      player: 'Player',
      products: 'Products',
      servers: 'Servers',
      settings: 'Account settings',
      realms: 'Realms',
      stats: 'Stats',
      users: 'Users',
      virtualEvents: 'Virtual events',
    },
    button: {
      select_project: 'Select a project',
      create: 'Create',
      access_token: 'Get access token',
      add_output_file: 'Add output file',
      add: 'Add',
      save: 'Save',
      modify: 'Modify',
      add_role: 'Add role',
      edit: 'Edit',
      edit_roles: 'Edit roles',
      edit_filters: 'Edit filters',
      add_participant: 'Add participant',
      add_rule: 'Add rule',
      add_product_access: 'Add product access',
      add_server_access: 'Add server credentials',
      add_users: 'Add users',
      add_variant: 'Add variant',
      invite_link: 'Get invite link',
      create_aid: 'Create AID',
      details: 'Details',
      forgot_password: 'Forgot password',
      reset_password: 'Reset password',
      cancel: 'Cancel',
      send_verification_email: 'Send verification e-mail',
      save_password: 'Save password',
      generate_legacy_key: 'Generate application.key',
      download: 'Download',
      edit_rule: 'Edit rules',
      settings: 'Settings',
      delete: 'Delete',
      download_csv: 'Download CSV',
      previous: 'Previous step',
      next: 'Next step',
      back: 'Go back',
      close: 'Close',
      send: 'Send',
      pick_image: 'Pick image',
      play: 'Play',
    },
    fields: {
      email: 'E-mail',
      principal: 'Principal',
      metadata: 'Metadata',
      name: 'Name',
      value: 'Value',
      type: 'Type',
    },
    desc: {
      access_token:
        "Note your access token carefully, you won't be able to retrieve it later:",
      enter_email: 'Please enter your e-mail:',
      email_not_verified:
        "Your e-mail has not been verified yet. If you did not receive the confirmation e-mail yet, click the button below to send it again. Don't forget to check your SPAM folder as well!",
      enter_new_password: 'Choose your new password:',
      password_dont_match: 'Passwords do not match',
      select_account: 'You must select a project in the top left menu first.',
      product_list:
        'Here are the IDVIU products you have access to for this project:',
      your_api_key: 'Your API key is:',
      explain_action: 'Please describe your action:',
    },
    rules: {
      operators: {
        eq: 'Equals',
        eqi: 'Equals Ignore Case',
        neq: 'Not Equals',
        neqi: 'Not Equals Ignore Case',
        gt: 'Greater Than',
        gte: 'Greater Than Or Equals',
        lt: 'Lesser Than',
        lte: 'Lesser Than Or Equals',
        oneof: 'One Of',
        noneof: 'Not One Of',
        oneofi: 'One Of Ignore Case',
        noneofi: 'Not One Of Ignore Case',
        regexp: 'Like (regexp)',
      },
      keys: {
        device_manufacturer: 'Device Manufacturer',
        device_model: 'Device Model',
        device_id: 'Device ID',
        hashed_device_id: 'SHA1(Device ID)',
        device_a: 'Device A',
        os_version: 'OS Version',
        os_build: 'OS Build',
        rom_id: 'ROM ID',
        app_id: 'App package name',
        app_version: 'App version',
      },
    },
    principal: {
      company: 'Company',
      account: 'Account',
    },
  },
  player: {
    title: 'Test player',
  },
  stats: {
    title: {
      account_stats: 'Statistics',
      player_stats: 'Player statistics',
    },
    fields: {
      start_date: 'Start date',
      end_date: 'End date',
    },
    player: {
      csv_description:
        'Player statistics are stored into Elastic Search for a period of 3 months. If you want to export the data to CSV, please select the begin and end date below and click download.',
    },
  },
  products: {
    hss_android: {
      desc: 'The Android SDK for streaming and downloading media content in a secure way.',
    },
    hss_ios: {
      desc: 'The iOS/iPadOS SDK for streaming and downloading media content in a secure way.',
    },
    hss_tvos: {
      desc: 'The TV OS SDK for streaming media content in a secure way.',
    },
    hss_osx: {
      desc: 'The Mac OS SDK for streaming and downloading media content in a secure way.',
    },
    hss_w10: {
      desc: 'The Windows 10 SDK for streaming and downloading media content in a secure way.',
    },
    hss_unknown: {
      desc: 'Legacy SDK access. This will be removed shortly.',
    },
    immersive_tour: {
      desc: 'Virtual events with 360 cameras, interactivity and broadcasting.',
    },
    provisioning: {
      desc: 'The media provisioning platform',
    },
    cdm: {
      desc: 'The media delivery platform',
    },
  },
  resources: {
    accounts: {
      name: 'Account |||| Accounts',
      project: 'Project',
      fields: {
        realm: 'User realm',
      },
    },
    audioCodecs: AUDIO_CODECS,
    catalogs: {
      name: 'Catalog |||| Catalogs',
      fields: {
        name: 'Name',
        redemptionCode: 'Redemption code',
        owner_account: 'this project only',
        owner_company: 'all company projects',
        item: 'Item',
        items: 'Items',
        url: 'URL',
        ...DRM,
        playreadyUrl: 'Playready licenser URL',
        playreadyCustomdata: 'Playready Custom data',
        widevineUrl: 'Widevine licenser URL',
        widevineCustomdata: 'Widevine Custom data',
        fairplayUrl: 'Fairplay licenser URL',
        fairplayCustomdata: 'Fairplay Custom data',
        fairplayPublicKey: 'Fairplay public key',
      },
      desc: {
        choose_name: 'Choose a name for your catalog:',
        choose_scope:
          "Choose if this catalog should be associated to this project only (%{project}) or to all you company's projects:",
        choose_redemption_code:
          'You can choose a redemption code for you catalog (optional). If you do so, you will be able to access this catalog using the redemption code in IDVIU sample apps. Otherwise, the catalog will only be visible here.',
      },
      steps: {
        name: 'Name',
        scope: 'Access rights',
        redemption_code: 'Redemption code',
        content: 'Content',
      },
      help: 'Catalogs allow you to test your own contents in the sample apps bundled with the IDVIU SDK. Create a catalog, add you sample streams to it and give it a redemption code. You can then use this redemption code in the sample apps to test your streams.',
    },
    changes: {
      lastModifications: 'Last modifications',
      fields: {
        on: 'Date',
        by: 'Modified by',
        comment: 'Comment',
        modifications: 'Modifications',
      },
    },
    constants: {
      fields: {
        productIds: 'Related products',
        products: 'Related products',
        className: 'Class/File name',
        language: 'Programing Language',
        name: 'Name',
        value: 'Value',
        extra: 'Extra',
      },
      download_code: 'Download code',
      desc: {
        download_code:
          'Select the programming language and the name of the class/file:',
      },
      help: 'Here you can find all the event related constants defined in the IDVIU SDK.<br/>Most of the time, you would be looking for IDVIU Event values, as they are the new unique and cross-product constants we use now. For legacy integrations, you will also find the player error (received in the player onError callback) and info (received in the player onInfo callback) values.',
      type: {
        idviu_event: 'IDVIU Event (onEvent)',
        hss_setting: 'HSS Setting',
        player_setting: 'Player setting',
        checkin_setting: 'Checkin setting',
        legacy_player_error: 'Player error (onError)',
        legacy_player_info: 'Player info (onInfo)',
        legacy_download_error: 'Download error (onDownloadError)',
      },
    },
    contents: {
      tabs: {
        general: 'General',
        variants: 'Variants',
      },
      fields: {
        title: 'Title',
        externalId: 'Ref ID',
        details: 'Details',
        state: 'State',
        created: 'Created',
        modified: 'Modified',
        variants: 'Variants',
      },
    },
    contentStates: CONTENT_STATES,
    drm: DRM,
    dynamicSettings: {
      fields: {
        name: 'Name',
        rules: 'Filter(s)',
        key: 'Parameter',
        value: 'Value',
        createdOn: 'Created on',
        updatedOn: 'Updated on',
        deletedOn: 'Deleted on',
      },
    },
    outputFiles: {
      fields: {
        id: 'ID',
        url: 'URL',
        quality: 'Quality',
        optimization: 'Optimized for',
        format: 'Format',
        created: 'Created',
        modified: 'Modified',
        tracks: 'Tracks',
        bitrates: 'Bitrates (bit/s)',
        width: 'Width (px)',
        height: 'Height (px)',
        drm: 'DRM',
      },
      tracks: {
        fields: {
          type: 'Type',
          codec: 'Codec',
          language: 'Language',
          role: 'Role',
          channels: 'Channels',
          sampleRate: 'Sample Rate',
          profile: 'Profile',
          width: 'Width',
          height: 'Height',
          framerate: 'Frame rate',
          name: 'Name',
        },
      },
      dialog: {
        title: {
          add: 'Create output file',
        },
      },
    },
    mediaTrackTypes: MEDIA_TRACK_TYPES,
    mediaTrackRoles: MEDIA_TRACK_ROLES,
    outputFileFormats: OUTPUT_FILE_FORMAT,
    realms: {
      name: 'Realm |||| Realms',
      tabs: {
        general: 'General',
        security: 'Security',
        email: 'Email',
        wsmp: 'WSMP',
      },
      fields: {
        id: 'ID',
        name: 'Name',
        principalType: 'Principal type',
        security: {
          passwordLength: 'Generated password length',
          passwordWithNumbers: 'Generated password contain numbers',
          passwordWithSymbols: 'Generated password contain symbols',
          passwordWithUppercase: 'Generated password contain uppercase letters',
          passwordWithLowercase: 'Generated password contain lowercase letters',
          validateEmailUrl: 'Email validation URL',
          resetPasswordUrl: 'Password reset URL',
          singleSession: 'Restrict plays to a single session at the same time',
          useMfa: 'Multi-factor authentication',
          passwordHash: 'Password encryption algorithm',
        },
        useCustomEmail: 'Use custom configuration',
        noCustomEmail: 'No custom email configuration',
        email: {
          smtpConfiguration: 'SMTP configuration',
          host: 'Host',
          port: 'Port',
          username: 'Username',
          password: 'Password',
          secure: 'Secure',
          selfSignedCertificate: 'Self-signed certificate',
          senderName: 'Sender name',
          senderEmail: 'Sender email',
          template: 'Email template',
          editTemplate: 'Edit email template',
          mjmlEditor: DEFAULT_MJML_EDITOR_LABELS,
          sendTestEmail: 'Send test email',
          testEmailRecipient: 'Recipient email address',
        },
        useWsmp: 'Use a WSMP server',
        noWsmp: 'No WSMP server',
        wsmp: {
          wsmpConfiguration: 'WSMP configuration',
          serverUrl: 'Server URL',
          service: 'Service',
          aid: 'AID',
          sig: 'Sig',
          account: 'Account',
        },
      },
      desc: {
        title: 'Realm',
      },
      passwordHash: {
        bcrypt: 'bcrypt (recommended)',
        sha1: 'SHA-1 (legacy)',
      },
      errors: {
        failedToRefresh: 'Failed to refresh template preview',
        testEmailFailed: 'Cannot send test email',
      },
      info: {
        sendingTestEmail: 'Sending test email...',
        testEmailSent: 'Test email sent successfully',
      },
    },
    roles: {
      name: 'Role |||| Roles',
      fields: {
        admin: 'Administrator',
        operator: 'Operator',
        developer: 'Developer',
        viewer: 'Viewer',
      },
    },
    rules: {
      name: 'Rule |||| Rules',
      desc: {
        choose_name: 'Please give a name to this rule:',
        choose_parameter: 'Choose the parameter you wish to modifiy:',
      },
      fields: {
        filter_key: 'Filter key',
        filter_operator: 'Operator',
        filter_value: 'Filter value',
        key: 'Key',
        operator: 'Operator',
        value: 'Value(s)',
        name: 'Rule name',
        description: 'Please describe the rule usage:',
        rules:
          'If you want to target specific devices, you can add filters below. Without any filters, all devices will be targeted:',
      },
    },
    products: {
      name: 'Product |||| Products',
      fields: {
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
    },
    productAccesses: {
      name: 'Product ||| Products',
      fields: {
        account: 'Project',
        product: 'Product',
        name: 'Product',
        blocked: 'Blocked',
        gdpr: 'GDPR confidentiality settings applied',
        apiKey: 'API key',
        cdmSettings: {
          servers: 'DRM servers',
        },
        drm: 'DRM type',
        priority: 'Server priority',
        url: 'Server URL',
        provider: 'Provider',
        providerKey: 'Provider KEY (hex)',
        providerIv: 'Provider IV (hex)',
      },
      tabs: {
        summary: 'Summary',
        blocked: 'Blocked devices',
        checkin_settings: 'Checkin Settings',
        hss_settings: 'HSS Settings',
        player_settings: 'Player Settings',
        cdm_settings: 'CDM Settings',
      },
      title: {
        show: 'Settings',
      },
      desc: {
        blocked:
          "The rules below allow you to block devices. Blocked devices won't be able to use the player anymore:",
        blocked_deleted: 'Blocking rules that are no longer active:',
        blocked_fingerprint:
          'If "fingerprint" requests were enabled for your product, you can block devices here. Fingerprint requests allow you to block DRM stream playback only:',
        blocked_fingerprint_deleted:
          'Blocking rules for fingerprint that are no longer active are listed below:',
        checkin:
          'The rules below allow you to change the frequency of the "checkin" requests. The higher the frequency, the faster the settings you set here will be applied by the devices:',
        checkin_deleted:
          'Rules below have been deleted and are no longer enforced:',
        sdk: 'You can change HSS SDK settings with the rules below:',
        sdk_deleted:
          'Rules below have been deleted and are no longer enforced:',
        player:
          'You can change the player default settings with the rules below:',
        player_deleted:
          'Rules below have been deleted and are no longer enforced:',
        no_rules: 'No rules to show',
      },
    },
    subtitleCodecs: SUBTITLE_CODECS,
    teamMembers: {
      fields: {
        scope: 'Access scope',
        principalType: 'Access scope',
        type: 'User access',
        service: 'Service',
      },
      scope: {
        company: 'Company',
        account: 'Project',
      },
      type: {
        backend: 'Backend (Admin console)',
        frontend: 'Frontend',
      },
      desc: {
        enterEmail:
          'Enter the e-mail address of the person you wish to invite:',
        choose_role: 'Choose the role this person should have:',
        type: 'Should this user have access to the backend (this console) or be a regular frontend user (requires a Realm)?',
        scope:
          'Should this user have access to this project only or all the projects of the company?',
        realm: 'Please select a realm:',
        name: 'Enter their first name and last name (optional):',
        realmWarning: [
          'Users registered with a realm cannot access the administration portal.',
          "You'll need to create a separate account outside of the realm if you want them to access this administration portal.",
        ].join(' '),
      },
    },
    users: {
      fields: {
        email: 'E-mail',
        username: 'Username',
        password: 'Password',
        firstName: 'First name',
        lastName: 'Last name',
        roles: 'Roles',
        id: 'User Id',
        singleSession: 'Restrict plays to a single session at the same time',
        useMfa: 'Multi-factor authentication',
        realm: 'Realm',
      },
      globalRealm: 'Global',
    },
    virtualEvents: {
      tabs: {
        settings: 'Settings',
        participants: 'Participants',
      },
      fields: {
        role: 'Role',
        name: 'Event name',
        userName: 'Name',
        moderator: 'Moderator',
        start: 'Event start',
        end: 'Event end',
        description: 'Description',
        accountId: 'Projet',
        locationName: 'Event location',
        locationCoord: 'Event GPS coordinates',
        website: 'Website',
        organizer: 'Organizer',
        image: 'Event image',
        logo: 'Event logo',
      },
      desc: {
        invite_link: 'The invite link is:',
        title: 'Virtual Event',
        invite_form:
          "You can get an invite link to join the event conference here. The user with the link won't be added to the participants' list but will be able to participate in the conference room",
      },
    },
    virtualEventAccesses: {
      fields: {
        role: 'Role',
        virtualEvent: 'Virtual event',
        participant: 'Participant',
      },
    },
    undefined: {
      fields: {
        title: 'Title',
        url: 'URL',
        language: 'Langage',
        className: 'Nom de la classe',
        widevineUrl: 'Widevine licenser URL',
        playreadyUrl: 'Playready licenser URL',
        playreadyCustomdata: 'Playready custom data',
        fairplayUrl: 'Fairplay licenser URL',
        fairplayPublicKey: 'Fairplay licenser public key (base64 encoded)',
      },
    },
    variants: {
      fields: {
        id: 'ID',
        type: 'Type',
        created: 'Created',
        modified: 'Modified',
        input: 'Input',
        output: 'Output',
      },
    },
    variantTypes: VARIANT_TYPES,
    videoCodecs: VIDEO_CODECS,
    inputFileFormats: INPUT_FILE_FORMATS,
  },
};

export default customEnglishMessages;
