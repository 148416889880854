import {isNil} from '@idviu/ts-helpers';
import {FC, useCallback} from 'react';
import {
  Create,
  email,
  NullableBooleanInput,
  PasswordInput,
  SaveButton,
  SaveButtonProps,
  TextInput,
  Toolbar,
} from 'react-admin';
import {useForm} from 'react-final-form';
import {SimpleForm} from '../components';

const validateEmail = email();

const SaveWithNoteButton: FC<SaveButtonProps> = ({
  handleSubmit,
  handleSubmitWithRedirect,
  redirect,
  ...props
}) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    const url = new URL(window.location.href);
    form.change('href', url.protocol + '//' + url.host + '/#/login');
    if (redirect && typeof redirect === 'string') {
      if (!isNil(handleSubmitWithRedirect)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        handleSubmitWithRedirect(redirect);
      }
    }
  }, [form, handleSubmitWithRedirect, redirect]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const PostCreateToolbar: FC = props => {
  return (
    <Toolbar {...props}>
      <SaveWithNoteButton
        label="ra.action.create"
        redirect="list"
        submitOnEnter={true}
      />
    </Toolbar>
  );
};

const UserCreate: FC = props => {
  return (
    <>
      <Create {...props}>
        <SimpleForm redirect="list" toolbar={<PostCreateToolbar />}>
          <TextInput source="email" validate={validateEmail} />
          <TextInput source="username" />
          <PasswordInput source="password" />
          <NullableBooleanInput source="singleSession" />
          <NullableBooleanInput source="useMfa" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default UserCreate;
