import {Layout, LayoutProps, Sidebar} from 'react-admin';
import {useSelector} from 'react-redux';
import {AppState} from '../types';
import AppBar from './AppBar';
import Menu from './Menu';
import {darkTheme, lightTheme} from './themes';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const AdminLayout = (props: LayoutProps) => {
  const theme = useSelector((state: AppState) =>
    state.theme === 'dark' ? darkTheme : lightTheme,
  );
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
    />
  );
};

export default AdminLayout;
