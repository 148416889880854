import DateFnsUtils from '@date-io/date-fns';
import {Grid, Paper, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/FileCopy';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  useDataProvider,
  useLocale,
  usePermissions,
  useTranslate,
} from 'ra-core';
import React, {useState} from 'react';
import {PleaseSelectAccount, useCurrentAccount} from '../components';
import PieStats from '../components/PieStats';
import UniqueCountStats from '../components/UniqueCountStats';
import PRRoyaltyReport from './RoyaltyReport';

const useStyle = makeStyles({
  root: {},
  fragment: {},
  header: {
    marginTop: '1em',
    minWidth: 120,
  },
  select: {
    minWidth: 200,
  },
  main: {
    marginTop: '1em',
    padding: '2em',
  },
  title: {
    textAlign: 'center',
    marginBottom: '1em',
  },
});

const StatsPage = props => {
  const classes = useStyle();
  const translate = useTranslate();
  const {loaded, permissions} = usePermissions();
  const locale = useLocale();
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  var minDate = new Date(yesterday);
  minDate.setDate(minDate.getDate() - 90);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const dataProvider = useDataProvider();
  const account = useCurrentAccount();
  const startDateChanged = e => {
    if (e < minDate) {
      e = minDate;
    }
    setStartDate(e);
    if (e > endDate) {
      setEndDate(e);
    }
  };

  const endDateChanged = e => {
    if (e > yesterday) {
      e = yesterday;
    }
    setEndDate(e);
    if (e < startDate) {
      setStartDate(e);
    }
  };

  const downloadCSV = e => {
    dataProvider
      .getOne('servers', {id: account.statsExporter})
      .then(({data}) => {
        const fakeLink = document.createElement('a');
        fakeLink.style.display = 'none';
        document.body.appendChild(fakeLink);
        let href =
          data.url +
          '/api/v1/exportCSV?start=' +
          startDate.getTime() +
          '&end=' +
          endDate.getTime() +
          '&access_token=' +
          encodeURIComponent(loaded ? permissions[0].getToken() : '') +
          '&account=' +
          account.id;
        fakeLink.setAttribute('href', href);
        fakeLink.setAttribute('download', 'data.zip');
        fakeLink.setAttribute('target', '_blank');
        fakeLink.click();
      })
      .catch(() => {});
  };

  return (
    <>
      {account ? (
        <>
          {account.statsEnabled && account.statsExporter && (
            <Paper className={classes.main}>
              <Typography className={classes.title} variant="h5" component="h3">
                {translate('stats.title.player_stats')}
              </Typography>
              <Typography
                style={{textAlign: 'center'}}
                variant="inline"
                component="p"
              >
                {translate('stats.player.csv_description')}
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around" alignItems="center">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    margin="normal"
                    id="date-picker-inline"
                    label={translate('stats.fields.start_date')}
                    value={startDate}
                    onChange={startDateChanged}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                  />
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format={locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
                    margin="normal"
                    id="date-picker-inline"
                    label={translate('stats.fields.end_date')}
                    value={endDate}
                    onChange={endDateChanged}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                  />
                  <Button
                    color="primary"
                    label={translate('pos.button.download_csv')}
                    variant="contained"
                    onClick={downloadCSV}
                  >
                    <DownloadIcon />
                    &nbsp;{translate('pos.button.download_csv')}
                  </Button>
                </Grid>
              </MuiPickersUtilsProvider>
            </Paper>
          )}
          <Paper className={classes.main}>
            <Typography className={classes.title} variant="h5" component="h3">
              {translate('stats.title.account_stats')}
            </Typography>
            <Grid container spacing={3}>
              {
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os"
                    filter={{account: account.name}}
                    max={5}
                    title="pos.dashboard.stats_os"
                  />
                </Grid>
              }
              {
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="sdk_version"
                    filter={{account: account.name}}
                    max={10}
                    title="pos.dashboard.stats_sdk_version"
                  />
                </Grid>
              }
              {
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os_version"
                    filter={{device_os: 'Android', account: account.name}}
                    title="pos.dashboard.stats_android"
                  />
                </Grid>
              }
              {
                <Grid item xs={12} lg={4} md={6}>
                  <PieStats
                    field="device_os_version"
                    filter={{device_os: 'iOS', account: account.name}}
                    title="pos.dashboard.stats_ios"
                  />
                </Grid>
              }
              {
                <Grid item xs={12} lg={4} md={6}>
                  <PRRoyaltyReport account={account} />
                </Grid>
              }
              {
                <Grid item xs={12} lg={4} md={6}>
                  <UniqueCountStats filter={{account: account.name}} />
                </Grid>
              }
            </Grid>
          </Paper>
        </>
      ) : (
        <PleaseSelectAccount />
      )}
    </>
  );
};

export default StatsPage;
