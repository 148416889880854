import {FC} from 'react';
import {SelectInput as RaSelectInput, SelectInputProps} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const SelectInput: FC<SelectInputProps> = props => (
  <RaSelectInput {...props} />
);

SelectInput.defaultProps = {
  ...RaSelectInput.defaultProps,
  ...COMMON_INPUT_PROPS,
  variant: 'outlined',
};
