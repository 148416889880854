import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate} from 'ra-core';
import {useState} from 'react';
import {withTypes} from 'react-final-form';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  desc: {
    'max-width': '30em',
  },
}));

interface FormValues {
  email?: string;
}

const {Form} = withTypes<FormValues>();

const ValidateEmailForm = (props: any) => {
  const {handleGoBack, onSubmit} = props;
  const translate = useTranslate();
  const classes = useStyles();
  const [loading] = useState(false);

  const validate = () => {
    const errors: FormValues = {};
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.desc}>
              {translate('pos.desc.email_not_verified')}
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('pos.button.send_verification_email')}
            </Button>
          </CardActions>
          <CardActions className={classes.actions}>
            <Button
              style={{fontSize: '0.7em'}}
              size="small"
              variant="outlined"
              disabled={loading}
              fullWidth
              onClick={handleGoBack}
            >
              {translate('pos.button.cancel')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

export default ValidateEmailForm;
