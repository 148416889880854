import {DrmType} from '@idviu/backbone-api-client';
import {FormDataConsumer, Identifier, Record, usePermissions} from 'ra-core';
import {FC, useCallback} from 'react';
import {
  BooleanInput,
  EditProps,
  ReferenceInput,
  SimpleFormIterator,
} from 'react-admin';
import {
  ArrayInput,
  Edit,
  FormTab,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
} from '../components';

const redirect = (base: string, _id: Identifier, data: any): string => {
  if (data && data.accountId) {
    return '/accounts/' + data.accountId + '/show/productAccess';
  } else {
    return base;
  }
};

const filterProduct = (data: Record) => {
  delete data.product;
  return data;
};

const ProductAccessEdit: FC<EditProps> = props => {
  const {loaded, permissions} = usePermissions();
  const canShow = useCallback((record: any | undefined, match: string) => {
    if (!record) return false;
    const product = record.product;
    if (product && product.includes(match)) return true;
    return false;
  }, []);

  return (
    <>
      <Edit {...props} transform={filterProduct}>
        <TabbedForm
          canDelete={loaded && permissions[0].isAdmin()}
          redirect={redirect}
        >
          <FormTab label="summary">
            <ReferenceInput source="accountId" reference="accounts" link="show">
              <SelectInput optionText="name" disabled />
            </ReferenceInput>

            <TextInput source="product" disabled />

            <BooleanInput source="blocked" />
            <BooleanInput source="gdpr" label="Enforce GDPR" />
            <TextInput source="apiKey" />
          </FormTab>
          <FormTab
            label="Immersive Settings"
            only={r => canShow(r, 'immersive')}
          >
            <TextInput
              label="JWT token app ID"
              source="immersiveTourSettings.jwtAppId"
            />
            <TextInput
              label="JWT token secret"
              source="immersiveTourSettings.jwtSecret"
            />
            <TextInput
              label="Jitsi Server"
              source="immersiveTourSettings.jitsiServer"
            />
          </FormTab>
          <FormTab label="Time settings" only={r => canShow(r, 'hss')}>
            <BooleanInput label="Time - Resync" source="timeResync" />
            <TextInput label="Time - Url" source="timeUrl" />
            <NumberInput
              label="Time - Resync period (in seconds)"
              step={1}
              source="timeResyncPeriod"
            />
            <NumberInput
              label="Time - Failed resync period (in seconds)"
              step={1}
              source="timeFailedResyncPeriod"
            />
          </FormTab>

          <FormTab label="Checkin settings" only={r => canShow(r, 'hss')}>
            <TextInput label="Checkin - Url" source="checkinUrl" />
            <NumberInput
              label="Checkin - Frequency (in seconds)"
              step={1}
              source="checkinFrequency"
            />
            <NumberInput
              label="Checkin - Blocked frequency (in seconds)"
              step={1}
              source="checkinBlockedFrequency"
            />
          </FormTab>
          <FormTab label="CDM settings" only={r => canShow(r, 'cdm')}>
            <ArrayInput source="cdmSettings.servers">
              <SimpleFormIterator>
                <SelectInput
                  source="drm"
                  choices={Object.entries(DrmType).map(e => ({
                    name: e[0],
                    id: e[1],
                  }))}
                  required
                />
                {/*<SelectInput source="type" choices={Object.entries(LicenseServerType).map(e => ({name: e[0], id:e[1]}))} /> */}
                <NumberInput source="priority" defaultValue={1} />
                <TextInput source="url" required />
                <FormDataConsumer>
                  {({getSource, scopedFormData}) =>
                    scopedFormData?.drm === DrmType.widevine &&
                    getSource && (
                      <>
                        <TextInput
                          source={getSource('options.provider')}
                          label="resources.productAccesses.fields.provider"
                          required
                        />
                        <TextInput
                          source={getSource('options.providerKey')}
                          label="resources.productAccesses.fields.providerKey"
                          required
                        />
                        <TextInput
                          source={getSource('options.providerIv')}
                          label="resources.productAccesses.fields.providerIv"
                          required
                        />
                      </>
                    )
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Edit>
    </>
  );
};

export default ProductAccessEdit;
