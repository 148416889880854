import {
  ContentOptimization,
  ContentQuality,
  DrmKeyMode,
  DrmSystemId,
  ENTITY_COLLECTIONS,
  OutputFileFormat,
} from '@idviu/backbone-api-client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/styles';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  useDataProvider,
  useTranslate,
} from 'ra-core';
import {Button, ButtonProps, Labeled, SaveButton} from 'ra-ui-materialui';
import {ChangeEvent, FC, MouseEventHandler, useCallback, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {
  AutocompleteArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from '../components';

const useStyle = makeStyles(() => ({
  button: {
    margin: '1em',
  },
  form: {},
  inlineBlock: {
    display: 'inline-flex',
    marginRight: '1em',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

interface MySaveButtonProps {
  onComplete(id?: string): void;
}

const MySaveButton: FC<MySaveButtonProps> = ({onComplete}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const values = formState.values;

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    values.created = values.modified = new Date().toISOString();

    dataProvider
      .create(ENTITY_COLLECTIONS.outputFile, {data: values})
      .then(res => {
        // Update the main react-admin form (in this case, the comments creation form)
        onComplete(res.data.id as string);
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [formState.valid, formState.values, dataProvider, dispatch, onComplete]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="pos.button.create"
    />
  );
};

interface FormActionsProps {
  basePath?: string;
  setOutputFileId: (id?: string) => void;
  handleCloseClick: MouseEventHandler;
}

const FormActions: FC<FormActionsProps> = ({
  basePath,
  setOutputFileId,
  handleCloseClick,
  ...props
}) => (
  <DialogActions {...props}>
    <MySaveButton onComplete={setOutputFileId} />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

export const DrmInput = () => {
  const form = useForm();
  const [visible, setVisible] = useState(!!form.getState().values.drm);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setVisible(true);
      } else {
        setVisible(false);
        form.change('drm', null);
      }
    },
    [setVisible, form],
  );
  return (
    <Labeled label="DRM">
      <>
        <div>
          <Switch onChange={onChange} checked={visible} />
        </div>
        {visible && (
          <>
            <SelectInput
              validate={required()}
              source="drm.keyMode"
              choices={Object.keys(DrmKeyMode).map(e => ({id: e, name: e}))}
            />
            <AutocompleteArrayInput
              validate={required()}
              source="drm.systemIds"
              choices={Object.entries(DrmSystemId).map(e => ({
                id: e[1],
                name: e[0],
              }))}
              optionText="name"
              optionValue="id"
            />
            <div>
              <TextInput
                fullWidth={false}
                validate={required()}
                source="drm.keyId"
                label="Key ID"
              />
              <TextInput
                style={{marginLeft: '1em'}}
                fullWidth={false}
                validate={required()}
                source="drm.contentKey"
                label="Content Key"
              />
              <TextInput
                fullWidth={false}
                source="drm.contentIV"
                label="Content IV"
              />
              <TextInput
                style={{marginLeft: '1em'}}
                fullWidth={false}
                source="drm.keySeed"
                label="Key Seed"
              />
            </div>
          </>
        )}
      </>
    </Labeled>
  );
};

interface OnUpdateProps {
  onChange: () => void;
}

const AddOutputFileButton: FC<ButtonProps & OnUpdateProps> = ({record}) => {
  const classes = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const form = useForm();
  const setOutputFileId = useCallback(
    (id?: string) => {
      console.log('created file with id', id);
      if (id) {
        const output: Array<string> = form.getState().values.output ?? [];
        form.change('output', [...output, id]);
      }
      setShowDialog(false);
    },
    [form],
  );

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label="pos.button.add_output_file"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add file"
      >
        <DialogTitle>
          {translate('resources.outputFiles.dialog.title.add')}
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            resource={ENTITY_COLLECTIONS.outputFile}
            // We want no toolbar at all as we have our modal actions
            noToolbar={true}
          >
            <TextInput
              source="accountId"
              label="Account"
              defaultValue={record && record.accountId}
              disabled
            />
            <TextInput source="url" validate={required()} />
            <SelectInput
              fullWidth={false}
              formClassName={classes.inlineBlock}
              source="format"
              choices={Object.keys(OutputFileFormat).map(e => ({
                id: e,
                name: e,
              }))}
            />
            <SelectInput
              fullWidth={false}
              formClassName={classes.inlineBlock}
              source="optimization"
              choices={Object.keys(ContentOptimization).map(e => ({
                id: e,
                name: e,
              }))}
            />
            <SelectInput
              fullWidth={false}
              formClassName={classes.inlineBlock}
              source="quality"
              choices={Object.keys(ContentQuality).map(e => ({id: e, name: e}))}
            />

            <DrmInput />
            <FormActions
              setOutputFileId={setOutputFileId}
              handleCloseClick={handleCloseClick}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddOutputFileButton;
