import {Button, ButtonProps, CircularProgress, Grid} from '@material-ui/core';
import {FC} from 'react';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loadingText?: string;
}

const Progress: FC<Pick<LoadingButtonProps, 'loadingText'>> = ({
  loadingText = 'Please wait...',
}) => (
  <Grid container direction="row" alignItems="center">
    <CircularProgress style={{marginRight: '.5em'}} />
    {loadingText}
  </Grid>
);

export const LoadingButton: FC<LoadingButtonProps> = ({
  loading,
  loadingText,
  children,
  ...props
}) =>
  loading ? (
    <Progress loadingText={loadingText} />
  ) : (
    <Button {...props}>{children}</Button>
  );
