import {useMediaQuery} from '@material-ui/core';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {FC} from 'react';
import {
  DeleteButton,
  SaveButton,
  SimpleForm as ReactAdminSimpleForm,
  SimpleFormProps,
  Toolbar,
  ToolbarProps,
} from 'react-admin';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function useWidth() {
  const theme: Theme = useTheme();
  const keys: Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      // FIXME - Move this hook outside of the reduce function
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const CustomToolbar: FC<IdviuToolbarProps> = ({
  noToolbar,
  basePath,
  resource,
  undoable,
  record,
  width,
  canDelete = false,
  ...props
}) => {
  const classes = useStyles();
  const themeWidth = useWidth();
  return noToolbar ? (
    <span />
  ) : (
    <Toolbar
      record={record}
      resource={resource}
      undoable={undoable}
      basePath={basePath}
      width={themeWidth}
      {...props}
      classes={classes}
    >
      <SaveButton
        basePath={basePath}
        record={record}
        resource={resource}
        undoable={undoable}
        label={record && record.id ? 'pos.button.save' : 'pos.button.create'}
      />
      {record && typeof record.id !== 'undefined' && canDelete && (
        <DeleteButton
          basePath={basePath}
          record={record}
          resource={resource}
          undoable={undoable}
        />
      )}
    </Toolbar>
  );
};

export interface IdviuToolbarProps extends ToolbarProps {
  canDelete?: boolean;
  noToolbar?: boolean;
}

const SimpleForm: FC<IdviuSimpleFormProps> = ({
  canDelete,
  children,
  noToolbar,
  ...props
}) => {
  if (!children) throw new Error('children is required for SimpleForm');
  return (
    <ReactAdminSimpleForm
      variant="outlined"
      toolbar={<CustomToolbar canDelete={canDelete} noToolbar={noToolbar} />}
      {...props}
    >
      {children}
    </ReactAdminSimpleForm>
  );
};

export interface IdviuSimpleFormProps extends SimpleFormProps {
  canDelete?: boolean;
  noToolbar?: boolean;
}

SimpleForm.defaultProps = {
  canDelete: false,
  noToolbar: false,
};

export default SimpleForm;
