import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {Chip, ListProps, Typography} from '@material-ui/core';
import {get} from 'lodash';
import {FC} from 'react';
import {
  ChipFieldProps,
  Datagrid,
  FieldProps,
  ReferenceField,
  ReferenceManyField,
  ShowButton,
  SingleFieldList,
  TextField,
  usePermissions,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import {Role} from '../backbone-records';
import {List, PleaseSelectAccount, useCurrentAccount} from '../components';
import {CrossReferenceSearch} from '../components/CrossReferenceSearch';

const ScopeField: FC<FieldProps> = ({record = {}, source = ''}) => {
  const tr = useTranslate();
  return (
    <Typography>
      {tr(`resources.teamMembers.scope.${get(record, source)}`)}
    </Typography>
  );
};

ScopeField.defaultProps = {
  addLabel: true,
};

const RoleChipField: FC<
  Pick<FieldProps<Role>, 'record'> & Omit<ChipFieldProps, 'record'>
> = props => {
  const tr = useTranslate();
  const role = useRecordContext<Role>(props);
  const label = tr(`resources.roles.fields.${role.name}`, {_: role.name});
  return <Chip label={label} />;
};

const filters = [
  <CrossReferenceSearch
    label="pos.search"
    reference="users"
    target="userId"
    alwaysOn
    source="q$email$username$firstName$lastName$metadata.wsmpService"
    key="search"
  />,
];

const TeamMembersList: FC<ListProps> = props => {
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  const canModify = loaded && permissions[0].canModifyAccountUsers(account);
  const tr = useTranslate();

  const filter = account
    ? {
        or: [
          {principalType: EntityType.account, principalId: account.id},
          {principalType: EntityType.company, principalId: account.companyId},
        ],
      }
    : {};

  return account ? (
    <List
      filter={filter}
      filters={filters}
      {...props}
      title="pos.menu.users"
      canDelete={canModify}
      canCreate={canModify}
    >
      <Datagrid>
        <ReferenceField
          label="resources.users.fields.email"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="resources.users.fields.username"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="username" />
        </ReferenceField>
        <ReferenceField
          label="resources.users.fields.firstName"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="firstName" />
        </ReferenceField>
        <ReferenceField
          label="resources.users.fields.lastName"
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          link={false}
        >
          <TextField source="lastName" />
        </ReferenceField>
        <ScopeField
          source="principalType"
          label="resources.teamMembers.fields.scope"
        />
        <ReferenceManyField
          label="Roles"
          reference={ENTITY_COLLECTIONS.roleMapping}
          target="principalId"
          filter={{principalType: EntityType.teamMember}}
        >
          <SingleFieldList linkType={false}>
            <ReferenceField
              label="role"
              source="roleId"
              reference={ENTITY_COLLECTIONS.role}
              link={false}
            >
              <RoleChipField />
            </ReferenceField>
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceField
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          label="resources.users.fields.realm"
          link={false}
        >
          <ReferenceField
            source="realmId"
            reference={ENTITY_COLLECTIONS.realm}
            emptyText={tr('resources.users.globalRealm')}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField
          source="userId"
          reference={ENTITY_COLLECTIONS.user}
          label="resources.realms.fields.wsmp.service"
          link={false}
        >
          <TextField source="metadata.wsmpService" />
        </ReferenceField>
        {canModify && <ShowButton label="pos.button.settings" />}
      </Datagrid>
    </List>
  ) : (
    <PleaseSelectAccount />
  );
};

export default TeamMembersList;
