import {Button, makeStyles} from '@material-ui/core';
import {useTranslate} from 'ra-core';
import {ButtonProps} from 'ra-ui-materialui';
import {FC} from 'react';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },
}));

export const AddVariantButton: FC<ButtonProps> = props => {
  const {record} = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  return (
    <Button
      component={Link}
      variant="contained"
      color="primary"
      className={classes.button}
      to={{
        pathname: '/variants/create',
        state: {
          record: {
            accountId: record?.accountId,
            contentId: record?.id,
          },
        },
        search: `?source=${JSON.stringify({
          accountId: record?.accountId,
          contentId: record?.id,
        })}`,
      }}
    >
      {translate('pos.button.add_variant')}
    </Button>
  );
};
