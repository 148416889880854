import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/styles';
import {useDataProvider} from 'ra-core';
import {Record} from 'ra-core/src/types';
import {FC, MouseEventHandler, useCallback, useState} from 'react';
import {
  Button,
  fetchEnd,
  fetchStart,
  refreshView,
  SaveButton,
  showNotification,
  TextInput,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {v4 as uuid} from 'uuid';
import {SimpleForm} from '../components';

const useStyle = makeStyles({
  button: {
    marginLeft: '1em',
    marginTop: '1em',
  },
});

const required =
  (
    message = 'Please enter a comment to remember for which application this AID will be used',
  ) =>
  (value: unknown) =>
    value ? undefined : message;

// const requiredServiceId = (
//   message = 'Required: use account name as default',
// ) => value => (value ? undefined : message);

interface MySaveButtonProps {
  onComplete(show: boolean): void;
}

const MySaveButton: FC<MySaveButtonProps> = ({onComplete}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    const values = formState.values;

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    values.AID = uuid().toUpperCase();
    values.KEY = uuid().toUpperCase();
    values.IV = uuid().toUpperCase();
    values.SIG = uuid().toUpperCase();

    dataProvider
      .create(ENTITY_COLLECTIONS.aid, {data: values})
      .then(() => {
        // Update the main react-admin form (in this case, the comments creation form)
        onComplete(false);
        dispatch(refreshView());
      })
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [formState.valid, formState.values, dataProvider, dispatch, onComplete]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="pos.button.create"
    />
  );
};

interface FormActionsProps {
  basePath?: string;
  setShowDialog: (show: boolean) => void;
  handleCloseClick: MouseEventHandler;
}

const FormActions: FC<FormActionsProps> = ({
  basePath,
  setShowDialog,
  handleCloseClick,
  ...props
}) => (
  <DialogActions {...props}>
    <MySaveButton onComplete={setShowDialog} />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

interface AddNewAIDButtonProps {
  record: Record;
}

const AddNewAIDButton: FC<AddNewAIDButtonProps> = ({record}) => {
  const classes = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const [sid, setSID] = useState(null);
  const dataProvider = useDataProvider();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  dataProvider
    .getOne(ENTITY_COLLECTIONS.account, {id: record && record.accountId})
    .then(data => {
      setSID(data.data.name);
    })
    .catch(err => {
      console.error(err);
    });

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        label="pos.button.create_aid"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add AID"
      >
        <DialogTitle>Add AID</DialogTitle>
        <DialogContent>
          <SimpleForm
            resource={ENTITY_COLLECTIONS.aid}
            // We want no toolbar at all as we have our modal actions
            toolbar={undefined}
          >
            <TextInput
              source="accountId"
              label="Account"
              defaultValue={record && record.accountId}
              disabled
            />
            {sid && (
              <TextInput
                source="serviceId"
                label="Service ID"
                defaultValue={sid}
                disabled
              />
            )}
            <TextInput
              source="comment"
              label="Comments"
              validate={required()}
            />
            <FormActions
              setShowDialog={setShowDialog}
              handleCloseClick={handleCloseClick}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddNewAIDButton;
