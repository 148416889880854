import Icon from '@material-ui/icons/Extension';
import CreateServerAccess from './CreateServerAccess';
import EditServerAccess from './EditServerAccess';
import ServerAccessList from './ServerAccessList';

const content = {
  icon: Icon,
  list: ServerAccessList,
  create: CreateServerAccess,
  edit: EditServerAccess,
};

export default content;
