import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {push} from 'connected-react-router';
import {FC} from 'react';
import {ReferenceInput, SimpleForm} from 'react-admin';
import {connect} from 'react-redux';
import {
  BooleanInput,
  Edit,
  NullableBooleanInput,
  SelectInput,
  TextInput,
} from '../components';
import {UserTitle} from './UserTitle';

const UserEdit: FC = props => (
  <Edit {...props} title={<UserTitle />}>
    <SimpleForm>
      <TextInput source="id" variant="outlined" disabled />
      <TextInput source="firstName" variant="outlined" />
      <TextInput source="lastName" variant="outlined" />
      <TextInput source="email" variant="outlined" />
      <BooleanInput source="emailVerified" />
      <TextInput source="username" variant="outlined" />
      <NullableBooleanInput source="singleSession" fullWidth={true} />
      <NullableBooleanInput source="useMfa" />
      <ReferenceInput source="realmId" reference={ENTITY_COLLECTIONS.user}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default connect(undefined, {push})(UserEdit);
