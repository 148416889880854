import {ResourceComponentProps, usePermissions} from 'ra-core';
import {FC} from 'react';
import {List} from '../components';
import ServerListDesktop from './ServerListDesktop';

const ServerList: FC<ResourceComponentProps> = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <>
      <List
        {...props}
        perPage={25}
        canCreate={loaded && permissions[0].isAdmin()}
        canDelete={loaded && permissions[0].isSuperAdmin()}
        sort={{field: 'name', order: 'ASC'}}
      >
        <ServerListDesktop />
      </List>
    </>
  );
};

export default ServerList;
