import {isNilOrEmpty} from '@idviu/ts-helpers';
import PropTypes from 'prop-types';
import {
  FilterPayload,
  ListContextProvider,
  ListControllerProps,
  ResourceContextProvider,
  SortPayload,
  useReferenceManyFieldController,
} from 'ra-core';
import React, {Children, cloneElement, FC, ReactElement} from 'react';
import {FieldProps, sanitizeFieldRestProps} from 'react-admin';

/**
 * Render related records to the current one.
 *
 * You must define the fields to be passed to the iterator component as children.
 *
 * @example Display all the comments of the current post as a datagrid
 * ```tsx
 * <ReferenceManyField reference="comments" target="post_id">
 *     <Datagrid>
 *         <TextField source="id" />
 *         <TextField source="body" />
 *         <DateField source="created_at" />
 *         <EditButton />
 *     </Datagrid>
 * </ReferenceManyField>
 * ```
 *
 * @example Display all the books by the current author, only the title
 * ```tsx
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 * ```
 *
 * By default, restricts the displayed values to 25. You can extend this limit
 * by setting the `perPage` prop.
 *
 * @example
 * ```tsx
 * <ReferenceManyField perPage={10} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 *
 * By default, orders the possible values by id desc. You can change this order
 * by setting the `sort` prop (an object with `field` and `order` properties).
 * ```
 *
 * @example
 * ```tsx
 * <ReferenceManyField sort={{ field: 'created_at', order: 'DESC' }} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 * ```
 *
 * Also, you can filter the query used to populate the possible values. Use the
 * `filter` prop for that.
 *
 * @example
 * ```tsx
 * <ReferenceManyField filter={{ is_published: true }} reference="comments" target="post_id">
 *    ...
 * </ReferenceManyField>
 * ```
 */
export const EmptyReferenceManyField: FC<ReferenceManyFieldProps> = props => {
  const {
    basePath,
    children,
    filter,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      '<ReferenceManyField> only accepts a single child (like <Datagrid>)',
    );
  }

  const controllerProps = useReferenceManyFieldController({
    basePath: basePath || '',
    filter,
    page,
    perPage,
    record,
    reference,
    resource: resource || '',
    sort,
    source,
    target,
  });

  return (
    <ResourceContextProvider value={reference}>
      <ListContextProvider value={controllerProps}>
        <ReferenceManyFieldView {...props} {...controllerProps} />
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

export interface ReferenceManyFieldProps extends FieldProps {
  children: ReactElement;
  filter?: FilterPayload;
  page?: number;
  pagination?: ReactElement;
  empty?: ReactElement;
  perPage?: number;
  reference: string;
  sort?: SortPayload;
  target: string;
}

EmptyReferenceManyField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.any,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

EmptyReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: {field: 'id', order: 'DESC'},
  source: 'id',
  addLabel: true,
};

export const ReferenceManyFieldView: FC<ReferenceManyFieldViewProps> =
  props => {
    const {empty, basePath, children, pagination, reference, ids, ...rest} =
      props;
    return (
      <>
        {isNilOrEmpty(ids) && empty ? cloneElement(empty) : <span />}
        {ids && ids.length ? (
          cloneElement(Children.only(children), {
            ...sanitizeFieldRestProps(rest),
            basePath,
            ids,
            resource: reference,
          })
        ) : (
          <span />
        )}
        {ids && ids.length && pagination && props.total !== undefined ? (
          cloneElement(pagination)
        ) : (
          <span />
        )}
      </>
    );
  };

export interface ReferenceManyFieldViewProps
  extends Omit<
      ReferenceManyFieldProps,
      'basePath' | 'resource' | 'page' | 'perPage'
    >,
    ListControllerProps {
  children: ReactElement;
  empty?: ReactElement;
}

ReferenceManyFieldView.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  empty: PropTypes.element,
  pagination: PropTypes.element,
};

export default EmptyReferenceManyField;
