import {Grid} from '@material-ui/core';
import {FC} from 'react';
import {
  BooleanInput,
  Labeled,
  NumberInput,
  PasswordInput,
  TextInput,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {TestEmailButton} from './TestEmailButton';

const EmailConfigInputGrid: FC = props => (
  <>
    <TextInput
      {...props}
      source="email.host"
      required
      fullWidth
      variant="outlined"
    />
    <NumberInput
      {...props}
      source="email.port"
      defaultValue={465}
      variant="outlined"
    />
    <TextInput
      {...props}
      source="email.username"
      fullWidth
      variant="outlined"
    />
    <PasswordInput {...props} source="email.password" variant="outlined" />
    <BooleanInput {...props} source="email.secure" defaultValue={true} />
    <BooleanInput
      {...props}
      source="email.selfSignedCertificate"
      defaultValue={false}
    />
    <TextInput
      {...props}
      source="email.senderName"
      fullWidth
      variant="outlined"
    />
    <TextInput
      {...props}
      source="email.senderEmail"
      fullWidth
      variant="outlined"
    />
  </>
);

export const EmailConfigInput: FC = props => {
  const {values} = useFormState();
  const hasEmail = typeof values.email === 'object';
  return (
    <Labeled label="resources.realms.fields.email.smtpConfiguration">
      <Grid container direction="column" alignItems="flex-start">
        <BooleanInput
          {...props}
          source="email"
          label="resources.realms.fields.useCustomEmail"
          format={(value: unknown) => typeof value === 'object'}
          parse={(value: boolean) => (value ? {} : undefined)}
          fullWidth
        />
        {hasEmail && (
          <>
            <EmailConfigInputGrid {...props} />
            <TestEmailButton {...props} />
          </>
        )}
      </Grid>
    </Labeled>
  );
};

EmailConfigInput.defaultProps = {
  fullWidth: true,
};
