import {ContentState} from '@idviu/backbone-core';
import {notNil} from '@idviu/ts-helpers';
import {Tooltip} from '@material-ui/core';
import {
  DirectionsRun as DirectionsRunIcon,
  Done as DoneIcon,
  Error as ErrorIcon,
  NewReleases as NewReleasesIcon,
} from '@material-ui/icons';
import {FC, ReactElement} from 'react';
import {FieldProps, useRecordContext, useTranslate} from 'react-admin';

const STATE_ICONS: Record<ContentState, FC<{htmlColor: string}>> = {
  created: props => <NewReleasesIcon {...props} />,
  updating: props => <DirectionsRunIcon {...props} />,
  encoding: props => <DirectionsRunIcon {...props} />,
  ready: props => <DoneIcon {...props} />,
  error: props => <ErrorIcon {...props} />,
};

const STATE_ICONS_COLORS: Record<ContentState, `#${string}`> = {
  created: '#0ff',
  updating: '#ff0',
  encoding: '#ff0',
  ready: '#0f0',
  error: '#f00',
};

export interface ContentStateIconProps extends FieldProps {
  label?: string;
}

function useIcon(state: ContentState): ReactElement {
  const icon = STATE_ICONS[state] ?? STATE_ICONS.error;
  const htmlColor = STATE_ICONS_COLORS[state] ?? '#f00';
  const iconElement = icon({htmlColor});
  notNil(iconElement);
  return iconElement;
}

export const ContentStateIcon: FC<ContentStateIconProps> = () => {
  const tr = useTranslate();
  const record = useRecordContext();
  const state: ContentState = record?.state ?? ContentState.created;
  const icon = useIcon(state);
  const stateText = tr(`resources.contentStates.${state}`);
  return <Tooltip title={stateText}>{icon}</Tooltip>;
};

ContentStateIcon.defaultProps = {
  addLabel: true,
  label: 'resources.contents.fields.state',
};
