import CompanyIcon from '@material-ui/icons/Domain';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import CompanyList from './CompanyList';
import CompanyShow from './CompanyShow';

const content = {
  icon: CompanyIcon,
  list: CompanyList,
  edit: CompanyEdit,
  show: CompanyShow,
  create: CompanyCreate,
};

export default content;
