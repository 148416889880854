import Icon from '@material-ui/icons/Extension';
import TeamMembersList from './TeamMembersList';
import TeamMembersCreate from './TeamMembersCreate';
import TeamMembersShow from './TeamMembersShow';
import TeamMembersEdit from './TeamMembersEdit';

const TeamMembers = {
  list: TeamMembersList,
  icon: Icon,
  show: TeamMembersShow,
  edit: TeamMembersEdit,
  create: TeamMembersCreate,
};

export default TeamMembers;
