import {useSelector} from 'react-redux';
import {AppState} from '../types';

function useCurrentAccount() {
  const account = useSelector((state: AppState) => state.account); // force rerender on theme change

  return account;
}

export default useCurrentAccount;
