import {RealmPrincipalType} from '@idviu/backbone-core';
import {FC} from 'react';
import {useRecordContext} from 'react-admin';
import accounts from '../accounts';
import companies from '../companies';
import {MaterialIconProps} from '../components/MaterialIconProps';

const PRINCIPAL_TYPE_ICON_PROPS: MaterialIconProps = {
  fontSize: 'small',
  style: {marginRight: '.25em'},
};

export const PrincipalTypeIcon: FC = props => {
  const record = useRecordContext(props);
  const principalType = record.principalType as RealmPrincipalType;
  return (
    <>
      {principalType === RealmPrincipalType.company && (
        <companies.icon {...PRINCIPAL_TYPE_ICON_PROPS} />
      )}
      {principalType === RealmPrincipalType.account && (
        <accounts.icon {...PRINCIPAL_TYPE_ICON_PROPS} />
      )}
    </>
  );
};
