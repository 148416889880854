import {ContentOptimization, QualityMode} from '@idviu/backbone-api-client';
import {Record, usePermissions, useTranslate} from 'ra-core';
import {
  Filter,
  FunctionField,
  ListProps,
  NumberField,
  ShowButton,
  TextField,
} from 'ra-ui-materialui';
import {FC} from 'react';
import {
  Datagrid,
  EnumField,
  List,
  SelectInput,
  TextInput,
  useCurrentAccount,
} from '../components';
import {OutputFile} from '../types';
import {getActiveDrm, getBitrates} from './OutputFileShow';

const ContentFilter: FC = props => (
  <Filter {...props} variant="outlined">
    <TextInput source="q$url" alwaysOn={true} label="pos.search" />
    <SelectInput
      source="quality"
      choices={Object.keys(QualityMode).map(type => ({
        id: type,
        name: type,
      }))}
      alwaysOn={true}
    />
    <SelectInput
      source="optimization"
      choices={Object.keys(ContentOptimization).map(type => ({
        id: type,
        name: type,
      }))}
      alwaysOn={true}
    />
  </Filter>
);

export const OutputFileList: FC<ListProps> = props => {
  const tr = useTranslate();
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  const canModify = loaded && permissions[0].canModifyAccountSettings(account);
  const filter = account ? {accountId: account.id} : {};

  return (
    <List
      {...props}
      canDelete={canModify}
      canCreate={canModify}
      filter={filter}
      filters={<ContentFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="url" />
        <EnumField source="format" labelPath="resources.outputFileFormats" />
        <NumberField source="width" />
        <NumberField source="height" />
        <FunctionField
          label="resources.outputFiles.fields.bitrates"
          render={(record?: Record) => getBitrates(record?.bitrates)}
        />
        <FunctionField
          label="resources.outputFiles.fields.drm"
          render={(record?: OutputFile) => getActiveDrm(record, tr)}
        />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
