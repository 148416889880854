import {isNil} from '@idviu/ts-helpers';
import {FC} from 'react';
import {Labeled, useRecordContext} from 'react-admin';

export const EmailPasswordField: FC = () => {
  const record = useRecordContext();
  return (
    <>
      {!isNil(record.email?.password) && (
        <Labeled label="resources.realms.fields.email.password">
          <div>{'*****'}</div>
        </Labeled>
      )}
    </>
  );
};
