const content = {
  save: function (key: string, value: any, expirationSec: number) {
    if (typeof Storage === 'undefined') return false;
    const expirationMS = expirationSec * 1000;
    const record = {
      value: value,
      timestamp: new Date().getTime() + expirationMS,
    };
    localStorage.setItem(key, JSON.stringify(record));
    return value;
  },
  load: function (key: string) {
    if (typeof Storage === 'undefined') return false;
    const item = localStorage.getItem(key);
    if (!item) return false;
    const record = JSON.parse(item);
    if (!record) return false;
    if (!record) {
      return false;
    }
    return new Date().getTime() < record.timestamp && record.value;
  },
  remove: function (key: string) {
    if (typeof Storage === 'undefined') return false;
    localStorage.removeItem(key);
    return true;
  },
  update: function (key: string, value: any) {
    if (typeof Storage === 'undefined') return false;
    const item = localStorage.getItem(key);
    if (!item) return false;
    const record = JSON.parse(item);
    if (!record) return false;
    const updatedRecord = {value, timestamp: record.timestamp};
    localStorage.setItem(key, JSON.stringify(updatedRecord));
    return updatedRecord;
  },
};

export default content;
