import {Reducer} from 'redux';
import {changeTheme, CHANGE_THEME} from '../actions/actions';
import {ThemeName} from '../types';

type State = ThemeName;
type Action =
  | ReturnType<typeof changeTheme>
  | {type: 'OTHER_ACTION'; payload?: any};

const getSavedTheme = () => {
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme !== null) {
    return savedTheme as ThemeName;
  }
  return 'dark' as ThemeName;
};

const themeReducer: Reducer<State, Action> = (
  previousState = getSavedTheme(),
  action,
) => {
  if (action.type === CHANGE_THEME) {
    return action.payload;
  }
  return previousState;
};

export default themeReducer;

export {getSavedTheme};
