import {Grid} from '@material-ui/core';
import {FC} from 'react';
import {BooleanInput, Labeled} from 'react-admin';
import {useFormState} from 'react-final-form';
import {ArrayInput, SingleValueFormIterator, TextInput} from '../components';

const WsmpConfigInputGrid: FC = props => (
  <>
    <TextInput {...props} source="wsmp.serverUrl" required fullWidth />
    <TextInput {...props} source="wsmp.service" required fullWidth />
    <ArrayInput {...props} source="wsmp.allowedServices" fullWidth>
      <SingleValueFormIterator>
        <TextInput source="" />
      </SingleValueFormIterator>
    </ArrayInput>
    <TextInput {...props} source="wsmp.aid" required fullWidth />
    <TextInput {...props} source="wsmp.sig" required fullWidth />
    <TextInput {...props} source="wsmp.account" required fullWidth />
  </>
);

export const WsmpConfigInput: FC = props => {
  const {values} = useFormState();
  const hasWsmp = typeof values.wsmp === 'object';
  return (
    <Labeled label="resources.realms.fields.wsmp.wsmpConfiguration">
      <Grid container direction="column" alignItems="flex-start">
        <BooleanInput
          {...props}
          source="wsmp"
          label="resources.realms.fields.useWsmp"
          format={(value: unknown) => typeof value === 'object'}
          parse={(value: boolean) => (value ? {} : undefined)}
          fullWidth
        />
        {hasWsmp && <WsmpConfigInputGrid {...props} />}
      </Grid>
    </Labeled>
  );
};

WsmpConfigInput.defaultProps = {
  fullWidth: true,
};
