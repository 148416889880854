import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import {usePermissions} from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import React, {Component} from 'react';
import {
  ArrayInput,
  BooleanInput,
  fetchEnd,
  fetchStart,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectInput,
  showNotification,
  TextInput,
} from 'react-admin';
import {Field, useForm} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {Edit, TabbedForm} from '../components';
import SingleValueFormIterator from '../components/SingleValueFormIterator';
import {storage} from '../dataProvider';
import {RealmInput} from '../teamMembers/RealmInput';

const AccountTitle = ({record}) => {
  return <span>Account {record ? `"${record.name}"` : ''}</span>;
};

class ApplicationFileInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {onSelectedFile} = this.props;
    onSelectedFile(e.target.files[0]);
  }

  render() {
    return (
      <div style={{marginTop: '0.5em'}}>
        <input
          type="file"
          accept=".key"
          name="filename"
          onChange={this.onChange}
        />
      </div>
    );
  }
}

const useStyle = makeStyles({
  input: {
    marginTop: '0.6em',
    minWidth: '540px',
  },

  title: {
    fontSize: 14,
    width: 'auto',
    marginTop: '2em',
    marginBottom: '0.1em',
  },
});

const PrefillField = props => {
  const dispatch = useDispatch();
  const form = useForm();

  const handleChange = data => {
    dispatch(fetchStart());
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBase64 = reader.result.substr(reader.result.indexOf(',') + 1);
      const bodyObj = {key: fileAsBase64};

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('authorization', storage.load('lbtoken').id);
      const request = new Request(
        `${BACKBONE_REST_API_V1_URL}/applicationKeys/dump`,
        {
          method: 'POST',
          body: JSON.stringify(bodyObj),
          headers: headers,
        },
      );
      fetch(request)
        .then(response => response.json())
        .then(({...res}) => {
          if (!res.error) {
            form.change('legacy_data_random1', res.DATA_RANDOM1);
            form.change('legacy_data_random2', res.DATA_RANDOM2);
            form.change('legacy_data_random3', res.DATA_RANDOM3);
            form.change('legacy_drm_random1', res.DRM_RANDOM1);
            form.change('legacy_drm_random2', res.DRM_RANDOM2);
            form.change('legacy_drm_random3', res.DRM_RANDOM3);
            form.change('legacy_data_signature', res.DATA_SIGNATURES);
            form.change('legacy_time_server_url', res.HSS_TIME_SERVER_URL);
            form.change('legacy_checkin_url', res.HSS_CHECKIN_URL);
            form.change('legacy_hss_url', res.HSS_URL);
          }
        })
        .catch(err => {
          dispatch(
            showNotification(err.message || 'Could not analyze key', 'warning'),
          );
        })
        .finally(() => {
          dispatch(fetchEnd());
        });
    };

    reader.onabort = () => fetchEnd();
    reader.onerror = () => fetchEnd();

    reader.readAsDataURL(data);
  };
  return (
    <Field
      name="keyFile"
      type="file"
      component={ApplicationFileInput}
      onSelectedFile={handleChange}
    />
  );
};

PrefillField.defaultProps = {
  addLabel: true,
};

const CustomTypography = ({basePath, variant, ...props}) => {
  return <Typography {...props} />;
};

const AccountEdit = props => {
  const classes = useStyle();
  const {loaded, permissions} = usePermissions();

  return (
    <Edit {...props} title={<AccountTitle />}>
      <TabbedForm
        redirect="show"
        canDelete={loaded && permissions[0].isSuperAdmin()}
      >
        <FormTab label="Account details">
          <TextInput source="id" disabled />
          <TextInput source="name" />
          <ArrayInput source="altNames">
            <SingleValueFormIterator>
              <TextInput />
            </SingleValueFormIterator>
          </ArrayInput>
          {loaded && permissions[0].isSuperAdmin() && (
            <ReferenceInput source="companyId" reference="companies">
              <SelectInput optionText="name" />
            </ReferenceInput>
          )}
          <RealmInput source="realmId" />
          <BooleanInput source="blocked" />
          <BooleanInput source="gdpr" label="enforce GDPR" />
          <RichTextInput source="description" />
        </FormTab>
        <FormTab label="Stats settings">
          <BooleanInput source="statsEnabled" />
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData.statsEnabled && (
                <ReferenceInput
                  source="statsServer"
                  reference="servers"
                  filter={{type: 'elastic-search'}}
                  {...rest}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({formData, ...rest}) =>
              formData.statsEnabled && (
                <ReferenceInput
                  source="statsExporter"
                  reference="servers"
                  filter={{type: 'api'}}
                  {...rest}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <BooleanInput
            source="distinctCheckin"
            label="Use distinct checkin index"
          />
        </FormTab>
        <FormTab label="Application key settings">
          <CustomTypography className={classes.title} color="textPrimary">
            Prefill using an existing application.key:
          </CustomTypography>
          <PrefillField name="key" addLabel={false} />

          <CustomTypography className={classes.title} color="textPrimary">
            Optional custom server url:
          </CustomTypography>
          <TextInput
            className={classes.input}
            label="HSS_URL"
            source="legacy_hss_url"
          />
          <TextInput
            className={classes.input}
            label="CHECKIN_URL"
            source="legacy_checkin_url"
          />
          <TextInput
            className={classes.input}
            label="TIME_SERVER_URL"
            source="legacy_time_server_url"
          />

          <CustomTypography className={classes.title} color="textPrimary">
            {'No longer used with SDK > 4.9.20:'}
          </CustomTypography>
          <TextInput
            className={classes.input}
            label="DATA_SIGNATURE"
            source="legacy_data_signature"
          />

          <CustomTypography className={classes.title} color="textPrimary">
            Only used is using Playready 1.2:
          </CustomTypography>
          <TextInput
            className={classes.input}
            label="DRM_RANDOM1"
            source="legacy_drm_random1"
          />
          <TextInput
            className={classes.input}
            label="DRM_RANDOM2"
            source="legacy_drm_random2"
          />
          <TextInput
            className={classes.input}
            label="DRM_RANDOM3"
            source="legacy_drm_random3"
          />

          <CustomTypography className={classes.title} color="textPrimary">
            Only used for Android (Make sure they match for existing clients or
            they will loose data):
          </CustomTypography>
          <TextInput
            className={classes.input}
            label="DATA_RANDOM1"
            source="legacy_data_random1"
          />
          <TextInput
            className={classes.input}
            label="DATA_RANDOM2"
            source="legacy_data_random2"
          />
          <TextInput
            className={classes.input}
            label="DATA_RANDOM3"
            source="legacy_data_random3"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default AccountEdit;
