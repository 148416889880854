import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {ListProps} from '@material-ui/core';
import {ReferenceField, TextField} from 'ra-ui-materialui';
import {FC} from 'react';
import {Datagrid, List} from '../components';

export const EncodingList: FC<ListProps> = props => {
  return (
    <List {...props}>
      <Datagrid>
        <ReferenceField
          source="contentId"
          reference={ENTITY_COLLECTIONS.encoding}
        >
          <TextField source="title" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
