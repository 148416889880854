import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {FC} from 'react';
import {useTranslate} from 'react-admin';
import {Field} from 'react-final-form';
import {FieldArray, useFieldArray} from 'react-final-form-arrays';

export interface FormProps {
  input: any;
  meta: any;
  placeholder?: string;
  value?: any;
}

const useStyle = makeStyles(theme => ({
  root: {},
  actionButton: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    padding: 0,
    minWidth: 32,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
  },
  content: {
    paddingTop: theme.spacing(1),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  card: {
    marginBottom: theme.spacing(1),
  },
  box: {
    display: 'flex',
    width: '100%',
  },
  text: {
    flex: '1 1 auto',
    marginRight: theme.spacing(1),
  },
}));

const TextFieldAdapter: FC<FormProps> = ({input, meta, ...rest}) => {
  const classes = useStyle();
  return (
    <TextField
      {...input}
      {...rest}
      className={classes.text}
      variant="outlined"
      margin="dense"
      onChange={(event: any) => input.onChange(event.target.value)}
    />
  );
};

const CheckboxAdapter: FC<FormProps> = ({
  input,
  meta,
  placeholder,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {value, ...restInput} = input;
  return (
    <span>
      <Checkbox
        {...restInput}
        {...rest}
        onChange={(event: any) => input.onChange(event)}
      />
      <Typography display="inline">{placeholder}</Typography>
    </span>
  );
};

const CatalogInput: FC<CatalogInputProps> = () => {
  const classes = useStyle();
  const translate = useTranslate();
  const {fields} = useFieldArray('items');
  return (
    <div className={classes.root}>
      <FieldArray name="items" key="items">
        {({fields}) =>
          fields.map((name, index) => (
            <Card key={`${name}-${index}`} className={classes.card}>
              <CardContent className={classes.header}>
                <Typography>
                  {translate('resources.catalogs.fields.item')} #{index}
                </Typography>
                <Button
                  className={classes.closeButton}
                  onClick={() => fields.remove(index)}
                >
                  <CloseIcon />{' '}
                </Button>
              </CardContent>

              <CardContent className={classes.content}>
                <Box className={classes.box}>
                  <Field
                    name={`${name}.title`}
                    component={TextFieldAdapter}
                    type="text"
                    placeholder={translate('resources.catalogs.fields.name')}
                  />
                </Box>
                <Box className={classes.box}>
                  <Field
                    name={`${name}.url`}
                    component={TextFieldAdapter}
                    type="text"
                    placeholder={translate('resources.catalogs.fields.url')}
                  />
                </Box>
                <Box className={classes.box}>
                  <Field
                    name={`${name}.drm`}
                    component={CheckboxAdapter}
                    type="checkbox"
                    value="playready"
                    placeholder={translate(
                      'resources.catalogs.fields.playready',
                    )}
                  />
                  <Field
                    name={`${name}.drm`}
                    component={CheckboxAdapter}
                    type="checkbox"
                    value="widevine"
                    placeholder={translate(
                      'resources.catalogs.fields.widevine',
                    )}
                  />
                  <Field
                    name={`${name}.drm`}
                    component={CheckboxAdapter}
                    type="checkbox"
                    value="fairplay"
                    placeholder={translate(
                      'resources.catalogs.fields.fairplay',
                    )}
                  />
                </Box>
                {fields.value[index] &&
                  fields.value[index].drm &&
                  fields.value[index].drm.includes('playready') && (
                    <Box className={classes.box}>
                      <Field
                        name={`${name}.playreadyUrl`}
                        component={TextFieldAdapter}
                        type="text"
                        placeholder={translate(
                          'resources.catalogs.fields.playreadyUrl',
                        )}
                      />
                      <Field
                        name={`${name}.playreadyCustomdata`}
                        component={TextFieldAdapter}
                        type="text"
                        placeholder={translate(
                          'resources.catalogs.fields.playreadyCustomdata',
                        )}
                      />
                    </Box>
                  )}
                {fields.value[index] &&
                  fields.value[index].drm &&
                  fields.value[index].drm.includes('widevine') && (
                    <Box className={classes.box}>
                      <Field
                        name={`${name}.widevineUrl`}
                        component={TextFieldAdapter}
                        type="text"
                        placeholder={translate(
                          'resources.catalogs.fields.widevineUrl',
                        )}
                      />
                      <Field
                        name={`${name}.widevineCustomdata`}
                        component={TextFieldAdapter}
                        type="text"
                        placeholder={translate(
                          'resources.catalogs.fields.widevineCustomdata',
                        )}
                      />
                    </Box>
                  )}
                {fields.value[index] &&
                  fields.value[index].drm &&
                  fields.value[index].drm.includes('fairplay') && (
                    <>
                      <Box className={classes.box}>
                        <Field
                          name={`${name}.fairplayUrl`}
                          component={TextFieldAdapter}
                          type="text"
                          placeholder={translate(
                            'resources.catalogs.fields.fairplayUrl',
                          )}
                        />
                        <Field
                          name={`${name}.fairplayCustomdata`}
                          component={TextFieldAdapter}
                          type="text"
                          placeholder={translate(
                            'resources.catalogs.fields.fairplayCustomdata',
                          )}
                        />
                      </Box>
                      <Box className={classes.box}>
                        <Field
                          name={`${name}.fairplayPublicKey`}
                          component={TextFieldAdapter}
                          type="text"
                          placeholder={translate(
                            'resources.catalogs.fields.fairplayPublicKey',
                          )}
                        />
                      </Box>
                    </>
                  )}
              </CardContent>
            </Card>
          ))
        }
      </FieldArray>
      <Button
        color="primary"
        variant="contained"
        className={classes.actionButton}
        onClick={() => fields.push({})}
      >
        {translate('pos.button.add')}
      </Button>
    </div>
  );
};

export interface CatalogInputProps {
  source?: string;
  id?: string;
  index?: number;
}

export default CatalogInput;
