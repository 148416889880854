import {usePermissions} from 'ra-core';
import React from 'react';
import AccountShowAdmin from './AccountShowAdmin';
import AccountShowUser from './AccountShowUser';

const AccountShow = props => {
  const {loaded, permissions} = usePermissions();

  return (
    <>
      {loaded && permissions[0].hasMainRole() && (
        <AccountShowAdmin {...props} />
      )}
      {loaded && !permissions[0].hasMainRole() && (
        <AccountShowUser {...props} />
      )}
    </>
  );
};

export default AccountShow;
