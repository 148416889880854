import {
  postApiV1RealmsRenderEmail,
  RenderEmailInput,
} from '@idviu/backbone-api-client';
import {isNil} from '@idviu/ts-helpers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {Cancel, Edit, Save} from '@material-ui/icons';
import {FC, useCallback, useState} from 'react';
import {InputProps, Labeled, showNotification, useTranslate} from 'react-admin';
import {Field, useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {getBackboneRequestOpts} from '../backbone-helpers';
import {
  DEFAULT_MJML_EDITOR_LABELS,
  MjmlEditor,
  MjmlEditorLabels,
} from '../components/MjmlEditor';

const DEFAULT_TEMPLATE = `<mjml>
<mj-body>
  <mj-section>
    <mj-column>
      <mj-text font-size="14px" color="#000" font-family="helvetica">
        {{ body }}
      </mj-text>
    </mj-column>
  </mj-section>
</mj-body>
</mjml>
`;

function useEditorLabels(): MjmlEditorLabels {
  const tr = useTranslate();
  return Object.keys(DEFAULT_MJML_EDITOR_LABELS).reduce(
    (labels, key) => ({
      ...labels,
      [key]: tr(`resources.realms.fields.email.mjmlEditor.${key}`),
    }),
    {},
  ) as MjmlEditorLabels;
}

async function renderPreview(mjml: string): Promise<string> {
  const input: RenderEmailInput = {
    templates: {default: mjml},
    root: 'default',
    data: {body: 'Hello world!'},
  };
  const requestOpts = getBackboneRequestOpts();
  return await postApiV1RealmsRenderEmail(input, requestOpts);
}

interface PreviewState {
  refreshing: boolean;
  html?: string;
}

function usePreview(mjml?: string): [PreviewState, () => void] {
  const [state, setState] = useState<PreviewState>({refreshing: false});
  const dispatch = useDispatch();
  const tr = useTranslate();
  const refresh = useCallback(() => {
    setState({refreshing: true});
    if (isNil(mjml)) return;
    renderPreview(mjml)
      .then(html => setState({refreshing: false, html}))
      .catch(error => {
        setState({refreshing: false});
        const msg = tr('resources.realms.errors.failedToRefresh');
        dispatch(showNotification(msg, 'error'));
        console.error(error);
      });
  }, [mjml, setState, dispatch, tr]);
  return [state, refresh];
}

interface EditDialogProps {
  value: string | undefined;
  show: boolean;
  onHide: () => void;
  onSave: (mjml?: string) => void;
}

const EditDialog: FC<EditDialogProps> = ({value, show, onHide, onSave}) => {
  const [mjml, setMjml] = useState<string | undefined>(
    value ?? DEFAULT_TEMPLATE,
  );
  const [previewState, refresh] = usePreview(mjml);
  const handleSave = useCallback(() => {
    onSave(mjml);
    onHide();
  }, [mjml, onSave, onHide]);
  const tr = useTranslate();
  const labels = useEditorLabels();
  return (
    <Dialog fullWidth={true} maxWidth="xl" open={show} onClose={onHide}>
      <DialogTitle>{tr('resources.realms.fields.email.template')}</DialogTitle>
      <DialogContent>
        <MjmlEditor
          value={mjml}
          onChange={setMjml}
          html={previewState.html}
          onRefresh={refresh}
          refreshing={previewState.refreshing}
          labels={labels}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onHide} startIcon={<Cancel />}>
          {tr('pos.button.cancel')}
        </Button>
        <Button color="primary" onClick={handleSave} startIcon={<Save />}>
          {tr('pos.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface EditButtonProps {
  value: string | undefined;
  onSave(mjml?: string): void;
}

const EditButton: FC<EditButtonProps> = ({onSave, value}) => {
  const [show, setShow] = useState(false);
  const onShow = useCallback(() => setShow(true), [setShow]);
  const onHide = useCallback(() => setShow(false), [setShow]);
  const tr = useTranslate();
  return (
    <>
      <Labeled label="resources.realms.fields.email.template">
        <Button onClick={onShow} startIcon={<Edit />}>
          {tr('resources.realms.fields.email.editTemplate')}
        </Button>
      </Labeled>
      <EditDialog value={value} show={show} onHide={onHide} onSave={onSave} />
    </>
  );
};

export const EmailTemplateInput: FC<InputProps<string>> = ({
  validate,
  ...props
}) => {
  const state = useFormState();
  const [template, setTemplate] = useState<string>(
    state.initialValues[props.source],
  );
  const onSave = useCallback(
    (newTemplate: string) => {
      setTemplate(newTemplate);
    },
    [setTemplate],
  );
  return (
    <Field name={props.name ?? props.source} value={template}>
      {props => (
        <EditButton
          value={props.input.value ?? DEFAULT_TEMPLATE}
          onSave={v => {
            onSave(v ?? '');
            props.input.onChange(v);
          }}
        />
      )}
    </Field>
  );
};
