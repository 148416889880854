import {
  DrmSystemId,
  ENTITY_COLLECTIONS,
  IMediaTrack,
  IVideoTrack,
  MediaTrackType,
  QualityMode,
} from '@idviu/backbone-api-client';
import {Grid} from '@material-ui/core';
import {Code, DeviceHub, Settings} from '@material-ui/icons';
import {FC} from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FieldProps,
  FunctionField,
  Record as RaRecord,
  ReferenceArrayField,
  ReferenceManyField,
  Show,
  ShowActionsProps,
  ShowButton,
  ShowProps,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  Translate,
  usePermissions,
  useTranslate,
} from 'react-admin';
import {
  DeleteWithConfirmButton,
  EnumField,
  MetadataField,
  useCurrentAccount,
} from '../components';
import {OutputFile} from '../types';
import {AddVariantButton} from './AddVariantButton';
import {ContentStateIcon} from './StateIcon';

function getSystemIdName(id: DrmSystemId): string {
  const index = Object.values(DrmSystemId).indexOf(id);
  if (index === -1) return '';
  return Object.keys(DrmSystemId)[index];
}

function getActiveDrm(file: OutputFile | undefined, tr: Translate): string {
  if (!file) return '';
  const drm: Array<string> = [];
  if (file.drm?.systemIds) {
    drm.push(...(file.drm?.systemIds.map(s => getSystemIdName(s)) ?? []));
  }
  file.tracks?.forEach(t => {
    if (t.drm && t.drm.systemIds) {
      drm.push(...(t.drm.systemIds.map(s => getSystemIdName(s)) ?? []));
    }
  });

  return drm.map(name => tr(`resources.drm.${name}`)).join(', ');
}

function getQuality(record: RaRecord | undefined): string {
  const height = record?.height ?? 0;
  if (height >= 2160) {
    return 'UHD';
  } else if (height >= 1920) {
    return 'FHD';
  } else if (height >= 720) {
    return 'HD';
  } else if (height > 0) {
    return 'SD';
  } else {
    return 'N/A';
  }
}

function getOptimization(record: RaRecord | undefined, tr: Translate): string {
  const tracks = record?.tracks;
  if (tracks) {
    let optim = 'streaming';
    (tracks as IMediaTrack[]).forEach(t => {
      if (t.type === MediaTrackType.video) {
        const videoTrack = t as IVideoTrack;
        if (videoTrack.qualityMode === QualityMode.crf) {
          optim = 'download';
        }
      }
    });
    return tr(`pos.optimizations.${optim}`);
  }
  return 'N/A';
}

const VariantQuickShow: FC<FieldProps> = props => {
  const tr = useTranslate();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceArrayField
          addLabel={true}
          label="resources.variants.fields.output"
          source="output"
          reference={ENTITY_COLLECTIONS.outputFile}
        >
          <Datagrid rowClick="show">
            <EnumField
              source="format"
              labelPath="resources.outputFileFormats"
            />
            <FunctionField
              label="resources.outputFiles.fields.quality"
              render={(record?: RaRecord) => getQuality(record)}
            />
            <FunctionField
              label="resources.outputFiles.fields.optimization"
              render={(record?: RaRecord) => getOptimization(record, tr)}
            />
            <FunctionField
              label="resources.outputFiles.fields.drm"
              render={(record?: OutputFile) => getActiveDrm(record, tr)}
            />
            <TextField source="url" />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

const VariantsDatagrid: FC<FieldProps> = () => {
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  const canModify = loaded && permissions[0].canModifyAccountSettings(account);
  return (
    <ReferenceManyField
      reference={ENTITY_COLLECTIONS.variant}
      target="contentId"
    >
      <Datagrid expand={<VariantQuickShow />}>
        <TextField source="id" />
        <EnumField source="type" labelPath="resources.variantTypes" />
        <DateField source="created" showTime={true} />
        <DateField source="modified" showTime={true} />
        <ShowButton />
        {canModify && (
          <DeleteWithConfirmButton redirect={false} resource="variants" />
        )}
      </Datagrid>
    </ReferenceManyField>
  );
};

VariantsDatagrid.defaultProps = {
  addLabel: true,
  label: 'resources.contents.fields.variants',
};

const StateField: FC<FieldProps> = () => {
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={0}
    >
      <Grid item>
        <ContentStateIcon />
      </Grid>
      <Grid item style={{marginLeft: '.25em'}}>
        <EnumField source="state" labelPath="resources.contentStates" />
      </Grid>
    </Grid>
  );
};

StateField.defaultProps = {
  addLabel: true,
  label: 'resources.contents.fields.state',
};

const ContentShowActions: FC<ShowActionsProps> = ({basePath, data}) => {
  const {loaded, permissions} = usePermissions();

  return (
    <TopToolbar>
      {loaded &&
        permissions[0].canModifyAccountSettings(data?.accountId ?? '') && (
          <EditButton basePath={basePath} record={data} variant="outlined" />
        )}
    </TopToolbar>
  );
};

export const ContentShow: FC<ShowProps> = props => {
  return (
    <Show {...props} actions={<ContentShowActions />}>
      <TabbedShowLayout>
        <Tab label="resources.contents.tabs.general" icon={<Settings />}>
          <TextField source="title" />
          <TextField source="externalId" />
          <StateField />
          <DateField source="created" showTime={true} />
          <DateField source="modified" showTime={true} />
        </Tab>
        <Tab label="resources.contents.tabs.variants" icon={<DeviceHub />}>
          <VariantsDatagrid addLabel={false} />
          <AddVariantButton />
        </Tab>
        <Tab label="pos.fields.metadata" icon={<Code />}>
          <MetadataField source="metadata" addLabel={false} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
