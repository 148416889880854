import {FC} from 'react';
import {DateInput as RaDateInput, DateInputProps} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const DateInput: FC<DateInputProps> = props => (
  <RaDateInput {...props} />
);

DateInput.defaultProps = {
  ...RaDateInput.defaultProps,
  ...COMMON_INPUT_PROPS,
};
