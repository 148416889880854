import {FC} from 'react';
import {
  SelectArrayInput as RaSelectArrayInput,
  SelectArrayInputProps,
} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const SelectArrayInput: FC<SelectArrayInputProps> = props => (
  <RaSelectArrayInput {...props} />
);

SelectArrayInput.defaultProps = {
  ...RaSelectArrayInput.defaultProps,
  ...COMMON_INPUT_PROPS,
};
