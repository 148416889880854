import React, {Component} from 'react';
import {
  BooleanInput,
  Edit,
  FormTab,
  NumberInput,
  ReferenceField,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin';
import {connect} from 'react-redux';
import 'url-search-params-polyfill';
import {v4 as uuid} from 'uuid';

class ProductAccessEdit extends Component {
  render() {
    const props = this.props;
    return (
      <>
        <Edit {...props}>
          <TabbedForm>
            <FormTab label="summary">
              <ReferenceField
                source="accountId"
                reference="accounts"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="product" />
              <TextInput source="apiKey" defaultValue={uuid()} />
            </FormTab>

            <FormTab label="Time settings">
              <BooleanInput label="Time - Resync" source="timeResync" />
              <TextInput label="Time - Url" source="timeUrl" />
              <NumberInput
                label="Time - Resync period (in seconds)"
                step={1}
                source="timeResyncPeriod"
              />
              <NumberInput
                label="Time - Failed resync period (in seconds)"
                step={1}
                source="timeFailedResyncPeriod"
              />
            </FormTab>

            <FormTab label="Checkin settings">
              <TextInput label="Checkin - Url" source="checkinUrl" />
              <NumberInput
                label="Checkin - Frequency (in seconds)"
                step={1}
                source="checkinFrequency"
              />
              <NumberInput
                label="Checkin - Blocked frequency (in seconds)"
                step={1}
                source="checkinBlockedFrequency"
              />
            </FormTab>
          </TabbedForm>
        </Edit>
      </>
    );
  }
}

export default connect(undefined, {})(ProductAccessEdit);
