import {Button} from '@material-ui/core';
import React, {FC} from 'react';
import {
  Edit as ReactAdminEdit,
  EditActionsProps,
  EditProps,
  ShowButton,
  TopToolbar,
  useTranslate,
} from 'react-admin';
import {useHistory} from 'react-router-dom';

const EditActions: FC<IdviuEditActionsProps> = ({basePath, data, cancelTo}) => {
  const translate = useTranslate();
  const history = useHistory();
  return (
    <TopToolbar>
      {cancelTo && cancelTo === 'show' && (
        <ShowButton
          basePath={basePath}
          record={data}
          variant="contained"
          label="pos.button.cancel"
        />
      )}
      {(!cancelTo || cancelTo !== 'show') && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.goBack()}
        >
          {translate('pos.button.cancel')}
        </Button>
      )}
    </TopToolbar>
  );
};

export interface IdviuEditActionsProps extends EditActionsProps {
  cancelTo?: string;
}

const Edit: FC<IdviuEditProps> = ({children, cancelTo, ...props}) => {
  if (!React.isValidElement(children))
    throw new Error('Edit should have one, and only one child');
  return (
    <ReactAdminEdit
      actions={<EditActions {...props} cancelTo={cancelTo} />}
      {...props}
    >
      {children}
    </ReactAdminEdit>
  );
};

export interface IdviuEditProps extends EditProps {
  cancelTo?: string;
}

Edit.defaultProps = {
  undoable: false,
  cancelTo: 'back',
};

export default Edit;
