import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {FC} from 'react';
import {
  Admin,
  resolveBrowserLocale,
  Resource,
  ResourceProps,
} from 'react-admin';
import HttpsRedirect from 'react-https-redirect';
import {BrowserRouter as Router, useHistory} from 'react-router-dom';
import accounts from './accounts';
import aids from './aids';
import {BACKBONE_REST_API_V1_URL} from './app-constants';
import applicationKeys from './applicationKeys';
import catalogs from './catalogs';
import certAccesses from './certAccesses';
import certificates from './certificates';
import companies from './companies';
import constants from './constants';
import contents from './contents';
import credentials from './credentials';
import {Dashboard} from './dashboard';
import dataProvider from './dataProvider';
import authProvider from './dataProvider/authProvider';
import dynamicSettings from './dynamicSettings';
import encodings from './encodings';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import InputFiles from './inputFiles';
import {Layout, Login, Menu} from './layout';
import outputFiles from './outputFiles';
import productAccesses from './productAccesses';
import products from './products';
import realms from './realms';
import {accountReducer, themeReducer} from './reducers';
import customRoutes from './routes';
import serverAccesses from './serverAccesses';
import servers from './servers';
import teamMembers from './teamMembers';
import users from './users';
import variants from './variants';
import virtualEventAccesses from './virtualEventAccesses';
import virtualEvents from './virtualEvents';

const resolveLocale = () => {
  const preference = localStorage.getItem('language');
  if (preference !== null) return preference;
  return resolveBrowserLocale();
};

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'fr') {
    return frenchMessages;
  }
  // Always fallback on english
  return englishMessages;
}, resolveLocale());

const LOGIN_URL = `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/login`;
console.info(`NODE_ENV=${process.env.NODE_ENV}`);
console.info(`PUBLIC_URL=${process.env.PUBLIC_URL}`);
console.info(`BACKBONE_REST_URL=${BACKBONE_REST_API_V1_URL}`);
console.info(`LOGIN_URL=${LOGIN_URL}`);

const RESOURCES: Record<EntityType, Omit<ResourceProps, 'name'> | undefined> = {
  account: accounts,
  aid: aids,
  applicationKey: applicationKeys,
  catalog: catalogs,
  catalogEntry: undefined,
  certAccess: certAccesses,
  certificate: certificates,
  change: undefined,
  cidAccess: undefined,
  company: companies,
  content: contents,
  constant: constants,
  credential: credentials,
  dynamicSetting: dynamicSettings,
  encoding: encodings,
  outputFile: outputFiles,
  inputFile: InputFiles,
  product: products,
  productAccess: productAccesses,
  realm: realms,
  role: undefined,
  roleMapping: undefined,
  server: servers,
  serverAccess: serverAccesses,
  teamMember: teamMembers,
  user: users,
  variant: variants,
  virtualEvent: virtualEvents,
  virtualEventAccess: virtualEventAccesses,
};

const AppBody = () => {
  const history = useHistory();
  return (
    <Admin
      disableTelemetry
      title=""
      dataProvider={dataProvider}
      authProvider={authProvider}
      customReducers={{theme: themeReducer, account: accountReducer}}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      menu={Menu}
      i18nProvider={i18nProvider}
      history={history}
    >
      {() =>
        Object.entries(RESOURCES).map(([entityName, components]) => {
          const name = ENTITY_COLLECTIONS[entityName as EntityType];
          return <Resource key={name} name={name} {...components} />;
        })
      }
    </Admin>
  );
};

const App: FC = () => (
  <HttpsRedirect>
    <Router basename={process.env.PUBLIC_URL}>
      <AppBody />
    </Router>
  </HttpsRedirect>
);

export default App;
