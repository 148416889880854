import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import {stringify} from 'query-string';
import React from 'react';
import {Link} from 'react-router-dom';
import compose from 'recompose/compose';

const styles = {
  icon: {paddingRight: '0.5em'},
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

const EditButton = ({classes, record, location}) => (
  <Button
    size="small"
    color="primary"
    component={Link}
    to={{
      pathname: '/productAccesses/' + record.id,
      search: stringify({
        companyId: record.companyId,
        filter: JSON.stringify({companyId: record.companyId}),
      }),
    }}
    className={classes.link}
  >
    <EditIcon className={classes.icon} />
  </Button>
);

const enhance = compose(withStyles(styles));
export default enhance(EditButton);
