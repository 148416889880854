import {makeStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import {
  CRUD_DELETE,
  Identifier,
  Record as RaRecord,
  useDelete,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'ra-core';
// The RedirectionSideEffect exported by ra-core is not the same than the one
// received as argument of useRedirect.
import {RedirectionSideEffect} from 'ra-core/src/sideEffect/useRedirect';
import React, {useCallback, useState} from 'react';
import {Button, ButtonProps, Confirm} from 'react-admin';

type RaRecordWithId = Omit<RaRecord, 'id'> & {id: Identifier};

export interface DeleteWithConfirmButtonProps extends ButtonProps {
  filterValues?: never;
  selectedIds?: never;
  redirect: RedirectionSideEffect;
  resource: string;
}

type DeleteWithConfirmButtonPrivateProps = Omit<
  DeleteWithConfirmButtonProps,
  'record'
> & {record: RaRecordWithId};

type ButtonPropsToSanitize = Omit<
  DeleteWithConfirmButtonPrivateProps,
  'basePath' | 'classes' | 'record' | 'resource' | 'redirect'
>;

type SanitizedButtonProps = Omit<
  ButtonPropsToSanitize,
  | 'onClick'
  | 'label'
  | 'className'
  | 'filterValues'
  | 'handleSubmit'
  | 'handleSubmitWithRedirect'
  | 'invalid'
  | 'label'
  | 'pristine'
  | 'resource'
  | 'saving'
  | 'selectedIds'
  | 'submitOnEnter'
  | 'redirect'
>;

function sanitizeRestProps({
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  saving,
  selectedIds,
  submitOnEnter,
  ...rest
}: ButtonPropsToSanitize): SanitizedButtonProps {
  return rest;
}

const useStyles = makeStyles(theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export const DeleteWithConfirmButton: React.FC<DeleteWithConfirmButtonProps> =
  props => (
    <>
      {props.record && props.record?.id && (
        <DeleteWithConfirmButtonPrivate {...{...props, record: props.record}} />
      )}
    </>
  );

const DeleteWithConfirmButtonPrivate: React.FC<DeleteWithConfirmButtonPrivateProps> =
  ({
    basePath,
    classes: classesOverride,
    className,
    icon = <ActionDelete />,
    label = 'ra.action.delete',
    onClick,
    record,
    resource,
    redirect: redirectTo = 'list',
    ...rest
  }) => {
    const [open, setOpen] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const classes = useStyles({classes: classesOverride});

    const [deleteOne, {loading}] = useDelete(resource, record.id, record, {
      action: CRUD_DELETE,
      onSuccess: () => {
        notify('ra.notification.deleted', 'info', {smart_count: 1});
        redirect(redirectTo, basePath, record.id, record);
        refresh();
      },
      onFailure: error =>
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        ),
      undoable: false,
    });

    const handleClick = React.useCallback(
      (event: React.MouseEvent) => {
        setOpen(true);
        event.stopPropagation();
      },
      [setOpen],
    );

    const handleDialogClose = React.useCallback(
      (event: React.MouseEvent) => {
        setOpen(false);
        event.stopPropagation();
      },
      [setOpen],
    );

    const handleDelete = useCallback(
      async (event: React.MouseEvent<HTMLButtonElement>) => {
        await deleteOne();
        if (typeof onClick === 'function') {
          onClick(event);
        }
      },
      [deleteOne, onClick],
    );

    return (
      <>
        <Button
          onClick={handleClick}
          label={label}
          className={classnames(
            'ra-delete-button',
            classes.deleteButton,
            className,
          )}
          key="button"
          {...sanitizeRestProps(rest)}
        >
          {icon}
        </Button>
        <Confirm
          isOpen={open}
          loading={loading}
          title="ra.message.delete_title"
          content="ra.message.delete_content"
          translateOptions={{
            name: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true,
            ),
            id: record.id,
          }}
          onConfirm={handleDelete}
          onClose={handleDialogClose}
        />
      </>
    );
  };
