import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useTranslate} from 'ra-core';
import {useState} from 'react';
import {Field, withTypes} from 'react-final-form';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));

const renderInput = ({
  meta: {touched, error} = {touched: false, error: undefined},
  input: {...inputProps},
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  newPassword?: string;
  password?: string;
  password2?: string;
}

const {Form} = withTypes<FormValues>();

const ResetPasswordForm = (props: any) => {
  const {onSubmit} = props;
  const [loading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.newPassword) {
      errors.newPassword = translate('ra.validation.required');
    }
    if (!values.password2) {
      errors.password2 = translate('ra.validation.required');
    }
    if (values.password2 !== values.newPassword) {
      errors.password2 = translate('pos.desc.password_dont_match');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.form}>
            <div>{translate('pos.desc.enter_new_password')}</div>
            <div className={classes.input}>
              <Field
                name="newPassword"
                // @ts-ignore
                component={renderInput}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                name="password2"
                // @ts-ignore
                component={renderInput}
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('pos.button.save_password')}
            </Button>
          </CardActions>
          <CardActions className={classes.actions}>
            <Button
              style={{fontSize: '0.7em'}}
              size="small"
              variant="outlined"
              // @ts-ignore
              component={Link}
              to={{
                pathname: '/login',
                search: '',
              }}
              fullWidth
            >
              {translate('pos.button.cancel')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};
export default ResetPasswordForm;
