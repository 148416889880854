import ServerIcon from '@material-ui/icons/Domain';
import {ResourceProps} from 'ra-core';
import ServerCreate from './ServerCreate';
import ServerEdit from './ServerEdit';
import ServerList from './ServerList';
import ServerShow from './ServerShow';

const content: Required<
  Pick<ResourceProps, 'icon' | 'list' | 'show' | 'edit' | 'create'>
> = {
  icon: ServerIcon,
  list: ServerList,
  show: ServerShow,
  edit: ServerEdit,
  create: ServerCreate,
};

export default content;
