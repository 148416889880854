import {notNil} from '@idviu/ts-helpers';
import {get} from 'lodash';
import {FC} from 'react';
import {
  FieldProps,
  FunctionField,
  Record as RaRecord,
  useTranslate,
} from 'react-admin';

export interface EnumFieldProps extends FieldProps {
  labelPath: string;
}

export const EnumField: FC<EnumFieldProps> = props => {
  const tr = useTranslate();
  const {source, labelPath} = props;
  notNil(source);
  return (
    <FunctionField
      render={(record?: RaRecord) => {
        notNil(record);
        const value = get(record, source);
        return tr(`${labelPath}.${value}`);
      }}
    />
  );
};

EnumField.defaultProps = {addLabel: true};
