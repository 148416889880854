import CertAccessIcon from '@material-ui/icons/Layers';
import CertAccessEdit from './CertAccessEdit';
import CertAccessList from './CertAccessList';

const content = {
  icon: CertAccessIcon,
  list: CertAccessList,
  edit: CertAccessEdit,
};

export default content;
