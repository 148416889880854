import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import ActionCheck from '@material-ui/icons/Check';
import {FC, MouseEvent, MouseEventHandler, useCallback, useState} from 'react';
import {useTranslate} from 'react-admin';

export interface ConfirmProps {
  isOpen: boolean;
  loading: boolean;
  title: string;
  content: string;
  confirm?: string;
  confirmCode?: string;
  cancel?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onConfirm: (event: MouseEvent<HTMLButtonElement>, comment: string) => void;
}

/**
 * Confirmation dialog
 *
 * @example
 * ```tsx
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 * ```
 */
const Confirm: FC<ConfirmProps> = ({
  isOpen = false,
  loading,
  title,
  content,
  confirm,
  cancel,
  onClose,
  onConfirm,
}) => {
  // const translate = useTranslate();

  const [comment, setComment] = useState('');

  const translate = useTranslate();

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = event => {
    event.stopPropagation();
    onConfirm(event, comment);
  };

  const handleClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="_edit"
          label={translate('resources.changes.fields.comment')}
          type="text"
          onChange={e => {
            setComment(e.target.value);
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          <CancelIcon />
          {cancel}
        </Button>
        <Button disabled={loading} onClick={handleConfirm} autoFocus>
          <ActionCheck />
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
