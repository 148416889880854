import {Typography} from '@material-ui/core';
import {Email, Security, Settings} from '@material-ui/icons';
import {FC} from 'react';
import {
  BooleanField,
  NumberField,
  ResourceComponentPropsWithId,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import {EmailPasswordField} from './EmailPasswordField';
import {PrincipalField} from './PrincipalField';
import {SecurityPasswordHashField} from './SecurityPasswordHashField';
import {SecuritySingleSessionField} from './SecuritySingleSessionField';
import {SecurityUseMfaField} from './SecurityUseMfaField';

function useHasEmail(): boolean {
  const record = useRecordContext();
  return typeof record?.email === 'object';
}

const NoCustomEmailField: FC = () => {
  const tr = useTranslate();
  const hasEmail = useHasEmail();
  return (
    <>
      {!hasEmail && (
        <Typography>{tr('resources.realms.fields.noCustomEmail')}</Typography>
      )}
    </>
  );
};

const EmailTab: FC = props => {
  const hasEmail = useHasEmail();
  return (
    <Tab {...props} label="resources.realms.tabs.email" icon={<Email />}>
      {!hasEmail && <NoCustomEmailField />}
      {hasEmail && <TextField source="email.host" />}
      {hasEmail && <NumberField source="email.port" />}
      {hasEmail && <TextField source="email.username" />}
      {hasEmail && <EmailPasswordField />}
      {hasEmail && <BooleanField source="email.secure" />}
      {hasEmail && <BooleanField source="email.selfSignedCertificate" />}
      {hasEmail && <TextField source="email.senderName" />}
      {hasEmail && <TextField source="email.senderEmail" />}
    </Tab>
  );
};

EmailTab.defaultProps = {
  path: 'email',
};

function useHasWsmp(): boolean {
  const record = useRecordContext();
  return typeof record?.wsmp === 'object';
}

const NoCustomWsmpField: FC = () => {
  const tr = useTranslate();
  const hasWsmp = useHasWsmp();
  return (
    <>
      {!hasWsmp && (
        <Typography>{tr('resources.realms.fields.noWsmp')}</Typography>
      )}
    </>
  );
};

const WsmpTab: FC = props => {
  const hasWsmp = useHasWsmp();
  return (
    <Tab {...props} label="resources.realms.tabs.wsmp" icon={<Email />}>
      {!hasWsmp && <NoCustomWsmpField />}
      {hasWsmp && <TextField source="wsmp.serverUrl" />}
      {hasWsmp && <TextField source="wsmp.service" />}
      {hasWsmp && <TextField source="wsmp.aid" />}
      {hasWsmp && <TextField source="wsmp.sig" />}
      {hasWsmp && <TextField source="wsmp.account" />}
    </Tab>
  );
};

WsmpTab.defaultProps = {
  path: 'wsmp',
};

export const RealmShow: FC<ResourceComponentPropsWithId> = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="resources.realms.tabs.general" icon={<Settings />}>
        <TextField source="id" />
        <TextField source="name" />
        <PrincipalField />
      </Tab>
      <Tab
        path="security"
        label="resources.realms.tabs.security"
        icon={<Security />}
      >
        <SecurityPasswordHashField />
        <NumberField source="security.passwordLength" />
        <BooleanField source="security.passwordWithNumbers" />
        <BooleanField source="security.passwordWithSymbols" />
        <BooleanField source="security.passwordWithUppercase" />
        <BooleanField source="security.passwordWithLowercase" />
        <TextField source="security.validateEmailUrl" />
        <TextField source="security.resetPasswordUrl" />
        <SecuritySingleSessionField />
        <SecurityUseMfaField />
      </Tab>
      <EmailTab />
      <WsmpTab />
    </TabbedShowLayout>
  </Show>
);
