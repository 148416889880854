import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/styles';
import {useDataProvider} from 'ra-core';
import React from 'react';
import {refreshView, showNotification} from 'react-admin';
import {useDispatch} from 'react-redux';

const useStyle = makeStyles({
  button: {
    float: 'right',
  },
});

const DeleteButton = props => {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const classes = useStyle();

  const handleClick = async () => {
    await dataProvider
      .delete('teamMembers', {id: props.record.id})
      .then(() => {
        dispatch(refreshView());
      })
      .catch(e => {
        dispatch(showNotification('Error: could not remove user', 'warning'));
      });
  };

  return (
    <Button
      label="Delete"
      className={classes.button}
      color="secondary"
      onClick={handleClick}
    >
      <DeleteIcon />
    </Button>
  );
};

export default DeleteButton;
