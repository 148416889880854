import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {makeStyles} from '@material-ui/core';
import {ReferenceInput} from 'ra-ui-materialui/lib/input';
import {FC} from 'react';
import {
  Datagrid,
  Filter,
  ReferenceField,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import {List} from '../components';

//import LinkToRelatedAccounts from './LinkToRelatedAccounts'; // mktodo : deprecated, supprimer tous les fichiers

const useStyle = makeStyles(() => ({
  headerRow: {
    borderLeftColor: 'white',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const AccountFilter: FC = props => {
  const {...otherProps} = props;

  return (
    <Filter {...otherProps} variant="outlined">
      <TextInput label="Name" source="qname" alwaysOn={true} />
      <ReferenceInput
        label="Company"
        source="companyId"
        reference={ENTITY_COLLECTIONS.company}
        sort={{field: 'name', order: 'ASC'}}
      >
        <SelectInput source="Name" />
      </ReferenceInput>
    </Filter>
  );
};

const AccountList: FC = props => {
  const classes = useStyle();
  const {loaded, permissions} = usePermissions();
  return (
    <List
      {...props}
      title="pos.menu.accounts"
      perPage={25}
      filters={<AccountFilter />}
      canCreate={loaded && permissions[0]?.isAdmin()}
      canDelete={loaded && permissions[0]?.isSuperAdmin()}
      sort={{field: 'name', order: 'ASC'}}
    >
      <Datagrid classes={{headerRow: classes.headerRow}} {...props}>
        <TextField source="name" label="name" />
        <ReferenceField
          label="Company"
          source="companyId"
          reference={ENTITY_COLLECTIONS.company}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="id" />
        <ShowButton label="settings" />
      </Datagrid>
    </List>
  );
};

export default AccountList;
