import {isNil, notNil} from '@idviu/ts-helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {get} from 'lodash';
import {FC} from 'react';
import {FieldProps, useRecordContext, useTranslate} from 'react-admin';

const Header: FC = () => {
  const tr = useTranslate();
  return (
    <TableHead>
      <TableRow>
        <TableCell>{tr('pos.fields.name')}</TableCell>
        <TableCell>{tr('pos.fields.type')}</TableCell>
        <TableCell>{tr('pos.fields.value')}</TableCell>
      </TableRow>
    </TableHead>
  );
};

const ValueCell: FC<{value: unknown}> = ({value}) => {
  const tr = useTranslate();
  switch (typeof value) {
    case 'string':
    case 'number':
      return <TableCell>{value}</TableCell>;
    case 'boolean':
      return (
        <TableCell>
          {isNil(value)
            ? 'undefined'
            : value
            ? tr('pos.boolean.true')
            : tr('pos.boolean.true')}
        </TableCell>
      );
    default:
      return <TableCell>{JSON.stringify(value)}</TableCell>;
  }
};

const Body: FC<{metadata: Record<string, unknown>}> = ({metadata}) => (
  <TableBody>
    {Object.entries(metadata).map(([name, value]) => (
      <TableRow key={name}>
        <TableCell>{name}</TableCell>
        <TableCell>{typeof value}</TableCell>
        <ValueCell value={value} />
      </TableRow>
    ))}
  </TableBody>
);

export const MetadataField: FC<FieldProps> = props => {
  const {source} = props;
  notNil(source);
  const record = useRecordContext(props);
  const metadata = get(record, source);
  return (
    <Table>
      <Header />
      <Body metadata={metadata} />
    </Table>
  );
};

MetadataField.defaultProps = {
  addLabel: true,
  label: 'pos.fields.metadata',
};
