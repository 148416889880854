import {ENTITY_COLLECTIONS, TeamMember} from '@idviu/backbone-api-client';
import {isNil} from '@idviu/ts-helpers';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/styles';
import {useDataProvider} from 'ra-core';
import {FC} from 'react';
import {
  FieldProps,
  refreshView,
  showNotification,
  useRecordContext,
} from 'react-admin';
import {useDispatch} from 'react-redux';
import {RoleMapping} from '../backbone-records';
import {useHasRealm, useTeamMember} from '../resource-hooks';

const useStyle = makeStyles({
  button: {
    float: 'right',
  },
});

const DeleteButton: FC<FieldProps<RoleMapping>> = ({record}) => {
  const classes = useStyle();
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const handleClick = async () => {
    if (!record || !record.id) return;
    await dataProvider
      .delete(ENTITY_COLLECTIONS.roleMapping, {
        id: record.id,
        previousData: record,
      })
      .then(() => {
        dispatch(refreshView());
      })
      .catch(() => {
        dispatch(showNotification('Error: could not remove role', 'warning'));
      });
  };
  return (
    <Button className={classes.button} color="secondary" onClick={handleClick}>
      <DeleteIcon />
    </Button>
  );
};

const DeleteRoleButtonWithRealm: FC<{teamMember: TeamMember}> = ({
  teamMember,
  ...props
}) => {
  const hasRealm = useHasRealm(teamMember.userId);
  return <>{hasRealm && <DeleteButton {...props} />}</>;
};

const DeleteRoleButton: FC<FieldProps<RoleMapping>> = props => {
  const roleMapping = useRecordContext(props);
  const teamMember = useTeamMember(roleMapping.principalId);
  return (
    <>
      {!isNil(teamMember) && (
        <DeleteRoleButtonWithRealm teamMember={teamMember} {...props} />
      )}
    </>
  );
};

export default DeleteRoleButton;
