import {EntityType, ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {isNil} from '@idviu/ts-helpers';
import {Typography} from '@material-ui/core';
import React, {FC, useCallback} from 'react';
import {
  Create,
  CreateProps,
  FormDataConsumer,
  Record,
  required,
  useGetOne,
  usePermissions,
  useTranslate,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {
  PleaseSelectAccount,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useCurrentAccount,
} from '../components';
import {RealmInput} from './RealmInput';

const FormTypography: FC = ({children}) => {
  return (
    <Typography>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) return child;
        else return React.cloneElement(child);
      })}
    </Typography>
  );
};

enum TeamMemberType {
  frontend = 'frontend',
  backend = 'backend',
}

const TYPE_CHOICES = [
  {
    id: TeamMemberType.frontend,
    name: 'resources.teamMembers.type.frontend',
  },
  {
    id: TeamMemberType.backend,
    name: 'resources.teamMembers.type.backend',
  },
];

const SCOPE_CHOICES = [
  {
    id: EntityType.account,
    name: 'resources.teamMembers.scope.account',
  },
  {
    id: EntityType.company,
    name: 'resources.teamMembers.scope.company',
  },
];

const Email: FC = () => {
  const tr = useTranslate();
  return (
    <>
      <FormTypography>
        {tr('resources.teamMembers.desc.enterEmail')}
      </FormTypography>
      <TextInput
        source="_email"
        label="resources.users.fields.email"
        validate={required()}
      />
    </>
  );
};

const Name: FC = () => {
  const tr = useTranslate();
  return (
    <>
      <FormTypography>{tr('resources.teamMembers.desc.name')}</FormTypography>
      <TextInput source="_firstName" label="resources.users.fields.firstName" />
      <TextInput source="_lastName" label="resources.users.fields.lastName" />
    </>
  );
};

const Type: FC = () => {
  const tr = useTranslate();
  return (
    <>
      <FormTypography>{tr('resources.teamMembers.desc.type')}</FormTypography>
      <RadioButtonGroupInput
        row
        defaultValue={TeamMemberType.frontend}
        label="resources.teamMembers.fields.type"
        source="type"
        choices={TYPE_CHOICES}
      />
    </>
  );
};

const Realm: FC = () => {
  const tr = useTranslate();
  return (
    <>
      <FormTypography>{tr('resources.teamMembers.desc.realm')}</FormTypography>
      <RealmInput source="realmId" autoDisable />
    </>
  );
};

const Scope: FC = () => {
  const tr = useTranslate();
  return (
    <>
      <FormTypography>{tr('resources.teamMembers.desc.scope')}</FormTypography>
      <RadioButtonGroupInput
        row
        defaultValue={EntityType.account}
        label="resources.teamMembers.fields.scope"
        source="principalType"
        choices={SCOPE_CHOICES}
      />
    </>
  );
};

const Role: FC = () => {
  const tr = useTranslate();
  const allowedRoles = ['admin', 'operator', 'developer', 'viewer'];
  const roleChoices = allowedRoles.map(r => ({
    id: r,
    name: 'resources.roles.fields.' + r,
  }));
  return (
    <>
      <FormTypography>
        {tr('resources.teamMembers.desc.chooseRole')}
      </FormTypography>
      <RadioButtonGroupInput
        row={false}
        source="role"
        optionText="name"
        choices={roleChoices}
      />
    </>
  );
};

const MaybeWsmpService: FC = () => {
  const form = useFormState();
  const {data: realm} = useGetOne(
    ENTITY_COLLECTIONS.realm,
    form.values.realmId,
    {
      enabled: !!form.values.realmId,
    },
  );
  const selectService = !!realm?.wsmp?.allowedServices;
  return selectService ? (
    <SelectInput
      label="resources.teamMembers.fields.service"
      source="_metadata.wsmpService"
      choices={
        realm?.wsmp?.allowedServices?.map((t: string) => ({id: t, name: t})) ??
        []
      }
      required
    />
  ) : (
    <span />
  );
};

const TeamMembersCreate: FC<CreateProps> = props => {
  const tr = useTranslate();
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  const transform = useCallback(
    (data: Record) => {
      if (isNil(account)) throw new Error('No project selected');
      if (!isNil(data.role) && data.type === TeamMemberType.backend) {
        data._roles = [data.role];
      } else if (data.type === TeamMemberType.frontend) {
        data._roles = ['viewer'];
      }
      delete data.role;
      if (!isNil(data.realmId)) {
        if (data.type === TeamMemberType.frontend) {
          data._realmId = data.realmId;
        }
        delete data.realmId;
      }
      delete data.type;
      if (!data.principalType) {
        data.principalType = EntityType.account;
      }
      if (data.principalType === EntityType.account) {
        data.principalId = account.id;
      } else if (data.principalType === EntityType.company) {
        data.principalId = account.companyId;
      }
      data._addedToAccountTitle = tr('emails.addedToProjectTitle');
      data._addedToAccountNewUser = tr('emails.addedToProjectNewUser');
      data._addedToAccount = tr('emails.addedToProject');
      data._addedToCompanyTitle = tr('emails.addedToCompanyTitle');
      data._addedToCompanyNewUser = tr('emails.addedToCompanyNewUser');
      data._addedToCompany = tr('emails.addedToCompany');
      data._href =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '#/login'; //our login page

      return data;
    },
    [account, tr],
  );

  return (
    <>
      {account ? (
        <Create {...props} transform={transform}>
          <SimpleForm redirect="list">
            <Email />
            <Name />
            <Type />
            <FormDataConsumer>
              {({formData}) =>
                formData?.type === TeamMemberType.frontend && (
                  <>
                    <Realm />
                    <MaybeWsmpService />
                  </>
                )
              }
            </FormDataConsumer>
            {loaded &&
              permissions[0].canModifyCompanyUsers(
                account ? account.companyId : undefined,
              ) && <Scope />}
            <FormDataConsumer>
              {({formData}) =>
                formData?.type === TeamMemberType.backend && <Role />
              }
            </FormDataConsumer>
          </SimpleForm>
        </Create>
      ) : (
        <PleaseSelectAccount />
      )}
    </>
  );
};

export default TeamMembersCreate;
