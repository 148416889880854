import {FC} from 'react';
import {TextInput as RaTextInput, TextInputProps} from 'react-admin';
import {COMMON_INPUT_PROPS} from './common';

export const TextInput: FC<TextInputProps> = props => (
  <RaTextInput {...props} />
);

TextInput.defaultProps = {
  ...RaTextInput.defaultProps,
  ...COMMON_INPUT_PROPS,
  variant: 'outlined',
};
