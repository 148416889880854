import React from 'react';
import {Datagrid, TextField} from 'react-admin';
import {ShowButton} from 'react-admin';

const CompanyListDesktop = ({classes, ...props}) => (
  <Datagrid {...props}>
    <TextField source="name" />
    <TextField source="id" />
    <ShowButton />
  </Datagrid>
);

export default CompanyListDesktop;
