import {
  DynamicSetting,
  EntityType,
  ENTITY_COLLECTIONS,
} from '@idviu/backbone-api-client';
import {Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {makeStyles} from '@material-ui/styles';
import {useDataProvider, useQuery, useTranslate} from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import React, {FC, useCallback, useState} from 'react';
import {
  ArrayInput,
  BooleanInput,
  Button,
  fetchEnd,
  fetchStart,
  FieldProps,
  FormDataConsumer,
  NumberInput,
  refreshView,
  SaveButton,
  SelectInput,
  showNotification,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import {useFormState} from 'react-final-form';
import {useDispatch} from 'react-redux';
import {SimpleForm} from '../components';
import {ProductAccess} from '../types';

const useStyle = makeStyles({
  button: {
    marginTop: '1em',
    marginBottom: '2em',
  },
  formIterator: {
    paddingTop: 20,
  },
});

// const requiredSettingKey = (message = 'Please enter a setting key') =>
//     (value: any) => value ? undefined : message;

const requiredSettingValue =
  (message = 'Please enter a setting value') =>
  (value: any) =>
    value !== null && value !== undefined ? undefined : message;

const requiredKey =
  (message = 'Please enter a key that will be used to apply the rule') =>
  (value: any) =>
    value ? undefined : message;

const requiredOperator =
  (
    message = 'Please select an operator that will be used to compare the key with the value',
  ) =>
  (value: any) =>
    value ? undefined : message;

const requiredValue =
  (message = 'Please enter a value that will be used to apply the rule') =>
  (value: any) =>
    value !== null && value !== undefined ? undefined : message;

const MySaveButton: FC<SaveButtonFieldProps> = ({
  onComplete,
  productAccess,
  type,
}) => {
  const formState = useFormState();
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();

  const handleSaveClick = useCallback(() => {
    if (!formState.valid) {
      return;
    }

    // Dispatch an action letting react-admin know a API call is ongoing
    dispatch(fetchStart());

    const {name, description, key, value, rules} = formState.values;
    const data: DynamicSetting = {
      type,
      principalType: EntityType.productAccess,
      principalId: productAccess.id.toString(),
      name,
      description,
      key,
      value,
      rules,
    };
    dataProvider
      .create(ENTITY_COLLECTIONS.dynamicSetting, {data})
      .catch(error => {
        dispatch(showNotification(error.message, 'error'));
      })
      .then(() => {
        onComplete(false);
        dispatch(refreshView());
      })
      .finally(() => {
        // Dispatch an action letting react-admin know a API call has ended
        dispatch(fetchEnd());
      });
  }, [
    formState.valid,
    formState.values,
    dataProvider,
    dispatch,
    onComplete,
    productAccess.id,
    type,
  ]);

  return (
    <SaveButton
      handleSubmitWithRedirect={handleSaveClick}
      label="ra.action.save"
    />
  );
};

export interface SaveButtonFieldProps extends FieldProps<ProductAccess> {
  onComplete: (value: boolean) => void;
  productAccess: ProductAccess;
  type: any;
}

const FormActions = ({
  setShowDialog,
  handleCloseClick,
  productAccess,
  type,
}: {
  basePath: string | undefined;
  setShowDialog: any;
  handleCloseClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  productAccess: any;
  type: any;
}) => (
  <DialogActions>
    <MySaveButton
      productAccess={productAccess}
      type={type}
      onComplete={setShowDialog}
    />
    <Button label="ra.action.cancel" onClick={handleCloseClick}>
      <IconCancel />
    </Button>
  </DialogActions>
);

const AddNewRuleButton: FC<AddRuleFieldProps> = ({record, type, basePath}) => {
  const {formIterator, ...classes} = useStyle();
  const [showDialog, setShowDialog] = useState(false);
  const product = record ? record.product : '';
  const translate = useTranslate();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const {data: constants} = useQuery({
    type: 'getList',
    resource: ENTITY_COLLECTIONS.constant,
    payload: {
      pagination: {page: 1, perPage: 1000},
      sort: {id: 'name', order: 'ASC'},
      filter: {type: type, products: product},
    },
  });

  return record ? (
    <>
      <Button
        classes={classes}
        onClick={handleClick}
        label="pos.button.add_rule"
        variant="contained"
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Add new rule"
      >
        <DialogTitle>{translate('pos.title.new_rule')}</DialogTitle>
        <DialogContent>
          <SimpleForm
            resource="rules"
            canDelete={false}
            // We want no toolbar at all as we have our modal actions
            noToolbar={true}
          >
            <Typography>
              {translate('resources.rules.desc.choose_name')}
            </Typography>
            <TextInput source="name" />
            <RichTextInput
              source="description"
              toolbar={[['bold', 'italic', 'underline', 'link']]}
            />
            {type && !type.startsWith('blocked') && (
              <Typography>
                {translate('resources.rules.desc.choose_parameter')}
              </Typography>
            )}
            {type && !type.startsWith('blocked') && (
              <SelectInput
                source="key"
                choices={
                  constants
                    ? constants.map((c: any) => {
                        return {id: c.value, name: c.name};
                      })
                    : []
                }
              />
            )}
            <FormDataConsumer>
              {({formData, ...rest}) =>
                formData &&
                formData.key &&
                constants.filter((c: any) => c.value === formData.key)[0]
                  .valueType === 'boolean' && (
                  <BooleanInput
                    source="value"
                    {...rest}
                    validate={requiredSettingValue()}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({formData, ...rest}) =>
                formData &&
                formData.key &&
                constants.filter((c: any) => c.value === formData.key)[0]
                  .valueType === 'number' && (
                  <NumberInput
                    source="value"
                    {...rest}
                    validate={requiredSettingValue()}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({formData, ...rest}) =>
                formData &&
                formData.key &&
                constants.filter((c: any) => c.value === formData.key)[0]
                  .valueType === 'string' && (
                  <TextInput
                    source="value"
                    {...rest}
                    validate={requiredSettingValue()}
                  />
                )
              }
            </FormDataConsumer>

            <ArrayInput source="rules" defaultValue={[]}>
              <SimpleFormIterator className={formIterator}>
                <SelectInput
                  source="key"
                  label="resources.rules.fields.filter_key"
                  choices={[
                    {id: 'device_manufacturer', name: 'Device Manufacturer'},
                    {id: 'device_model', name: 'Device Model'},
                    {id: 'device_id', name: 'Device ID'},
                    {id: 'hashed_device_id', name: 'SHA1(Device ID)'},
                    {id: 'device_a', name: 'device A'},
                    {id: 'os_version', name: 'OS Version'},
                    {id: 'os_build', name: 'OS Build'},
                    {id: 'rom_id', name: 'ROM ID'},
                    {id: 'app_id', name: 'App package name'},
                    {id: 'app_version', name: 'App version'},
                  ]}
                  validate={requiredKey()}
                />
                <SelectInput
                  source="operator"
                  label="resources.rules.fields.filter_operator"
                  choices={[
                    {id: 'eq', name: 'Equals'},
                    {id: 'eqi', name: 'Equals Ignore Case'},
                    {id: 'neq', name: 'Not Equals'},
                    {id: 'neqi', name: 'Not Equals Ignore Case'},
                    {id: 'lt', name: 'Less Than'},
                    {id: 'lte', name: 'Less Than Or Equal'},
                    {id: 'gt', name: 'Greater Than'},
                    {id: 'gte', name: 'Greater Than Or Equal'},
                    {id: 'regexp', name: 'Like (regexp)'},
                    {id: 'oneof', name: 'One Of (comma separated)'},
                    {
                      id: 'oneofi',
                      name: 'One Of Ignore Case (comma separated)',
                    },
                    {id: 'noneof', name: 'Not One Of (comma separated)'},
                    {
                      id: 'noneofi',
                      name: 'Not One Of Ignore Case (comma separated)',
                    },
                  ]}
                  validate={requiredOperator()}
                />
                <TextInput
                  source="value"
                  label="resources.rules.fields.filter_value"
                  validate={requiredValue()}
                />
              </SimpleFormIterator>
            </ArrayInput>

            <FormActions
              basePath={basePath}
              productAccess={record}
              type={type}
              setShowDialog={setShowDialog}
              handleCloseClick={handleCloseClick}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <div />
  );
};

export interface AddRuleFieldProps extends FieldProps<ProductAccess> {
  type?: any;
}

export default AddNewRuleButton;
