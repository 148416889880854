import {ENTITY_COLLECTIONS, RoleMapping} from '@idviu/backbone-api-client';
import {notNil} from '@idviu/ts-helpers';
import {Button} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/styles';
import {useDataProvider} from 'ra-core';
import {FC} from 'react';
import {Record as RaRecord, refreshView, showNotification} from 'react-admin';
import {useDispatch} from 'react-redux';

const useStyle = makeStyles({
  button: {
    float: 'right',
  },
});

function useOnClick(record: (RoleMapping & RaRecord) | undefined) {
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const handleClick = async () => {
    notNil(record);
    try {
      await dataProvider.delete(ENTITY_COLLECTIONS.roleMapping, {
        id: record.id,
        previousData: record,
      });
      dispatch(refreshView());
    } catch {
      dispatch(showNotification('Error: could not remove role', 'warning'));
    }
  };
  return handleClick;
}

const DeleteButton: FC<{record?: RoleMapping & RaRecord}> = ({record}) => {
  const classes = useStyle();
  const onClick = useOnClick(record);
  return (
    <Button className={classes.button} color="secondary" onClick={onClick}>
      <DeleteIcon />
    </Button>
  );
};

export default DeleteButton;
