import {
  ENTITY_COLLECTIONS,
  RealmPrincipalType,
} from '@idviu/backbone-api-client';
import {FC} from 'react';
import {ReferenceInput, SelectInput} from 'react-admin';
import {useFormState} from 'react-final-form';

export const PrincipalIdInput: FC = props => {
  const {
    values: {principalType},
  } = useFormState();
  return (
    <ReferenceInput
      {...props}
      source="principalId"
      label={`pos.principal.${principalType}`}
      reference={ENTITY_COLLECTIONS[principalType as RealmPrincipalType]}
      perPage={-1}
      sort={{field: 'name', order: 'ASC'}}
      required
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  );
};

PrincipalIdInput.defaultProps = {
  fullWidth: true,
};
