import {ENTITY_COLLECTIONS, IChange} from '@idviu/backbone-api-client';
import {isNil, isObject, notNil} from '@idviu/ts-helpers';
import {ObjectDiff} from '@idviu/types-core';
import {Box, TableCell, TableCellProps, TableRow} from '@material-ui/core';
import {get} from 'lodash';
import {Identifier, Record as RaRecord} from 'ra-core';
import {CSSProperties, FC} from 'react';
import {
  DatagridBody,
  DateField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import {Datagrid, User} from '../components';
export interface ChangesProps {
  principalType: string;
  principalId: Identifier;
}

type ChangeOperation = 'added' | 'removed' | 'modified';

const PROPERTY_CELL_STYLE: CSSProperties = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: '.5em',
  paddingRight: '.5em',
};

const PropertyCell: FC<TableCellProps> = ({style, ...props}) => (
  <TableCell {...props} style={{...style, ...PROPERTY_CELL_STYLE}} />
);

const ValueCell: FC = ({children}) => (
  <PropertyCell style={{fontFamily: 'monospace', textAlign: 'center'}}>
    {children}
  </PropertyCell>
);

const AddedValue: FC<PropertyChangeProps> = ({operation, newValue}) => (
  <ValueCell>
    <Box color="success.main">
      {(operation === 'added' || operation === 'modified') && (
        <>{JSON.stringify(newValue)}</>
      )}
    </Box>
  </ValueCell>
);

const RemovedValue: FC<PropertyChangeProps> = ({operation, oldValue}) => (
  <ValueCell>
    <Box color="error.main">
      {(operation === 'removed' || operation === 'modified') && (
        <>{JSON.stringify(oldValue)}</>
      )}
    </Box>
  </ValueCell>
);

const PropertyPath: FC<PropertyChangeProps> = ({path}) => (
  <PropertyCell style={{fontFamily: 'monospace'}}>{path}</PropertyCell>
);

const PropertyChange: FC<PropertyChangeProps> = props => (
  <>
    <PropertyPath {...props} />
    <RemovedValue {...props} />
    <AddedValue {...props} />
  </>
);

interface PropertyChangeProps {
  path: string;
  operation: ChangeOperation;
  newValue?: unknown;
  oldValue?: unknown;
}

function propertyPath(path: string | undefined, key: string) {
  return isNil(path) ? key : `${path}.${key}`;
}

function flattenPropertyChange(
  diff: ObjectDiff,
  path?: string,
): {path: string; value?: unknown}[] {
  if (isObject(diff)) {
    const entries = Object.entries(diff);
    return entries
      .map(([key, value]) => {
        const newPath = propertyPath(path, key);
        return flattenPropertyChange(value, newPath);
      })
      .flat();
  } else {
    notNil(path);
    return [{path, value: diff}];
  }
}

function getAdditions(change: IChange): PropertyChangeProps[] {
  return isNil(change.added)
    ? []
    : flattenPropertyChange(change.added).map(({path, value}) => ({
        path,
        newValue: value,
        operation: 'added',
      }));
}

function getRemovals(change: IChange): PropertyChangeProps[] {
  return isNil(change.removed)
    ? []
    : flattenPropertyChange(change.removed).map(({path, value}) => ({
        path,
        oldValue: value,
        operation: 'removed',
      }));
}

function getModifications(change: IChange): PropertyChangeProps[] {
  const newModifications =
    isNil(change.modified) || isNil(change.modified.new)
      ? []
      : flattenPropertyChange(change.modified.new);
  return newModifications.map(({path, value}) => ({
    path,
    newValue: value,
    oldValue: get(change.modified?.old, path),
    operation: 'modified',
  }));
}

function flattenChange(change: IChange): PropertyChangeProps[] {
  const additions = getAdditions(change);
  const removals = getRemovals(change);
  const modifications = getModifications(change);
  return additions.concat(removals, modifications);
}

const ChangesRow: FC<{record?: RaRecord & IChange; label?: string}> = ({
  record: change,
}) => {
  notNil(change);
  const rows = isNil(change) ? [] : flattenChange(change);
  const rowSpan = rows.length > 0 ? rows.length : undefined;
  const [firstRow, ...restRows] = rows;
  return (
    <>
      <TableRow>
        <TableCell style={{verticalAlign: 'top'}} rowSpan={rowSpan}>
          <DateField record={change} source="on" showTime={true} />
        </TableCell>
        <TableCell style={{verticalAlign: 'top'}} rowSpan={rowSpan}>
          <ReferenceField
            record={change}
            source="by"
            reference={ENTITY_COLLECTIONS.user}
            link={false}
          >
            <User />
          </ReferenceField>
        </TableCell>
        <TableCell style={{verticalAlign: 'top'}} rowSpan={rowSpan}>
          <TextField record={change} source="comment" />
        </TableCell>
        {!isNil(firstRow) && (
          <PropertyChange key={`${change.id}${firstRow.path}`} {...firstRow} />
        )}
      </TableRow>
      {restRows.map(({path, operation, newValue: value, oldValue}) => (
        <TableRow key={`${change.id}${path}`}>
          <PropertyChange
            path={path}
            operation={operation}
            newValue={value}
            oldValue={oldValue}
          />
        </TableRow>
      ))}
    </>
  );
};

const ChangesDatagridBody: FC = props => (
  <DatagridBody {...props} row={<ChangesRow />} />
);

export const Changes: FC<ChangesProps> = ({principalType, principalId}) => (
  <Labeled label="resources.changes.lastModifications">
    <ReferenceManyField
      reference={ENTITY_COLLECTIONS.change}
      sortBy="on"
      sortByOrder="DESC"
      target={''}
      filter={{
        principalType,
        principalId,
      }}
    >
      <Datagrid body={<ChangesDatagridBody />} />
    </ReferenceManyField>
  </Labeled>
);
