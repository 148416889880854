import CertificateIcon from '@material-ui/icons/Layers';
import CertificateCreate from './CertificateCreate';
import CertificateEdit from './CertificateEdit';
import CertificateList from './CertificateList';

const content = {
  icon: CertificateIcon,
  list: CertificateList,
  create: CertificateCreate,
  edit: CertificateEdit,
};

export default content;
