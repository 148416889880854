import {ChangeEvent, Component} from 'react';

export interface FieldFileInputProps {
  input: {onChange: (file?: File) => void};
}

export default class FieldFileInput extends Component<FieldFileInputProps> {
  constructor(props: FieldFileInputProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: ChangeEvent<HTMLInputElement>) {
    const {
      input: {onChange},
    } = this.props;
    onChange(event.target.files?.[0]);
  }

  render() {
    return (
      <div style={{marginTop: '0.5em'}}>
        <input type="file" accept=".key" onChange={this.onChange} />
      </div>
    );
  }
}
