import {FC} from 'react';
import {BooleanField, useTranslate} from 'react-admin';

export const SecuritySingleSessionField: FC = props => {
  const tr = useTranslate();
  return <BooleanField {...props} emptyText={tr('pos.boolean.false')} />;
};

SecuritySingleSessionField.defaultProps = {
  source: 'security.singleSession',
  addLabel: true,
};
