import {ThemeName, Account} from '../types';

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_COMPANY = 'CHANGE_COMPANY';
export const DO_NOTHING = 'DO_NOTHING';
export const CHANGE_ACCOUNT = 'CHANGE_ACCOUNT';

export const changeTheme = (theme: ThemeName) => ({
  type: CHANGE_THEME,
  payload: theme,
});

export const changeCompany = (company: string | null) => ({
  type: CHANGE_COMPANY,
  payload: company,
});

export const changeAccount = (account: Account | null) => ({
  type: CHANGE_ACCOUNT,
  payload: account,
});
