import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {useLogin, useNotify} from 'ra-core';
import {useState} from 'react';
import {
  fetchEnd,
  fetchStart,
  Notification,
  showNotification,
} from 'react-admin';
import {useDispatch} from 'react-redux';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {authProvider} from '../dataProvider';
import LoginForm from './LoginForm';
import PasswordForm from './PasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import {darkTheme} from './themes';
import ValidateEmailForm from './ValidateEmailForm';

const useStyle = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));

const SCREEN_LOGIN = 'login';
const SCREEN_FORGOT_PASSWORD = 'forgot_password';
const SCREEN_EMAIL_NOT_VERIFIED = 'email_not_verified';
const SCREEN_RESET_PASSWORD = 'reset_password';

const Login = (props: any) => {
  const classes = useStyle(props);
  const options =
    props.location && props.location.search
      ? queryString.parse(props.location.search)
      : null;
  let initialScreen = SCREEN_LOGIN;
  let initialAccessToken = null;

  const dispatch = useDispatch();
  if (
    options &&
    options.resetPassword === 'true' &&
    options.accessToken &&
    !options.verify
  ) {
    initialScreen = SCREEN_RESET_PASSWORD;
    initialAccessToken = options.accessToken;
  } else if (
    options &&
    options.verify === 'true' &&
    options.uid &&
    options.token
  ) {
    const request = new Request(
      `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/confirm?uid=${options.uid}&token=${options.token}`,
      {
        method: 'GET',
      },
    );
    dispatch(fetchStart());
    fetch(request)
      .then(response => {
        if (response.status === 204) return {}; //this happens when the request was successful
        return response.json();
      })
      .then((json: any) => {
        if (json.data && json.data.error) {
          dispatch(
            showNotification(
              json.data.error.code || 'Could not verify email',
              'warning',
            ),
          );
        } else {
          dispatch(showNotification('Email verified, you can now log in'));
        }
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  }
  const [screen, setScreen] = useState(initialScreen);
  const [accessToken] = useState(initialAccessToken);
  const [authError, setAuthError] = useState(null);
  const login = useLogin();
  const notify = useNotify();

  const doLogin = (auth: any) => {
    login(
      auth,
      props.location.state ? props.location.state.nextPathname : '/',
    ).catch(async (error: Error) => {
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        'warning',
      );
      const lastError = await authProvider.getLastError();
      setAuthError(lastError);
      if (error.message === 'LOGIN_FAILED_EMAIL_NOT_VERIFIED') {
        setScreen(SCREEN_EMAIL_NOT_VERIFIED);
      }
    });
  };

  const onBackButtonClicked = () => {
    setScreen(SCREEN_LOGIN);
  };

  const resetPassword = (data: any) => {
    data.href = window.location.href; //we add current location to be included in the reset password email
    const request = new Request(
      `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/reset`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({'Content-Type': 'application/json'}),
      },
    );
    fetchStart();
    fetch(request)
      .then(response => {
        if (response.status === 204) return {}; //this happens when the request was successful
        return response.json();
      })
      .then((json: any) => {
        if (json.data && json.data.error) {
          if (json.data.error.code === 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
            setScreen(SCREEN_EMAIL_NOT_VERIFIED);
            setAuthError(json.data.error);
          } else {
            onBackButtonClicked();
            dispatch(
              showNotification(
                json.data.error.code || 'Could not reset email',
                'warning',
              ),
            );
          }
        } else {
          onBackButtonClicked();
          dispatch(
            showNotification('Reset password e-mail sent, check your inbox'),
          );
        }
      })
      .catch(() => {
        dispatch(showNotification('Could not reset password', 'warning'));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  const setNewPassword = (data: any) => {
    delete data.password2;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('authorization', accessToken as string);
    const request = new Request(
      `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/resetPassword`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
      },
    );
    dispatch(fetchStart());
    fetch(request)
      .then(response => {
        if (response.status === 204) return {}; //this happens when the request was successful
        return response.json();
      })
      .then((json: any) => {
        if (json.data && json.data.error) {
          dispatch(
            showNotification(
              json.data.error.code || 'Could not change password',
              'warning',
            ),
          );
        } else {
          setScreen(SCREEN_LOGIN);
          dispatch(showNotification('Password changed'));
        }
      })
      .catch(() => {
        dispatch(showNotification('Could not change password', 'warning'));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  const verifyEmail = (data: any) => {
    if (authError === null) return;
    const details = (authError as any)!.details;
    if (details === null) return;
    data.id = details.userId;
    data.href = window.location.href;
    const request = new Request(
      `${BACKBONE_REST_API_V1_URL}/${ENTITY_COLLECTIONS.user}/verify`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({'Content-Type': 'application/json'}),
      },
    );
    dispatch(fetchStart());
    fetch(request)
      .then(response => {
        if (response.status === 204) return {}; //this happens when the request was successful
        return response.json();
      })
      .then(async (json: any) => {
        if (json.data && json.data.error) {
          dispatch(
            showNotification(
              json.data.error.code || 'Could not send verification email',
              'warning',
            ),
          );
        } else {
          await authProvider.clearLastError();
          setScreen(SCREEN_LOGIN);
          dispatch(showNotification('Verification email sent'));
        }
      })
      .catch(() => {
        dispatch(
          showNotification('Could not send verification email', 'warning'),
        );
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.avatar}>
          <Avatar className={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        {screen === SCREEN_LOGIN && (
          <LoginForm
            onSubmit={doLogin}
            handleForgotPassword={() => setScreen(SCREEN_FORGOT_PASSWORD)}
          />
        )}
        {screen === SCREEN_FORGOT_PASSWORD && (
          <PasswordForm
            onSubmit={resetPassword}
            handleGoBack={onBackButtonClicked}
          />
        )}
        {screen === SCREEN_EMAIL_NOT_VERIFIED && (
          <ValidateEmailForm
            onSubmit={verifyEmail}
            handleGoBack={onBackButtonClicked}
          />
        )}
        {screen === SCREEN_RESET_PASSWORD && (
          <ResetPasswordForm
            onSubmit={setNewPassword}
            handleGoBack={onBackButtonClicked}
          />
        )}
        <div style={{height: 10}} />
      </Card>
      <Notification />
    </div>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(darkTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
