import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import {usePermissions} from 'ra-core';
import {FC, useEffect, useState} from 'react';
import {BACKBONE_REST_API_V1_URL} from '../app-constants';
import {storage} from '../dataProvider';

const useStyle = makeStyles({
  main: {
    width: '100%',
  },
  title: {
    textAlign: 'right',
  },
  count: {
    fontSize: '3em',
    fontStyle: 'bold',
  },
  root: {
    width: '100%',
    maxWidth: 360,
  },
  canvas: {
    paddingBottom: -20,
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
});

export interface UniqueCountStatsProps {
  filter?: any;
}

const UniqueCountStats: FC<UniqueCountStatsProps> = props => {
  const {loaded, permissions} = usePermissions();
  const [loading, setLoading] = useState(false);
  const filter = props.filter || null;
  const account = filter && filter.account ? filter.account : null;
  const [count, setCount] = useState<string | undefined>(undefined);
  const classes = useStyle();
  const start = new Date();
  //default to last 30 days
  start.setDate(
    start.getDate() -
      (loaded && permissions[0].hasMainRole() && !filter ? 30 : 7),
  );
  const title =
    'Unique devices (last ' +
    (loaded && permissions[0].hasMainRole() && !filter ? 30 : 7) +
    ' days)';

  const fetchData = async () => {
    if (!loaded) return;
    setLoading(true);
    const url = BACKBONE_REST_API_V1_URL;
    const headers = new Headers({
      'Content-Type': 'application/json',
      cache: 'no-cache',
      authorization: storage.load('lbtoken').id,
    });
    const index =
      permissions[0].hasMainRole() && !filter ? 'global_checkin' : 'checkin*';
    const options: any = {index: index, since: start.getTime()};
    if (filter || !permissions[0].hasMainRole()) {
      options.field = 'device_id';
    }
    if (filter) {
      options.filter = filter;
    }
    const type =
      permissions[0].hasMainRole() && !filter ? 'count' : 'unique_count';
    const opts = encodeURIComponent(JSON.stringify(options));
    const request = new Request(
      `${url}/${ENTITY_COLLECTIONS.account}/stats?type=${type}&options=${opts}`,
      {
        method: 'GET',
        headers: headers,
      },
    );
    await fetch(request)
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.value && typeof data.value === 'string') setCount(data.value);
        else if (typeof data.value === 'number')
          setCount(data.value.toString());
        else setCount('error, no data');
      })
      .catch(() => {
        setCount('could not get data');
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
    // FIXME: Find what's going on here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded && account]); // last parameter is to limit the number of fetches

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="h4" component="h2">
            <>{count !== undefined && count}</>
          </Typography>
        )}
      </Card>
    </div>
  );
};

export default UniqueCountStats;
