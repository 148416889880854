import {ENTITY_COLLECTIONS} from '@idviu/backbone-api-client';
import {Code, Settings} from '@material-ui/icons';
import {FC} from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceArrayField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  usePermissions,
} from 'react-admin';
import {EnumField, MetadataField, useCurrentAccount} from '../components';

export const VariantShow: FC = props => {
  const account = useCurrentAccount();
  const {loaded, permissions} = usePermissions();
  const canModify = loaded && permissions[0].canModifyAccountSettings(account);
  return (
    <Show {...props} hasEdit={canModify}>
      <TabbedShowLayout>
        <Tab label="resources.contents.tabs.general" icon={<Settings />}>
          <TextField source="id" />
          <EnumField source="type" labelPath="resources.variantTypes" />
          <DateField source="created" showTime={true} />
          <DateField source="modified" showTime={true} />
          <ReferenceArrayField
            source="output"
            reference={ENTITY_COLLECTIONS.outputFile}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <EnumField
                source="format"
                labelPath="resources.outputFileFormats"
              />
              <NumberField source="width" />
              <NumberField source="height" />
              <TextField source="created" />
              <TextField source="modified" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="pos.fields.metadata" icon={<Code />}>
          <MetadataField source="metadata" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
