import React from 'react';
import Typography from '@material-ui/core/Typography';

const User = props => {
  const {record} = props;
  let display;
  if (record) {
    if (record.name) display = record.name;
    else if (record.username) display = record.username;
    else display = 'user #' + record.id;
  }

  return (
    <Typography variant="body1" component="div">
      {display}
    </Typography>
  );
};

export default User;
