import {EntityType} from '@idviu/backbone-api-client';
import {sanitizeListRestProps, useListContext, usePermissions} from 'ra-core';
import {cloneElement, FC} from 'react';
import {
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  ListProps,
  TextField,
  TopToolbar,
} from 'react-admin';
import {
  HelpCard,
  List,
  PleaseSelectAccount,
  useCurrentAccount,
} from '../components';

const CatalogActions = (props: any) => {
  const {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    className,
    exporter,
    filters,
    maxResults,
    /* eslint-enable @typescript-eslint/no-unused-vars */
    ...rest
  } = props;
  const {resource, displayedFilters, filterValues, basePath, showFilter} =
    useListContext();
  return (
    <div style={{width: '100%', marginBottom: '1em'}}>
      <TopToolbar {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
        <CreateButton
          variant="contained"
          basePath={basePath}
          label="pos.button.create"
        />
      </TopToolbar>
      <HelpCard reference="catalog" text="resources.catalogs.help" />
    </div>
  );
};

const CatalogList: FC<ListProps> = props => {
  const {loaded, permissions} = usePermissions();
  const account = useCurrentAccount();
  return (
    <>
      {account ? (
        <List
          {...props}
          perPage={25}
          canCreate={true}
          canDelete={false}
          filter={{
            or: [
              {principalType: EntityType.account, principalId: account.id},
              {
                principalType: EntityType.company,
                principalId: account.companyId,
              },
            ],
          }}
          actions={<CatalogActions />}
          sort={{field: 'name', order: 'ASC'}}
        >
          <Datagrid>
            <TextField source="name" />
            <TextField source="redemptionCode" />
            {/*<ShowButton /> we will add this back when we can retrieve catalog content from api server*/}
            {loaded && permissions[0].canModifyAccountSettings(account) && (
              <EditButton label="pos.button.edit" />
            )}
            {loaded && permissions[0].canModifyAccountSettings(account) && (
              <DeleteButton undoable={false} />
            )}
          </Datagrid>
        </List>
      ) : (
        <PleaseSelectAccount />
      )}
    </>
  );
};

export default CatalogList;
